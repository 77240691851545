import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IconButton from '../../components/atoms/IconButton/IconButton'
import Icon from '../../components/atoms/Icon/Icon'

export default class LeftAsideLayout extends PureComponent {
  static propTypes = {
    aside: PropTypes.node,
    content: PropTypes.node,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  handleMenuClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { aside, content } = this.props

    return (
      <div className="layout left-aside-layout">
        <aside className={classnames(this.state.open && 'open')}>
          {aside}
          <div className="close-button">
            <IconButton
              icon={Icon.icon.Close2}
              color={Icon.color.White}
              onClick={this.handleMenuClick}
            />
          </div>
        </aside>
        <div className="burger-menu">
          <IconButton
            icon={Icon.icon.BurgerMenu}
            color={Icon.color.Accent}
            onClick={this.handleMenuClick}
          />
        </div>
        <div className={classnames('overlay', { open: this.state.open })} />
        <section>{content}</section>
      </div>
    )
  }
}
