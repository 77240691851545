import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../containers/global/Translator/Translator'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import Page from '../../containers/global/Page/Page'

import LineMenu from '../../components/organisms/LineMenu/LineMenu'

import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import SearchField from '../../components/molecules/SearchField/SearchField'

import {
  selectors as ImpersonateSelectors,
  actions as ImpersonateActions,
} from '../../redux/ImpersonateRedux'
import ImpersonateUserRow from '../../components/molecules/ImpersonateUserRow/ImpersonateUserRow'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state) => ({
  impersonateUsers: ImpersonateSelectors.impersonateUsers(state),
})

const mapDispatchToProps = (dispatch) => ({
  searchImpersonateUsers: (query) => dispatch(ImpersonateActions.searchImpersonateUsers(query)),
  impersonate: (userId) => dispatch(ImpersonateActions.impersonate(userId)),
  resetImpersonateUsers: () => dispatch(ImpersonateActions.resetImpersonateUsers()),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminImpersonatePage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    search: '',
  }

  componentWillUnmount() {
    this.props.resetImpersonateUsers()
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'impersonate', label: t('impersonate.impersonate') }]

    return <LineMenu value={links[0].id} links={links} />
  }

  handleSearchChange = ({ value }) => {
    this.setState({
      search: value,
    })
  }

  handleSearchClick = () => {
    const { searchImpersonateUsers } = this.props
    searchImpersonateUsers(this.state.search)
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()

    this.handleSearchClick()
  }

  renderSearchField = () => {
    const { t } = this.props

    return (
      <form noValidate className="searchWrapper" onSubmit={this.handleSearchSubmit}>
        <SearchField
          showLine
          reversed
          allowReset={false}
          placeholder={t('impersonate.user_search')}
          onChange={this.handleSearchChange}
          value={this.state.search}
        />
        <PrimaryButton
          type="submit"
          label={t('impersonate.search')}
          id="search"
          onClick={this.handleSearchClick}
        />
      </form>
    )
  }

  handleImpersonate = ({ id }) => {
    const { impersonate } = this.props
    impersonate(id)
  }

  renderUsers = () => {
    const { impersonateUsers, t } = this.props

    let usersNodes = []

    if (impersonateUsers !== null) {
      usersNodes = impersonateUsers.map((user) => {
        const { id, role, first_name, last_name } = user

        return (
          <ImpersonateUserRow
            key={id}
            id={id.toString()}
            name={`${first_name} ${last_name} (id: ${id})`}
            roleLabel={t(`user_roles.${role}`)}
            labelButton={t('impersonate.impersonate_action')}
            onClick={this.handleImpersonate}
          />
        )
      })
    }

    return <div className="usersWrapper">{usersNodes}</div>
  }

  renderContent = () => {
    return (
      <div>
        {this.renderSearchField()}
        {this.renderUsers()}
      </div>
    )
  }

  render() {
    const aside = <AdminPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="admin-impersonate-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
