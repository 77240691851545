import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withI18n } from '../../global/Translator/Translator'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import {
  needToFillFinancerInfo,
  waitingCreationValidation,
  waitingPersonnalInfoValidation,
} from '../../../helpers/beneficiaryStatus'
import InfoPage from '../../../components/molecules/InfoPage/InfoPage'
import BeneficiaryProfileForm from './BeneficiaryProfileForm'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

@withI18n
@connect(mapStateToProps, null)
export default class BeneficiaryProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: null,
  }

  renderStatusMessage = () => {
    const { t, user } = this.props
    let message = null

    if (waitingCreationValidation(user)) {
      message = t('beneficiary.waiting_creation_validation')
    }

    if (needToFillFinancerInfo(user)) {
      message = t('beneficiary.need_to_fill_financer_info')
    }

    if (waitingPersonnalInfoValidation(user)) {
      message = t('beneficiary.waiting_personnal_info_validation')
    }

    if (message !== null) {
      return <InfoPage text={message} />
    }

    return null
  }

  handleOnChange = (value) => {
    const { onChange } = this.props
    if (onChange !== null) {
      onChange(value)
    }
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    return (
      <div className="beneficiary-profile">
        {this.renderStatusMessage()}
        <BeneficiaryProfileForm user={user} onProfileChange={this.handleOnChange} />
      </div>
    )
  }
}
