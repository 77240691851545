import {
  hasFollowUpStatus,
  inProgress,
  isArchived,
  waitingPersonnalInfoValidation,
} from './beneficiaryStatus'

export const profileFieldsInCreation = ['email', 'first_name', 'last_name', 'consultant_id']

export const profileFields = [
  'gender',
  'email',
  'first_name',
  'last_name',
  'birth_date',
  'phone_mobile',
  'address',
  'zip_code',
  'city',
  'cursus_professional_situation',
  'cursus_formation',
  'cursus_socio_professional_category',
  'cursus_function',
  'cursus_company_activity_sector',
]

export const profileFieldsForConsultant = [
  ...profileFields,
  'accompaniment_start_date',
  'accompaniment_end_date',
]

export const getValidationRules = (user, values, isConsultant = false, inCreation = false) => {
  if (user === null) return {}

  let rules = {
    email: { rule: 'required|email' },
    last_name: { rule: 'required' },
    first_name: { rule: 'required' },
  }
  if (isConsultant && inCreation) return rules

  if (inCreation) {
    return {
      ...rules,
      consultant_id: { rule: 'required|int' },
    }
  }

  rules = {
    ...rules,
    gender: { rule: 'required' },
    phone_mobile: { rule: 'required' },
    cursus_professional_situation: { rule: 'required' },
  }

  if (
    waitingPersonnalInfoValidation(user) ||
    inProgress(user) ||
    hasFollowUpStatus(user) ||
    isArchived(user)
  ) {
    rules = {
      ...rules,
      birth_date: { rule: 'dateWithFormat:DD/MM/YYYY' },
      cursus_formation: { rule: 'required' },
    }
  }

  if (
    user.cursus_professional_situation === 'cdd' ||
    user.cursus_professional_situation === 'cdi'
  ) {
    if (
      waitingPersonnalInfoValidation(user) ||
      inProgress(user) ||
      hasFollowUpStatus(user) ||
      isArchived(user)
    ) {
      rules = {
        ...rules,
        cursus_function: { rule: 'required' },
      }
    }
  }

  if (isConsultant) {
    rules = {
      ...rules,
      accompaniment_start_date: { rule: 'dateWithFormat:DD/MM/YYYY' },
      accompaniment_end_date: {
        rule: `dateWithFormat:DD/MM/YYYY|dateAfter:DD/MM/YYYY:${values.accompaniment_start_date}`,
      },
    }
  }

  return rules
}
