import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Droppable as DroppableContainer } from 'react-beautiful-dnd'

export default class Droppable extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
    isDropDisabled: PropTypes.bool,
    type: PropTypes.string,
    props: PropTypes.object,
    direction: PropTypes.oneOf(['vertical', 'horizontal']),
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    isDropDisabled: false,
    props: {},
    disabled: false,
  }

  render() {
    const { className, children, id, isDropDisabled, type, props, direction, disabled } = this.props
    const droppableId = JSON.stringify({ id, ...props })

    if (disabled) {
      return children
    }

    return (
      <DroppableContainer
        droppableId={droppableId}
        isDropDisabled={isDropDisabled}
        type={type}
        direction={direction}
      >
        {({ innerRef, placeholder }, { isDraggingOver }) => (
          <div
            className={classnames(className, 'droppable', { 'dragging-over': isDraggingOver })}
            ref={innerRef}
          >
            {children}
            {placeholder}
          </div>
        )}
      </DroppableContainer>
    )
  }
}
