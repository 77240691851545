import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'
import _isEqual from 'lodash/isEqual'

//
// Initial state
//
const initialState = Map({
  consultants: [],
  officeConsultants: [],
  consultant: null,
  consultant_: null,
  syncInProgress: false,
  beneficiariesFilter: {
    status: 'in_progress',
    q: '',
  },
})

//
// Actions
//
export const actions = {
  getConsultants: createAction('getConsultants'),
  getOfficeConsultants: createAction('getOfficeConsultants'),
  removeConsultant: createAction('removeConsultant', (id) => ({ id })),
  activateConsultant: createAction('activateConsultant', (id) => ({ id })),
  archiveConsultant: createAction('archiveConsultant', (id) => ({ id })),
  getConsultant: createAction('getConsultant', (id) => ({ id })),
  saveConsultant: createAction('saveConsultant', (consultant, isCurrent) => ({
    consultant,
    isCurrent,
  })),
  setConsultant: createAction('setConsultant', (consultant) => ({ consultant })),
  setBeneficiariesFilter: createAction('setBeneficiariesFilter', (beneficiariesFilter) => ({
    beneficiariesFilter,
  })),
  setConsultants: createAction('setConsultants', (consultants) => ({
    consultants: _mapKeys(consultants, 'id'),
  })),
  setOfficeConsultants: createAction('setOfficeConsultants', (officeConsultants) => ({
    officeConsultants: _mapKeys(officeConsultants, 'id'),
  })),
  updateConsultant: createAction('updateConsultant', (consultant) => ({ consultant })),
  removeBeneficiaryAtIndex: createAction('removeBeneficiaryAtIndex', (index) => ({ index })),
  syncCronofy: createAction('syncCronofy', ({ code, user }) => ({ code, consultant: user })),
  setSyncInProgress: createAction('setSyncInProgress'),
  setSyncIsFinished: createAction('setSyncIsFinished'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.removeConsultant]: (state, { id }) => state.deleteIn(['consultants', id]),
    [actions.setConsultant]: (state, { consultant }) =>
      state.merge({ consultant, consultant_: consultant }),
    [actions.setBeneficiariesFilter]: (state, { beneficiariesFilter }) =>
      state.merge({ beneficiariesFilter }),
    [actions.setConsultants]: (state, { consultants }) => state.merge({ consultants }),
    [actions.setOfficeConsultants]: (state, { officeConsultants }) =>
      state.merge({ officeConsultants }),
    [actions.updateConsultant]: (state, { consultant }) =>
      state.mergeIn(['consultant'], consultant),
    [actions.removeBeneficiaryAtIndex]: (state, { index }) =>
      state.setIn(['consultant', 'beneficiaries', index, 'is_primary'], false),
    [actions.setSyncInProgress]: (state) => state.merge({ syncInProgress: true }),
    [actions.setSyncIsFinished]: (state) => state.merge({ syncInProgress: false }),
  },
  initialState,
)

//
// Selectors
//
const consultants = (state) => state.consultant.get('consultants')
const officeConsultants = (state) => state.consultant.get('officeConsultants')
const consultant_ = (state) => state.consultant.get('consultant_')
const consultant = (state) => state.consultant.get('consultant')
const beneficiariesFilter = (state) => state.consultant.get('beneficiariesFilter')
const syncInProgress = (state) => state.consultant.get('syncInProgress')
const beneficiaries = (state) => state.consultant.getIn(['consultant', 'beneficiaries'])
const consultantsArray = createSelector(consultants, Object.values)
const officeConsultantsArray = createSelector(officeConsultants, Object.values)
const hasChanged = createSelector(consultant_, consultant, (a, b) => !_isEqual(a, b))

export const selectors = {
  consultants: consultantsArray,
  officeConsultants: officeConsultantsArray,
  consultant,
  beneficiaries,
  hasChanged,
  syncInProgress,
  beneficiariesFilter,
}
