import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'

import { withI18n } from '../../global/Translator/Translator'

import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../../redux/BeneficiaryRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'

import Icon from '../../../components/atoms/Icon/Icon'
import ActionPopinHeader from '../../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import PopinLayout from '../../../layouts/PopinLayout/PopinLayout'
import RightAsideLayout from '../../../layouts/RightAsideLayout/RightAsideLayout'
import TabbedContentLayout from '../../../layouts/TabbedContentLayout/TabbedContentLayout'
import LineMenu from '../../../components/organisms/LineMenu/LineMenu'
import ComboField from '../../../components/molecules/ComboField/ComboField'
import CircleButton from '../../../components/atoms/CircleButton/CircleButton'
import CourseEditDragDropContext from '../../course/CourseEditDragDropContext/CourseEditDragDropContext'
import BeneficiaryArchivedReasonPopin from '../BeneficiaryArchivedReasonPopin/BeneficiaryArchivedReasonPopin'
import ConsultantShareBeneficiary from '../ConsultantShareBeneficiary/ConsultantShareBeneficiary'
import AvatarCircle from '../../../components/atoms/AvatarCircle/AvatarCircle'
import { history } from '../../../navigation/History'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  cvs: BeneficiarySelectors.cvs(state),
  maestros: BeneficiarySelectors.maestros(state),
  template: CourseTemplateSelectors.template(state),
})

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  setBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.setBeneficiary(beneficiary)),
  onBack: (disableSave) => dispatch(BeneficiaryActions.onBack(disableSave)),
  setTemplateSteps: (steps) => dispatch(CourseTemplateActions.setTemplateSteps(steps)),
  setTemplate: (template) => dispatch(CourseTemplateActions.setTemplate(template)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantBeneficiary extends PureComponent {
  static defaultProps = {
    children: null,
    disableSave: true,
    onSave: () => undefined,
  }

  static propTypes = {
    t: PropTypes.func,
    children: PropTypes.node,
    renderAside: PropTypes.func,
    onSave: PropTypes.func,
    onBack: PropTypes.func,
    disableSave: PropTypes.bool,
  }

  state = {
    openSharingPopin: false,
    openArchivedPopin: false,
    unblock: null,
    backIsClicked: false,
  }

  componentDidMount() {
    const unblock = history.block(() => {
      if (this.state.backIsClicked && !this.props.disableSave) {
        return 'true'
      }
    })

    this.setState({
      unblock,
    })
  }

  componentWillUnmount() {
    if (this.state.unblock !== null) {
      this.state.unblock()
    }
    if (this.state.backIsClicked && this.props.template !== null) {
      this.props.setTemplate(null)
    }
  }

  isPrimary = () => {
    const { user, beneficiary } = this.props

    if (!beneficiary.id) {
      return true
    }

    if (user.role === 'manager') {
      return true
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    )
  }

  getArchivedReasonsOptions = () => {
    const { t } = this.props
    const beneficiaryArchivedReasons = t('beneficiary_archived_reasons')

    return Object.keys(beneficiaryArchivedReasons).map((key) => ({
      value: key,
      label: beneficiaryArchivedReasons[key],
    }))
  }

  handleBack = () => {
    const { onBack, disableSave } = this.props
    this.setState(
      {
        backIsClicked: true,
      },
      () => {
        onBack(disableSave)
      },
    )
  }

  handleBeneficiaryArchivedReasonChange = ({ value }) => {
    const newBeneficiary = {
      ...this.props.beneficiary,
      archived_reason: value,
    }

    this.props.setBeneficiary(newBeneficiary)
  }

  handleBeneficiaryStatusChange = ({ value }) => {
    let archivedReason = {}
    if (value === 'archived') {
      archivedReason = {
        archived_reason: this.getArchivedReasonsOptions()[0].value,
      }

      this.setState({
        openArchivedPopin: true,
      })
    }

    const newBeneficiary = {
      ...this.props.beneficiary,
      status: value,
      ...archivedReason,
    }

    this.props.setBeneficiary(newBeneficiary)
  }

  handleCloseArchivedReasonPopin = () => {
    this.setState({
      openArchivedPopin: false,
    })
  }

  handleOrderModulesChange = (index, modules) => {
    const { template } = this.props
    const steps = template.steps.slice(0)

    steps[index] = { ...steps[index], modules }

    this.props.setTemplateSteps(steps)
  }

  handleOrderStepsChange = (steps) => {
    this.props.setTemplateSteps(steps)
  }

  handleSave = () => {
    this.props.onSave()
  }

  handleToggleSharingPopin = (open = null) => {
    let newOpenSharingPopin = open
    if (newOpenSharingPopin === null) {
      newOpenSharingPopin = !this.state.openSharingPopin
    }
    this.setState({
      openSharingPopin: newOpenSharingPopin,
    })
  }

  handleTabChange = (tabId) => {
    const { pathname, redirect } = this.props

    if (/\/courses/.test(pathname) && tabId !== 'courses') {
      redirect(pathname.replace(/(\/courses.*)/, `/${tabId}`))
      return
    }
    let current = pathname.substring(pathname.lastIndexOf('/') + 1)
    if (tabId !== current) {
      redirect(pathname.replace(`/${current}`, `/${tabId}`))
    }
  }

  renderContent = () => {
    const { children } = this.props

    return <TabbedContentLayout header={this.renderTabs()} content={children} />
  }

  renderHeader = () => {
    const { t, beneficiary, disableSave, pathname } = this.props
    const { status, first_name, last_name } = beneficiary

    const beneficiaryStatus = status || ''

    const statuses = t('beneficiary.statuses')
    const options = Object.keys(statuses).map((key) => ({
      value: key,
      label: statuses[key],
    }))

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        lightTitle={
          beneficiaryStatus !== '' ? t('beneficiary.beneficiary') : t('beneficiary.new_beneficiary')
        }
        boldTitle={beneficiaryStatus !== '' ? `${first_name} ${last_name}` : ''}
        onIconClick={this.handleBack}
      >
        <PrimaryButton
          id="save"
          label={t('actions.save')}
          disabled={disableSave}
          onClick={this.handleSave}
        />

        <div className="statuses_combo">
          <ComboField
            name="status"
            title={t('beneficiary.status_label')}
            options={options}
            onChange={this.handleBeneficiaryStatusChange}
            value={beneficiaryStatus || 'created'}
            disabled={!this.isPrimary() || beneficiaryStatus === '' || /\/courses/.test(pathname)}
          />
        </div>
      </ActionPopinHeader>
    )
  }

  renderShareComponent = () => {
    const { user, beneficiary } = this.props

    if (!this.isPrimary() || user.role === 'manager') {
      return null
    }

    if (!beneficiary?.id) {
      return null
    }

    return (
      <div className="share-container">
        {this.renderSharedConsultants()}
        <CircleButton icon={Icon.icon.Share} onClick={this.handleToggleSharingPopin} />
      </div>
    )
  }

  renderSharedConsultants = () => {
    const { beneficiary } = this.props

    if (!beneficiary.id) {
      return null
    }

    const consultantsNodes = beneficiary.consultants.map((consultant, i) => (
      <div key={i} className="avatar-wrapper" style={{ zIndex: i }}>
        <AvatarCircle photoUrl={consultant.image_url} />
      </div>
    ))

    return <div className="consultants">{consultantsNodes}</div>
  }

  renderSharingPopin = () => {
    const open = !!(this.state.openSharingPopin && this.props.beneficiary)

    return (
      <ConsultantShareBeneficiary
        open={open}
        onClose={() => this.handleToggleSharingPopin(false)}
      />
    )
  }

  renderArchivedReasonPopin() {
    return (
      <BeneficiaryArchivedReasonPopin
        onClose={this.handleCloseArchivedReasonPopin}
        open={this.state.openArchivedPopin}
        onReasonChange={this.handleBeneficiaryArchivedReasonChange}
        onValidate={this.handleSave}
        reasonsOptions={this.getArchivedReasonsOptions()}
      />
    )
  }

  renderTabs = () => {
    const { t, pathname, beneficiary, cvs, maestros } = this.props

    if (!beneficiary?.id) {
      return null
    }

    let links = [
      {
        id: 'courses',
        label: t('consultant.enrolled_course'),
      },
      {
        id: 'profile',
        label: t('consultant.personal_informations'),
        sublabel: t('consultant.last_edition_date', {
          date: moment(beneficiary.updated_at).format('DD/MM/YYYY HH:MM'),
        }),
      },
      {
        id: 'assessments',
        label: 'Central Test',
      },
    ]

    if (cvs && cvs.length > 0) {
      links.push({
        id: 'cvs',
        label: t('consultant.cvs'),
      })
    }

    if (maestros && maestros.length > 0) {
      links.push({
        id: 'maestros',
        label: t('consultant.maestros'),
      })
    }

    let current = pathname.substring(pathname.lastIndexOf('/') + 1)
    if (/\/courses/.test(pathname)) {
      current = 'courses'
    }

    return (
      <LineMenu
        value={current}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderShareComponent()}
        withSublabel
      />
    )
  }

  render() {
    const { renderAside } = this.props
    const header = this.renderHeader()
    const content = (
      <CourseEditDragDropContext
        onChangeSteps={this.handleOrderStepsChange}
        onChangeModules={this.handleOrderModulesChange}
      >
        <RightAsideLayout content={this.renderContent()} aside={renderAside && renderAside()} />
      </CourseEditDragDropContext>
    )

    return (
      <div className="consultant-beneficiary">
        <PopinLayout header={header} content={content} />

        {this.renderSharingPopin()}
        {this.renderArchivedReasonPopin()}
      </div>
    )
  }
}
