import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputTagField from '../../molecules/InputTagField/InputTagField'
import InputField from '../../molecules/InputField/InputField'
import TextAreaField from '../../molecules/TextAreaField/TextAreaField'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import ClosePopin from '../ClosePopin/ClosePopin'

export default class MessagePopin extends Component {
  static propTypes = {
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ).isRequired,
    content: PropTypes.shape({
      recipients: PropTypes.array,
      subject: PropTypes.string,
      message: PropTypes.string,
    }),
    locale: PropTypes.shape({
      recipientTitle: PropTypes.string.isRequired,
      subjectTitle: PropTypes.string.isRequired,
      messageTitle: PropTypes.string.isRequired,
      send: PropTypes.string.isRequired,
      recipientPlaceholder: PropTypes.string.isRequired,
      subjectPlaceholder: PropTypes.string.isRequired,
      messagePlaceholder: PropTypes.string.isRequired,
    }).isRequired,
    isBeneficiary: PropTypes.bool,
    onClosePopin: PropTypes.func,
    onChangeRecipients: PropTypes.func,
    onChangeSubject: PropTypes.func.isRequired,
    onChangeMessage: PropTypes.func.isRequired,
    onClickSend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isBeneficiary: false,
    content: {},
    onClosePopin: () => undefined,
    onChangeRecipients: ({ values }) => undefined,
  }

  onClosePopin = () => {
    this.props.onClosePopin()
  }

  render() {
    return <div className="message-popin">{this.renderPopin()}</div>
  }

  renderPopin() {
    const {
      onClickSend,
      onChangeRecipients,
      onChangeSubject,
      onChangeMessage,
      isEnabled,
      isBeneficiary,
      content,
      contacts,
      locale,
    } = this.props
    const { recipients, subject, message } = content
    const {
      recipientPlaceholder,
      subjectPlaceholder,
      messagePlaceholder,
      recipientTitle,
      subjectTitle,
      messageTitle,
    } = locale
    const disabled = [recipients, subject, message].some((value) => !value || !value.length)

    return (
      <ClosePopin onClose={this.onClosePopin} isEnabled={isEnabled}>
        <div className="message-popin-content">
          {isBeneficiary && (
            <InputTagField
              name="mail-to"
              values={recipients}
              options={contacts}
              onChange={onChangeRecipients}
              title={recipientTitle}
              placeholder={recipientPlaceholder}
              required
              readOnly
            />
          )}

          {!isBeneficiary && (
            <InputTagField
              name="mail-to"
              values={recipients}
              options={contacts}
              onChange={onChangeRecipients}
              title={recipientTitle}
              placeholder={recipientPlaceholder}
              required
            />
          )}

          <InputField
            placeholder={subjectPlaceholder}
            value={subject}
            onChange={onChangeSubject}
            title={subjectTitle}
            name="subject"
            required
          />

          <TextAreaField
            onChange={onChangeMessage}
            title={messageTitle}
            placeholder={messagePlaceholder}
            name="message"
            value={message}
            required
          />

          <div className="btn-send-wrapper">
            <PrimaryButton
              id="btn-send"
              label={locale.send}
              onClick={onClickSend}
              disabled={disabled}
            />
          </div>
        </div>
      </ClosePopin>
    )
  }
}
