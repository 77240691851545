import { call, put, select } from 'redux-saga/effects'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../redux/CourseTemplateRedux'
import { actions as ModuleTemplateActions } from '../redux/ModuleTemplateRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'

import { services } from '../services'

export function* getTemplates({ payload }) {
  const { filter } = payload

  yield call(services.moduleTemplate.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(ModuleTemplateActions.setTemplates(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getTemplate({ payload }) {
  const { id } = payload

  yield call(services.moduleTemplate.get, { id }, function* ({ ok, data }) {
    ok
      ? yield put(ModuleTemplateActions.setTemplate(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveTemplate({ payload }) {
  const { template } = payload
  const { stepIndex, moduleIndex } = template

  yield call(services.moduleTemplate.save, template, function* ({ ok, data }) {
    if (ok) {
      yield put(ModuleTemplateActions.setTemplate({ ...data, stepIndex, moduleIndex }))
      yield put(ModuleTemplateActions.addTemplate(data))

      const courseTemplate = yield select(CourseTemplateSelectors.template)

      if (courseTemplate) {
        courseTemplate.steps[stepIndex].modules[moduleIndex] = data
        yield put(CourseTemplateActions.setTemplate(courseTemplate))
      }
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeTemplate({ payload }) {
  const { id } = payload
  const result = yield call(services.moduleTemplate.remove, { id })

  yield put(NotificationActions.status(result))
}
