import { call, put } from 'redux-saga/effects'

import { actions as ImpersonateActions } from '../redux/ImpersonateRedux'
import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as UserActions } from '../redux/UserRedux'
import { actions as NavigationActions } from '../redux/NavigationRedux'

export function* searchImpersonateUsers({ payload }) {
  const { search } = payload

  yield call(services.user.searchUsers, { query: search }, function* ({ ok, data }) {
    ok
      ? yield put(ImpersonateActions.setImpersonateUsers(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* simulateLogin({ token, user }) {
  yield put(UserActions.setUser(user))
  yield put(UserActions.setToken(token))
  yield put(NavigationActions.redirect(`/${user.role}`))
}

export function* impersonate({ payload }) {
  const { userId } = payload

  yield call(services.auth.impersonate, { userId }, function* ({ ok, data }) {
    ok ? yield call(simulateLogin, data) : yield put(NotificationActions.status({ ok, data }))
  })
}
