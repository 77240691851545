import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class UserLeftColumnLayout extends PureComponent {
  static propTypes = {
    header: PropTypes.node,
    menu: PropTypes.node,
    footer: PropTypes.node,
  }

  render() {
    const { header, menu, footer } = this.props

    return (
      <aside className="layout user-left-column-layout">
        <header>{header}</header>
        <section>{menu}</section>
        <footer>{footer}</footer>
      </aside>
    )
  }
}
