import FineUploaderTraditional from 'fine-uploader-wrappers'
import AppConfig from '../config/AppConfig'

export const createUploader = (startCallback, resolveCallback) =>
  new FineUploaderTraditional({
    options: {
      callbacks: {
        onUpload() {
          if (typeof startCallback === 'function') {
            startCallback()
          }
        },
        onComplete() {
          if (typeof resolveCallback === 'function') {
            resolveCallback()
          }
        },
      },
      autoUpload: false,
      validation: {
        sizeLimit: parseInt(AppConfig.API_MAX_FILE_SIZE, 10),
      },
      request: {
        endpoint: `${AppConfig.API_URL}/uploads`,
        uuidName: 'uuid',
        filenameParam: 'fileName',
        inputName: 'file',
      },
      chunking: {
        enabled: true,
        partSize: 2 * 1024 * 1024,
        paramNames: {
          partIndex: 'partIndex',
          totalParts: 'totalParts',
        },
      },
    },
  })
