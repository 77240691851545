import { call, put, select } from 'redux-saga/effects'
import { actions as AppointmentActions } from '../redux/AppointmentRedux'
import { actions as CourseActions, selectors as CourseSelectors } from '../redux/CourseRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { services } from '../services'

export function* getAppointments({ payload }) {
  const { filter } = payload

  yield call(services.appointment.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(AppointmentActions.setAppointments(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* exportAppointments({ payload }) {
  const { filter } = payload

  yield call(services.appointment.exportAll, { filter }, function* ({ ok, data }) {
    if (!ok) {
      yield put(NotificationActions.status({ ok, data }))
    } else {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(new Blob([data], { type: 'text/ics' }))

      a.href = url
      a.download = 'MyTalents.ics'
      a.click()
      window.URL.revokeObjectURL(url)
    }
  })
}

export function* postpone({ payload }) {
  const { id, courseId } = payload
  const course = yield select(CourseSelectors.course)

  yield call(services.appointment.postpone, { id }, function* ({ ok, data }) {
    if (ok) {
      if (course || courseId) {
        yield put(CourseActions.getCourse(courseId || course.id))
      }
      yield call(getAppointments, { payload: { filter: { q: '' } } })
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}

export function* confirm({ payload }) {
  const { id, courseId } = payload
  const course = yield select(CourseSelectors.course)

  yield call(services.appointment.confirm, { id }, function* ({ ok, data }) {
    if (ok) {
      if (course || courseId) {
        yield put(CourseActions.getCourse(courseId || course.id))
      }
      yield call(getAppointments, { payload: { filter: { q: '' } } })
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}
