import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PasswordPopin from '../PasswordPopin/PasswordPopin'
import ForgotPasswordForm from '../../../containers/auth/ForgotPasswordForm/ForgotPasswordForm'

export default class ForgotPasswordPopin extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    done: PropTypes.bool,
    disabled: PropTypes.bool,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      infoDescription: PropTypes.string.isRequired,
      sentDescription: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    done: false,
    disabled: false,
    onSubmit: ({ email }) => undefined,
  }

  handleSubmit = (values) => {
    this.props.onSubmit(values)
  }

  render() {
    const { locale, done, disabled } = this.props

    return (
      <PasswordPopin
        onClose={this.props.onClose}
        title={locale.title}
        description={done ? locale.sentDescription : locale.infoDescription}
        done={done}
      >
        <ForgotPasswordForm disabled={disabled} onSubmit={this.handleSubmit} />
      </PasswordPopin>
    )
  }
}
