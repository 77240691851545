import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import ComboField from '../../components/molecules/ComboField/ComboField'
import InfoPage from '../../components/molecules/InfoPage/InfoPage'
import MessageRow from '../../components/molecules/MessageRow/MessageRow'
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import NewMessage from '../../containers/global/NewMessage/NewMessage'
import Page from '../../containers/global/Page/Page'
import Popin from '../../containers/global/Popin/Popin'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import { withI18n } from '../../containers/global/Translator/Translator'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { history } from '../../navigation/History'
import { actions as MessageActions, selectors as MessageSelectors } from '../../redux/MessageRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

const mapStateToProps = (state) => ({
  isNew: NavigationSelectors.lastParam(state) === 'new',
  type: NavigationSelectors.hash(state) || 'inbox',
  user: UserSelectors.user(state),
  basePath: NavigationSelectors.basePath(state),
  pathname: NavigationSelectors.pathname(state),
  messages: MessageSelectors.messages(state),
  unreadMessages: MessageSelectors.unreadMessages(state),
  isBeneficiary: UserSelectors.isBeneficiary(state),
  currentTab: NavigationSelectors.query(state, 'tab') || 'inbox',
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getMessages: (filter) => dispatch(MessageActions.getMessages(filter)),
  removeMessages: (ids, type) => dispatch(MessageActions.removeMessages(ids, type)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class MessagesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    type: PropTypes.string.isRequired,
  }

  static messageTypes = ['inbox', 'sent']

  constructor(props) {
    super(props)

    this.state = {
      state: null,
      q: '',
      selected: [],
      type: props.type,
      showConfirm: false,
      unblock: null,
      touchedMessage: false,
    }
  }

  componentDidMount() {
    const { currentTab } = this.props
    this.getMessages()

    if (this.state.type !== currentTab) {
      this.handleTabChange(currentTab)
    }

    const unblock = history.block(() => {
      if (this.state.touchedMessage) {
        return 'true'
      }
      return null
    })

    this.setState({
      unblock,
    })
  }

  componentWillUnmount() {
    if (this.state.unblock !== null) {
      this.state.unblock()
    }
  }

  getMessages = () => {
    const { type, state, q } = this.state
    const getMessages = this.props.getMessages

    this.handleCloseNewMessagePopin()

    getMessages({
      type,
      state,
      q,
    })
  }

  goToMessage = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  handleSearch = (value) => {
    this.setState({ q: value }, this.getMessages)
  }

  handleTabChange = (type) => {
    this.setState({ type, selected: [] }, this.getMessages)
    this.props.redirect(`${this.props.location.pathname}?tab=${type}`)
  }

  removePopin = () => {
    this.setState({ showConfirm: false })
  }

  renderConfirmClose = () => {
    const { t, removing } = this.props

    return (
      <Popin onClose={this.removePopin} open={this.state.showConfirm}>
        <AlertPopin
          disabled={removing}
          label={
            this.state.selected.length > 1
              ? t('message.remove_multiple_title')
              : t('message.remove_single_title')
          }
          text={
            this.state.selected.length > 1
              ? t('message.remove_multiple_confirmation')
              : t('message.remove_single_confirmation')
          }
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={this.removePopin}
          onConfirmButtonClick={this.handleConfirmRemove}
        />
      </Popin>
    )
  }

  renderTabs = () => {
    const { t, unreadMessages } = this.props
    const links = [
      { id: 'inbox', label: t('message.my_messages'), countIndicator: unreadMessages },
      { id: 'sent', label: t('message.sent_messages') },
    ]

    return (
      <LineMenu
        value={this.state.type}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  handleRemoveClick = () => {
    this.setState({ showConfirm: true })
  }

  handleConfirmRemove = () => {
    this.props.removeMessages(this.state.selected, this.state.type)

    this.setState({ selected: [], showConfirm: false })
  }

  handleOpenNewMessagePopin = () => {
    this.setState({ touchedMessage: false })
    this.props.redirect(`${this.props.pathname}/new`)
  }

  handleCloseNewMessagePopin = () => {
    this.setState({ unblock: null, touchedMessage: false }, () => {
      this.props.redirect(this.props.pathname.replace(/\/new/, '') + this.props.location.search)
    })
  }

  renderActionButtons = () => (
    <div className="action-buttons">
      {MessagesPage.messageTypes.includes(this.state.type) && (
        <CircleButton icon="edit" onClick={this.handleOpenNewMessagePopin} />
      )}

      <CircleButton
        icon="trash"
        onClick={this.handleRemoveClick}
        disabled={this.state.selected.length === 0}
      />
    </div>
  )

  handleComboChange = ({ value }) => {
    this.setState({ state: value === 'all' ? null : value }, this.getMessages)
  }

  renderComboFilter = () => {
    const { t } = this.props
    const filters = t('messages_filter.states')
    const options = Object.keys(filters).map((key) => ({
      value: key,
      label: filters[key],
    }))

    return (
      <>
        {this.state.type === 'inbox' && (
          <ComboField name="filter" options={options} onChange={this.handleComboChange} />
        )}
      </>
    )
  }

  renderLabels = () => {
    const { t } = this.props

    if (this.state.type === 'sent') {
      return (
        <div className="message-labels">
          <p>{t('message.tos_label')}</p>
          <p>{t('message.subject_label')}</p>
        </div>
      )
    }

    return (
      <div className="message-labels">
        <p>{t('message.sender_label')}</p>
        <p>{t('message.subject_label')}</p>
      </div>
    )
  }

  handleTouchedMessage = (recipients, subject, message) => {
    const touchedMessage = !(subject === '' && message === '')
    this.setState({ touchedMessage })
  }

  renderNewMessagePopin = () => {
    const { user } = this.props
    const { beneficiaries } = user

    return (
      <NewMessage
        contacts={beneficiaries}
        isBeneficiary={user.role === 'beneficiary'}
        onClose={this.handleCloseNewMessagePopin}
        handleTouchedMessage={this.handleTouchedMessage}
        open
      />
    )
  }

  handleSelectMessage = (id) => {
    const { selected } = this.state
    const index = selected.indexOf(id)

    if (index === -1) {
      this.setState(({ selected }) => ({ selected: [...selected, id] }))
    } else {
      selected.splice(index, 1)
      this.setState(({ selected }) => ({ selected: [...selected] }))
    }
  }

  checkUser = () => {
    const { user } = this.props

    if (user.role === 'beneficiary') {
      return <BeneficiaryPageAside />
    }
    if (user.role === 'consultant') {
      return <ConsultantPageAside />
    }
    return null
  }

  renderMessageList() {
    const { t, messages } = this.props
    const hasMessages = !!messages.length

    let messagesNodes = (
      <>
        {this.renderLabels()}
        {this.renderMessages()}
      </>
    )

    if (!hasMessages) {
      messagesNodes = <InfoPage text={t('message.is_empty')} />
    }

    return (
      <>
        <div className="buttons-filters">
          {this.renderActionButtons()}
          {this.renderComboFilter()}
        </div>

        {messagesNodes}
      </>
    )
  }

  renderMessages = () => {
    const { t, messages } = this.props

    return messages.map(({ id, user_from, subject, created_at, user_tos }) => {
      let read = false

      let senderValue = `${user_from.first_name} ${user_from.last_name}`

      if (this.state.type === 'sent') {
        senderValue = user_tos
          .map((userTo) => `${userTo.user.first_name} ${userTo.user.last_name}`)
          .join(',')
      }
      if (
        user_tos[0].read_at ||
        this.state.type === 'sent' ||
        user_tos[0].user.id === user_from.id
      ) {
        read = true
      }

      return (
        <MessageRow
          key={id}
          id={id}
          isRead={read}
          isChecked={this.state.selected.includes(id)}
          sender={senderValue}
          subject={subject}
          day={1}
          month={2}
          onClick={this.goToMessage}
          created_at={created_at}
          locale={t('calendar_labels')}
          onCheckClick={this.handleSelectMessage}
        />
      )
    })
  }

  renderContent = () => (this.props.isNew ? this.renderNewMessagePopin() : this.renderMessageList())

  renderSearchBar() {
    return <TopSearch onSearch={this.handleSearch} />
  }

  render() {
    const aside = this.checkUser()
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="messages-page">
        <LeftAsideLayout aside={aside} content={content} />
        {this.renderConfirmClose()}
      </div>
    )
  }
}
