import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withI18n } from '../../containers/global/Translator/Translator'

import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../redux/OfficeRedux'

import Logo from '../../../public/static/logos/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  office: OfficeSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  saveCurrentBeneficiary: (beneficiary) =>
    dispatch(BeneficiaryActions.saveCurrentBeneficiary(beneficiary)),
})

/** TODO ***
 *
 * - Manage loader during submit
 * - Manage error after submit
 *
 ********** */

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryCreationPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  handleChange = (value) => {
    const newValues = {
      ...this.state.values,
      [value.id]: value.value,
    }

    if (newValues.situation !== 'cdd' && newValues.situation !== 'cdi') {
      newValues.activity = null
    }

    this.setState({
      values: newValues,
    })
  }

  handleSubmit = () => {
    const { user, saveCurrentBeneficiary } = this.props

    const newUser = {
      ...user,
      phone_mobile: this.state.values.mobile,
      cursus_professional_situation: this.state.values.situation,
      cursus_company_activity_sector: this.state.values.activity,
    }

    saveCurrentBeneficiary(newUser)
  }

  getSituationOptions = () => {
    const { t } = this.props

    const beneficiaryProfessionalSituations = t('beneficiary_professional_situations')
    return Object.keys(beneficiaryProfessionalSituations).map((key) => ({
      value: key,
      label: beneficiaryProfessionalSituations[key],
    }))
  }

  getActivityOptions = () => {
    const { t } = this.props

    const beneficiaryCompanyActivitySectors = t('beneficiary_company_activity_sectors')
    return Object.keys(beneficiaryCompanyActivitySectors).map((key) => ({
      value: key,
      label: beneficiaryCompanyActivitySectors[key],
    }))
  }

  buildSteps = () => {
    const { office, t, user } = this.props

    const situationOptions = this.getSituationOptions()
    const activityOptions = this.getActivityOptions()

    const disabledNextButtonStep1 = this.state.values.mobile === ''
    let disabledNextButtonStep2 = false

    const situationComboContent = [
      {
        title: `${t('your_form.cursus_professional_situation')} *`,
        id: 'situation',
        placeholder: `${t('actions.choose')} ${t('your_form.cursus_professional_situation')}`,
        options: situationOptions,
        value: this.state.values.situation,
      },
    ]
    if (this.state.values.situation === 'cdd' || this.state.values.situation === 'cdi') {
      situationComboContent.push({
        title: `${t('your_form.cursus_company_activity_sector')} *`,
        id: 'activity',
        placeholder: `${t('actions.choose')} ${t('your_form.cursus_company_activity_sector')}`,
        options: activityOptions,
        value: this.state.values.activity,
      })

      if (this.state.values.activity === null) {
        disabledNextButtonStep2 = true
      }
    }

    return [
      {
        steps: 2,
        current: 1,
        mainTitle: t('beneficiary.creation.title'),
        name: `${user.first_name} ${user.last_name}`,
        content: t('beneficiary.creation.content', { name: office.name }),
        inputType: WelcomePopin.inputTypes.phone,
        nextId: 'next-step',
        next: t('actions.next'),
        inputContent: {
          inputTitle: `${t('your_form.mobile')} *`,
          placeholder: '06 45 25 15 15',
          inputName: 'mobile',
          inputValue: this.state.values.mobile,
        },
        onNext: () => {
          this.setState({
            step: 1,
          })
        },
        disabledNextButton: disabledNextButtonStep1,
      },
      {
        steps: 2,
        current: 2,
        mainTitle: t('beneficiary.creation.title'),
        name: `${user.first_name} ${user.last_name}`,
        content: t('beneficiary.creation.content', { name: office.name }),
        inputType: WelcomePopin.inputTypes.situation,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: t('actions.previous'),
        next: t('actions.validate'),
        comboContent: situationComboContent,
        onPrev: () => {
          this.setState({
            step: 0,
          })
        },
        onNext: this.handleSubmit,
        disabledNextButton: disabledNextButtonStep2,
      },
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      values: {
        mobile: '',
        situation: this.getSituationOptions()[0].value,
        activity: null,
      },
    }
  }

  componentDidMount() {
    this.props.getOffice()
  }

  render() {
    const { office } = this.props

    if (!office) {
      return null
    }

    const steps = this.buildSteps()

    return (
      <div className="beneficiary-creation-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[this.state.step]} onChange={this.handleChange} />
      </div>
    )
  }
}
