import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'
import IconProgState from '../../atoms/IconProgState/IconProgState'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import CheckBoxField from '../CheckBoxField/CheckBoxField'

export default class ParcoursStepQuiz extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isActivated: PropTypes.bool,
    iconProgState: PropTypes.string,
    stepIndex: PropTypes.number,
    moduleIndex: PropTypes.number,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    text: PropTypes.string,
    onResultsClick: PropTypes.func,
    onClickLock: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickReload: PropTypes.func,
    onClickCompleted: PropTypes.func,
    onWorkOnClick: PropTypes.func,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    isChecked: PropTypes.bool,
    showProgress: PropTypes.bool,
    complete: PropTypes.shape({ done: PropTypes.bool.isRequired }).isRequired,
    locale: PropTypes.shape({
      totalQuestion: PropTypes.string.isRequired,
      totalQuestions: PropTypes.string.isRequired,
      multipleChoice: PropTypes.string.isRequired,
      multipleChoices: PropTypes.string.isRequired,
      questionToFill: PropTypes.string.isRequired,
      questionsToFill: PropTypes.string.isRequired,
      complete: PropTypes.string,
      lockTooltip: PropTypes.string,
    }).isRequired,
    totalQuestion: PropTypes.number,
    multipleChoice: PropTypes.number,
    questionToFill: PropTypes.number,
  }

  static defaultProps = {
    stepIndex: null,
    moduleIndex: null,
    type: '',
    showProgress: false,
    text: '',
    isActivated: true,
    isEditable: false,
    iconProgState: IconProgState.icons.locked,
    onClick: () => {},
    onClickLock: () => {},
    onClickEdit: () => {},
    onClickDelete: () => {},
    onClickReload: () => {},
    onClickCompleted: () => {},
    onWorkOnClick: () => {},
    onResultsClick: () => {},
    totalQuestion: 0,
    multipleChoice: 0,
    questionToFill: 0,
    isLocked: false,
    isChecked: false,
  }

  handleWorkOnClick = () => {
    this.props.onWorkOnClick(this.props.id)
  }

  handleShowResults = () => {
    this.props.onResultsClick(this.props.id)
  }

  handleReloadClick = () => {
    const { id, stepIndex, moduleIndex } = this.props
    this.props.onClickReload({ id, stepIndex, moduleIndex, done: false })
  }

  handleDeleteClick = () => {
    const { stepIndex, moduleIndex } = this.props
    this.props.onClickDelete({ stepIndex, moduleIndex })
  }

  handleEditClick = () => {
    const { type, moduleIndex } = this.props
    this.props.onClickEdit({ type, moduleIndex })
  }

  handleLockClick = () => {
    const { stepIndex, moduleIndex, isLocked } = this.props
    this.props.onClickLock({ stepIndex, moduleIndex, locked: !isLocked })
  }

  handleClickComplete = () => {
    const { id, iconProgState } = this.props
    this.props.onClickCompleted({ id, iconProgState })
  }

  renderToolbar() {
    const { isLocked, moduleIndex, locale } = this.props
    return (
      <>
        {moduleIndex > 0 && (
          <div className="action-item">
            <IconButton
              icon={Icon.icon.Lock}
              color={isLocked ? IconButton.color.Accent : IconButton.color.Grey2}
              onClick={this.handleLockClick}
              id="lock"
              tooltip={locale.lockTooltip || ''}
            />
          </div>
        )}
        <div className="action-item">
          <IconButton
            icon={Icon.icon.Edit2}
            color={IconButton.color.Grey2}
            onClick={this.handleEditClick}
          />
        </div>
        <div className="action-item">
          <IconButton
            icon={Icon.icon.Trash2}
            color={IconButton.color.Grey2}
            onClick={this.handleDeleteClick}
          />
        </div>
      </>
    )
  }

  renderDoneOrReload = () => {
    const { isEditable, iconProgState, id, isBeneficiary, isChecked, locale } = this.props

    if (isBeneficiary && iconProgState !== 'locked') {
      return (
        <>
          {!isChecked && (
            <div className="action-item action-completed">
              <PrimaryButton id={id} label={locale.complete} onClick={this.handleClickComplete} />
              <CheckBoxField
                name={`${id}quiz-check`}
                label={locale.complete}
                value={isChecked}
                onChange={this.handleClickComplete}
              />
            </div>
          )}

          {!isChecked && (
            <div className="action-item">
              <CircleButton icon="edit2" onClick={this.handleWorkOnClick} />
            </div>
          )}
        </>
      )
    }
    if (!isEditable && !isBeneficiary) {
      return (
        <>
          {iconProgState === IconProgState.icons.finish && (
            <div className="action-item">
              <IconButton
                icon="reload"
                color={IconButton.color.Grey2}
                onClick={this.handleReloadClick}
              />
            </div>
          )}

          <div className="action-item">
            <IconButton
              icon={Icon.icon.Trash2}
              color={IconButton.color.Grey2}
              onClick={this.handleDeleteClick}
            />
          </div>
        </>
      )
    }
  }

  renderProgState = () => {
    const { iconProgState, isEditable, showProgress } = this.props

    if (isEditable) {
      return showProgress ? <IconProgState icon={iconProgState} /> : null
    }

    return <IconProgState icon={iconProgState} />
  }

  render() {
    const {
      id,
      isActivated,
      isBeneficiary,
      iconProgState,
      label,
      text,
      totalQuestion,
      multipleChoice,
      questionToFill,
      locale,
      isEditable,
    } = this.props
    const totalQuestionLabel = totalQuestion > 1 ? locale.totalQuestions : locale.totalQuestion
    const multipleChoiceLabel = multipleChoice > 1 ? locale.multipleChoices : locale.multipleChoice
    const questionToFillLabel = questionToFill > 1 ? locale.questionsToFill : locale.questionToFill

    return (
      <div
        className={`parcours-module-container parcours-step-quiz ${
          isEditable ? 'editable' : 'no-editable'
        }`}
        id={id}
      >
        <div className={`header ${isActivated ? 'active' : 'disable'}`}>
          {this.renderProgState()}
          <IconModuleType moduleType={IconModuleType.moduleType.quiz} />
          <span className="label">{label}</span>
          <div className="actions">
            {isEditable && this.renderToolbar()}
            {this.renderDoneOrReload()}
          </div>
        </div>
        {text && (
          <div className="parcours-step-body">
            <p className="text">{text}</p>
          </div>
        )}
        <div className="parcours-step-separator" />
        <div className="children">
          <div className="children-item">
            <div className="question-counter">
              <span className="count-value">{totalQuestion}</span>
              <span className="count-label">{totalQuestionLabel}</span>
            </div>
            <div className="question-counter">
              <span className="count-value">{multipleChoice}</span>
              <span className="count-label">{multipleChoiceLabel}</span>
            </div>
            <div className="question-counter">
              <span className="count-value">{questionToFill}</span>
              <span className="count-label">{questionToFillLabel}</span>
            </div>
            {!isEditable &&
              ((!isBeneficiary &&
                (iconProgState === IconProgState.icons.finish ||
                  iconProgState === IconProgState.icons.ongoing)) ||
                (isBeneficiary && iconProgState === IconProgState.icons.finish)) && (
                <PrimaryButton
                  id="btn-show-result"
                  onClick={this.handleShowResults}
                  label={this.props.locale.showResult}
                />
              )}
          </div>
        </div>
      </div>
    )
  }
}
