import { call, put } from 'redux-saga/effects'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { services } from '../services'

export function* createInvite({ payload }) {
  yield call(services.videocall.createInvite, payload, function* ({ ok, data }) {
    ok ? openVideoCallInvite(data.invite) : yield put(NotificationActions.status({ ok, data }))
  })
}

function openVideoCallInvite(invite) {
  window.open(process.env.REACT_APP_VISIO_FRONT_URL + '/' + invite, '_blank').focus()
}
