import React from 'react'

const GradientSVG = () => (
  <svg style={{ height: '0' }}>
    <defs>
      <linearGradient id="progress">
        <stop offset="0%" stopColor="#56ab2f" />
        <stop offset="100%" stopColor="#a8e063" />
      </linearGradient>
    </defs>
  </svg>
)

export default GradientSVG
