import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import AvatarField from '../../molecules/AvatarField/AvatarField'
import InputField from '../../molecules/InputField/InputField'
import InputFieldContainer from '../../molecules/InputField/InputFieldContainer'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import RadioField from '../../molecules/RadioField/RadioField'
import DateField from '../../molecules/DateField/DateFieldContainer'
import ComboField from '../../molecules/ComboField/ComboField'

class PresentationCard extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      inputList: PropTypes.array,
    }),
    lastName: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    firstName: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    birth: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    group: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.any,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    onChange: PropTypes.func,
    onPictureClick: PropTypes.func,
    uploading: PropTypes.bool,
    locale: PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      namePlaceHolder: PropTypes.string,
      firstName: PropTypes.string,
      firstNamePlaceHolder: PropTypes.string,
      age: PropTypes.string,
      birth: PropTypes.string,
      birthPlaceHolder: PropTypes.string,
      groupLabel: PropTypes.string,
      sources: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    }).isRequired,
    readOnly: PropTypes.bool,
    source: PropTypes.string,
  }

  static defaultProps = {
    gender: { id: '', value: '', inputList: [] },
    lastName: { id: '', value: '' },
    firstName: { id: '', value: '' },
    age: null,
    birth: null,
    group: null,
    photoUrl: null,
    onChange: ({ id, value }) => {
    },
    onPictureClick: () => undefined,
    readOnly: false,
    uploading: false,
    source: null,
  }

  constructor() {
    super()

    this.state = {
      sourceOther: false,
      sourceValue: null,
    }
  }

  onChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handlePictureClick = () => {
    this.props.onPictureClick()
  }

  handleSourceChange = ({ value }) => {
    this.setState({
      sourceValue: value,
      sourceOther: value === 'other',
    })

    this.onChange({ id: 'source', value })
  }

  handleSourceInputChange = ({ value }) => {
    this.setState({
      ...this.state,
      sourceValue: value,
    })

    this.onChange({ id: 'source', value })
  }

  componentDidMount() {
    const source = this.props.source

    this.setState({
      sourceOther: !Object.values(this.props.locale.sources)
        .map((item) => item.key)
        .includes(source.value),
      sourceValue: source.value,
    })
  }

  render() {
    const {
      title,
      photoUrl,
      gender,
      lastName,
      firstName,
      age,
      birth,
      group,
      locale,
      readOnly,
      uploading,
    } = this.props

    const sources = Object.values(locale.sources).map((item) => ({
      label: item.value,
      value: item.key,
    }))

    return (
      <div className="presentation-card">
        <IconCardTitle label={title} icon={Icon.icon.Person} />
        <div className="form">
          <div className="photo">
            <AvatarField
              photoUrl={photoUrl}
              onClick={this.handlePictureClick}
              readOnly={readOnly}
              uploading={uploading}
            />
          </div>

          <div className="fields">
            <RadioField
              title={locale.title}
              name={gender.id}
              inputList={gender.inputList}
              value={gender.value}
              onChange={this.onChange}
              readOnly={readOnly}
            />

            <InputFieldContainer
              title={locale.firstName}
              name={firstName.id}
              placeholder={locale.firstNamePlaceHolder}
              value={firstName.value}
              onChange={this.onChange}
              readonly={readOnly}
            />

            <InputFieldContainer
              title={locale.name}
              name={lastName.id}
              placeholder={locale.name}
              value={lastName.value}
              onChange={this.onChange}
              readonly={readOnly}
            />

            {age !== null && (
              <InputField title={locale.age} name="age" placeholder="age" value={age} readonly />
            )}

            {birth && (
              <DateField
                title={locale.birth}
                placeholder={locale.birthPlaceHolder}
                name={birth.id}
                value={birth.value}
                calendarLabels={{
                  dayShortLabels: locale.dayShortLabels,
                  monthLabels: locale.monthLabels,
                }}
                onChange={this.onChange}
                locale={locale.locale}
                readOnly={readOnly}
                required={false}
              />
            )}

            {group !== null && (
              <ComboField
                value={group.value}
                title={locale.groupLabel}
                name={group.id}
                options={group.options}
                onChange={this.onChange}
                readOnly={readOnly}
              />
            )}

            <ComboField
              title="Source"
              options={sources}
              onChange={this.handleSourceChange}
              readOnly={readOnly}
              name="Source"
              placeholder="Veuillez choisir"
              value={this.state.sourceOther ? 'other' : this.state.sourceValue}
            />

            {this.state.sourceOther && (
              <InputField
                title="Autre source"
                name="source"
                placeholder="Entrez la source"
                onChange={this.handleSourceInputChange}
                value={this.state.sourceValue === 'other' ? '' : this.state.sourceValue}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default PresentationCard
