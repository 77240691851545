import React from 'react'
import AlertPopin from '../../organisms/AlertPopin/AlertPopin'
import Popin from '../../../containers/global/Popin/Popin'
import { useDispatch, useSelector } from 'react-redux'
import { actions as CentralTestActions } from '../../../redux/CentralTestRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'
import { selectors as CourseTemplateSelectors } from '../../../redux/CourseTemplateRedux'

const labels = {
  title: "Souhaitez vous finaliser le module et envoyer l'invitation",
  body: "Une fois l'invitation envoyée, des crédits Central Test seront utilisés, et vous ne pourrez plus modifier le module",
  cancelButtonLabel: 'Retour',
  confirmButtonLabel: "Confirmer et envoyer l'invitation",
}

const ModuleCentralTestConfirmationPopin = ({ module: _module, isModalOpen, onClose }) => {
  const dispatch = useDispatch()
  const beneficiary = useSelector(BeneficiarySelectors.beneficiary)
  const template = useSelector(CourseTemplateSelectors.template)

  const sendInvitation = () => {
    dispatch(
      CentralTestActions.inviteBeneficiary({
        user_id: beneficiary.id,
        module_id: _module.id,
        template_id: template.id,
      }),
    )

    onClose()
  }

  return (
    <Popin open={isModalOpen}>
      <AlertPopin
        label={labels.title}
        text={labels.body}
        labelCancelButton={labels.cancelButtonLabel}
        labelConfirmButton={labels.confirmButtonLabel}
        onCancelButtonClick={onClose}
        onConfirmButtonClick={sendInvitation}
      />
    </Popin>
  )
}

export default ModuleCentralTestConfirmationPopin
