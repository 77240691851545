import moment from 'moment'
import ApiService from './ApiService'

const api = ApiService

export const get = ({ token }) => {
  return api.get('/stats/admin', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getManagerOfficeStats = ({ statsYear, token }) => {
  const startDateMoment = moment(`${statsYear}-01-01 00:00:00.000`)
  const endDateMoment = moment(`${statsYear}-12-31 24:00:00.000`)

  const data = {
    start_date: startDateMoment.valueOf(),
    end_date: endDateMoment.valueOf(),
  }

  return api.get('/stats/manager', data, { headers: { Authorization: `Bearer ${token}` } })
}

export const getConsultantStats = ({ currentConsultant, statsYear, token }) => {
  const startDateMoment = moment(`${statsYear}-01-01 00:00:00.000`)
  const endDateMoment = moment(`${statsYear}-12-31 24:00:00.000`)

  const data = {
    start_date: startDateMoment.valueOf(),
    end_date: endDateMoment.valueOf(),
  }

  return api.get(`/stats/manager/consultants/${currentConsultant}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
