import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AddToCalendar from 'react-add-to-calendar'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import AgendaRowDate from '../AgendaRowDate/AgendaRowDate'
import { actions as VideoCallActions } from '../../../redux/VideocallRedux'
import { connect } from 'react-redux'
import CircleButton from '../../atoms/CircleButton/CircleButton'

const mapDispatchToProps = (dispatch) => ({
  createInvite: (payload) => dispatch(VideoCallActions.createInvite(payload)),
})

@connect(null, mapDispatchToProps)
class AgendaRow extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    course_title: PropTypes.string.isRequired,
    step_title: PropTypes.string.isRequired,
    step_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    step_index: PropTypes.number.isRequired,
    onEditClick: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    hours: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    primaryConsultantPhotoUrl: PropTypes.string,
    user_id: PropTypes.number.isRequired,
    course_id: PropTypes.number.isRequired,
    status: PropTypes.bool,
    textStatus: PropTypes.string,
    locale: PropTypes.shape({
      dayLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
      monthLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
      postpone: PropTypes.string.isRequired,
      confirm: PropTypes.string.isRequired,
      proposeDate: PropTypes.string.isRequired,
      addToCalendar: PropTypes.string.isRequired,
    }),
    isConsultant: PropTypes.bool,
    onPostponeClick: PropTypes.func.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    event: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      startTime: PropTypes.object.isRequired,
      endTime: PropTypes.object.isRequired,
    }),
    displayAddToCalender: PropTypes.bool,
    isVideoCall: PropTypes.bool.isRequired,
    calll: PropTypes.object,
  }

  static defaultProps = {
    status: false,
    textStatus: null,
    displayAddToCalender: false,
    photoUrl: null,
    isConsultant: false,
    primary_consultant_avatar: null,
  }

  handleClick = () => {
    const { id, user_id, course_id, step_index } = this.props

    this.props.onEditClick({ id, user_id, course_id, step_index })
  }

  handleAddToCalendarClick = (e) => {
    e.currentTarget.querySelector('.react-add-to-calendar__button').click()
  }

  handlePostponeClick = () => {
    const { id, course_id } = this.props

    this.props.onPostponeClick({ id, courseId: course_id })
  }

  handleConfirmClick = () => {
    const { id, course_id } = this.props

    this.props.onConfirmClick({ id, courseId: course_id })
  }

  handleVideoCallClick = (invite) => {
    window.open(process.env.REACT_APP_VISIO_FRONT_URL + '/' + invite, '_blank')
  }

  render() {
    const {
      id,
      title,
      name,
      status,
      course_title,
      step_title,
      step_count,
      step_index,
      photoUrl,
      primaryConsultantPhotoUrl,
      day,
      month,
      date,
      hours,
      locale,
      isConsultant,
      event,
      displayAddToCalender,
      textStatus,
      isVideoCall,
      call,
    } = this.props

    return (
      <div className="agenda-row">
        <AgendaRowDate
          date={date}
          day={day}
          month={month}
          accentColor={
            textStatus === 'ko' ? 'red' : textStatus === 'rescheduled' ? 'orange' : 'default'
          }
          locale={locale}
        />
        <div className="wrapper">
          <div className="appointment-title">{hours}</div>
          <div className="appointment-title">{title}</div>
          <div className="avatar-name">
            <AvatarCircle photoUrl={photoUrl} />
            <div className="name">{name}</div>
          </div>
          <div className="subject">
            <div className="row-label">{course_title}</div>
            <div className="step_title">{`(${step_index + 1}/${step_count}) ${step_title}`}</div>
          </div>
          <div className="actions">
            {primaryConsultantPhotoUrl && <AvatarCircle photoUrl={primaryConsultantPhotoUrl} />}

            {isVideoCall && (
              <>
                <CircleButton id={id} icon={Icon.icon.Visio} onClick={() => this.handleVideoCallClick(call.invite)} />
              </>
            )}

            {status && isConsultant && (
              <PrimaryButton id={id} label={locale.proposeDate} onClick={this.handleClick} />
            )}

            {textStatus === 'rescheduled' && !isConsultant && (
              <PrimaryButton id={id} label={locale.confirm} onClick={this.handleConfirmClick} />
            )}

            {!status && !isConsultant && (
              <PrimaryButton id={id} label={locale.postpone} onClick={this.handlePostponeClick} />
            )}

            {!status && isConsultant && (
              <>
                {displayAddToCalender && (
                  <div className="add-to-calendar-icon" onClick={this.handleAddToCalendarClick}>
                    <Icon icon={Icon.icon.Calendar} color={Icon.color.Accent} />
                    <AddToCalendar
                      event={event}
                      buttonLabel={''}
                      listItems={[
                        { google: 'Google' },
                        { outlookcom: 'Outlook.com' },
                        { yahoo: 'Yahoo' },
                      ]}
                    />
                  </div>
                )}
                <IconButton
                  id={id}
                  icon={Icon.icon.Edit2}
                  color={Icon.color.Grey2}
                  onClick={this.handleClick}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AgendaRow
