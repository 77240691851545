import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'

import AboCard from '../AboCard/AboCard'
import CabinetCard from '../CabinetCard/CabinetCard'
import ActionPopinHeader from '../../molecules/ActionPopinHeader/ActionPopinHeader'
import FileUpload from '../../../containers/global/FileUpload/FileUpload'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import CentralTestOfficeManagerCard from '../CentralTestManualAccountCard/CentralTestOfficeManagerCard'

export default class EditCustomerPopin extends Component {
  static propTypes = {
    office: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string,
      region: PropTypes.string,
      image_url: PropTypes.string,
      no_consultants: PropTypes.number.isRequired,
      no_consultants_allocated: PropTypes.number.isRequired,
    }),
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    onResendInvitation: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
      validateResendInvitation: PropTypes.string.isRequired,
      resendInvitation: PropTypes.string.isRequired,
      presentationTitle: PropTypes.string.isRequired,
      subscriptionTitle: PropTypes.string.isRequired,
      nameTitle: PropTypes.string.isRequired,
      namePlaceholder: PropTypes.string.isRequired,
      phoneTitle: PropTypes.string.isRequired,
      phonePlaceholder: PropTypes.string.isRequired,
      emailTitle: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      regionTitle: PropTypes.string.isRequired,
      regionPlaceholder: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.string).isRequired,
      noConsultantsAvailable: PropTypes.string.isRequired,
      noConsultantsAllocated: PropTypes.string.isRequired,
      notActivatedYet: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    disabled: false,
    onResendInvitation: () => undefined,
    onSubmit: (office) => undefined,
    onClose: () => undefined,
  }

  state = {
    office: null,
  }

  static getDerivedStateFromProps({ office }, state) {
    return state.office ? null : { office }
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.office)
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleChange = ({ id, value }) => {
    this.setState(({ office }) => ({
      office: { ...office, [id]: value },
    }))
  }

  handleFileChange = ({ file }) => {
    this.handleChange({ id: 'image_url', value: file.url })
  }

  handleChangeNoConsultants = ({ value }) => {
    this.handleChange({ id: 'no_consultants', value })
  }

  emailHasChanged() {
    const {
      office: { id, email },
    } = this.props
    const {
      office: { email: newEmail },
    } = this.state

    return id && email !== newEmail
  }

  shouldBeActivated() {
    const {
      office: { id, manager },
    } = this.props

    return id && manager && !manager.activated_at
  }

  hasChanged() {
    return !_isEqual(this.props.office, this.state.office)
  }

  renderInvitationButton() {
    const { locale, onResendInvitation, office } = this.props
    const { manager } = office

    if (manager) {
      if (!manager.activated_at && !this.emailHasChanged()) {
        return (
          <PrimaryButton
            key="resend-invitation"
            id="resend-invitation"
            label={locale.resendInvitation}
            onClick={onResendInvitation}
          />
        )
      }
    }

    return null
  }

  render() {
    const { locale, valid, disabled } = this.props
    const { office } = this.state
    const isNew = !office.id
    const buttonDisabled = disabled || !valid || !this.hasChanged()
    const cta = this.emailHasChanged() ? locale.validateResendInvitation : locale.cta

    return (
      <div className="edit-customer-popin">
        <ActionPopinHeader
          icon="back"
          color="accent"
          boldTitle={locale.title}
          iconButton="back"
          disabled={buttonDisabled}
          labelButton={cta}
          onIconClick={this.handleClose}
          onLabelButton={this.handleSubmit}
        />

        <div className="content">
          <FileUpload id="image_url" accept="image/*" onChange={this.handleFileChange}>
            {({ onClick, uploading }) => (
              <CabinetCard
                logoUrl={office.image_url}
                name={office.name}
                email={office.email}
                phone={office.phone}
                region={office.region}
                emails={office.emails}
                uploading={uploading}
                locale={{
                  title: locale.presentationTitle,
                  nameTitle: locale.nameTitle,
                  namePlaceholder: locale.namePlaceholder,
                  emailTitle: locale.emailTitle,
                  emailPlaceholder: locale.emailPlaceholder,
                  phoneTitle: locale.phoneTitle,
                  phonePlaceholder: locale.phonePlaceholder,
                  regionTitle: locale.regionTitle,
                  regionPlaceholder: locale.regionPlaceholder,
                  regions: locale.regions,
                }}
                onChange={this.handleChange}
                onFileClick={onClick}
              />
            )}
          </FileUpload>

          <div>
            <AboCard
              isNew={isNew}
              available={office.no_consultants_allocated}
              allocated={office.no_consultants}
              onChange={this.handleChangeNoConsultants}
              locale={{
                title: locale.subscriptionTitle,
                noConsultantsAvailable: locale.noConsultantsAvailable,
                noConsultantsAllocated: locale.noConsultantsAllocated,
              }}
            />

            <CentralTestOfficeManagerCard
              centraltestId={office.ctCompany ? office.ctCompany.company_id : null}
              officeId={office.id}
            />

            {this.shouldBeActivated() && <p className="invite-status">{locale.notActivatedYet}</p>}

            <div className="invitation-button">{this.renderInvitationButton()}</div>
          </div>
        </div>
      </div>
    )
  }
}
