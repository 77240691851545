import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withI18n } from '../../containers/global/Translator/Translator'

import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'

import Logo from '../../../public/static/logos/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'

const mapDispatchToProps = (dispatch) => ({
  disableWaitingPage: () => dispatch(BeneficiaryActions.disableWaitingPage()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(null, mapDispatchToProps)
export default class BeneficiaryWaitingPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  buildSteps = () => {
    const { t } = this.props

    return [
      {
        steps: 1,
        current: 1,
        mainTitle: t('beneficiary.waiting.title'),
        content: t('beneficiary.waiting.content'),
        nextId: 'next-step',
        next: t('actions.next'),
        onNext: () => {
          this.props.disableWaitingPage()
          this.props.redirect('/beneficiary/profile')
        },
      },
    ]
  }

  render() {
    const steps = this.buildSteps()

    return (
      <div className="beneficiary-waiting-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[0]} />
      </div>
    )
  }
}
