import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../redux/ConsultantRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../redux/WSRedux'

import { withI18n } from '../../containers/global/Translator/Translator'
import Page from '../../containers/global/Page/Page'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'

import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import Icon from '../../components/atoms/Icon/Icon'

import ManagerConsultantEdit from '../../containers/manager/ManagerConsultantEdit/ManagerConsultantEdit'
import ManagerConsultantBeneficiaries from '../../containers/manager/ManagerConsultantBeneficiaries/ManagerConsultantBeneficiaries'
import TopSearch from '../../containers/global/TopSearch/TopSearch'

const mapStateToProps = (state, props) => ({
  id: Number(props.match.params.consultant_id) || null,
  pathname: NavigationSelectors.pathname(state),
  consultant: ConsultantSelectors.consultant(state),
  beneficiaries: ConsultantSelectors.beneficiaries(state),
  saving: WSSelectors.pending(state, 'consultant.save'),
  saved: WSSelectors.resolved(state, 'consultant.save'),
})

const mapDispatchToProps = (dispatch) => ({
  saveConsultant: (data) => dispatch(ConsultantActions.saveConsultant(data)),
  setConsultant: (data) => dispatch(ConsultantActions.setConsultant(data)),
  getConsultant: (id) => dispatch(ConsultantActions.getConsultant(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  resetSave: () => dispatch(WSActions.reset('consultant.save')),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerConsultantEditPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    category: 'beneficiaries',
    consultant: null,
    q: '',
  }

  handleMenuChange = (category) => {
    this.setState({ category })
  }

  handleChange = ({ id, value }) => {
    this.setState(({ consultant }) => ({
      consultant: { ...consultant, [id]: value },
    }))
  }

  handleSave = () => {
    const { consultant } = this.state
    const { id, first_name, last_name, image_url, phone, email } = consultant
    const data = { id, first_name, last_name, image_url, phone, email }

    this.props.saveConsultant(data)
  }

  handleSearch = (value) => {
    this.setState({ q: value })
  }

  handleBack = () => {
    const { pathname, redirect } = this.props
    redirect(pathname.replace(/\/\w+$/, ''))
  }

  static getDerivedStateFromProps(props, state) {
    if (state.consultant) {
      return null
    }

    const { id, consultant } = props
    const category = consultant && consultant.activated_at ? 'beneficiaries' : 'informations'

    return id ? { category, consultant: consultant || null } : { category, consultant: {} }
  }

  componentDidMount() {
    const { id } = this.props

    return id ? this.props.getConsultant(id) : this.props.setConsultant(null)
  }

  componentDidUpdate() {
    if (this.props.saved) {
      this.props.resetSave()
      this.handleBack()
    }
  }

  componentWillUnmount() {
    this.props.setConsultant(null)
  }

  getLinks() {
    const { t, beneficiaries } = this.props
    const { consultant, q } = this.state

    let filteredBeneficiaries = beneficiaries
    if (q && q.length > 0) {
      const reg = new RegExp(q, 'gi')
      filteredBeneficiaries = beneficiaries.filter(
        (beneficiary) => reg.test(beneficiary.first_name) || reg.test(beneficiary.last_name),
      )
    }
    return [
      {
        id: 'beneficiaries',
        label: t('consultant.beneficiaries'),
        render: () => <ManagerConsultantBeneficiaries beneficiaries={filteredBeneficiaries} />,
      },
      {
        id: 'informations',
        label: t('consultant.personal_informations'),
        showButton: true,
        render: () => (
          <ManagerConsultantEdit consultant={consultant} onChange={this.handleChange} />
        ),
      },
    ]
  }

  getCurrentLink() {
    const { category } = this.state
    return this.getLinks().find(({ id }) => id === category)
  }

  renderTabs() {
    const { category, consultant } = this.state
    const { activated_at } = consultant
    const links = this.getLinks()

    if (!activated_at) {
      links.splice(0, 1)
    }

    return (
      <LineMenu
        value={category}
        links={links}
        onChange={this.handleMenuChange}
        components={<TopSearch onSearch={this.handleSearch} />}
      />
    )
  }

  renderHeader() {
    const { t, saving } = this.props
    const { consultant } = this.state
    const { id, first_name = '', last_name = '' } = consultant
    const { showButton } = this.getCurrentLink()

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        lightTitle={t(id ? 'consultant.consultant' : 'consultant.new_consultant')}
        boldTitle={`${first_name} ${last_name}`}
        disabled={saving}
        labelButton={showButton && t('actions.save')}
        onIconClick={this.handleBack}
        onLabelButton={this.handleSave}
      />
    )
  }

  renderContent() {
    return this.getCurrentLink().render()
  }

  render() {
    const { consultant } = this.state

    if (!consultant) {
      return null
    }

    const header = this.renderHeader()
    const contentLayout = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="manager-consultant-edit-page">
        <PopinLayout header={header} content={contentLayout} />
      </div>
    )
  }
}
