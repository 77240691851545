import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import querystring from 'querystring'
import { fromJS } from 'immutable'

//
// Initial state
//
const initialState = fromJS({
  lastPages: [],
})

//
// Actions
//
export const actions = {
  evaluate: createAction('evaluate'),
  push: createAction('push', (path, goingBack) => ({ path, goingBack })),
  redirect: createAction('redirect', (path) => ({ path })),
  open: createAction('open', (url) => ({ url })),
  replace: createAction('replace', (path) => ({ path })),
  back: createAction('back', (prefix, usePrevious) => ({ prefix, usePrevious })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.push]: (state, { path, goingBack }) =>
      goingBack ? state : state.set('lastPages', state.get('lastPages').unshift(path).slice(0, 3)),
    [actions.back]: (state) => state.set('lastPages', state.get('lastPages').slice(1)),
    [actions.replace]: (state, { path }) =>
      state.set('lastPages', state.get('lastPages').slice(1).unshift(path).slice(0, 3)),
  },
  initialState,
)

//
// Selectors
//
const router = (state) => state.router
const navigation = (state) => state.navigation
const props = (state, ...props) => props
const location = createSelector(router, ({ location }) => location || window.location)
const qs = createSelector(location, ({ search }) => querystring.parse(search.slice(1)))
const query = createSelector(qs, props, (query, [key]) => query[key])
const pathname = createSelector(location, ({ pathname }) => pathname)
const fullPathname = createSelector(location, ({ pathname, hash }) => pathname + hash)
const hash = createSelector(location, ({ hash }) => hash.replace('#', ''))
const paths = createSelector(pathname, (pathname) => pathname.split('/').slice(1))
const basePath = createSelector(pathname, (pathname) => pathname.replace(/\/[^/]+$/, ''))
const lastParam = createSelector(paths, (params) => params.pop())
const lastPages = createSelector(navigation, (state) => state.get('lastPages').toJS())

export const selectors = {
  query,
  pathname,
  basePath,
  fullPathname,
  hash,
  lastParam,
  lastPages,
  paths,
}
