import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

const icons = {
  locked: Icon.icon.Locked,
  untouched: Icon.icon.Untouched,
  ongoing: Icon.icon.Ongoing,
  finish: Icon.icon.Finish,
}

export default class IconProgState extends Component {
  static icons = icons

  static propTypes = {
    icon: PropTypes.oneOf(Object.values(icons)),
  }

  renderIcon() {
    const { icons } = IconProgState

    switch (this.props.icon) {
      case icons.locked:
        return <Icon icon={icons.locked} color={Icon.color.Grey2} />
      case icons.untouched:
        return <Icon icon={icons.untouched} color={Icon.color.Grey2} />
      case icons.ongoing:
        return <Icon icon={icons.ongoing} color={Icon.color.Orange1} />
      case icons.finish:
        return <Icon icon={icons.finish} color={Icon.color.Green1} />
      default:
        break
    }
  }

  render() {
    const { icon } = this.props

    return <div className={`icon-prog-state-${icon}`}>{this.renderIcon()}</div>
  }
}
