import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'
import _orderBy from 'lodash/orderBy'
import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  appointments: {},
})

//
// Actions
//
export const actions = {
  getAppointments: createAction('getAppointments', (filter) => ({ filter })),
  setAppointments: createAction('setAppointments', (appointments) => ({
    appointments: _orderBy(_mapKeys(appointments, 'id'), ['rdv_at'], ['asc']),
  })),
  exportAppointments: createAction('exportAppointments', (filter) => ({ filter })),
  postpone: createAction('postpone', (id, courseId) => ({ id, courseId })),
  confirm: createAction('confirm', (id, courseId) => ({ id, courseId })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setAppointments]: (state, { appointments }) => state.merge({ appointments }),
  },
  initialState,
)

//
// Selectors
//
const appointments = (state) => state.appointment.get('appointments')
const appointmentsArray = createSelector([appointments], Object.values)

export const selectors = {
  appointments: appointmentsArray,
}
