import classnames from 'classnames'
import _omit from 'lodash/omit'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import IconFormQuestionType from '../../../components/atoms/IconFormQuestionType/IconFormQuestionType'
import FormQuestionRow from '../../../components/molecules/FormQuestionRow/FormQuestionRow'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import Draggable from '../../dragdrop/Draggable/Draggable'
import Droppable from '../../dragdrop/Droppable/Droppable'
import { withI18n } from '../../global/Translator/Translator'
import EditQuestionFormModulePopin from '../EditQuestionFormModulePopin/EditQuestionFormModulePopin'
import RemoveQuestionFromFormModulePopin from '../RemoveQuestionFromFormModulePopin/RemoveQuestionFromFormModulePopin'

const mapStateToProps = (state) => ({
  template: ModuleTemplateSelectors.template(state),
  groupIsDragging: ModuleTemplateSelectors.groupIsDragging(state),
  selectedQuestionGroup: ModuleTemplateSelectors.selectedQuestionGroup(state),
  dragInfo: ModuleTemplateSelectors.dragInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  setQuestion: (question) => dispatch(ModuleTemplateActions.setQuestion(question)),
  setQuestions: (questions) => dispatch(ModuleTemplateActions.setQuestions(questions)),
  setSelectedQuestionGroup: (selectedQuestionGroup) =>
    dispatch(ModuleTemplateActions.setSelectedQuestionGroup(selectedQuestionGroup)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ModuleFormEdit extends Component {
  /* Question deletion management */
  handleDeleteQuestion = ({ id }) => {
    this.setState({
      questionKeyToDelete: id,
      removePopinIsOpen: true,
    })
  }

  handleDeleteQuestionConfirmation = () => {
    if (this.state.questionKeyToDelete !== null) {
      const id = this.state.questionKeyToDelete

      const { template } = this.props
      const { data } = template

      const deleteQuestion = (questions, prefix = '') => {
        return questions.reduce((res, question, questionKey) => {
          if (`${prefix}${questionKey}` !== id) {
            let newValue = question
            if (question.type === 'group') {
              newValue = {
                ...newValue,
                questions: deleteQuestion(newValue.questions, `${questionKey}_`),
              }
            }

            res.push(newValue)
          }

          return res
        }, [])
      }

      const newQuestions = deleteQuestion(data.questions)

      this.props.setQuestions(newQuestions)
    }
  }

  handleCloseRemovePopin = () => {
    this.setState({
      removePopinIsOpen: false,
      questionKeyToDelete: null,
    })
  }

  /* Question copy management */
  handleCopyQuestion = ({ id }) => {
    const { template } = this.props
    const { data } = template

    const copyQuestion = (questions, prefix = '') => {
      return questions.reduce((res, question, questionKey) => {
        let currentQuestion = question
        let copiedQuestion = null

        if (`${prefix}${questionKey}` === id) {
          copiedQuestion = _omit(currentQuestion, ['id'])

          if (currentQuestion.type === 'group') {
            copiedQuestion.questions = copiedQuestion.questions.map((question) =>
              _omit(question, ['id']),
            )
          }
        } else if (currentQuestion.type === 'group') {
          currentQuestion = {
            ...currentQuestion,
            questions: copyQuestion(currentQuestion.questions, `${questionKey}_`),
          }
        }

        res.push(currentQuestion)
        if (copiedQuestion !== null) res.push(copiedQuestion)

        return res
      }, [])
    }

    const newQuestions = copyQuestion(data.questions)

    this.props.setQuestions(newQuestions)
  }

  /* Question edition management */
  handleEditQuestion = ({ id }) => {
    const { template, setQuestion } = this.props
    const { data } = template

    let toEdit = null
    const findQuestion = (questions, prefix = '') => {
      questions.forEach((question, questionKey) => {
        if (toEdit === null) {
          if (`${prefix}${questionKey}` === id) {
            toEdit = question
          } else if (question.type === 'group') {
            findQuestion(question.questions, `${questionKey}_`)
          }
        }
      })
    }

    findQuestion(data.questions)

    if (toEdit !== null) {
      setQuestion(toEdit)

      this.setState({
        editPopinIsOpen: true,
        editQuestionKey: id,
      })
    }
  }

  handleCloseEditPopin = () => {
    this.props.setQuestion(null)

    this.setState({
      editPopinIsOpen: false,
      editQuestionKey: null,
    })
  }

  handleMouseOver = (e) => {
    this.props.setSelectedQuestionGroup(
      `group-question-add-zone-${e.currentTarget.getAttribute('data-group')}`,
    )
  }

  handleMouseOut = (e) => {
    this.props.setSelectedQuestionGroup(null)
  }

  /* Questions display */
  buildQuestionsNodes = (currentIndex, questions, prefix = '') => {
    const questionsNodes = questions.map((question, questionKey) => {
      let questionType = IconFormQuestionType.questionType.Text

      switch (question.type) {
        case 'text': {
          questionType = IconFormQuestionType.questionType.Text
          break
        }
        case 'select': {
          questionType = IconFormQuestionType.questionType.Select
          break
        }
        case 'multiple_choice': {
          questionType = IconFormQuestionType.questionType.MultipleChoice
          break
        }
        case 'group': {
          questionType = IconFormQuestionType.questionType.Group
          break
        }
        case 'yes_no': {
          questionType = IconFormQuestionType.questionType.YesNo
          break
        }
        case 'rating': {
          questionType = IconFormQuestionType.questionType.Rating
          break
        }
        case 'opinion_scale': {
          questionType = IconFormQuestionType.questionType.OpinionScale
          break
        }
        case 'date': {
          questionType = IconFormQuestionType.questionType.Date
          break
        }
        default: {
          questionType = IconFormQuestionType.questionType.Text
        }
      }

      let questionNode = (
        <FormQuestionRow
          id={`${prefix}${questionKey}`}
          questionType={questionType}
          title={question.title || this.props.t('question.new_question')}
          questionNumber={questionKey + 1}
          onDeleteClick={this.handleDeleteQuestion}
          onCopyClick={this.handleCopyQuestion}
          onEditClick={this.handleEditQuestion}
        />
      )

      const questionIndex = currentIndex + questionKey

      if (question.type === 'group' && question.questions) {
        currentIndex += question.questions.length

        const groupNodes = (
          <div className="group-question">
            {question.questions.length > 0 &&
              this.buildQuestionsNodes(questionIndex + 1, question.questions, `${questionKey}_`)}
          </div>
        )

        const { dragInfo } = this.props
        let newQuestionIsAGroup = false
        if (dragInfo !== null) {
          const draggableIdObject = JSON.parse(dragInfo.draggableId)
          newQuestionIsAGroup = draggableIdObject.type === 'group'
        }

        let fromGroup = false
        if (dragInfo !== null) {
          const dragSource = dragInfo.source
          if (
            dragSource.index > questionIndex &&
            dragSource.index <= questionIndex + question.questions.length
          ) {
            fromGroup = true
          }
        }

        let isInsideGroup = false
        if (dragInfo !== null && question.questions.length > 0) {
          const { destination } = dragInfo

          if (!destination) {
            if (fromGroup) {
              isInsideGroup = true
            }
          } else if (destination !== null && destination.index > questionIndex) {
            if (fromGroup && destination.index < questionIndex + question.questions.length) {
              isInsideGroup = true
            } else if (
              !fromGroup &&
              destination.index <= questionIndex + question.questions.length
            ) {
              isInsideGroup = true
            }
          }
        }

        questionNode = (
          <Fragment key={`question-list-${prefix}${questionKey}`}>
            <div
              className={classnames(
                'group-question-add-zone',
                `group-question-add-zone-${prefix}${questionKey}`,
              )}
              data-group={`${prefix}${questionKey}`}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
            >
              <Draggable
                id={`question-list-${prefix}${questionKey}`}
                className={classnames(
                  'draggable-group-question',
                  this.props.groupIsDragging && 'group-is-dragging',
                )}
                index={questionIndex}
              >
                {questionNode}
              </Draggable>
              {groupNodes}
              <div
                className={classnames(
                  this.props.selectedQuestionGroup !== null &&
                    this.props.selectedQuestionGroup ===
                      `group-question-add-zone-${prefix}${questionKey}` &&
                    !this.props.groupIsDragging &&
                    !newQuestionIsAGroup &&
                    !isInsideGroup &&
                    'droppable dragging-over',
                  fromGroup && !isInsideGroup && 'from-group',
                )}
              />
            </div>
          </Fragment>
        )
      } else {
        questionNode = (
          <Draggable
            key={`question-list-${prefix}${questionKey}`}
            id={`question-list-${prefix}${questionKey}`}
            index={questionIndex}
          >
            {questionNode}
          </Draggable>
        )
      }

      return questionNode
    })

    return questionsNodes
  }

  constructor(props) {
    super(props)

    this.state = {
      removePopinIsOpen: false,
      questionKeyToDelete: null,
      editPopinIsOpen: false,
      editQuestionKey: null,
    }
  }

  render() {
    const { template } = this.props
    const { data } = template

    const questions = this.buildQuestionsNodes(0, data.questions)

    return (
      <Droppable id="questions" className="module-form-edit" type="question-list">
        {questions}
        <RemoveQuestionFromFormModulePopin
          onClose={this.handleCloseRemovePopin}
          onConfirm={this.handleDeleteQuestionConfirmation}
          open={this.state.removePopinIsOpen}
        />
        <EditQuestionFormModulePopin
          onClose={this.handleCloseEditPopin}
          open={this.state.editPopinIsOpen}
          questionKey={this.state.editQuestionKey}
        />
      </Droppable>
    )
  }
}
