import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModuleRow from '../../../components/molecules/ModuleRow/ModuleRow'
import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as ResourceTemplateActions } from '../../../redux/ResourceTemplateRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import Popin from '../../global/Popin/Popin'
import { withI18n } from '../../global/Translator/Translator'

const resourceTypes = {
  document: 'document',
  to_return_document: 'to_return_document',
  video: 'video',
  link: 'link',
}

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeTemplate: (id) => dispatch(ResourceTemplateActions.removeTemplate(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ResourceList extends Component {
  static propTypes = {
    t: PropTypes.func,
    resources: PropTypes.array.isRequired,
    editable: PropTypes.bool,
    onEditClick: PropTypes.func,
    onVideoClicked: PropTypes.func.isRequired,
  }

  state = {
    openRow: '',
    showConfirm: false,
    onEditClick: ({ id, type, title, url }) => undefined,
  }

  isEditable({ template_owner }) {
    if (!template_owner) {
      return false
    }

    const { user } = this.props
    const { role, profile_id } = user

    switch (role) {
      case 'admin':
        return true
      case 'consultant':
        return template_owner.consultant_id === profile_id
      default:
        return false
    }
  }

  handleChevron = ({ id }) => {
    this.state.openRow === id ? this.setState({ openRow: '' }) : this.setState({ openRow: id })
  }

  removePopin = () => {
    this.setState({ showConfirm: false, currentId: null })
  }

  handleConfirmRemove = () => {
    this.props.removeTemplate(this.state.currentId)
    this.removePopin()
  }

  handleEdit = ({ id }) => {
    const resource = this.props.resources.find((resource) => resource.id === id)

    this.props.onEditClick(resource)
  }

  renderConfirmClose = () => {
    const { t, removing } = this.props

    return (
      <Popin onClose={this.removePopin} open={this.state.showConfirm}>
        <AlertPopin
          disabled={removing}
          label={t('resource.remove_title')}
          text={t('resource.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={this.removePopin}
          onConfirmButtonClick={this.handleConfirmRemove}
        />
      </Popin>
    )
  }

  handleRemove = ({ id }) => {
    this.setState({ showConfirm: true, currentId: id })
  }

  renderResources = (resources) => {
    const mapType = {
      link: 'file',
      to_return_document: 'file-return',
    }

    return resources.map((resource, i) => {
      const { id, type, title, url } = resource
      const resourceType = mapType[type] || type

      return (
        <ModuleRow
          key={i}
          id={id}
          moduleType={resourceType}
          editable={this.isEditable(resource)}
          label={title}
          onEditClick={this.handleEdit}
          onRemoveClick={this.handleRemove}
          onChevronClick={this.handleChevron}
          onVideoClicked={this.props.onVideoClicked}
          url={url}
        />
      )
    })
  }

  renderResourceCategory = ({ type, resources }) => {
    const { t } = this.props
    const order = resources.length === 0 ? 4 : null

    return (
      <div style={{ order }} key={type}>
        <SectionTitle label={t(`resource_types.${type}`)} />
        {this.renderResources(resources)}
      </div>
    )
  }

  renderSections = (types) => {
    return Object.keys(types).map((type) =>
      this.renderResourceCategory({ type, resources: types[type] }),
    )
  }

  renderModuleCollections = () => {
    const document = []
    const to_return_document = []
    const video = []
    const link = []

    this.props.resources.map((resource) => {
      switch (resource.type) {
        case resourceTypes.document:
          return document.push(resource)
        case resourceTypes.to_return_document:
          return to_return_document.push(resource)
        case resourceTypes.video:
          return video.push(resource)
        case resourceTypes.link:
        default:
          return link.push(resource)
      }
    })

    return this.renderSections({ document, to_return_document, video, link })
  }

  render() {
    return (
      <>
        {this.renderConfirmClose()}
        <div className="resource-list">{this.renderModuleCollections()}</div>
      </>
    )
  }
}
