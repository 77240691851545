import ForgotPasswordForm from '../../../containers/auth/ForgotPasswordForm/ForgotPasswordForm'
import PortalPasswordPopin from '../PortalPasswordPopin/PortalPasswordPopin'
import PropTypes from 'prop-types'
import React from 'react'
import { usePortalCustomization } from '../../../hooks/usePortalCustomization'
import { useParams } from 'react-router'

const PortalForgotPasswordPopin = ({ onClose, done, onSubmit, disabled }) => {
  const { office } = useParams()
  const { labels } = usePortalCustomization({ slug: office })

  // eslint-disable-next-line no-unused-vars
  const _ = usePortalCustomization({ office })

  return (
    <PortalPasswordPopin
      onClose={onClose}
      title={labels?.forgotPasswordPrompt}
      description={
        done ? labels?.forgotPasswordSent : labels?.forgotPasswordHint
      }
      done={done}
    >
      <ForgotPasswordForm disabled={disabled} onSubmit={onSubmit} />
    </PortalPasswordPopin>
  )
}

PortalForgotPasswordPopin.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  done: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default PortalForgotPasswordPopin
