import { call, put } from 'redux-saga/effects'
import _pick from 'lodash/pick'

import config from '../config/AppConfig'
import { actions as OfficeActions } from '../redux/OfficeRedux'
import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'

export function* getOffices({ payload }) {
  const { filter } = payload

  yield call(services.office.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(OfficeActions.setOffices(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getOffice({ payload }) {
  const { id } = payload

  yield call(services.office.get, { id }, function* ({ ok, data }) {
    ok
      ? yield put(OfficeActions.setOffice(ok ? data : null))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeOffice({ payload }) {
  const { id } = payload

  yield call(services.office.remove, { id }, function* ({ ok, data }) {
    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getCurrentUserOffice() {
  yield call(services.office.getMine, null, function* ({ ok, data }) {
    ok
      ? yield put(OfficeActions.setOffice(ok ? data : null))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveCurrentUserOffice({ payload }) {
  const { office } = payload
  const fields = ['id', 'name', 'email', 'phone', 'image_url', 'region', 'emails']

  yield call(services.office.saveMine, _pick(office, fields), function* ({ ok, data }) {
    if (ok) {
      yield put(OfficeActions.setOffice(data))
      yield put(OfficeActions.addOffices([data]))
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveOffice({ payload }) {
  const { office, dontSet } = payload
  const { manager } = office
  const fields = ['id', 'name', 'email', 'phone', 'no_consultants', 'image_url', 'region']

  yield call(services.office.save, _pick(office, fields), function* ({ ok, data }) {
    if (ok) {
      if (office.id && manager && manager.email !== data.email) {
        yield call(services.auth.sendInvitation, { user_id: manager.id })
      }

      if (!dontSet) {
        yield put(OfficeActions.setOffice(data))
      }

      yield put(OfficeActions.addOffices([data]))
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function contactMyTalents() {
  window.location.assign(`mailto:${config.MAIL_MY_TALENTS}`)
}
