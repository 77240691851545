import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QuizStep from '../QuizStep/QuizStep'
import TextAreaField from '../TextAreaField/TextAreaField'

class QuizTextArea extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => undefined,
    name: '',
    value: '',
    stepLabel: null,
    maxLength: 0,
    disabled: false,
  }

  onChange = ({ value }) => {
    const { name, maxLength } = this.props
    let newValue = value

    if (maxLength > 0 && newValue.length > maxLength) {
      newValue = newValue.substring(0, maxLength)
    }

    this.props.onChange({ id: name, value: newValue })
  }

  render() {
    const {
      placeholder,
      name,
      value,
      stepLabel,
      label,
      stepOutline,
      maxLength,
      disabled,
    } = this.props

    return (
      <div className="quiz-text-area">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <TextAreaField
          name={name}
          onChange={this.onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
        <p className="text-area-field-printable">{value}</p>
        {maxLength > 0 && (
          <div className="text-length-container">
            <div className="text-length">{`${value.length}/${maxLength}`}</div>
          </div>
        )}
      </div>
    )
  }
}

export default QuizTextArea
