import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'

import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import OngoingStepModuleWrapper from '../../containers/consultant/OngoingStepModuleWrapper/OngoingStepModuleWrapper'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import ParcoursHeader from '../../components/molecules/ParcoursHeader/ParcoursHeader'
import ParcoursStepHeader from '../../components/molecules/ParcoursStepHeader/ParcoursStepHeader'

import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import Icon from '../../components/atoms/Icon/Icon'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import { stepDone } from '../../helpers/courseHelper'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state, props) => ({
  module_id: props.match.params.module_id || NavigationSelectors.query(state, 'module_id'),
  courseId: Number(props.match.params.course_id) || null,
  course: CourseSelectors.course(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCourse: (id) => dispatch(CourseActions.getCourse(id)),
  exportCourse: (id) => dispatch(CourseActions.exportCourse(id)),
  updateCourseValue: (key, value) => dispatch(CourseActions.updateCourseValue(key, value)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  back: () => dispatch(NavigationActions.back()),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryCoursePage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    courseToggled: true,
    openStep: 0,
  }

  constructor(props) {
    super(props)

    const { course } = props

    if (course) {
      this.state.openStep = course.openStep || 0
    }
  }

  renderHeaderCover = () => {
    return (
      <div
        className="cover-photo"
        style={{ backgroundImage: `url(${this.props.course.cover_url})` }}
      />
    )
  }

  handleParcourToggle = () => {
    this.setState(({ courseToggled }) => ({
      courseToggled: !courseToggled,
    }))
  }

  handleExportCourse = () => {
    const { courseId: id } = this.props
    this.props.exportCourse(id)
  }

  handleBack = () => {
    this.props.back()
  }

  handleStepToggle = ({ id }) => {
    const openStep = this.state.openStep === id ? '' : id

    this.props.updateCourseValue('openStep', openStep)
    this.setState(({ openStep }) => ({
      openStep,
    }))
  }

  renderCounts = () => {
    const { t, course } = this.props
    const { steps } = course
    const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)
    return (
      <div className="course-count">
        <div>
          {steps.length} <span>{steps.length > 1 ? t('step.steps') : t('step.step')}</span>
        </div>
        <div>
          {moduleCount} <span>{moduleCount > 1 ? t('module.modules') : t('module.module')}</span>
        </div>
      </div>
    )
  }

  renderDescription = () => {
    return <div className="course-description">{this.props.course.description}</div>
  }

  renderStepContent = (stepIndex, step, locked) => {
    return (
      <OngoingStepModuleWrapper
        step={step}
        module_id={this.props.module_id}
        stepIndex={stepIndex}
        isBeneficiary
        locked={locked}
      />
    )
  }

  checkProgress = (values) => {
    return values.every((value) => {
      return value.done === false
    })
      ? 'untouched'
      : 'ongoing'
  }

  renderSteps = () => {
    const { t, course } = this.props
    let prevStep

    return course.steps.map((step, i) => {
      const { title, modules, is_visible } = step
      let progState = 'finish'
      let locked = false

      const isDone = stepDone(step)

      if (!isDone && !prevStep) {
        progState = this.checkProgress(modules)
        prevStep = true
      } else if (prevStep) {
        progState = 'locked'
        locked = true
      }

      return (
        <ParcoursStepHeader
          key={i}
          id={i}
          open={this.state.openStep === i}
          isActivated={progState !== 'locked'}
          progState={progState}
          step={t('step.nthStep', { i: i + 1 })}
          title={title}
          collapsable
          onCollapseClick={this.handleStepToggle}
          deletable={false}
          isEditable={false}
          isVisible={is_visible}
          isLocked={locked}
        >
          <div className="course-content-list">{this.renderStepContent(i, step, locked)}</div>
        </ParcoursStepHeader>
      )
    })
  }

  renderBackButton = () => {
    return (
      <div className="back-button">
        <CircleButton icon={Icon.icon.Back} onClick={this.handleBack} />
      </div>
    )
  }

  renderCourseContent = () => {
    const { steps, id, title } = this.props.course
    const count = steps.reduce((acc, step) => acc + (step.done ? 1 : 0), 0)
    let icon = 'ongoing'

    if (count === steps.length) {
      icon = 'finish'
    } else if (count === 0) {
      icon = this.checkProgress(steps[0].modules)
    }

    return (
      <ParcoursHeader
        progState={icon}
        id={id}
        open={this.state.courseToggled}
        title={title}
        onClick={this.handleParcourToggle}
      >
        <div className="course-content">
          {this.renderCounts()}
          {this.renderDescription()}
          {this.renderExportButton()}
          {this.renderSteps()}
        </div>
      </ParcoursHeader>
    )
  }

  renderExportButton = () => {
    const { t } = this.props
    return (
      <div className="export-button">
        <PrimaryButton
          label={t('beneficiary.export_courses')}
          onClick={this.handleExportCourse.bind(this)}
          id="export-button"
        />
      </div>
    )
  }

  renderContent = () => {
    if (!this.props.course) return

    return (
      <div className="course-layout">
        {this.renderHeaderCover()}
        {this.renderBackButton()}
        {this.renderCourseContent()}
      </div>
    )
  }

  componentDidMount() {
    const { course, courseId: id } = this.props

    if (!course || course.id !== id) {
      this.props.getCourse(id)
    }
  }

  componentDidUpdate() {
    const { course } = this.props
    const { openStep: prevOpenStep } = this.state
    let openStep = ''

    if (course) {
      openStep = typeof course.openStep === 'undefined' ? 0 : course.openStep

      if (prevOpenStep !== openStep) {
        this.setState({
          openStep,
        })
      }
    }
  }

  render() {
    const aside = <BeneficiaryPageAside />

    return (
      <div className="beneficiary-course-page">
        <LeftAsideLayout aside={aside} content={this.renderContent()} />
      </div>
    )
  }
}
