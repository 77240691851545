import { Map } from 'immutable'
import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  files: {},
})

//
// Actions
//
export const actions = {
  setFile: createAction('setFile', (id, file) => ({ id, file })),
  upload: createAction('upload', (id, uploader) => ({ id, uploader })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setFile]: (state, { id, file }) => state.setIn(['files', id], file),
  },
  initialState,
)

//
// Selectors
//
const props = (state, ...props) => props
const files = (state) => state.upload.get('files')
const file = createSelector(files, props, (files, [id]) => files[id])

export const selectors = {
  file,
}
