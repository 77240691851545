import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import BeneficiaryProfileContent from '../../beneficiary/BeneficiaryProfileContent/BeneficiaryProfileContent'
import Page from '../../global/Page/Page'
import { withI18n } from '../../global/Translator/Translator'
import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'
import moment from 'moment'

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({})

const formProperties = {
  form: 'beneficiary-profile',
  asyncBlurFields: profileFieldsForConsultant,
  asyncChangeFields: profileFieldsForConsultant,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(props.beneficiary, values, true)
    return asyncValidate(values, validationRules)
  },
}

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm(formProperties)
export default class ConsultantBeneficiaryMaestrosContainer extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  isPrimary = () => {
    const { user, beneficiary } = this.props

    if (user.role === 'manager') {
      return true
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    )
  }

  renderContent = () => {
    const { beneficiary, user } = this.props

    const isPrimary = this.isPrimary()

    return (
      <BeneficiaryProfileContent
        user={beneficiary}
        onChange={this.handleChange}
        consultantEdition
        readOnly={!isPrimary}
        currentUserRole={user.role}
      />
    )
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  constructor(props) {
    super(props)

    this.state = {
      beneficiary: null,
      consultantNotes: null,
      resetBeneficiary: false,
      resetNotes: false,
      unblock: null,
    }
  }

  renderMaestro(maestro) {
    const { t } = this.props
    return (
      <div key={maestro.id} className={'maestro-wrapper'}>
        <h3>{maestro.name}</h3>
        <div className={'maestro-table'}>
          {maestro.columns.map((column, k) => (
            <div className={'maestro-column'} key={`maesto-column-${maestro.id}-${k}`}>
              <h4>{t(`consultant.maestros_col.${column.name}`)}</h4>
              <div className={'maestro-cards-wrapper'}>
                {column.applications.map((application) => this.renderApplicationCard(application))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderApplicationCard(application) {
    return (
      <div key={application.maestro_application_id} className={'maestro-card'}>
        <strong>{application.label}</strong>
        {!!application.company_name && <em>{application.company_name}</em>}
        {!!application.notes && (
          <div className={'card-notes'} dangerouslySetInnerHTML={{ __html: application.notes }} />
        )}
        <div className={'card-time'}>{moment.unix(application.moved_in_column_at).fromNow()}</div>
      </div>
    )
  }

  render() {
    const { maestros } = this.props
    return (
      <ConsultantBeneficiary renderAside={this.renderAside} disableSave>
        <div className="consultant-beneficiary-maestros-page">
          {maestros.map((maestro) => this.renderMaestro(maestro))}
        </div>
      </ConsultantBeneficiary>
    )
  }
}
