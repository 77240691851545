import React from 'react'
import DropDown from 'react-dropdown'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'

const BigCombo = ({id, title, value, options, onChange, dropDownProps}) => {
  const handleChange = (value) => {
    onChange({
      value,
      id,
    })
  }

  return <div className="big-combo">
    {title && <div className="common-form-label">{title}</div>}

    <div className="select-container">
      <DropDown options={options} onChange={handleChange} value={value} {...dropDownProps} />
      <Icon icon={Icon.icon.Combo} color={Icon.color.Accent}/>
    </div>
  </div>
}

BigCombo.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  dropDownProps: PropTypes.object,
}

export default BigCombo
