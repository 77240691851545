import { call, put, select } from 'redux-saga/effects'

import { services } from '../services'
import { actions as ModuleActions } from '../redux/ModuleRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../redux/NavigationRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as CourseActions, selectors as CourseSelectors } from '../redux/CourseRedux'

export function* getModule({ payload }) {
  const { module_id } = payload

  yield call(services.module.get, { module_id }, function* ({ ok, data }) {
    ok
      ? yield put(ModuleActions.setModule(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveModuleFormAnswers({ payload }) {
  const { module_id, answers } = payload

  yield call(
    services.module.saveModuleFormAnswers,
    {
      module_id,
      answers: { questions: answers },
    },
    function* ({ ok, data }) {
      if (ok) {
        yield put(ModuleActions.setModule(null))

        const pathname = yield select(NavigationSelectors.pathname)
        const newPath = `${pathname.replace(/\/forms\/\w+$/, '')}?module_id=${module_id}`

        yield put(NavigationActions.push(newPath))
      }

      yield put(NotificationActions.status({ ok, data }))
    },
  )
}

export function* saveSynthesisNotes({ payload }) {
  const { template, notes } = payload
  const { id, stepIndex, moduleIndex, description } = template

  yield call(services.module.saveSynthesisNotes, { module_id: id, notes, description }, function* ({
    ok,
    data,
  }) {
    if (ok) {
      const newTemplate = { ...template, data: { beneficiary_notes: notes } }
      yield put(CourseActions.updateModuleAtIndex(stepIndex, moduleIndex, newTemplate))
      const pathname = yield select(NavigationSelectors.pathname)
      yield put(NavigationActions.push(pathname.replace(/\/forms\/\w+$/, '')))
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveModuleCompleteStatus({ payload }) {
  const { module_id, done } = payload
  const course = yield select(CourseSelectors.course)

  yield call(services.module.saveModuleCompleteStatus, { module_id, done }, function* ({
    ok,
    data,
  }) {
    if (ok) {
      if (course) {
        yield put(CourseActions.getCourse(course.id))
      }
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}
