import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

import { withI18n } from '../../../containers/global/Translator/Translator'

import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps, null)
export default class ManagerBeneficiaryByAgeAndBilanStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  render() {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'age_16_25',
        title: t('statistics.beneficiaryCountByAgeAndBilan.age_16_25'),
        selector: 'age_16_25',
      },
      {
        id: 'age_26_44',
        title: t('statistics.beneficiaryCountByAgeAndBilan.age_26_44'),
        selector: 'age_26_44',
      },
      {
        id: 'age_45_100',
        title: t('statistics.beneficiaryCountByAgeAndBilan.age_45_100'),
        selector: 'age_45_100',
      },
    ]

    let data = []
    if (managerOfficeStats.beneficiaryCountByAgeAndBilan.length > 0) {
      const appraisalTypes = t('beneficiary_appraisal_types')
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByAgeAndBilan, 'label')
      const defaultData = {
        age_16_25: 0,
        age_26_44: 0,
        age_45_100: 0,
      }
      data = Object.keys(appraisalTypes).map((appraisalTypeKey, index) => {
        let values = defaultData
        if (dataValues[appraisalTypeKey]) {
          values = {
            ...values,
            ...dataValues[appraisalTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: appraisalTypes[appraisalTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="statsTable"
        title={t('statistics.beneficiaryCountByAgeAndBilan.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }
}
