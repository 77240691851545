import ApiService from './ApiService'

const api = ApiService

export const login = ({ email, password }) => {
  return api.post('/auth/login', { email, password })
}

export const refreshToken = ({ token, refresh_token }) => {
  const url = '/auth/refresh-token'
  const body = { refresh_token }

  return api.post(url, body, { headers: { Authorization: `Bearer ${token}` } })
}

export const logout = ({ token }) => {
  return api.post('/auth/logout', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const forgotPassword = ({ email }) => {
  return api.post('/auth/forgot-password', { email })
}

export const resetPassword = ({ user_id, code, password }) => {
  return api.post('/auth/reset-password', { user_id, code, password })
}

export const sendInvitation = ({ token, user_id }) => {
  const url = '/auth/send-invitation'
  const body = { user_id }

  return api.post(url, body, { headers: { Authorization: `Bearer ${token}` } })
}

export const impersonate = ({ token, userId }) => {
  const url = '/auth/impersonate'
  const body = { user_id: userId }

  return api.post(url, body, { headers: { Authorization: `Bearer ${token}` } })
}
