export default {
  statuses: {
    created: 'created',
    wait_financer: 'wait_financer',
    in_validation: 'in_validation',
    in_progress: 'in_progress',
    follow_up: 'follow_up',
    archived: 'archived',
  },
  financer_states: {
    to_fill: 'to_fill',
    pending: 'pending',
    done: 'done',
  },
  hidden_financer_types: ['opacif', 'opca'],
}
