import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'

export default class DisabledConsultantCard extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    avatar: PropTypes.string,
    onActivate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  handleActivate = () => {
    this.props.onActivate({ id: this.props.id })
  }

  render() {
    const { firstName, lastName, email, phone, avatar, id, disabled } = this.props

    return (
      <div className="disabled-consultant-card">
        <div className="top-container">
          <div className="avatar-container">
            <AvatarCircle photoUrl={avatar || ''} />
          </div>
          <div className="informations">
            <div className="fullname">
              {firstName} {lastName}
            </div>
            <div className="small-informations">
              <div className="entry email">{email}</div>
              <div className="entry phone">{phone}</div>
            </div>
          </div>
        </div>

        <div className="bottom-container">
          <CircleButton
            disabled={disabled}
            isActivated={!disabled}
            icon={Icon.icon.PersonPlus}
            id={id}
            onClick={this.handleActivate}
          />
        </div>
      </div>
    )
  }
}
