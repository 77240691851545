import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { selectors as NotificationSelectors } from '../../../redux/NotificationRedux'
import Notification from '../Notification/Notification'

const mapStateToProps = (state) => ({
  notifications: NotificationSelectors.notifications(state),
})

@connect(mapStateToProps, null)
export default class NotificationList extends PureComponent {
  renderNotifications() {
    return this.props.notifications.map(({ id, type, text }) => (
      <Notification key={id} id={id.toString()} text={text} type={type} />
    ))
  }

  render() {
    return <div className="notification-list">{this.renderNotifications()}</div>
  }
}
