import { Map } from 'immutable'
import _isEqual from 'lodash/isEqual'
import _mapKeys from 'lodash/mapKeys'
import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  template_: null,
  template: null,
  templates: {},
  question: null,
  groupIsDragging: false,
  selectedQuestionGroup: null,
  dragInfo: null,
})

//
// Actions
//
export const actions = {
  addTemplate: createAction('addModuleTemplate', (template) => ({ template })),
  addTemplates: createAction('addModuleTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  getTemplate: createAction('getModuleTemplate', (id) => ({ id })),
  getTemplates: createAction('getModuleTemplates', (filter) => ({ filter })),
  setResources: createAction('setResources', (resources) => ({ resources })),
  saveTemplate: createAction('saveModuleTemplate', (template) => ({ template })),
  setTemplate: createAction('setModuleTemplate', (template) => ({ template })),
  setTemplates: createAction('setModuleTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  updateTemplate: createAction('updateModuleTemplate', (template) => ({ template })),
  updateTemplateDeep: createAction('updateModuleTemplateDeep', (template) => ({ template })),
  removeTemplate: createAction('removeTemplate', (id) => ({ id })),
  setQuestion: createAction('setQuestion', (question) => ({ question })),
  setQuestions: createAction('setQuestions', (questions) => ({ questions })),
  setGroupIsDragging: createAction('setGroupIsDragging', (groupIsDragging) => ({
    groupIsDragging,
  })),
  setSelectedQuestionGroup: createAction('setSelectedQuestionGroup', (selectedQuestionGroup) => ({
    selectedQuestionGroup,
  })),
  setDragInfo: createAction('setDragInfo', (dragInfo) => ({ dragInfo })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.addTemplate]: (state, { template }) =>
      state.setIn(['templates', template.id], template),
    [actions.addTemplates]: (state, { templates }) => state.mergeDeep({ templates }),
    [actions.setResources]: (state, { resources }) =>
      state.mergeIn(['template', 'data'], { resources }),
    [actions.setTemplate]: (state, { template }) => state.merge({ template, template_: template }),
    [actions.setTemplates]: (state, { templates }) => state.merge({ templates }),
    [actions.updateTemplate]: (state, { template }) => state.mergeIn(['template'], template),
    [actions.updateTemplateDeep]: (state, { template }) =>
      state.mergeDeepIn(['template'], template),
    [actions.removeTemplate]: (state, { id }) => state.deleteIn(['templates', id]),
    [actions.setQuestion]: (state, { question }) => state.merge({ question }),
    [actions.setQuestions]: (state, { questions }) =>
      state.mergeIn(['template', 'data'], { questions }),
    [actions.setGroupIsDragging]: (state, { groupIsDragging }) => state.merge({ groupIsDragging }),
    [actions.setSelectedQuestionGroup]: (state, { selectedQuestionGroup }) =>
      state.merge({ selectedQuestionGroup }),
    [actions.setDragInfo]: (state, { dragInfo }) => state.merge({ dragInfo }),
  },
  initialState,
)

//
// Selectors
//
const template_ = (state) => state.moduleTemplate.get('template_')
const template = (state) => state.moduleTemplate.get('template')
const templates = (state) => state.moduleTemplate.get('templates')
const question = (state) => state.moduleTemplate.get('question')
const groupIsDragging = (state) => state.moduleTemplate.get('groupIsDragging')
const selectedQuestionGroup = (state) => state.moduleTemplate.get('selectedQuestionGroup')
const dragInfo = (state) => state.moduleTemplate.get('dragInfo')

const hasChanged = createSelector([template_, template], (a, b) => !_isEqual(a, b))
const getTemplatesArray = createSelector([templates], Object.values)

export const selectors = {
  template,
  templates: getTemplatesArray,
  hasChanged,
  question,
  groupIsDragging,
  selectedQuestionGroup,
  dragInfo,
}
