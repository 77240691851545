import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

import { withI18n } from '../../../containers/global/Translator/Translator'

import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps)
export default class ManagerGlobalStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderGlobalRevenueAmount = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'consultant',
        title: t('statistics.global_revenue_amount.by_consultant.consultant'),
        selector: 'label',
      },
      {
        id: 'revenue_ht',
        title: t('statistics.global_revenue_amount.by_consultant.revenue_ht'),
        selector: 'tax_excluded',
      },
      {
        id: 'revenue_tva',
        title: t('statistics.global_revenue_amount.by_consultant.revenue_tva'),
        selector: 'tax_included',
      },
    ]

    const defaultData = {
      consultant: '',
      revenue_ht: 0,
      revenue_tva: 0,
    }

    const data = managerOfficeStats.revenueByConsultant.map((item) => ({
      ...defaultData,
      ...item,
    }))

    return (
      <StatsTable
        title={t('statistics.global_revenue_amount.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderGlobalRevenueByAccompaniment = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: t('statistics.global_revenue_amount.by_accompaniment.type'),
        selector: 'label',
        highlight: true,
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.global_revenue_amount.by_accompaniment.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'tax_excluded',
        title: t('statistics.global_revenue_amount.by_accompaniment.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'taxable',
        title: t('statistics.global_revenue_amount.by_accompaniment.taxable'),
        selector: 'taxable',
      },
      {
        id: 'tax_free',
        title: t('statistics.global_revenue_amount.by_accompaniment.tax_free'),
        selector: 'tax_free',
      },
    ]

    let data = []

    if (managerOfficeStats.revenueByAccompaniment.length > 0) {
      const accompanimentTypes = t('beneficiary_accompaniment_types')
      const dataValues = _keyBy(managerOfficeStats.revenueByAccompaniment, 'label')
      const defaultData = {
        accompaniment_count: 0,
        tax_excluded: 0,
        taxable: 0,
        tax_free: 0,
      }
      data = Object.keys(accompanimentTypes).map((opacifTypeKey, index) => {
        let values = defaultData
        if (dataValues[opacifTypeKey]) {
          values = {
            ...values,
            ...dataValues[opacifTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: accompanimentTypes[opacifTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="statsTable"
        title={t('statistics.global_revenue_amount.by_accompaniment_title')}
        columns={columns}
        data={data}
        dataIdSelector="label"
      />
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className="statistics-section">
        <SectionTitle label={t('statistics.global_statistics')} />
        {this.renderGlobalRevenueAmount()}
        {this.renderGlobalRevenueByAccompaniment()}
      </div>
    )
  }
}
