import _pick from 'lodash/pick'
import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const prepareForSave = (template) => {
  const clone = JSON.parse(JSON.stringify(template))
  const fields = {
    module: [
      'id',
      'step_id',
      'type',
      'title',
      'description',
      'template_published',
      'order',
      'data',
    ],
    resource: ['type', 'title', 'url', 'order', 'private', 'url_uploaded'],
    appointment: [
      'rdv_at',
      'rdv_to',
      'status',
      'is_videocall',
      'user_from',
      'user_to',
      'private_notes',
      'public_notes',
    ],
    question: ['type', 'order', 'title', 'options'],
    questionOptions: ['required', 'max_length', 'choices', 'allow_other', 'scale'],
    centraltest: ['test_id', 'test_name'],
  }

  if (template.id) {
    fields.module.push('done')
    fields.resource.push('id')
    fields.question.push('id', 'done')
  }

  if (clone.type === 'text') {
    Object.assign(clone.data, {
      resources: clone.data.resources.map((resource) => _pick(resource, fields.resource)),
    })
  }

  if (clone.type === 'appointment') {
    clone.data = Object.assign(_pick(clone.data, fields.appointment))
  }

  if (clone.type === 'form') {
    Object.assign(clone.data, {
      questions: clone.data.questions.map((question) => {
        const questions =
          question.type === 'group'
            ? (question.questions || []).map((question) => _pick(question, fields.question))
            : undefined

        return Object.assign(_pick(question, fields.question), {
          questions,
          options: _pick(question.options, fields.questionOptions),
        })
      }),
    })
  }

  return Object.assign(_pick(clone, fields.module))
}

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })

  query.limit = 200

  return api.get('/module-templates', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const get = ({ token, id }) => {
  return api.get(`/module-templates/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const save = ({ token, ...data }) => {
  const prepared = prepareForSave(data)

  return data.id
    ? api.patch(`/module-templates/${data.id}`, prepared, {
        headers: { Authorization: `Bearer ${token}` },
      })
    : api.post(`/module-templates`, prepared, { headers: { Authorization: `Bearer ${token}` } })
}

export const remove = ({ token, id }) => {
  return api.delete(`/module-templates/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
