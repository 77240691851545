import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class PopinLayout extends Component {
  static propTypes = {
    header: PropTypes.node,
    content: PropTypes.node,
  }

  render() {
    const { header, content } = this.props

    return (
      <div className="layout popin-layout">
        <header>{header}</header>
        <section>{content}</section>
      </div>
    )
  }
}
