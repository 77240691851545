import { Map } from 'immutable'
import { createAction, createReducer } from 'redux-act'

let leaveCallback = null

export const getLeaveCallback = () => leaveCallback

//
// Initial state
//
const initialState = Map({
  started: false,
  loading: false,
  windowWidth: 0,
  displayLeavePopin: false,
})

//
// Actions
//
export const actions = {
  start: createAction('Starting app'),
  updateSize: createAction('Update window size'),
  showLeavePopin: createAction('showLeavePopin', (callback) => {
    leaveCallback = callback
  }),
  hideLeavePopin: createAction('hideLeavePopin'),
  setLoading: createAction('setLoading', (loading) => ({ loading })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.start]: (state) => state.merge({ started: true }),
    [actions.updateSize]: (state) => state.merge({ windowWidth: window ? window.innerWidth : 0 }),
    [actions.showLeavePopin]: (state) => state.merge({ displayLeavePopin: true }),
    [actions.hideLeavePopin]: (state) => state.merge({ displayLeavePopin: false }),
    [actions.setLoading]: (state, { loading }) => state.merge({ loading }),
  },
  initialState,
)

//
// Selectors
//
const started = (state) => state.app.get('started')
const loading = (state) => state.app.get('loading')
const windowWidth = (state) => state.app.get('windowWidth')
const displayLeavePopin = (state) => state.app.get('displayLeavePopin')

export const selectors = {
  loading,
  started,
  windowWidth,
  displayLeavePopin,
}
