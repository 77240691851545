import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActionPopin from '../ActionPopin/ActionPopin'
import ComboField from '../../molecules/ComboField/ComboField'

export default class BeneficiaryAttribPopin extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onBack: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
      selectLabel: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    onBack: () => undefined,
    onSubmit: ({ id }) => undefined,
  }

  state = {
    value: '',
  }

  handleChange = (option) => {
    this.setState({ value: option.value })
  }

  handleSubmit = () => {
    this.props.onSubmit({ id: this.state.value })
  }

  static getDerivedStateFromProps({ options }, state) {
    if (state.value === '') {
      return { value: (options[0] && options[0].value) || '' }
    }
    return null
  }

  render() {
    const { locale, options, onBack, children } = this.props
    const { value } = this.state

    return (
      <div className="beneficiary-attrib-popin">
        <ActionPopin
          title={locale.title}
          labelButton={locale.cta}
          onBack={onBack}
          onSubmit={this.handleSubmit}
        >
          <ComboField
            name="id"
            title={locale.selectLabel}
            options={options}
            onChange={this.handleChange}
            value={value}
            placeholder={locale.comboPlaceholder}
          />
          {children}
        </ActionPopin>
      </div>
    )
  }
}
