import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import CabinetCard from '../../components/organisms/CabinetCard/CabinetCard'
import CentralTestCard from '../../components/organisms/CentralTestCard/CentralTestCard'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import SubscribeCard from '../../components/organisms/SubscribeCard/SubscribeCard'
import FileUpload from '../../containers/global/FileUpload/FileUpload'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { history } from '../../navigation/History'
import { actions as OfficeActions, selectors as CabinetSelectors } from '../../redux/OfficeRedux'

const mapStateToProps = (state) => ({
  office: CabinetSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  setOffice: (office) => dispatch(OfficeActions.setOffice(office)),
  saveOffice: (office) => dispatch(OfficeActions.saveCurrentUserOffice(office)),
  contactMyTalents: () => dispatch(OfficeActions.contactMyTalents()),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerOfficePage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  static getDerivedStateFromProps({ office }, state) {
    if (state.office) {
      return null
    }

    return office ? { office } : null
  }

  state = {
    office: null,
    officeChange: false,
    unblock: null,
  }

  handleOfficeChange = ({ id, value, valid }) => {
    if (!id) {
      return
    }

    this.setState(({ office }) => {
      return {
        office: { ...office, [id]: value },
        officeChange: true,
      }
    })
  }

  handleFileChange = ({ file }) => {
    this.handleOfficeChange({ id: 'image_url', value: file.url })
  }

  handleSave = () => {
    this.props.saveOffice(this.state.office)
    this.setState({
      officeChange: false,
    })
  }

  handleContactClick = () => {
    this.props.contactMyTalents()
  }

  componentDidMount() {
    const unblock = history.block(() => {
      if (this.state.officeChange) {
        return 'true'
      }
    })

    this.setState({
      unblock,
    })

    this.props.getOffice()
  }

  componentWillUnmount() {
    if (this.state.unblock !== null) {
      this.state.unblock()
    }
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'office', label: t('consultant.my_office') }]

    const components = (
      <PrimaryButton id="save" label={t('actions.save')} onClick={this.handleSave} />
    )

    return <LineMenu value={links[0].id} links={links} components={components} />
  }

  renderContent() {
    const { office } = this.state

    if (!office) {
      return
    }

    const { t } = this.props
    const { email, name, phone, image_url, region, emails } = office

    const hasCentralTestRequiredAttributes = email && name && phone;

    return (
      <>
        <FileUpload id="image_url" accept="image/*" onChange={this.handleFileChange}>
          {({ onClick, uploading }) => (
            <CabinetCard
              logoUrl={image_url}
              email={email}
              emails={emails}
              name={name}
              phone={phone}
              region={region}
              uploading={uploading}
              onChange={this.handleOfficeChange}
              onFileClick={onClick}
              locale={{
                title: t('office.presentation'),
                emailTitle: t('form.email'),
                emailPlaceholder: t('form.email'),
                emailsTitle: t('form.emails'),
                emailsPlaceholder: t('form.emails_placeholder'),
                phoneTitle: t('form.phone'),
                phonePlaceholder: t('form.phone'),
                nameTitle: t('office.name'),
                namePlaceholder: t('office.name'),
                regionTitle: t('form.region'),
                regionPlaceholder: t('form.region'),
                regions: Object.values(t('office.regions')),
              }}
            />
          )}
        </FileUpload>

        <SubscribeCard
          labelTitle={t('office.subscription')}
          labelCount={t('office.no_consultants_label')}
          labelButton={t('office.contact_mytalents')}
          count={office.no_consultants}
          onButtonClick={this.handleContactClick}
        />

        <CentralTestCard canRegister={hasCentralTestRequiredAttributes}/>
      </>
    )
  }

  render() {
    const aside = <ManagerPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="manager-office-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
