import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withI18n } from '../../global/Translator/Translator'

import { selectors as ConsultantSelectors } from '../../../redux/ConsultantRedux'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'

import AvatarCard from '../../../components/organisms/AvatarCard/AvatarCard'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  consultant: ConsultantSelectors.consultant(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getMyConsultant: () => dispatch(BeneficiaryActions.getMyConsultant()),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryConsultant extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
  }

  handleSendMessage = () => {
    this.props.redirect(this.props.pathname.replace(/\/profile\/consultant$/, '/messages/new'))
  }

  componentDidMount() {
    this.props.getMyConsultant()
  }

  render() {
    const { t, consultant } = this.props

    if (!consultant) {
      return null
    }

    const cardAttr = {
      name: `${consultant.first_name} ${consultant.last_name}`,
      job: consultant.function || '',
      phone: consultant.phone || '',
      photoUrl: consultant.image_url || '',
      labelButton: t('actions.send_message'),
      onButtonClick: this.handleSendMessage,
    }

    return (
      <div className="beneficiary-consultant">
        <AvatarCard {...cardAttr} />
      </div>
    )
  }
}
