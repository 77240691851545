import ApiService from './ApiService'

const api = ApiService

export const getMe = ({ token }) => {
  return api.get('/users/me', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const searchUsers = ({ query, token }) => {
  return api.post('/users/search', { query }, { headers: { Authorization: `Bearer ${token}` } })
}

export const exportAll = ({ token }) => {
  return api.get('/users/export', null, { headers: { Authorization: `Bearer ${token}` } })
}
