import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import { createSelector } from 'reselect'
import _mapKeys from 'lodash/mapKeys'
import _omit from 'lodash/omit'
import _orderBy from 'lodash/orderBy'

//
// Initial state
//
const initialState = Map({
  messages: {},
  message: null,
  unreadMessages: 0,
})

//
// Actions
//
export const actions = {
  getMessage: createAction('getMessage', (id) => ({ id })),
  setMessage: createAction('setMessage', (message) => ({ message })),
  setMessages: createAction('setMessages', (messages) => ({ messages: _mapKeys(messages, 'id') })),
  getMessages: createAction('getMessages', (filter) => ({ filter })),
  setUnreadMessages: createAction('setUnreadMessages', (unread) => ({ unread })),
  getUnreadMessages: createAction('getUnreadMessages'),
  removeMessages: createAction('removeMessages', (ids, type) => ({ ids, type })),
  setRead: createAction('setRead', (id) => ({ id })),
  sendMessage: createAction('sendMessage', (message) => ({ message })),
  getMessageAndMarkAsRead: createAction('getMessageAndMarkAsRead', (id) => ({ id })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setMessage]: (state, { message }) => state.merge({ message }),
    [actions.setMessages]: (state, { messages }) => state.merge({ messages }),
    [actions.setUnreadMessages]: (state, { unread }) => state.set('unreadMessages', unread),
    [actions.removeMessages]: (state, { ids }) =>
      state.set('messages', _omit(state.get('messages'), ids)),
  },
  initialState,
)

//
// Selectors
//
const message = (state) => state.message.get('message')
const messages = (state) => _orderBy(state.message.get('messages'), ['created_at'], ['desc'])
const unreadMessages = (state) => state.message.get('unreadMessages')

const getMessagesArray = createSelector([messages], Object.values)

export const selectors = {
  message,
  unreadMessages,
  messages: getMessagesArray,
}
