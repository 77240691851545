import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ComboField from '../ComboField/ComboField'
import QuizStep from '../QuizStep/QuizStep'

class QuizDate extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    monthLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.string,
    dayTitle: PropTypes.string.isRequired,
    monthTitle: PropTypes.string.isRequired,
    yearTitle: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    value: '',
    stepLabel: null,
    onChange: () => undefined,
    disabled: false,
  }

  getDate() {
    const { value } = this.props
    const format = value && /^\d+\//.test(value) ? 'DD/MM/YYYY' : 'YYYY-MM-DD'

    return value ? moment(value, format) : moment()
  }

  handleChange = (newValue) => {
    const { id, onChange } = this.props
    const date = this.getDate()

    const year = newValue.id === 'year' ? newValue.value : date.year().toString()
    const month =
      newValue.id === 'month'
        ? newValue.value
        : date.month() + 1 < 10
        ? `0${date.month() + 1}`
        : (date.month() + 1).toString()
    const day = newValue.id === 'day' ? newValue.value : date.date().toString()

    onChange({ id, value: moment(`${day}/${month}/${year}`, 'DD/MM/YYYY').format('DD/MM/YYYY') })
  }

  render() {
    const {
      stepLabel,
      stepOutline,
      label,
      monthLabels,
      dayTitle,
      monthTitle,
      yearTitle,
      disabled,
    } = this.props
    const date = this.getDate()

    const year = date.year().toString()
    const month = date.month() + 1 < 10 ? `0${date.month() + 1}` : (date.month() + 1).toString()
    const day = date.date() < 10 ? `0${date.date()}` : date.date().toString()

    const currentYear = moment().year()
    const years = [...Array(currentYear + 20 - 1900)].map((y, index) => ({
      value: (1900 + index).toString(),
      label: (1900 + index).toString(),
    }))

    const months = [...Array(12)].map((m, index) => ({
      value: index + 1 < 10 ? `0${index + 1}` : (index + 1).toString(),
      label: monthLabels[index],
    }))

    const days = [...Array(date.daysInMonth())].map((d, index) => ({
      value: index + 1 < 10 ? `0${index + 1}` : (index + 1).toString(),
      label: index + 1 < 10 ? `0${index + 1}` : (index + 1).toString(),
    }))

    return (
      <div className="quiz-date">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <div className="date-container">
          <ComboField
            title={dayTitle}
            name="day"
            options={days}
            onChange={this.handleChange}
            value={day}
            readOnly={disabled}
          />
          <ComboField
            title={monthTitle}
            name="month"
            options={months}
            onChange={this.handleChange}
            value={month}
            readOnly={disabled}
          />
          <ComboField
            title={yearTitle}
            name="year"
            options={years}
            onChange={this.handleChange}
            value={year}
            readOnly={disabled}
          />
        </div>
      </div>
    )
  }
}

export default QuizDate
