import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../global/Translator/Translator'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  removing: WSSelectors.pending(state, 'beneficiary.remove'),
  removed: WSSelectors.resolved(state, 'beneficiary.remove'),
})

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(BeneficiaryActions.removeBeneficiary(id)),
  resetRemoveAction: () => dispatch(WSActions.reset('beneficiary.remove')),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantBeneficiaryRemove extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    this.props.remove(this.props.id)
  }

  componentDidUpdate() {
    if (this.props.removed) {
      this.props.onClose()
      this.props.resetRemoveAction()
    }
  }

  render() {
    const { onClose, t, removing } = this.props

    return (
      <Popin onClose={onClose} open>
        <AlertPopin
          disabled={removing}
          label={t('beneficiary.remove_title')}
          text={t('beneficiary.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleSubmit}
        />
      </Popin>
    )
  }
}
