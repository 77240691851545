import moment from 'moment/moment'

moment.updateLocale('fr')

export const dateWithFormatFn = (data, field, message, args, get) => {
  return new Promise((resolve, reject) => {
    if (args.length < 1) {
      return reject('rule needs to have date format as args.')
    }

    const fieldValue = get(data, field)

    if (fieldValue) {
      const dateFormat = args[0]

      if (fieldValue.length !== dateFormat.length) {
        return reject(message)
      }

      const dateMoment = moment(fieldValue, dateFormat, true)

      if (dateMoment.format() === 'Invalid Date') {
        return reject(message)
      }
    }

    resolve('validation passed')
  })
}

export const dateAfterFn = (data, field, message, args, get) => {
  return new Promise((resolve, reject) => {
    if (args.length < 2) {
      return reject('rule needs to have date format and before date as args.')
    }

    const fieldValue = get(data, field)

    if (fieldValue) {
      const dateFormat = args[0]
      const beforeDateValue = args[1]

      const afterMoment = moment(fieldValue, dateFormat)
      const beforeMoment = moment(beforeDateValue, dateFormat)

      if (!afterMoment.isAfter(beforeMoment)) return reject(message)
    }

    resolve('validation passed')
  })
}

export const dateAfterNowFn = (data, field, message, args, get) => {
  return new Promise((resolve, reject) => {
    if (args.length < 1) {
      return reject('rule needs to have date format as args.')
    }

    const fieldValue = get(data, field)

    if (fieldValue) {
      const dateFormat = args[0]

      const afterMoment = moment(fieldValue, dateFormat)
      const nowMoment = moment().subtract(1, 'days')

      if (!afterMoment.isAfter(nowMoment)) return reject(message)
    }

    resolve('validation passed')
  })
}
