import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'

//
// Initial state
//
const initialState = Map({
  templates: {},
})

//
// Actions
//
export const actions = {
  addTemplates: createAction('addResourceTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  saveTemplate: createAction('saveResourceTemplate', (template) => ({ template })),
  getTemplates: createAction('getResourceTemplates', (filter) => ({ filter })),
  setTemplates: createAction('setResourceTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  removeTemplate: createAction('removeTemplate', (id) => ({ id })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.addTemplates]: (state, { templates }) => state.mergeDeep({ templates }),
    [actions.setTemplate]: (state, { template }) => state.merge({ template, template_: template }),
    [actions.setTemplates]: (state, { templates }) => state.merge({ templates }),
    [actions.removeTemplate]: (state, { id }) => state.deleteIn(['templates', id]),
  },
  initialState,
)

//
// Selectors
//
const templatesRefs = (state) => state.resourceTemplate.get('templates')
const templates = createSelector(templatesRefs, (templates) => Object.values(templates))

export const selectors = {
  templates,
}
