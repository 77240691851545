import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as UserActions } from '../../redux/UserRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
import { withI18n } from '../../containers/global/Translator/Translator'
import ResetPasswordPopin from '../../components/organisms/ResetPasswordForm/ResetPasswordPopin'
import Logo from '../../../public/static/logos/logo-my-talents.svg'
import config from '../../config/AppConfig'

const mapStateToProps = (state) => ({
  isNew: NavigationSelectors.query(state, 'is_new') === '1',
  pending: WSSelectors.pending(state, 'auth.resetPassword'),
  code: NavigationSelectors.query(state, 'code'),
  userId: NavigationSelectors.query(state, 'user_id'),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(NavigationActions.push('/auth/login')),
  resetPassword: (userId, code, password) =>
    dispatch(UserActions.resetPassword(userId, code, password)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ResetPasswordPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    resetPassword: PropTypes.func,
  }

  static defaultProps = {
    isNew: false,
  }

  handleSubmit = ({ password }) => {
    const { code, userId } = this.props
    this.props.resetPassword(userId, code, password)
  }

  handleClose = () => {
    this.props.close()
  }

  render() {
    const { t, pending, isNew } = this.props

    return (
      <div className="reset-password-page">
        <Logo className="logo" />

        <ResetPasswordPopin
          onSubmit={this.handleSubmit}
          onClose={this.handleClose}
          disabled={pending}
          termsUrl={`${config.API_STATIC_URL}/documents/cgu_beneficiaire.pdf`}
          isNew={isNew}
          locale={{
            title: t(`reset_password.title${isNew ? '_new_user' : ''}`),
            description: t('reset_password.description'),
            cta: t('reset_password.reset_password'),
            passwordLabel: t('reset_password.password_label'),
            terms: t('reset_password.terms'),
          }}
        />
      </div>
    )
  }
}
