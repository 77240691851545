import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as UserActions } from '../../../redux/UserRedux'

import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import Icon from '../../../components/atoms/Icon/Icon'
import { withI18n } from '../../global/Translator/Translator'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  redirect: (path) => dispatch(NavigationActions.replace(path)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminPageAside extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
  }

  handleMenuChange = ({ id }) => {
    const link = this.getLinks().find((link) => link.id === id)

    if (!link || !link.options) {
      this.props.redirect(id)
    }
  }

  getLinks() {
    const { t } = this.props

    return [
      {
        id: '/admin/offices',
        label: t('customer.customers'),
        icon: Icon.icon.Building,
      },
      {
        id: '/admin/templates',
        label: t('template.templates'),
        icon: Icon.icon.Paperboard,
        options: [
          { id: '/admin/templates/courses', label: t('course.courses') },
          { id: '/admin/templates/modules', label: t('module.modules') },
          { id: '/admin/templates/resources', label: t('resource.resources') },
        ],
      },
      {
        id: '/admin/stats',
        label: t('stats.stats'),
        icon: Icon.icon.Piechart,
      },
    ]
  }

  renderHeader() {
    return (
      <img className="mytalents-logo" alt="Logo" src="/static/logos/logo-my-talents-dark.png" />
    )
  }

  renderMenu() {
    const { pathname } = this.props
    const links = this.getLinks()

    return (
      <SideMenu
        links={links}
        pathname={pathname}
        onClick={this.handleMenuChange}
        onClickOption={this.handleMenuChange}
      />
    )
  }

  renderFooter() {
    return <LogoutButton label={this.props.t('actions.logout')} onClick={this.props.logout} />
  }

  render() {
    return (
      <div className="admin-page-aside">
        <UserLeftColumnLayout
          header={this.renderHeader()}
          menu={this.renderMenu()}
          footer={this.renderFooter()}
        />
      </div>
    )
  }
}
