import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../IconButton/IconButton'
import Icon from '../Icon/Icon'

export default class IconChevron extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    color: PropTypes.oneOf(Object.values(Icon.color)),
    onClick: PropTypes.func,
  }

  static defaultProps = {
    color: 'accent',
    onClick: () => {},
  }

  handleClick = () => {
    this.props.onClick()
  }

  render() {
    const { open, color } = this.props

    return (
      <IconButton
        icon={open ? 'chevron-up' : 'chevron-down'}
        color={color}
        onClick={this.handleClick}
      />
    )
  }
}
