import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ParcoursStepHeader from "../../../components/molecules/ParcoursStepHeader/ParcoursStepHeader";
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors
} from "../../../redux/CourseTemplateRedux";
import OngoingStepModuleWrapper from "../../consultant/OngoingStepModuleWrapper/OngoingStepModuleWrapper";
import Draggable from "../../dragdrop/Draggable/Draggable";
import Droppable from "../../dragdrop/Droppable/Droppable";
import { withI18n } from "../../global/Translator/Translator";
import EditStepModuleWrapper from "../EditStepModuleWrapper/EditStepModuleWrapper";
import RemoveStepPopin from "../RemoveStepPopin/RemoveStepPopin";

const mapStateToProps = (state, props) => ({
  template: CourseTemplateSelectors.template(state),
  step: CourseTemplateSelectors.stepAtIndex(state, props.stepIndex)
});

const mapDispatchToProps = (dispatch, props) => ({
  updateStep: (value) => dispatch(CourseTemplateActions.updateStepAtIndex(props.stepIndex, value))
});

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class EditStepWrapper extends Component {
  static propTypes = {
    t: PropTypes.func,
    stepIndex: PropTypes.number.isRequired,
    course: PropTypes.object,
    step: PropTypes.object,
    editOngoing: PropTypes.bool,
    progState: PropTypes.string,
    locked: PropTypes.bool,
    onCollapseClick: PropTypes.func,
    module_id: PropTypes.string,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    module_id: "",
    onCollapseClick: () => {
    },
    locked: false,
    progState: null,
    editOngoing: false,
    readOnly: false
  };

  state = {
    popin: {
      remove: false
    }
  };

  toggleShowRemovePopin = () => {
    this.setState(({ popin }) => ({
      popin: { ...popin, remove: !popin.remove }
    }));
  };

  handleContentChange = ({ id, value }) => {
    this.props.updateStep({ ...this.props.step, [id]: value });
  };

  handleRemoveClick = () => {
    this.toggleShowRemovePopin();
  };

  handleToggleVisibleClick = (id) => {
    this.handleContentChange({ id: "is_visible", value: !this.props.step.is_visible });
  };

  handleCollapseClick = () => {
    this.props.onCollapseClick(this.props.stepIndex);
  };

  renderRemovePopin() {
    return (
      <RemoveStepPopin
        open={this.state.popin.remove}
        stepIndex={this.props.stepIndex}
        onClose={this.toggleShowRemovePopin}
      />
    );
  }

  renderStepContent = () => {
    const { step, locked, stepIndex, module_id } = this.props;

    return (
      <OngoingStepModuleWrapper
        module_id={module_id}
        step={step}
        stepIndex={stepIndex}
        locked={locked}
      />
    );
  };

  renderTemplateStepModules() {
    const { step, stepIndex, editOngoing, readOnly } = this.props;

    if (editOngoing) return this.renderStepContent();

    return step.modules.map(({ id, _id }, i) => {
      const key = (id || _id).toString();

      const editStepModuleWrapperNode = (
        <EditStepModuleWrapper
          key={key}
          stepIndex={stepIndex}
          moduleIndex={i}
          readOnly={readOnly}
        />
      );

      if (readOnly) {
        return editStepModuleWrapperNode;
      }

      return (
        <Draggable key={key} id={key} index={i} direction="vertical">
          {editStepModuleWrapperNode}
        </Draggable>
      );
    });
  }

  renderStep() {
    const { t, step, stepIndex, editOngoing, progState, open, readOnly } = this.props;
    const { title, is_visible } = step;
    const cards = this.renderTemplateStepModules();
    const droppableProps = { stepIndex };

    return (
      <div className={classNames("course-step", open && "open")}>
        <ParcoursStepHeader
          id={stepIndex}
          step={t("step.nthStep", { i: stepIndex + 1 })}
          title={title}
          placeholder={t("step.title")}
          open={editOngoing ? open : true}
          isEditable={!readOnly}
          deletable={!readOnly}
          collapsable={!!editOngoing}
          isActivated={progState !== "locked"}
          isVisible={is_visible}
          progState={progState}
          onChange={this.handleContentChange}
          onRemoveClick={this.handleRemoveClick}
          onToggleVisibleClick={this.handleToggleVisibleClick}
          onCollapseClick={this.handleCollapseClick}
        >
          {!readOnly && (
            <Droppable
              id={`step-${step.id}`}
              type="module"
              props={droppableProps}
              direction="vertical"
              className="modules"
            >
              {cards}
            </Droppable>
          )}

          {readOnly && <div className="modules">{cards}</div>}
        </ParcoursStepHeader>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderStep()}
        {!this.props.readOnly && this.renderRemovePopin()}
      </>
    );
  }
}
