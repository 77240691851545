import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import {
  actions as MessageActions,
  selectors as MessageSelectors,
} from '../../../redux/MessageRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as UserActions, selectors as UserSelectors } from '../../../redux/UserRedux'
import { useI18n } from '../../../hooks/useI18n'
import { useNavigation } from '../../../hooks/useNavigation'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'

const ConsultantPageAside = () => {
  const dispatch = useDispatch()
  const office = useSelector(OfficeSelectors.office)
  const user = useSelector(UserSelectors.user)
  const unreadMessages = useSelector(MessageSelectors.unreadMessages)
  const isActive = useSelector(portalSelectors.isActive)
  const { t } = useI18n()
  const { pathname, redirect } = useNavigation()

  const handleMenuChange = ({ id }) => {
    const link = getLinks().find((link) => link.id === id)

    if (!link || !link.options) {
      redirect(id)
    }
  }

  useEffect(() => {
    dispatch(OfficeActions.getCurrentUserOffice())
    dispatch(MessageActions.getUnreadMessages())
  }, [dispatch])

  const logout = () => dispatch(UserActions.logout({force: true}))

  const getLinks = () => [
    {
      id: '/consultant/beneficiaries',
      label: t('consultant.beneficiary'),
      options: [
        { id: '/consultant/beneficiaries/me#primary', label: t('consultant.my_beneficiaries') },
        {
          id: '/consultant/beneficiaries/me#shared',
          label: t('consultant.shared_beneficiaries'),
        },
      ],
      icon: Icon.icon.People,
    },
    {
      id: '/consultant/templates',
      label: t('template.templates'),
      icon: Icon.icon.Paperboard,
      options: [
        { id: '/consultant/templates/courses', label: t('course.courses') },
        { id: '/consultant/templates/modules', label: t('module.modules') },
        { id: '/consultant/templates/resources', label: t('resource.resources') },
      ],
    },
    {
      id: '/consultant/calendar',
      label: t('calendar_labels.calendar'),
      icon: Icon.icon.Calendar,
    },
    {
      id: '/consultant/messages',
      label: t('message.messages'),
      icon: Icon.icon.Inbox,
      count: unreadMessages,
    },
    {
      id: '/consultant/profile',
      label: t('user.profile'),
      icon: Icon.icon.Settings,
    },
  ]

  const tutorialButton = () => (
    <div className="tutorial">
      <Icon icon="help" color="accent" />
      <a href={t('consultant_tutorial.url')} target="_blank">
        {t('consultant_tutorial.tutorial')}
      </a>
    </div>
  )

  const renderHeader = () => {
    if (!office || !user) {
      return null
    }

    return (
      <>
        <div className="logos">
          {!isActive && (
            <>
              <img
                className="mytalents-logo"
                alt="Logo"
                src="/static/logos/logo-my-talents-dark.png"
              />
              <div className="separator" />
            </>
          )}
          <img className="office-logo" src={office.image_url} alt={office.name} />
        </div>
        <div className="user">
          <div className="user-image" style={{ backgroundImage: `url("${user.image_url}")` }} />
          <div className="user-name">
            {user.first_name} {user.last_name}
          </div>
          <div className="function">{user.function}</div>
          {tutorialButton()}
        </div>
      </>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return (
      <SideMenu
        links={links}
        pathname={pathname}
        onClick={handleMenuChange}
        onClickOption={handleMenuChange}
      />
    )
  }

  const renderFooter = () => <LogoutButton label={t('actions.logout')} onClick={logout} />

  if (!user) {
    return null
  }

  return (
    <div className="consultant-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()} />
    </div>
  )
}

export default ConsultantPageAside
