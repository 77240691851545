import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'

export default class ModuleResourceRow extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOf([
      ...Object.values(IconResourceType.resourceType),
      ...Object.values(IconModuleType.moduleType),
    ]),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired.isRequired,
      }),
    ),

    onActionClick: PropTypes.func,
  }

  static defaultProps = {
    actions: [],
    onActionClick: ({ id, action }) => undefined,
  }

  handleActionClick = (e) => {
    this.props.onActionClick({
      id: this.props.id,
      action: e.currentTarget.id,
    })
  }

  handleClick = () => {
    this.props.onActionClick({
      id: this.props.id,
      action: 'edit',
    })
  }

  renderActionIcon() {
    return this.props.actions.map((action, i) => (
      <div key={i} className="action-container" id={action.id} onClick={this.handleActionClick}>
        <Icon icon={action.icon} color={Icon.color.Grey2} />
      </div>
    ))
  }

  renderTitle() {
    return <div className="label">{this.props.label}</div>
  }

  renderActions() {
    const { actions } = this.props

    if (!actions) {
      return null
    }

    return <div className="actions">{this.renderActionIcon()}</div>
  }

  render() {
    const { icon } = this.props

    return (
      <div className="module-resource-row">
        <Icon icon={icon} color={Icon.color.Grey3} />
        {this.renderTitle()}
        {this.renderActions()}
      </div>
    )
  }
}
