import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'

//
// Initial state
//
const initialState = Map({
  impersonateUsers: null,
})

//
// Actions
//
export const actions = {
  searchImpersonateUsers: createAction('searchImpersonateUsers', (search) => ({ search })),
  setImpersonateUsers: createAction('setImpersonateUsers', (impersonateUsers) => ({
    impersonateUsers,
  })),
  resetImpersonateUsers: createAction('resetImpersonateUsers'),
  impersonate: createAction('impersonate', (userId) => ({ userId })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setImpersonateUsers]: (state, { impersonateUsers }) =>
      state.merge({ impersonateUsers }),
    [actions.resetImpersonateUsers]: (state) => state.merge({ impersonateUsers: null }),
  },
  initialState,
)

//
// Selectors
//
const impersonateUsers = (state) => state.impersonate.get('impersonateUsers')

export const selectors = {
  impersonateUsers,
}
