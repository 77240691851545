import PropTypes from 'prop-types'
import React, { Component } from 'react'

class OAuthPopup extends Component {
  static propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    title: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    type: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    onChange: () => null,
    title: 'oauth',
    url: null,
    width: null,
    height: null,
    type: 'code',
  }

  createPopup = () => {
    const { url, width, height, title } = this.props
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`)
  }

  onMessage = (e) => {
    const { onChange, type } = this.props
    if (e?.data?.type && e?.data?.type === type) {
      onChange(e.data)
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage)
  }

  render() {
    const { children } = this.props
    return (
      <div className="oauth-popup-wrapper" onClick={this.createPopup}>
        {children}
      </div>
    )
  }
}

export default OAuthPopup
