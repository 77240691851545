import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../global/Translator/Translator'
import { actions as ConsultantActions } from '../../../redux/ConsultantRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'

import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapStateToProps = (state) => ({
  activating: WSSelectors.pending(state, 'consultant.activate'),
  activated: WSSelectors.resolved(state, 'consultant.activate'),
  inError: WSSelectors.error(state, 'consultant.activate'),
})

const mapDispatchToProps = (dispatch) => ({
  activate: (id) => dispatch(ConsultantActions.activateConsultant(id)),
  resetActivateAction: () => dispatch(WSActions.reset('consultant.activate')),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerConsultantActivate extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    this.props.activate(this.props.id)
  }

  componentDidUpdate() {
    if (this.props.activated || this.props.inError) {
      this.props.onClose({})
      this.props.resetActivateAction()
    }
  }

  render() {
    const { onClose, t, activating } = this.props

    return (
      <Popin onClose={onClose} open>
        <AlertPopin
          disabled={activating}
          label={t('consultant.activate_title')}
          text={t('consultant.activate_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.activate')}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleSubmit}
        />
      </Popin>
    )
  }
}
