import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withI18n } from '../../global/Translator/Translator'
import ComboFilterField from '../../../components/molecules/ComboFilterField/ComboFilterField'
import ManagerConsultantBeneficiaryAttribute from '../ManagerConsultantBeneficiaryAttribute/ManagerConsultantBeneficiaryAttribute'
import CandidatCard from '../../../components/organisms/CandidatCard/CandidatCard'
import { connect } from 'react-redux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as ResourceTemplateActions } from '../../../redux/ResourceTemplateRedux'
import { selectors as WSSelectors } from '../../../redux/WSRedux'

const accompanimentTypes = {
  appraisal: 'appraisal',
  outplacement_individual: 'outplacement_individual',
  outplacement_group: 'outplacement_group',
  formation: 'formation',
  vae: 'vae',
  coaching: 'coaching',
  orientation_review: 'orientation_review',
  business_creation: 'business_creation',
  collective_scheme: 'collective_scheme',
  other: 'other',
}

const financerState = {
  to_fill: 'to_fill',
  pending: 'pending',
  done: 'done',
}
const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  pending: WSSelectors.pending(state, 'beneficiary.attribute'),
  resolved: WSSelectors.resolved(state, 'beneficiary.attribute'),
})

const mapDispatchToProps = (dispatch) => ({
  removeTemplate: (id) => dispatch(ResourceTemplateActions.removeTemplate(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerConsultantBeneficiaries extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        image_url: PropTypes.string,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  state = {
    beneficiary: null,
    attributeResolved: false,
    status: 'in_progress',
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.resolved && this.state.beneficiary) {
      this.setState({ attributeResolved: true })
    }
  }

  handleFilter = ({ value }) => {
    this.setState({ status: value })
  }

  calculateProgress = (start, end, today) => {
    if (start > today) {
      return 0
    }

    if (end < today) {
      return 100
    }

    return Number.parseInt(((end - today) / (end - start)) * 100, 10)
  }

  checkAccompanimentType = (type, t) => {
    switch (type) {
      case accompanimentTypes.appraisal:
        return t('beneficiary_accompaniment_types.appraisal')
      case accompanimentTypes.outplacement_individual:
        return t('beneficiary_accompaniment_types.outplacement_individual')
      case accompanimentTypes.vae:
        return t('beneficiary_accompaniment_types.vae')
      case accompanimentTypes.coaching:
        return t('beneficiary_accompaniment_types.coaching')
      case accompanimentTypes.business_creation:
        return t('beneficiary_accompaniment_types.business_creation')
      case accompanimentTypes.orientation_review:
        return t('beneficiary_accompaniment_types.orientation_review')
      case accompanimentTypes.collective_scheme:
        return t('beneficiary_accompaniment_types.collective_scheme')
      case accompanimentTypes.outplacement_group:
        return t('beneficiary_accompaniment_types.outplacement_group')
      case accompanimentTypes.formation:
        return t('beneficiary_accompaniment_types.formation')
      case accompanimentTypes.other:
      default:
        return t('beneficiary_accompaniment_types.other')
    }
  }

  checkFinancerState = (state, t) => {
    switch (state) {
      case financerState.to_fill:
        return { label: t('beneficiary_financer_states.to_fill'), color: 'red1' }
      case financerState.pending:
        return { label: t('beneficiary_financer_states.pending'), color: 'orange1' }
      case financerState.done:
      default:
        return { label: t('beneficiary_financer_states.done'), color: 'green1' }
    }
  }

  handleSelectBeneficiary = (value) => {
    const { beneficiaries } = this.props
    const selectedId = value && Number(value.id)
    const beneficiary = value ? beneficiaries.find(({ id }) => id === selectedId) : null

    this.setState({ beneficiary, attributeResolved: false })
  }

  renderFilter() {
    const { t } = this.props
    const { status } = this.state
    const statuses = { '': t('beneficiary.status_all'), ...t('beneficiary.statuses') }
    const options = Object.keys(statuses).map((key) => ({
      value: key,
      label: statuses[key],
    }))

    return (
      <div className="status-filter">
        <ComboFilterField
          id="status"
          options={options}
          value={status}
          title={t('beneficiary.status_label')}
          onChange={this.handleFilter}
        />
      </div>
    )
  }

  handleBeneficiaryClick = (id) => {
    this.props.redirect(this.props.pathname + `/beneficiaries/${id}/courses`)
  }

  renderBeneficiaries() {
    const { t, beneficiaries } = this.props
    const locale = {
      financeStateLabel: t('financer.financer_state'),
      lengthLabel: t('accompaniment.duration'),
      reasonLabel: t('beneficiary_card.reason'),
      typeOfAccompanimentLabel: t('accompaniment.accompaniment_type'),
      programFollowedLabel: t('beneficiary_card.enrolled_course'),
      nextAppointmentLabel: t('beneficiary_card.next_appointment'),
    }
    const { status } = this.state
    const rows = beneficiaries
      .filter((beneficiary) => !status || beneficiary.status === status)
      .map((beneficiary, i) => {
        const { isPrimary } = this.props
        const {
          status,
          first_name,
          last_name,
          email,
          phone_mobile,
          image_url,
          accompaniment_start_date,
          accompaniment_end_date,
          accompaniment_duration,
          accompaniment_duration_type,
          financer_state,
          accompaniment_type,
          phone_home,
          archived_reason = null,
          id,
          next_rdv,
          is_primary,
        } = beneficiary

        const primaryConsultant =
          beneficiary.consultants && beneficiary.consultants.find(({ is_primary }) => is_primary)

        const { course, count_notifications, count_messages } = beneficiary
        const startDate = new Date(accompaniment_start_date)
        const endDate = new Date(accompaniment_end_date)
        const todayDate = new Date()
        const percentage = accompaniment_start_date
          ? this.calculateProgress(startDate, endDate, todayDate)
          : 0
        const courseLength = `${Number.parseInt(accompaniment_duration, 10)} ${t(
          `beneficiary_accompaniment_duration_types.${accompaniment_duration_type}`,
        )}`
        const typeOfAccompaniment = this.checkAccompanimentType(accompaniment_type, t)
        const financerState = this.checkFinancerState(financer_state, t)

        return (
          <CandidatCard
            key={i}
            id={id}
            status={{ status, label: t(`beneficiary.statuses.${status}`) }}
            name={`${first_name} ${last_name}`}
            email={email}
            phone={phone_mobile || phone_home || ''}
            avatar={image_url || ''}
            locale={locale}
            color={financerState.color}
            length={courseLength}
            accompaniment_type={typeOfAccompaniment}
            financer_state={financerState.label}
            percentage={percentage}
            programFollowed={course && course.title}
            mail={!!count_messages}
            notification={!!count_notifications}
            reason={archived_reason ? t(`beneficiary_archived_reasons.${archived_reason}`) : ''}
            onClick={this.handleBeneficiaryClick}
            onNotificationsClick={this.handleNotificationsClick}
            onMessagesClick={this.handleMessagesClick}
            onDeleteClick={this.handleDeleteClick}
            nextAppointment={next_rdv}
            isDeletable={isPrimary}
            isManager
            onAttributeClick={this.handleSelectBeneficiary}
            attributeLabelButton={t('beneficiary.attribute')}
            attributeReadonly={is_primary}
            primaryConsultant={primaryConsultant}
          />
        )
      })

    return rows.length ? (
      <div className="beneficiaries">{rows}</div>
    ) : (
      <div className="no-result">{t('beneficiary.no_result')}</div>
    )
  }

  renderAttributePopin() {
    const { beneficiary, attributeResolved } = this.state

    return (
      <ManagerConsultantBeneficiaryAttribute
        isOpen={!!beneficiary}
        beneficiary={beneficiary}
        onClose={this.handleSelectBeneficiary}
        resolved={attributeResolved}
        pending={this.props.pending}
      />
    )
  }

  render() {
    return (
      <div className="manager-consultant-beneficiaries">
        {this.renderFilter()}
        {this.renderBeneficiaries()}
        {this.renderAttributePopin()}
      </div>
    )
  }
}
