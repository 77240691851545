import { call, put, select } from 'redux-saga/effects'

import { services } from '../services'
import { actions as StatsActions, selectors as StatsSelectors } from '../redux/StatsRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { selectors as UserSelectors } from '../redux/UserRedux'

export function* getAdminStats() {
  yield call(services.stats.get, null, function* ({ ok, data }) {
    ok
      ? yield put(StatsActions.setAdminStats(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getManagerOfficeStats() {
  const statsYear = yield select(StatsSelectors.managerOfficeStatsYear)

  yield call(services.stats.getManagerOfficeStats, { statsYear }, function* ({ ok, data }) {
    ok
      ? yield put(StatsActions.setManagerOfficeStats(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getConsultantStats() {
  const user = yield select(UserSelectors.user)
  if (user.role === 'beneficiary') {
    return
  }
  const currentConsultant = yield select(StatsSelectors.consultantStatsCurrentConsultant)
  if (currentConsultant !== null) {
    const statsYear = yield select(StatsSelectors.managerOfficeStatsYear)

    yield call(services.stats.getConsultantStats, { currentConsultant, statsYear }, function* ({
      ok,
      data,
    }) {
      ok
        ? yield put(StatsActions.setConsultantStats(data))
        : yield put(NotificationActions.status({ ok, data }))
    })
  }
}

export function* setManagerOfficeStatsYear() {
  yield put(StatsActions.getManagerOfficeStats())
  yield put(StatsActions.getConsultantStats())
}

export function* setConsultantStatsCurrentConsultant() {
  yield put(StatsActions.getConsultantStats())
}

export function* initConsultantStats() {
  const consultantsResult = yield call(services.manager.getAllConsultants)

  if (consultantsResult.ok) {
    if (consultantsResult.data.data && consultantsResult.data.data.length > 0) {
      const consultants = consultantsResult.data.data

      yield put(StatsActions.setConsultants(consultants))
      yield put(StatsActions.setConsultantStatsCurrentConsultant(consultants[0].id))
    }
  } else {
    yield put(NotificationActions.status(consultantsResult))
  }
}
