import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectors as CourseSelectors } from '../../../redux/CourseRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'

import CourseRemove from '../CourseRemove/CourseRemove'
import { withI18n } from '../../global/Translator/Translator'
import ParcoursCard from '../../../components/organisms/ParcoursCard/ParcoursCard'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  user: UserSelectors.user(state),
  courses: CourseSelectors.courses(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class CourseList extends Component {
  static propTypes = {
    t: PropTypes.func,
    onCardClick: PropTypes.func,
  }

  static defaultProps = {
    onCardClick: ({ id }) => undefined,
  }

  handleCardClick = ({ id }) => {
    this.props.onCardClick({ id })
  }

  handleRemoveClick = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}/remove`)
  }

  handleClosePopin = () => {
    this.props.redirect(this.props.pathname.replace(/\/courses\/.+$/, '/courses'))
  }

  renderRemovePopin() {
    if (!/\/\d+\/remove/.test(this.props.pathname)) {
      return null
    }

    return <CourseRemove onClose={this.handleClosePopin} />
  }

  renderCards() {
    const { beneficiary, user } = this.props

    const { consultants = [] } = beneficiary

    const currentConsultant = consultants.find((consultant) => {
      return consultant.profile_id === user.profile_id && consultant.is_primary
    })

    const { courses } = this.props
    const cards = courses.map(({ id, title, cover_url, steps, percentage_done }) => {
      const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

      return (
        <ParcoursCard
          course={{
            id,
            coverUrl: cover_url,
            title,
            stepsCount: steps.length,
            modulesCount: moduleCount,
            isRemovable: !!currentConsultant,
            percentage_done,
          }}
          key={id}
          onCardClick={this.handleCardClick}
          onRemoveClick={this.handleRemoveClick}
        />
      )
    })

    return <div className="courses">{cards}</div>
  }

  render() {
    return (
      <div className="course-list">
        {this.renderCards()}
        {this.renderRemovePopin()}
      </div>
    )
  }
}
