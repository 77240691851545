import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../atoms/IconButton/IconButton'

class LabelRow extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    onAction: PropTypes.func,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
      }),
    ),
  }

  static defaultProps = {
    onAction: ({ id, action }) => undefined,
  }

  handleClick = ({ id: action }) => {
    this.props.onAction({
      action,
      id: this.props.id,
    })
  }

  render() {
    const { label, actions } = this.props
    return (
      <div className="label-row">
        <div className="label">{label}</div>
        <div className="actions">
          {actions.map((action, i) => (
            <IconButton
              key={i}
              icon={action.icon}
              onClick={this.handleClick}
              id={action.value}
              color="grey2"
            />
          ))}
        </div>
      </div>
    )
  }
}

export default LabelRow
