import React, { Component } from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import Modal from 'react-modal'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'

const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

class VideoPopin extends Component {
  static propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  onClose = () => {
    this.props.onClose()
  }

  renderVideo() {
    const { title, videoId } = this.props
    const match = videoId.match(regex)
    const id = match && match[7].length ? match[7] : videoId

    return (
      <div className="video-popin">
        <div className="head">
          <Icon icon="video" color="accent" />
          <div className="title">{title}</div>
          <CircleButton id="circle-button" onClick={this.onClose} icon="close" />
        </div>
        <div className="video-container">
          <YouTube videoId={id} className="youtube" />
        </div>
      </div>
    )
  }

  render() {
    const { open, onClose, overrideModalStyles } = this.props
    const styles = {
      overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 10000 },
      content: { padding: 0, ...overrideModalStyles },
    }

    return (
      <Modal isOpen={open} onRequestClose={onClose} style={styles}>
        {this.renderVideo()}
      </Modal>
    )
  }
}

export default VideoPopin
