import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  group: null,
  groups: {},
})

//
// Actions
//
export const actions = {
  addGroups: createAction('addGroup', (groups) => ({ groups: _mapKeys(groups, 'id') })),
  getCurrentUserGroups: createAction('getCurrentUserGroups'),
  getGroup: createAction('getGroup', (id) => ({ id })),
  removeGroup: createAction('removeGroup', (id) => ({ id })),
  setGroup: createAction('setGroup', (group) => ({ group })),
  setGroups: createAction('setGroups', (groups) => ({ groups: _mapKeys(groups, 'id') })),
  saveGroup: createAction('saveGroup', (group) => ({ group })),

  resetGroups: createAction('resetGroups'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.removeGroup]: (state, { id }) => state.deleteIn(['groups', id]),
    [actions.setGroup]: (state, { group }) => state.merge({ group }),
    [actions.addGroups]: (state, { groups }) => state.mergeDeep({ groups }),
    [actions.setGroups]: (state, { groups }) => state.merge({ groups }),

    [actions.resetGroups]: (state) => state.merge({ groups: {} }),
  },
  initialState,
)

//
// Selectors
//
const root = (state) => state.beneficiaryGroups.toJS()
const groups = createSelector(root, ({ groups }) => Object.values(groups))
const group = createSelector(root, ({ group }) => group)

export const selectors = {
  group,
  groups,
}
