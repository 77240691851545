import PropTypes from 'prop-types'
import React, { Component } from 'react'
import OAuthPopup from '../OAuthPopup/OAuthPopup'
import classnames from 'classnames'

class CronofyConnect extends Component {
  static propTypes = {
    error: PropTypes.string,
    help: PropTypes.string,
    url: PropTypes.string,
    onChange: PropTypes.func, // {name, value, event}
    width: PropTypes.number,
    height: PropTypes.number,
    popupTitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    unlinkLabel: PropTypes.string,
    editLabel: PropTypes.string,
    pendingLabel: PropTypes.string,
    successLabel: PropTypes.string,
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    error: null,
    help: null,
    onChange: () => undefined,
    url: '',
    width: 375,
    height: 790,
    popupTitle: undefined,
    buttonLabel: '',
    unlinkLabel: '',
    editLabel: '',
    pendingLabel: '',
    successLabel: '',
    isPending: false,
    isSuccess: false,
    disabled: false,
  }

  render() {
    const { onChange, url, width, height, popupTitle, buttonLabel, disabled } = this.props
    const className = classnames('primary-button', {
      disabled,
    })

    return (
      <div className="cronofy-connect-container">
        <div className="cronofy-connect-wrapper">
          <OAuthPopup
            onChange={onChange}
            type="code"
            url={url}
            width={width}
            height={height}
            title={popupTitle}
          >
            <button type="button" className={className} disabled={disabled}>
              <div className="label">{buttonLabel}</div>
            </button>
          </OAuthPopup>
        </div>
      </div>
    )
  }
}

export default CronofyConnect
