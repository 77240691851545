import React from 'react'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'

const CentralTestModuleBeneficiary = ({module: _module}) => {
  return (
    <div className="centraltest-module-beneficiary">
      <div className="selection">
        <div className="details">
          <CircleButton icon={Icon.icon.Book} color={Icon.color.White}/>
          <span className="label">{_module.data.test_name}</span>
        </div>
        {_module.done ? (
          <>
            {_module.data.show_report ? (
              <a
                className="report-trigger"
                target="_blank"
                href={_module.data.assessment && _module.data.assessment.candidate_pdf_link}
              >
                Consulter le rapport
              </a>
            ) : (
              <Icon icon={Icon.icon.Check}/>
            )}
          </>
        ) : (
          <PrimaryButton
            className="trigger"
            title="Compléter l'évaluation sur la plateforme Central Test"
            id="beneficiary-complete-assessment"
            label="Compléter l'évaluation sur la plateforme Central Test"
            onClick={() => window.open(_module.data.assessment.invites[0].link)}
            disabled={_module.done || !_module.data.assessment_id}
          />
        )}
      </div>
    </div>
  )
}

export default CentralTestModuleBeneficiary
