import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import IconChevron from '../../atoms/IconChevron/IconChevron'
import InputField from '../InputField/InputField'
import TextAreaField from '../TextAreaField/TextAreaField'
import { useI18n } from '../../../hooks/useI18n'
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'

const SyntheseStep = ({ open, id, stepTitle, subjectId, contents, onClick, onChange, stepId }) => {
  const { t } = useI18n()
  const user = useSelector(UserSelectors.user)
  const isNotBeneficiary = user.role !== 'beneficiary'

  const handleToggle = () => {
    onClick({ id })
  }

  return (
    <div className="synthese-step">
      <div className="header" onClick={handleToggle}>
        <div className="step-count">{t('step.nthStep', { i: id + 1 })}</div>
        <IconChevron open={open} onClick={handleToggle} />
        <div className="title">{stepTitle}</div>
      </div>

      <div className="content">
        {open && (
          <div className="hidden-area">
            <div className="hidden-area-content">
              {contents.map((content) => {
                return (
                  <Fragment key={content.id}>
                    <InputField
                      name={subjectId}
                      onChange={({ value }) =>
                        onChange(id, content.id, stepId, content.module_id, 'title', value)
                      }
                      placeholder={t('synthesis.title')}
                      value={content.title}
                    />

                    <TextAreaField
                      name="consultant_notes"
                      onChange={({ value }) =>
                        onChange(
                          id,
                          content.id,
                          stepId,
                          content.module_id,
                          'consultant_notes',
                          value,
                        )
                      }
                      placeholder={t('synthesis.your_notes')}
                      value={content.consultant_notes}
                    />

                    {isNotBeneficiary && (
                      <div className="private">
                        <TextAreaField
                          name="consultant_private_notes"
                          onChange={({ value }) =>
                            onChange(
                              id,
                              content.id,
                              stepId,
                              content.module_id,
                              'consultant_private_notes',
                              value,
                            )
                          }
                          placeholder={t('synthesis.your_notes') + ' privées'}
                          value={content.consultant_private_notes}
                          className="private"
                        />
                      </div>
                    )}
                  </Fragment>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

SyntheseStep.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any,
  stepId: PropTypes.any,
  stepTitle: PropTypes.string.isRequired,
  subjectId: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      module_id: PropTypes.any,
      title: PropTypes.string,
      consultant_notes: PropTypes.string,
      consultant_private_notes: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
}

SyntheseStep.defaultProps = {
  subjectTect: '',
  subjectPlaceholder: '',
  contentPlaceholder: '',
  onClick: ({ id }) => undefined,
  onChange: ({ id, name, value }) => undefined,
}

export default SyntheseStep
