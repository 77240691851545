import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { actions as CourseTemplateActions } from '../../../redux/CourseTemplateRedux'
import { withI18n } from '../../global/Translator/Translator'

import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapDispatchToProps = (dispatch, props) => ({
  remove: () =>
    dispatch(CourseTemplateActions.removeModuleAtIndex(props.stepIndex, props.moduleIndex, true)),
})

@withI18n
@connect(null, mapDispatchToProps)
export default class RemoveStepModulePopin extends PureComponent {
  static propTypes = {
    stepIndex: PropTypes.number.isRequired,
    moduleIndex: PropTypes.number.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handleRemove = () => {
    this.props.remove()
    this.props.onClose()
  }

  render() {
    const { onClose, t, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={t('module.remove_title')}
          text={t('module.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleRemove}
        />
      </Popin>
    )
  }
}
