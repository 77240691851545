import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import { withI18n } from '../../containers/global/Translator/Translator'
import AddModulePopin from '../../containers/module/AddModulePopin/AddModulePopin'
import ModuleList from '../../containers/module/ModuleList/ModuleList'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  moduleTemplate: ModuleTemplateSelectors.templates(state),
  currentTab: NavigationSelectors.query(state, 'tab') || 'consultant',
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(ModuleTemplateActions.getTemplates(filter)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantModulesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    scope: 'consultant',
    title: '',
    openAddNew: false,
    videoPopin: '',
    videoTitle: '',
  }

  handleSearch = (value) => {
    this.setState({ title: value }, this.getTemplates)
  }

  handleTabChange = (scope) => {
    this.setState({ scope }, this.getTemplates)
    this.props.redirect(`${this.props.location.pathname}?tab=${scope}`)
  }

  renderSearchBar() {
    return <TopSearch onSearch={this.handleSearch} />
  }

  renderTabs = () => {
    const { t } = this.props
    const links = [
      { id: 'consultant', label: t('consultant.my_modules') },
      { id: 'office', label: t('consultant.our_modules') },
      { id: 'mytalents', label: t('consultant.my_talents') },
    ]

    return (
      <LineMenu
        value={this.state.scope}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  handleAddPopin = () => {
    this.setState(({ openAddNew }) => ({ openAddNew: !openAddNew }))
  }

  renderVideo = () => {
    const { videoPopin, videoTitle } = this.state

    return (
      <VideoPopin
        title={videoTitle}
        videoId={videoPopin}
        onClose={this.handleCloseVideoPopin}
        open={!!videoPopin}
      />
    )
  }

  handleVideoClicked = (url, title) => {
    this.setState({ videoPopin: url, videoTitle: title })
  }

  handleCloseVideoPopin = () => {
    this.setState({ videoPopin: '', videoTitle: '' })
  }

  renderContent = () => (
    <>
      <div className="add-button-wrapper">
        {this.state.scope === 'consultant' && (
          <CircleButton icon="plus" onClick={this.handleAddPopin} />
        )}
      </div>
      <ModuleList modules={this.props.moduleTemplate} onVideoClicked={this.handleVideoClicked} />
      <AddModulePopin onClose={this.handleAddPopin} open={this.state.openAddNew} />
      {this.renderVideo()}
    </>
  )

  getTemplates = () => {
    const { scope, title } = this.state
    this.props.getTemplates({ scope, title })
  }

  componentDidMount() {
    const { currentTab } = this.props

    this.getTemplates()

    if (this.state.scope !== currentTab) {
      this.handleTabChange(currentTab)
    }
  }

  render() {
    const aside = <ConsultantPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="consultant-modules-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
