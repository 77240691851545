import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../components/atoms/LoadingSpinner/LoadingSpinner'
import LeavePopin from '../containers/global/LeavePopin/LeavePopin'
import NotificationList from '../containers/global/NotificationList/NotificationList'
import NavigationRouter from '../navigation/NavigationRouter'
import {
  actions as AppActions,
  getLeaveCallback,
  selectors as AppSelectors,
} from '../redux/AppRedux'
import '../stylesheets/main.css'
import { usePortalCustomization } from '../hooks/usePortalCustomization'

const ConnectedApp = () => {
  const dispatch = useDispatch()
  const started = useSelector(AppSelectors.started)
  const loading = useSelector(AppSelectors.loading)

  // eslint-disable-next-line no-unused-vars
  const _ = usePortalCustomization({ office: null })

  const handleWindowResize = useCallback(() => {
    dispatch(AppActions.updateSize())
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  const cancelLeave = () => {
    const leaveCallback = getLeaveCallback()

    if (leaveCallback !== null) {
      leaveCallback(false)
    }

    dispatch(AppActions.hideLeavePopin())
  }

  const confirmLeave = () => {
    const leaveCallback = getLeaveCallback()

    if (leaveCallback !== null) {
      leaveCallback(true)
    }

    dispatch(AppActions.hideLeavePopin())
  }

  if (!started) {
    return null
  }

  return (
    <>
      <NavigationRouter />
      <NotificationList />
      <LeavePopin cancel={cancelLeave} confirm={confirmLeave} />

      {loading && <LoadingSpinner />}
    </>
  )
}
export default ConnectedApp
