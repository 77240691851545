import React, { Component } from 'react'
import { connect } from 'react-redux'

import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { actions as UserActions } from '../../redux/UserRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
import { withI18n } from '../../containers/global/Translator/Translator'

import Logo from '../../../public/static/logos/logo-my-talents.svg'
import LoginForm from '../../components/organisms/LoginForm/LoginForm'
import Icon from '../../components/atoms/Icon/Icon'
import Config from '../../config/AppConfig'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state) => ({
  pending: WSSelectors.pending(state, 'auth.login'),
  error: WSSelectors.error(state, 'auth.login'),
})

const mapDispatchToProps = (dispatch) => ({
  evaluate: () => dispatch(NavigationActions.evaluate()),
  login: (email, password, persist) => dispatch(UserActions.login(email, password, persist)),
  sendInvitation: (id, token) => dispatch(UserActions.sendInvitation(id, token)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class LoginPage extends Component {
  handleSubmit = (values) => {
    this.props.login(values)
  }

  handleGoToWebsiteClick = () => {
    window.location.assign(Config.WEBSITE_URL)
  }

  handleSendInvitation = () => {
    const { error } = this.props
    const { details } = error

    this.props.sendInvitation(details.id, details.token.token)
  }

  componentDidMount() {
    this.props.evaluate()
  }

  renderNotActivated() {
    const { error, t } = this.props
    const notActivated = error && error.message === 'E_USER_NOT_ACTIVATED'

    if (!notActivated) {
      return
    }

    return (
      <section className="error">
        <span>{t('login.not_authenticated')}</span>
        <PrimaryButton label={t('login.resend_invitation')} onClick={this.handleSendInvitation} />
      </section>
    )
  }

  renderForm() {
    const { t, pending } = this.props

    return (
      <LoginForm
        persist
        onSubmit={this.handleSubmit}
        disabled={pending}
        locale={{
          email: t('form.email'),
          emailPlaceholder: t('your_form.email'),
          password: t('form.password'),
          passwordPlaceholder: t('your_form.password'),
          forgotPassword: t('login.forgot_password'),
          persist: t('login.persist'),
          cta: t('login.cta'),
        }}
      />
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className="login">
        <header className="login-back">
          <CircleButton id="icon" icon={Icon.icon.Back} onClick={this.handleGoToWebsiteClick} />
        </header>

        <section className="login-form-container">
          <h2 dangerouslySetInnerHTML={{ __html: t('login.title') }} />
          {this.renderForm()}
          {this.renderNotActivated()}
        </section>

        <section className="cover">
          <Logo />
          <p dangerouslySetInnerHTML={{ __html: t('application.baseline') }} />
        </section>
      </div>
    )
  }
}
