import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import QuizStep from '../QuizStep/QuizStep'

class QuizStars extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    starsCount: PropTypes.number.isRequired,
    selectedStars: PropTypes.number,
    onChange: PropTypes.func,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    stepLabel: null,
    stepOutline: true,
    selectedStars: 0,
    onChange: () => undefined,
    disabled: false,
  }

  onStarClick = (e, selectedCount) => {
    if (!this.props.disabled) this.props.onChange({ id: this.props.id, value: selectedCount })
  }

  onStarLeave = () => {
    if (!this.props.disabled)
      this.setState({
        starHover: false,
      })
  }

  onStarEnter = (e, hoveredStar) => {
    if (!this.props.disabled)
      this.setState({
        starHover: true,
        hoveredStar,
      })
  }

  activeStarClass = (index) => {
    if (this.state.starHover) {
      if (index <= this.state.hoveredStar) {
        return ' active'
      }
    } else if (index <= this.props.selectedStars) {
      return ' active'
    }
    return ''
  }

  renderStars = () => {
    const { starsCount } = this.props
    const starsNode = []

    for (let i = 0; i < starsCount; i++) {
      starsNode[i] = (
        <span
          key={i}
          className={`star-container${this.activeStarClass(i + 1)}`}
          onMouseEnter={(e) => this.onStarEnter(e, i + 1)}
          onMouseLeave={this.onStarLeave}
          onClick={(e) => this.onStarClick(e, i + 1)}
        >
          <Icon icon="star" />
        </span>
      )
    }

    return starsNode
  }

  constructor(props) {
    super(props)

    this.state = {
      starHover: false,
      hoveredStar: 0,
    }
  }

  render() {
    const { label, stepLabel, stepOutline } = this.props

    return (
      <div className="quiz-stars">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <div className="stars-container">{this.renderStars()}</div>
      </div>
    )
  }
}

export default QuizStars
