import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'

//
// Initial state
//
const initialState = Map({
  courses: {},
  course: null,
})

//
// Actions
//
export const actions = {
  removeCourse: createAction('removeCourse', (id) => ({ id })),
  addCourses: createAction('addCourses', (courses) => ({ courses: _mapKeys(courses, 'id') })),
  setCourses: createAction('setCourses', (courses) => ({ courses: _mapKeys(courses, 'id') })),
  getCourses: createAction('getCourses', (filter) => ({ filter })),
  setCourse: createAction('setCourse', (course) => ({ course })),
  getCourse: createAction('getCourse', (id) => ({ id })),
  exportCourse: createAction('exportCourse', (id) => ({ id })),
  updateCourseValue: createAction('updateCourseValue', (key, value) => ({ key, value })),
  getCoursesForBeneficiary: createAction('getCoursesForBeneficiary', (id) => ({ id })),
  exportSynthesis: createAction('exportSynthesis', (id) => ({ id })),
  updateModuleAtIndex: createAction('updateModuleAtIndex', (stepIndex, moduleIndex, module) => ({
    module,
    stepIndex,
    moduleIndex,
  })),

  resetCourses: createAction('resetCourses'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.removeCourse]: (state, { id }) => state.deleteIn(['courses', id]),
    [actions.addCourses]: (state, { courses }) => state.mergeDeep({ courses }),
    [actions.setCourses]: (state, { courses }) => state.merge({ courses }),
    [actions.setCourse]: (state, { course }) => state.merge({ course }),
    [actions.updateCourseValue]: (state, { key, value }) => state.setIn(['course', key], value),
    [actions.resetCourses]: (state) => state.merge({ courses: {} }),
    [actions.updateModuleAtIndex]: (state, { module, stepIndex, moduleIndex }) =>
      state.mergeIn(['course', 'steps', stepIndex, 'modules', moduleIndex], module),
  },
  initialState,
)

//
// Selectors
//
const courses = (state) => state.course.get('courses')
const course = (state) => state.course.get('course')
const coursesArray = createSelector([courses], Object.values)

export const selectors = {
  coursesObj: courses,
  courses: coursesArray,
  course,
}
