import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import VideoPopin from '../../../components/organisms/VideoPopin/VideoPopin'
import {inFollowUp, inProgress} from '../../../helpers/beneficiaryStatus'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import {actions as MessageActions, selectors as MessageSelectors,} from '../../../redux/MessageRedux'
import {actions as OfficeActions, selectors as OfficeSelectors} from '../../../redux/OfficeRedux'
import {actions as UserActions, selectors as UserSelectors} from '../../../redux/UserRedux'
import {actions as BeneficiaryActions, selectors as BeneficiarySelectors,} from '../../../redux/BeneficiaryRedux'
import {useI18n} from '../../../hooks/useI18n'
import {useNavigation} from '../../../hooks/useNavigation'
import {selectors as portalSelectors} from '../../../redux/PortalRedux'
import {useTakeEvery} from "../../../hooks/useSaga";

const BeneficiaryPageAside = () => {
  const dispatch = useDispatch()
  const {t} = useI18n()
  const {pathname, redirect} = useNavigation()
  const [tutorial, setTutorial] = useState(false)
  const office = useSelector(OfficeSelectors.office)
  const user = useSelector(UserSelectors.user)
  const unreadMessages = useSelector(MessageSelectors.unreadMessages)
  const loadingCvDesignrLink = useSelector(BeneficiarySelectors.loadingCvDesignrLink)
  const isPortalActive = useSelector(portalSelectors.isActive)

  const handleMenuChange = ({id}) => {
    redirect(id)
  }

  const handleTutorialPopin = () => {
    setTutorial(!tutorial)
  }

  useTakeEvery(BeneficiaryActions.setCvDesignrExternalLink.getType(), useCallback((action) => {
    action.payload.cvDesignrExternalLink.length && window.open(action.payload.cvDesignrExternalLink)
  }, []))

  const handleOpenToCvDesignr = (type) => {
    if (loadingCvDesignrLink) {
      return false
    }

    dispatch(BeneficiaryActions.getCvDesignrLink(type))

    return false
  }


  const getLinks = () => {
    const isInProgress = inProgress(user)
    const isInFollowUp = inFollowUp(user)

    let links = []

    if (isInProgress || isInFollowUp) {
      links.push({
        id: '/beneficiary/courses',
        label: t('accompaniment.accompaniment'),
        icon: Icon.icon.Paperboard,
      })
    }

    if (isInProgress) {
      links.push({
        id: '/beneficiary/calendar',
        label: t('calendar_labels.calendar'),
        icon: Icon.icon.Calendar,
      })
    }

    links = [
      ...links,
      {
        id: '/beneficiary/messages',
        label: t('message.messages'),
        icon: Icon.icon.Inbox,
        count: unreadMessages,
      },
      {
        id: '/beneficiary/profile',
        label: t('user.profile'),
        icon: Icon.icon.Settings,
      },
    ]

    if (isInProgress || isInFollowUp) {
      links.push({
        id: 'cvdesignr_link_documents',
        label: t('user.cvdesignr_documents'),
        icon: Icon.icon.Export,
        onClick: () => handleOpenToCvDesignr('cv'),
      })
      links.push({
        id: 'cvdesignr_link_maestro',
        label: t('user.cvdesignr_maestro'),
        icon: Icon.icon.Export,
        onClick: () => handleOpenToCvDesignr('maestro'),
      })
      links.push({
        id: 'cvdesignr_link_offers',
        label: t('user.cvdesignr_offers'),
        icon: Icon.icon.Export,
        onClick: () => handleOpenToCvDesignr('offers'),
      })
    }

    return links
  }

  const logout = () => {
    dispatch(UserActions.logout())
  }

  useEffect(() => {
    dispatch(OfficeActions.getCurrentUserOffice())
    dispatch(MessageActions.getMessages({type: 'inbox', state: null, q: ''}))
  }, [dispatch])

  const renderTutorialButton = () => {
    return (
      <div className="tutorial">
        <Icon icon="help" color="accent"/>
        <a href={t('beneficiary_tutorial.url')} target="_blank">
          {t('beneficiary_tutorial.tutorial')}
        </a>
      </div>
    )
  }

  const renderTutorial = () => {
    return (
      <VideoPopin
        title={t('beneficiary_tutorial.title')}
        videoId={t('beneficiary_tutorial.id')}
        onClose={handleTutorialPopin}
        open={tutorial}
      />
    )
  }
  const renderHeader = () => {
    if (!office || !user) {
      return null
    }

    return (
      <>
        <div className="logos">
          {!isPortalActive && (
            <>
              <img
                className="mytalents-logo"
                alt="Logo"
                src="/static/logos/logo-my-talents-dark.png"
              />
              <div className="separator"/>
            </>
          )}
          <img className="office-logo" src={office.image_url} alt={office.name}/>
        </div>
        <div className="user">
          <div className="user-image" style={{backgroundImage: `url("${user.image_url}")`}}/>
          <div className="user-name">
            {user.first_name} {user.last_name}
          </div>
          {renderTutorialButton()}
        </div>
      </>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return <SideMenu links={links} pathname={pathname} onClick={handleMenuChange}/>
  }

  const renderFooter = () => {
    return <LogoutButton label={t('actions.logout')} onClick={logout}/>
  }

  if (!user) {
    return null
  }

  return (
    <div className="beneficiary-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()}/>
      {renderTutorial()}
    </div>
  )
}

export default BeneficiaryPageAside
