import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import ComboFilterField from '../../components/molecules/ComboFilterField/ComboFilterField'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import CourseTemplateList from '../../containers/course/CourseTemplateList/CourseTemplateList'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as CourseTemplateActions } from '../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { useI18n } from '../../hooks/useI18n'

const ConsultantCourseTemplatesPage = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const [scope, setScope] = useState('consultant')
  const [sort, setSort] = useState('')
  const pathname = useSelector(NavigationSelectors.pathname)
  const currentTab = useSelector((state) => NavigationSelectors.query(state, 'tab') || 'consultant')

  const redirect = useCallback(
    (destination) => {
      dispatch(NavigationActions.push(destination))
    },
    [dispatch],
  )

  const handleCreateClick = () => {
    redirect(`${pathname}/new`)
  }

  const handleSortChange = ({ value: sort }) => {
    setSort(sort)
  }

  const getTemplates = useCallback(
    (searchTitle = null) => {
      dispatch(
        CourseTemplateActions.getTemplates({
          scope,
          title: searchTitle,
        }),
      )
    },
    [dispatch, scope],
  )

  const handleTabChange = useCallback(
    (scope) => {
      setScope(scope)
      redirect(`${pathname}?tab=${scope}`)
    },
    [pathname, redirect],
  )

  useEffect(() => {
    getTemplates(sort)
  }, [getTemplates, sort])

  useEffect(() => {
    getTemplates()

    if (scope !== currentTab) {
      handleTabChange(currentTab)
    }
  }, [scope, currentTab, getTemplates, handleTabChange])

  const searchBar = () => <TopSearch onSearch={getTemplates} />

  const list = () => {
    const filters = t('course_template.sort_filter')
    const canAdd = scope === 'consultant'
    const options = Object.keys(filters).map((key) => ({
      value: key,
      label: filters[key],
    }))

    return (
      <>
        <div className="components">
          {canAdd && <CircleButton icon={Icon.icon.Plus} onClick={handleCreateClick} />}

          <ComboFilterField
            id="sort"
            options={options}
            value={sort}
            title={t('sort.sort_label')}
            onChange={handleSortChange}
          />
        </div>

        <CourseTemplateList scope={scope} enablePreview canAdd={canAdd} filters={{ scope }} />
      </>
    )
  }

  const tabs = () => {
    const tabs = [
      { id: 'consultant', label: t('course_template.scope_filter.consultant') },
      { id: 'office', label: t('course_template.scope_filter.office') },
      { id: 'mytalents', label: t('course_template.scope_filter.mytalents') },
    ]

    return (
      <LineMenu value={scope} links={tabs} onChange={handleTabChange} components={searchBar()} />
    )
  }

  const aside = <ConsultantPageAside />

  const content = <TabbedContentLayout header={tabs()} content={list()} />

  return (
    <div className="page">
      <div className="consultant-course-templates-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    </div>
  )
}

export default ConsultantCourseTemplatesPage
