import ApiService from './ApiService'

const api = ApiService

export const upload = ({ token, file }) => {
  const formData = new FormData()
  formData.set('file', file)

  return api.post('/uploads', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'multipart/form-data',
    },
  })
}
