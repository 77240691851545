import { call, put, select, take } from 'redux-saga/effects'
import { actions as UserActions, selectors as UserSelectors } from '../redux/UserRedux'
import { actions as WSActions } from '../redux/WSRedux'
import bugsnagClient from '../helpers/bugsnagClient'

export function* getToken(noAuth, retry = 2) {
  if (noAuth) {
    return null
  }

  const token = yield select(UserSelectors.token)
  const delay = 2 * 60 * 60 * 1000 //2h delay

  if (token && token.expires_at > Date.now() + delay) {
    return token
  } else if (retry === 0) {
    yield put(UserActions.logout(true))
    return null
  }

  yield put(UserActions.refreshToken())
  yield take(UserActions.setToken.getType())

  return yield call(getToken, false, retry - 1)
}

export function* request(handler, id, params, callback, noAuth, retry = 2) {
  const payload = params || {}

  const token = yield call(getToken, noAuth || Boolean(payload.token))

  if (token) {
    payload.token = token.token
  }

  yield put(WSActions.start(id))
  const result = yield call(handler, payload)
  const { ok, problem, data, status, config } = result

  if (callback) {
    yield callback({ ...result, payload })
  }

  ok ? yield put(WSActions.resolve(id)) : yield put(WSActions.reject(id, data && data[0]))

  if (problem) {
    if (status === 401 && !noAuth && !id.startsWith('auth.')) {
      if (retry > 0) {
        return yield call(request, handler, id, params, callback, noAuth, retry - 1)
      }
      return yield put(UserActions.logout(true))
    }

    if (![401, 403].includes(status)) {
      const error = (data && data[0]) || data
      const message = (error && error.message) || error || problem
      const { url, method } = config

      bugsnagClient.notify(new Error(message), {
        metaData: {
          method,
          url,
          status,
          problem,
          error,
        },
        severity: 'error',
      })
    }
  }

  return result
}
