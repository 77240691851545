import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import IconButton from '../../../components/atoms/IconButton/IconButton'
import IconModuleType from '../../../components/atoms/IconModuleType/IconModuleType'
import ParcoursModuleContainer from '../../../components/molecules/ParcoursModuleContainer/ParcoursModuleContainer'
import ParcoursModuleContentDocumentConsultant from '../../../components/molecules/ParcoursModuleContentDocumentConsultant/ParcoursModuleContentDocumentConsultant'
import ParcoursModuleContentFileReturnBeneficiary from '../../../components/molecules/ParcoursModuleContentFileReturnBeneficiary/ParcoursModuleContentFileReturnBeneficiary'
import ParcoursModuleContentLink from '../../../components/molecules/ParcoursModuleContentLink/ParcoursModuleContentLink'
import ParcoursModuleContentVideoLink from '../../../components/molecules/ParcoursModuleContentVideoLink/ParcoursModuleContentVideoLink'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../../redux/CourseTemplateRedux'
import { actions as ModuleTemplateActions } from '../../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { withI18n } from '../../global/Translator/Translator'
import RemoveStepModulePopin from '../RemoveStepModulePopin/RemoveStepModulePopin'
import CentralTestModuleWrapper from '../../../components/molecules/CentralTestModuleWrapper/CentralTestModuleWrapper'

const mapStateToProps = (state, props) => ({
  course: CourseTemplateSelectors.template(state),
  modules: CourseTemplateSelectors.modulesAtIndex(state, props.stepIndex),
  module: CourseTemplateSelectors.moduleAtIndex(state, props.stepIndex, props.moduleIndex),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  setModuleTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  updateModuleAtIndex: (stepIndex, moduleIndex, template) =>
    dispatch(CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, template)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class EditStepModuleWrapper extends Component {
  static types = {
    appointment: 'appointment',
    form: 'form',
    game: 'game',
    synthesis: 'synthesis',
    text: 'text',
    central: 'centraltest',
  }

  static propTypes = {
    t: PropTypes.func,
    stepIndex: PropTypes.number.isRequired,
    moduleIndex: PropTypes.number.isRequired,
    module: PropTypes.object,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    readOnly: false,
  }

  state = {
    popin: {
      remove: false,
    },
  }

  toggleShowRemovePopin = () => {
    this.setState(({ popin }) => ({
      popin: { ...popin, remove: !popin.remove },
    }))
  }

  handleRemoveClick = () => {
    this.toggleShowRemovePopin()
  }

  handleEditClick = () => {
    const { module, stepIndex, moduleIndex } = this.props

    this.props.setModuleTemplate({ ...module, stepIndex, moduleIndex })

    switch (module.type) {
      case 'appointment':
        this.props.redirect(`${this.props.pathname}/modules/appointment`)
        break
      default:
        this.props.redirect(`${this.props.pathname}/modules/edit`)
    }
  }

  handlePreviewClick = () => {
    const { module, stepIndex, moduleIndex } = this.props

    this.props.setModuleTemplate({ ...module, stepIndex, moduleIndex })
    this.props.redirect(`${this.props.pathname}/forms/${module.id}`)
  }

  handleChange = ({ id, value }) => {
    const { module, stepIndex, moduleIndex } = this.props

    this.props.updateModuleAtIndex(stepIndex, moduleIndex, {
      ...module,
      [id]: value,
    })
  }

  handleToggleResourcePrivate = ({ id: index }) => {
    const { module } = this.props
    const { data } = module
    const resources = [...data.resources]
    const resource = { ...resources[index] }

    resource.private = !resource.private
    resources.splice(index, 1, resource)

    this.handleChange({
      id: 'data',
      value: { ...data, resources },
    })
  }

  renderRemovePopin() {
    return (
      <RemoveStepModulePopin
        open={this.state.popin.remove}
        stepIndex={this.props.stepIndex}
        moduleIndex={this.props.moduleIndex}
        onClose={this.toggleShowRemovePopin}
      />
    )
  }

  renderLockedButton() {
    const { t, module, moduleIndex } = this.props

    if (moduleIndex === 0) {
      return null
    }

    return (
      <IconButton
        icon={Icon.icon.Lock}
        color={module.locked ? IconButton.color.Accent : IconButton.color.Grey2}
        onClick={() => this.handleChange({ id: 'locked', value: !module.locked })}
        id="lock"
        tooltip={t('actions.lock_tooltip')}
      />
    )
  }

  renderEditButton() {
    return (
      <IconButton icon={Icon.icon.Edit2} color={Icon.color.Grey2} onClick={this.handleEditClick} />
    )
  }

  renderPreviewButton() {
    return (
      <IconButton
        icon={Icon.icon.Preview2}
        color={Icon.color.Accent}
        onClick={this.handlePreviewClick}
      />
    )
  }

  renderRemoveButton() {
    return (
      <IconButton
        icon={Icon.icon.Trash2}
        color={Icon.color.Grey2}
        onClick={this.handleRemoveClick}
      />
    )
  }

  renderAppointment() {
    const { course, module, t, readOnly } = this.props
    const { title, data, template_owner_id } = module
    let actions = [this.renderEditButton(), this.renderLockedButton(), this.renderRemoveButton()]

    if (readOnly) {
      actions = []
    }

    const isTemplate = (course && !course.accompaniment) || template_owner_id

    if (!isTemplate && !data.rdv_at) {
      actions.unshift(<p className="rdv-config accent">{t('module.appointment.define')}</p>)
    }

    return (
      <ParcoursModuleContainer
        label={title}
        moduleType={IconModuleType.moduleType.rdv}
        actions={actions}
      />
    )
  }

  renderForm() {
    const { module, t, readOnly } = this.props
    const { title, description, data } = module
    const { questions = [] } = data
    const totalQuestion = questions.length
    const multipleChoice = questions.filter(({ type }) => type === 'multiple_choice').length
    const questionToFill = questions.filter(({ answer }) => !answer).length

    let actions = [this.renderLockedButton(), this.renderEditButton(), this.renderRemoveButton()]

    if (readOnly) {
      actions = [this.renderPreviewButton()]
    }

    const children = [
      <div className="questions">
        <div className="entry">
          <b>{totalQuestion}</b> {t('module.form.total_questions')}
        </div>
        <div className="entry">
          <b>{multipleChoice}</b> {t('module.form.multiple_choices')}
        </div>
        <div className="entry">
          <b>{questionToFill}</b> {t('module.form.questions_to_fill')}
        </div>
      </div>,
    ]

    return (
      <ParcoursModuleContainer
        label={title}
        description={description}
        moduleType={IconModuleType.moduleType.quiz}
        actions={actions}
        children={children}
      />
    )
  }

  renderSynthesis() {
    const { module, readOnly } = this.props
    const { title } = module
    let actions = [this.renderRemoveButton()]

    if (readOnly) {
      actions = []
    }

    return (
      <ParcoursModuleContainer
        label={title}
        moduleType={IconModuleType.moduleType.report}
        actions={actions}
      />
    )
  }

  renderText() {
    const { t, module, readOnly } = this.props
    const { title, data } = module
    const { resources = [] } = data
    let actions = [this.renderLockedButton(), this.renderEditButton(), this.renderRemoveButton()]

    if (readOnly) {
      actions = []
    }

    const children = resources.map(({ type, title, url, url_uploaded, private: isPrivate }, i) => {
      switch (type) {
        case 'video':
          return <ParcoursModuleContentVideoLink label={title} link={url} />

        case 'link':
          return <ParcoursModuleContentLink label={title} link={url} url={url} />

        case 'document':
          return (
            <ParcoursModuleContentDocumentConsultant
              id={i}
              url={url}
              onTogglePrivate={this.handleToggleResourcePrivate}
              fileName={title}
              isPrivate={isPrivate}
              locale={{
                private: t('resource.private'),
                public: t('resource.public'),
              }}
            />
          )

        case 'to_return_document':
          return (
            <ParcoursModuleContentFileReturnBeneficiary
              consultantFileName={title}
              beneficiaryFileName={url_uploaded}
              locale={{
                returnDocument: t('resource.to_return_document'),
                completedDocument: t('resource.completed_document'),
                dropFile: t('resource.drop_to_return_file'),
              }}
            />
          )

        default:
          throw new Error(`Unknown resource type: "${type}"`)
      }
    })

    return (
      <ParcoursModuleContainer
        label={title}
        moduleType={IconModuleType.moduleType.free}
        actions={actions}
        children={children}
      />
    )
  }

  renderGame() {
    return <p>ModuleWrapper.renderGame() is not set yet!</p>
  }

  renderCentral() {
    const { module, stepIndex, moduleIndex } = this.props

    let actions = [this.renderLockedButton(), this.renderRemoveButton()]

    return (
      <ParcoursModuleContainer
        moduleType={IconModuleType.moduleType.centraltest}
        label="Central Test"
        children={[
          <CentralTestModuleWrapper
            _module={module}
            stepIndex={stepIndex}
            moduleIndex={moduleIndex}
          />,
        ]}
        actions={actions}
      />
    )
  }

  renderModule() {
    const { types } = EditStepModuleWrapper
    const { type } = this.props.module

    switch (type) {
      case types.appointment:
        return this.renderAppointment()
      case types.form:
        return this.renderForm()
      case types.game:
        return this.renderGame()
      case types.synthesis:
        return this.renderSynthesis()
      case types.central:
        return this.renderCentral()
      case types.text:
        return this.renderText()
      default:
        throw new Error(`Unknown module type: ${type}`)
    }
  }

  render() {
    return (
      <div className="edit-step-module-wrapper">
        {this.renderModule()}
        {!this.props.readOnly && this.renderRemovePopin()}
      </div>
    )
  }
}
