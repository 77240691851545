import PropTypes from 'prop-types'
import React, { Component } from 'react'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'
import IconProgState from '../../atoms/IconProgState/IconProgState'

export default class ParcoursModuleContainer extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    progState: PropTypes.oneOf(Object.values(IconProgState.icons)),
    isActivated: PropTypes.bool,
    moduleType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.node),
    children: PropTypes.arrayOf(PropTypes.node),
  }

  static defaultProps = {
    id: '',
    isActivated: true,
    description: '',
    actions: null,
    children: null,
  }

  render() {
    const {
      id,
      progState,
      moduleType,
      label,
      description,
      actions,
      children,
      isActivated,
    } = this.props

    return (
      <div className={`parcours-module-container ${moduleType}`} id={id}>
        <div className={`header ${isActivated ? 'active' : 'disable'}`}>
          {progState && <IconProgState icon={progState} />}

          <IconModuleType moduleType={moduleType} />
          <span className="label">{label}</span>

          {actions && (
            <div className="actions">
              {actions.map((node, index) => {
                return (
                  node && (
                    <div className={`action-item ${moduleType}`} key={index}>
                      {node}
                    </div>
                  )
                )
              })}
            </div>
          )}
        </div>

        {description && <div className="description">{description}</div>}

        {children && (
          <div className="children">
            {children.map((node, index) => {
              return (
                <div className={`children-item ${moduleType}`} key={index}>
                  {node}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
