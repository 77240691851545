import { Map } from 'immutable'
import { createAction, createReducer } from 'redux-act'

//
// Initial state
//
const initialState = Map({
  invite: '',
  data: null,
})

//
// Actions
//
export const actions = {
  createInvite: createAction('createInvite'),
  setInvite: createAction('setInvite', ({ data }) => data),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setInvite]: (state, { invite }) => state.merge({ invite }),
  },
  initialState,
)

//
// Selectors
//
const invite = (state) => state.videocall.get('invite')

export const selectors = {
  invite,
}
