import moment from 'moment/moment'

const moduleTypes = {
  synthesis: 'synthesis',
  form: 'form',
  appointment: 'appointment',
  text: 'text',
  exercise: 'exercise',
}

/* Return done status for a module */
export const moduleDone = (module) => {
  if (module.type === moduleTypes.appointment && module.data && module.data.rdv_at) {
    return moment().isAfter(module.data.rdv_at)
  }

  return module.done
}

/* Return done status for a step */
export const stepDone = (step) => {
  return (
    step.modules.length > 0 &&
    step.modules.reduce((res, stepModule) => res && moduleDone(stepModule), true)
  )
}
