import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Step extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    outline: PropTypes.bool,
  }

  static defaultProps = {
    outline: false,
  }

  computedClasses() {
    const classes = []
    const { outline } = this.props
    classes.push('step')
    if (outline) classes.push('outline')
    return classes.join(' ')
  }

  render() {
    const { label } = this.props
    return <div className={this.computedClasses()}>{label}</div>
  }
}
