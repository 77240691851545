import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfoField from '../../molecules/InfoField/InfoField'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'

export default class AccompCard extends Component {
  static propTypes = {
    accompanimentType: PropTypes.string.isRequired,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    durationType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    bilanType: PropTypes.string,
    bilanAvailability: PropTypes.string,
    locale: PropTypes.shape({
      title: PropTypes.string,
      accompanimentTypeLabel: PropTypes.string,
      durationLabel: PropTypes.string,
      startDateLabel: PropTypes.string,
      endDateLabel: PropTypes.string,
      bilanTypeLabel: PropTypes.string,
      bilanAvailabilityLabel: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    duration: '',
    durationType: '',
    startDate: '',
    endDate: '',
    bilanType: '',
    bilanAvailability: '',
  }

  render() {
    const {
      accompanimentType,
      duration,
      durationType,
      startDate,
      endDate,
      bilanType,
      bilanAvailability,
    } = this.props
    const {
      title = '',
      accompanimentTypeLabel = '',
      durationLabel = '',
      startDateLabel = '',
      endDateLabel = '',
      bilanTypeLabel = '',
      bilanAvailabilityLabel = '',
    } = this.props.locale

    return (
      <div className="accomp-card">
        <IconCardTitle icon="clipboard" color="accent" label={title} />
        <div className="content">
          <InfoField boldLabel={accompanimentType} lightLabel={accompanimentTypeLabel} />
          {(duration !== '' || startDate !== '' || endDate !== '') && (
            <div className="inline-container">
              {duration !== '' && durationType !== '' && (
                <InfoField boldLabel={`${duration} ${durationType}`} lightLabel={durationLabel} />
              )}
              {startDate !== '' && <InfoField boldLabel={startDate} lightLabel={startDateLabel} />}
              {endDate !== '' && <InfoField boldLabel={endDate} lightLabel={endDateLabel} />}
            </div>
          )}
          {bilanType !== '' && <InfoField boldLabel={bilanType} lightLabel={bilanTypeLabel} />}
          {bilanAvailability !== '' && (
            <InfoField boldLabel={bilanAvailability} lightLabel={bilanAvailabilityLabel} />
          )}
        </div>
      </div>
    )
  }
}
