import React from 'react'
import PropTypes from 'prop-types'

// DONE: @alex A passer en stateless component.
// DONE: @alex Il serait préférable de transmettre plutôt day(0-6) et month(1-12) et que les labels (dayLabels & monthLabels) soient passées comme des clés de localisation (comme des tableaux de String).
// DONE: @alex Idéalement, ce composant devrait être responsive a +/- 25% de sa taille maquette (cf mutation responsive).

// PropTypes
const propTypes = {
  date: PropTypes.oneOf([...Array(31).keys()].map((o) => o + 1)).isRequired, // 1 -> 31
  day: PropTypes.oneOf([...Array(7).keys()]).isRequired, // 0 -> 6
  month: PropTypes.oneOf([...Array(12).keys()]).isRequired, // 0 -> 11
  accentColor: PropTypes.oneOf(['default', 'red', 'orange']).isRequired,
  locale: PropTypes.shape({
    dayLabels: PropTypes.arrayOf(PropTypes.string),
    monthLabels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

// Component
const AgendaRowDate = ({ date, day, month, accentColor, locale }) => (
  <div className="agenda-row-date">
    <div className="top" data-color={accentColor}>
      <p className="month-label">{locale.monthLabels[month]}</p>
    </div>
    <div className="bottom">
      <p className="day-number" data-color={accentColor}>
        {date}
      </p>
      <p className="day-label">{locale.dayLabels[day]}</p>
    </div>
  </div>
)

AgendaRowDate.propTypes = propTypes

export default AgendaRowDate
