import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  actions: {},
})

//
// Actions
//
export const actions = {
  start: createAction('request starting', (id) => ({ id })),
  resolve: createAction('request resolved', (id) => ({ id })),
  reject: createAction('request rejected', (id, error) => ({ id, error })),
  reset: createAction('request reset', (id) => ({ id })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.reset]: (state, { id }) => state.setIn(['actions', id], {}),
    [actions.start]: (state, { id }) =>
      state.setIn(['actions', id], { pending: true, done: false }),
    [actions.resolve]: (state, { id }) =>
      state.setIn(['actions', id], { resolved: true, done: true }),
    [actions.reject]: (state, { id, error }) => state.setIn(['actions', id], { error, done: true }),
  },
  initialState,
)

//
// Selectors
//
const action = (state, id) => state.actions.getIn(['actions', id], {})
const error = createSelector(action, ({ error }) => error)
const pending = createSelector(action, ({ pending }) => !!pending)
const resolved = createSelector(action, ({ resolved }) => !!resolved)

export const selectors = {
  action,
  error,
  pending,
  resolved,
}
