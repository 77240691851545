import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as UserActions } from '../../redux/UserRedux'
import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
import { withI18n } from '../../containers/global/Translator/Translator'
import ForgotPasswordPopin from '../../components/organisms/ForgotPasswordForm/ForgotPasswordPopin'
import Logo from '../../../public/static/logos/logo-my-talents.svg'

const mapStateToProps = (state) => ({
  pending: WSSelectors.pending(state, 'auth.forgotPassword'),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(NavigationActions.push('/')),
  forgotPassword: (email) => dispatch(UserActions.forgotPassword(email)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ForgotPasswordPage extends Component {
  static propTypes = {
    forgotPassword: PropTypes.func,
    t: PropTypes.func,
  }

  state = {
    done: false,
  }

  handleClose = () => {
    this.props.close()
  }

  handleSubmit = ({ email }) => {
    this.props.forgotPassword(email)
    this.setState({ done: true })
  }

  render() {
    const { t, pending } = this.props
    const { done } = this.state

    return (
      <div className="forgot-password-page">
        <Logo className="logo" />

        <ForgotPasswordPopin
          onClose={this.handleClose}
          onSubmit={this.handleSubmit}
          done={done}
          disabled={pending}
          locale={{
            title: t('forgot_password.title'),
            infoDescription: t('forgot_password.info_description'),
            sentDescription: t('forgot_password.sent_description'),
            cta: t('forgot_password.reset_password'),
            emailLabel: t('form.email'),
          }}
        />
      </div>
    )
  }
}
