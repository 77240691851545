import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import bugsnagClient from './helpers/bugsnagClient'

const Wrapper = bugsnagClient.getWrapper()
const WrappedApp = (
  <Wrapper>
    <App />
  </Wrapper>
)

ReactDOM.render(WrappedApp, document.getElementById('root'))
