import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class StatsRow extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    stats: PropTypes.string.isRequired,
  }

  render() {
    const { label, stats } = this.props

    return (
      <div className="stats-row">
        <div className="label">{label}</div>
        <div className="stats">{stats}</div>
      </div>
    )
  }
}
