// React & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'

// Proptypes
const propTypes = {
  consultantFileName: PropTypes.string.isRequired,
  beneficiaryFileName: PropTypes.string,
  onDownloadClick: PropTypes.func,
  url: PropTypes.string,
  urlUploaded: PropTypes.string,
  locale: PropTypes.shape({
    returnDocument: PropTypes.string.isRequired,
    completedDocument: PropTypes.string.isRequired,
  }),
}

// defaultProps
const defaultProps = {
  url: null,
  urlUploaded: null,
  beneficiaryFileName: null,
  onDownloadClick: () => {},
}

// Component
class ParcoursModuleContentFileReturnConsultant extends Component {
  handleDownloadClick = () => {
    this.props.onDownloadClick()
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 40
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  render() {
    const {
      consultantFileName,
      beneficiaryFileName,
      locale,
      url,
      urlUploaded,
      typeUploaded,
    } = this.props
    const { returnDocument, completedDocument } = locale

    return (
      <div className="parcours-module-content-file-return-consultant">
        <div className="consultant">
          <div className="text">
            <p className="label">{returnDocument}</p>
            <IconResourceType
              resourceType={IconResourceType.resourceType.fileReturn}
              color={Icon.color.Accent}
            />
            <a href={url} target="_blank" className="file-name">
              {consultantFileName}
            </a>
          </div>
        </div>
        <span className="separator" />
        <div className="beneficiary">
          <div className="text">
            <p className="label">{completedDocument}</p>
            <p className="file-name">
              {beneficiaryFileName ? (
                <a href={urlUploaded} target="_blank">
                  {this.truncateString(beneficiaryFileName)}
                </a>
              ) : (
                <span className="trait" />
              )}
            </p>
          </div>

          {!!urlUploaded && (
            <a href={urlUploaded} target="_blank" download>
              <CircleButton
                icon={typeUploaded === 'link' ? Icon.icon.Preview : Icon.icon.Download}
                onClick={this.handleDownloadClick}
              />
            </a>
          )}
        </div>
      </div>
    )
  }
}

ParcoursModuleContentFileReturnConsultant.propTypes = propTypes
ParcoursModuleContentFileReturnConsultant.defaultProps = defaultProps

export default ParcoursModuleContentFileReturnConsultant
