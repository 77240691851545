import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LineMenuLink from '../../molecules/LineMenuLink/LineMenuLink'

export default class LineMenu extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        countIndicator: PropTypes.number,
        label: PropTypes.string.isRequired,
        sublabel: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ).isRequired,
    withSublabel: PropTypes.bool,
    components: PropTypes.node,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    withSublabel: false,
    onChange: (id) => undefined,
  }

  onClickLink = (e, id) => {
    this.props.onChange(id)
  }

  render() {
    const { links, value, components, withSublabel } = this.props
    const menuLinks = links.map((link) => (
      <LineMenuLink
        key={link.id}
        withSublabel={withSublabel}
        id={link.id}
        isSelected={value === link.id}
        label={link.label}
        sublabel={link.sublabel}
        countIndicator={link.countIndicator}
        onClick={this.onClickLink}
      />
    ))

    return (
      <div className="line-menu">
        <div className="resources">{menuLinks}</div>

        {components && <div className="components">{components}</div>}
      </div>
    )
  }
}
