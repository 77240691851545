import moment from 'moment'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgressbar } from 'react-circular-progressbar'
import InfoField from '../../molecules/InfoField/InfoField'
import IconButton from '../../atoms/IconButton/IconButton'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import GradientSVG from './GradientSVG'
import Icon from '../../atoms/Icon/Icon'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import { actions as VideoCallActions } from '../../../redux/VideocallRedux'
import { connect } from 'react-redux'
import { selectors as ConsultantSelectors } from '../../../redux/ConsultantRedux'

const status = {
  created: 'created',
  wait_financer: 'wait_financer',
  in_validation: 'in_validation',
  in_progress: 'in_progress',
  follow_up: 'follow_up',
  archived: 'archived',
}

const mapDispatchToProps = (dispatch) => ({
  createInvite: (payload) => dispatch(VideoCallActions.createInvite(payload)),
})

const mapStateToProps = (state) => ({
  consultants: ConsultantSelectors.consultants(state),
})

@connect(mapStateToProps, mapDispatchToProps)
export default class CandidatCard extends Component {
  static propTypes = {
    status: PropTypes.shape({
      status: PropTypes.oneOf(Object.values(status)).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    length: PropTypes.string,
    accompaniment_type: PropTypes.string,
    programFollowed: PropTypes.string,
    nextAppointment: PropTypes.string,
    percentage: PropTypes.number,
    percentageNb: PropTypes.string,
    mail: PropTypes.bool,
    color: PropTypes.string,
    reason: PropTypes.string,
    sharedConsultants: PropTypes.arrayOf(PropTypes.shape({ avatar: PropTypes.string.isRequired })),
    financer_state: PropTypes.string,
    onMessagesClick: PropTypes.func,
    onDeleteClick: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      financeStateLabel: PropTypes.string,
      reasonLabel: PropTypes.string,
      lengthLabel: PropTypes.string,
      typeOfAccompanimentLabel: PropTypes.string,
      programFollowedLabel: PropTypes.string,
      nextAppointmentLabel: PropTypes.string,
    }),
    isDeletable: PropTypes.bool,
    isManager: PropTypes.bool,
    onAttributeClick: PropTypes.func,
    attributeLabelButton: PropTypes.string,
    attributeReadonly: PropTypes.bool,
  }

  static defaultProps = {
    isDeletable: false,
    isManager: false,
    onDeleteClick: () => null,
    onMessagesClick: () => null,
    onAttributeClick: () => null,
    attributeLabelButton: null,
    attributeReadonly: false,
  }

  static status = status

  renderInProgress = () => {
    const { length, accompaniment_type, programFollowed, nextAppointment, locale, percentage, percentageNb } =
      this.props
    const { lengthLabel, typeOfAccompanimentLabel, programFollowedLabel, nextAppointmentLabel } =
      locale

    return (
      <div className="content in-progress">
        <InfoField lightLabel={lengthLabel} boldLabel={length} />
        <InfoField lightLabel={typeOfAccompanimentLabel} boldLabel={accompaniment_type} />
        <div className="course-progress">
          <InfoField lightLabel={programFollowedLabel} boldLabel={programFollowed} />
          <div className="circular-prog-wrap">
            <CircularProgressbar
              value={percentage !== undefined ? percentage : 0}
              counterClockwise
              text={percentageNb !== undefined ? `${percentageNb}` : ''}
            />
            <GradientSVG />
          </div>
        </div>
        <InfoField
          lightLabel={nextAppointmentLabel}
          boldLabel={nextAppointment ? moment(nextAppointment).format('DD/MM/YYYY HH:mm') : '-'}
        />
      </div>
    )
  }

  renderArchive = () => {
    const { reason, locale } = this.props
    const { reasonLabel } = locale

    return (
      <div className="content">
        <InfoField lightLabel={reasonLabel} boldLabel={reason} />
      </div>
    )
  }

  renderSharedConsultants = () => {
    if (!this.props.sharedConsultants) return

    return this.props.sharedConsultants.map((consultant, i) => (
      <div key={i} className="avatar-wrapper" style={{ zIndex: i }}>
        <AvatarCircle photoUrl={consultant.avatar} />
      </div>
    ))
  }

  renderWaiting = () => {
    const { locale, financer_state } = this.props

    return (
      <div className="content">
        <InfoField
          color={this.props.color}
          lightLabel={locale.financeStateLabel}
          boldLabel={financer_state}
        />
      </div>
    )
  }

  renderStatusRelatedInformation = () => {
    const { status } = this.props

    switch (status.status) {
      case CandidatCard.status.wait_financer:
      case CandidatCard.status.in_validation:
        return this.renderWaiting()
      case CandidatCard.status.in_progress:
      case CandidatCard.status.follow_up:
        return this.renderInProgress()
      case CandidatCard.status.archived:
        return this.renderArchive()
      default:
    }
  }

  handleMailClick = () => {
    this.props.onMessagesClick({ id: this.props.id })
  }

  handleBeneficiaryClick = () => {
    this.props.onClick(this.props.id)
  }

  handleDeleteClick = () => {
    this.props.onDeleteClick({ id: this.props.id })
  }

  handleVideoCallInviteClick = async (id) => {
    this.props.createInvite({ user_id: id })
  }

  render() {
    const {
      id,
      status,
      avatar,
      name,
      email,
      phone,
      mail,
      isDeletable,
      isManager,
      attributeLabelButton,
      attributeReadonly,
      onAttributeClick,
      primaryConsultantLabel,
      primaryConsultantName,
      noPrimaryConsultantLabel,
      primaryConsultantDeleted,
      consultants,
      primaryConsultant,
    } = this.props

    const hasEnoughConsultants = !primaryConsultant
      ? consultants.length > 0
      : consultants.filter((consultant) => primaryConsultant?.id !== consultant.id).length > 0

    return (
      <div className="candidat-card">
        <div className="header">
          <p className="status">{status.label}</p>
          <div className="tools">
            <div className="consultants">{this.renderSharedConsultants()}</div>
            {isManager && attributeReadonly && hasEnoughConsultants && (
              <PrimaryButton label={attributeLabelButton} id={id} onClick={onAttributeClick} />
            )}
            {!isManager && (
              <>
                <div className="button-wrap">
                  {mail && <div className="red-dot" />}

                  <IconButton
                    icon="mail"
                    color={mail ? 'grey3' : 'grey2'}
                    onClick={this.handleMailClick}
                  />
                </div>
                <div className="button-wrap">
                  <IconButton
                    icon={Icon.icon.Visio}
                    onClick={() => this.handleVideoCallInviteClick(id)}
                    tooltip="Appel vidéo"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="candidat-info" onClick={this.handleBeneficiaryClick}>
          <AvatarCircle photoUrl={avatar} />
          <div>
            <p className="name">{name}</p>
            <p className="contact">{email}</p>
            <p className="contact">{phone}</p>

            {isDeletable && (
              <div className="action-buttons">
                <IconButton
                  icon="trash"
                  onClick={this.handleDeleteClick}
                  id="delete"
                  color={Icon.color.Grey2}
                />
              </div>
            )}
          </div>
        </div>

        {this.renderStatusRelatedInformation()}
        {isManager && primaryConsultantName && (
          <div className="candidat-info">
            {primaryConsultantDeleted && (
              <div className="candidat-orphan">{noPrimaryConsultantLabel}</div>
            )}
            <InfoField lightLabel={primaryConsultantLabel} boldLabel={primaryConsultantName} />
          </div>
        )}
      </div>
    )
  }
}
