import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import ComboField from '../../molecules/ComboField/ComboField'
import DateField from '../../molecules/DateField/DateFieldContainer'
import InputField from '../../molecules/InputField/InputField'

export default class AccompEditionCard extends Component {
  static propTypes = {
    accompanimentType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    duration: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
    }),
    durationType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    startDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    endDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    accompanimentSurveyCount: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
    }),
    bilanType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    bilanAvailability: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    locale: PropTypes.shape({
      title: PropTypes.string,
      accompanimentTypeLabel: PropTypes.string,
      durationLabel: PropTypes.string,
      startDateLabel: PropTypes.string,
      endDateLabel: PropTypes.string,
      bilanTypeLabel: PropTypes.string,
      bilanAvailabilityLabel: PropTypes.string,
      accompanimentSurveyCountLabel: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    duration: null,
    startDate: null,
    endDate: null,
    bilanType: null,
    bilanAvailability: null,
    readOnly: false,
    accompanimentSurveyCount: null,
  }

  handleChange = (newValue) => {
    if (!this.props.readOnly) {
      this.props.onChange(newValue)
    }
  }

  render() {
    const {
      accompanimentType,
      duration,
      durationType,
      startDate,
      endDate,
      bilanType,
      bilanAvailability,
      readOnly,
      accompanimentSurveyCount,
    } = this.props
    const {
      title = '',
      accompanimentTypeLabel = '',
      durationLabel = '',
      startDateLabel = '',
      endDateLabel = '',
      bilanTypeLabel = '',
      bilanAvailabilityLabel = '',
      dayShortLabels,
      monthLabels,
      locale,
      accompanimentSurveyCountLabel,
    } = this.props.locale

    const surveryTargets = [
      'outplacement_individual',
      'outplacement_group',
      'appraisal',
      'orientation_review',
    ]

    const showSurveryField = surveryTargets.includes(accompanimentType.value)

    return (
      <div className="accomp-edition-card">
        <IconCardTitle icon="clipboard" color="accent" label={title} />
        <div className="content">
          <ComboField
            key={accompanimentType.id}
            value={accompanimentType.value}
            title={accompanimentTypeLabel}
            name={accompanimentType.id}
            options={accompanimentType.options}
            onChange={this.handleChange}
            readOnly={readOnly}
          />

          {(bilanType !== null || bilanAvailability !== null) && (
            <div className="bilan-accompaniment">
              {bilanType !== null && (
                <ComboField
                  key={bilanType.id}
                  value={bilanType.value}
                  title={bilanTypeLabel}
                  name={bilanType.id}
                  options={bilanType.options}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                />
              )}
              {bilanAvailability !== null && (
                <ComboField
                  key={bilanAvailability.id}
                  value={bilanAvailability.value}
                  title={bilanAvailabilityLabel}
                  name={bilanAvailability.id}
                  options={bilanAvailability.options}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                />
              )}
            </div>
          )}
          {(duration !== null || startDate !== null || endDate !== null) && (
            <div className="inline-container">
              {duration !== null && durationType !== null && (
                <div className="accompaniment-duration">
                  <InputField
                    title={durationLabel}
                    name={duration.id}
                    value={duration.value}
                    onChange={this.handleChange}
                    type="number"
                    min={0}
                    readonly={readOnly}
                  />
                  <ComboField
                    key={durationType.id}
                    value={durationType.value}
                    name={durationType.id}
                    options={durationType.options}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </div>
              )}
              {startDate !== null && (
                <DateField
                  title={startDateLabel}
                  name={startDate.id}
                  value={startDate.value}
                  calendarLabels={{ dayShortLabels, monthLabels }}
                  onChange={this.handleChange}
                  locale={locale}
                  readOnly={readOnly}
                />
              )}
              {endDate !== null && (
                <DateField
                  title={endDateLabel}
                  name={endDate.id}
                  value={endDate.value}
                  calendarLabels={{ dayShortLabels, monthLabels }}
                  onChange={this.handleChange}
                  locale={locale}
                  readOnly={readOnly}
                />
              )}

              {showSurveryField && (
                <InputField
                  title={accompanimentSurveyCountLabel}
                  name={accompanimentSurveyCount.id}
                  value={accompanimentSurveyCount.value}
                  onChange={this.handleChange}
                  type="number"
                  readonly={readOnly}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
