import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'

//
// Initial state
//
const initialState = Map({
  locale: 'fr',
})

//
// Actions
//
export const actions = {
  setLocale: createAction('setLocale', (locale) => ({ locale })),
  getTranslations: createAction('getTranslations', (locale) => ({ locale })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setLocale]: (state, { locale }) => state.merge({ locale }),
  },
  initialState,
)

//
// Selectors
//
const locale = (state) => state.locale.get('locale')

export const selectors = {
  locale,
}
