import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Draggable from '../../../containers/dragdrop/Draggable/Draggable'
import Droppable from '../../../containers/dragdrop/Droppable/Droppable'
import ComboField from '../../molecules/ComboField/ComboField'
import SearchField from '../../molecules/SearchField/SearchField'
import SideResourceItem from '../../molecules/SideResourceButton/SideResourceButton'
import LineMenu from '../LineMenu/LineMenu'

export default class SideResourceSelector extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    locale: PropTypes.shape({
      links: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
    searchField: PropTypes.shape(SearchField.propTypes).isRequired,
    comboFields: PropTypes.arrayOf(PropTypes.shape(ComboField.propTypes)).isRequired,
    resources: PropTypes.arrayOf(PropTypes.shape(SideResourceItem.propTypes)).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.string,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }

  state = {
    tab: '',
  }

  handleMenuChange = (id) => {
    this.setState({ tab: id })
  }

  renderComboFields = () => {
    const fields = this.props.comboFields.map((combo, i) => (
      <ComboField
        key={i}
        name={combo.name}
        placeholder={combo.placeholder}
        options={combo.options}
        value={combo.value}
        onChange={combo.onChange}
      />
    ))

    return <div className="combo-fields-wrapper">{fields}</div>
  }

  renderSearchField = () => {
    const { searchField } = this.props

    return (
      <SearchField
        placeholder={searchField.placeholder}
        showLine={searchField.showLine}
        onChange={searchField.onChange}
        allowReset={searchField.activeInput}
        reversed={searchField.reversed}
        value={searchField.value}
      />
    )
  }

  renderExistingResourcesItems = () => (
    <div className="existing-items">
      {this.renderSearchField()}
      {this.renderComboFields()}
      {this.renderResources()}
    </div>
  )

  renderResources = () => {
    const { id, resources } = this.props

    return (
      <Droppable id={id} className="resources" isDropDisabled type={id}>
        {this.renderResourceItems(resources)}
      </Droppable>
    )
  }

  renderNewResourcesGroup = ({ id, items, label }, index) => {
    const sideResourceItems = items.map(({ id, label, icon }, i) => (
      <SideResourceItem key={i} id={id} label={label} icon={icon} />
    ))

    return (
      <Droppable id={id} type={id} isDropDisabled>
        <Draggable id={id} index={index} className="group">
          {label && <div className="title">{label}</div>}
          {sideResourceItems}
        </Draggable>
      </Droppable>
    )
  }

  renderResourceItems = (items) => {
    return items.map((item, i) => (
      <Draggable id={item.id} key={i} index={i} disabled={item.isDisabled}>
        <SideResourceItem
          key={i}
          id={item.id}
          label={item.label}
          icon={item.icon}
          attachmentIcon={item.attachmentIcon}
          attachmentLabel={item.attachmentLabel}
          isDisabled={item.isDisabled}
        />
      </Draggable>
    ))
  }

  renderNewResourcesItems = ({ id, label, items }) => {
    return (
      <div className="item">
        {label && <div className="title">{label}</div>}

        <Droppable id={id} type={id} isDropDisabled>
          {this.renderResourceItems(items)}
        </Droppable>
      </div>
    )
  }

  renderNewResourcesGroups = () => {
    const { items } = this.props
    const rows = items.map((group, i) => (
      <Fragment key={i}>
        {group.isGroup
          ? this.renderNewResourcesGroup(group, i)
          : this.renderNewResourcesItems(group)}
      </Fragment>
    ))

    return <div className="new-items">{rows}</div>
  }

  static getDerivedStateFromProps(props, { tab }) {
    return tab ? null : { tab: props.locale.links[0].id }
  }

  render() {
    const { locale } = this.props
    const { links } = locale
    const { tab } = this.state

    return (
      <div className="side-resource-selector">
        <LineMenu links={links} value={tab} onChange={this.handleMenuChange} />

        {tab === 'existing' ? this.renderExistingResourcesItems() : this.renderNewResourcesGroups()}
      </div>
    )
  }
}
