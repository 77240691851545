import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })

  return api.get('/contacts', query, { headers: { Authorization: `Bearer ${token}` } })
}
