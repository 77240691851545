import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import moment from 'moment/moment'
import 'moment/locale/fr'

import { reduxForm } from 'redux-form'

import { withI18n } from '../../global/Translator/Translator'

import BeneficiaryProfileContent from '../BeneficiaryProfileContent/BeneficiaryProfileContent'

import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'

import { getValidationRules, profileFields } from '../../../helpers/beneficiaryProfileForm'

const mapStateToProps = (state, props) => ({
  initialValues: {
    ...props.user,
    ..._mapValues(_pick(props.user, ['birth_date']), (date) => {
      if (date !== null && date !== '') return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return date
    }),
  },
})

const formProperties = {
  form: 'beneficiary-edit',
  asyncChangeFields: profileFields,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(props.user, values)
    return asyncValidate(values, validationRules)
  },
}

@withI18n
@connect(mapStateToProps, null)
@reduxForm(formProperties)
export default class BeneficiaryProfileForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    onProfileChange: PropTypes.func,
    user: PropTypes.any,
  }

  static defaultProps = {
    onProfileChange: null,
  }

  handleChange = ({ id, value }) => {
    if (this.props.onProfileChange !== null) {
      this.props.onProfileChange({ id: 'beneficiary', value })
    }
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    return (
      <form noValidate>
        <BeneficiaryProfileContent
          user={user}
          onChange={this.handleChange}
          currentUserRole={user.role}
        />
      </form>
    )
  }
}
