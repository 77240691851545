import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import StatsRow from '../../components/molecules/StatsRow/StatsRow'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as StatsActions, selectors as StatsSelectors } from '../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  stats: StatsSelectors.admin(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAdminStats: () => dispatch(StatsActions.getAdminStats()),
  setAdminStats: (stats) => dispatch(StatsActions.setAdminStats(stats)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminStatsPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'stats', label: t('stats.stats_mytalent') }]

    return <LineMenu value={links[0].id} links={links} />
  }

  componentDidMount() {
    this.props.getAdminStats()
  }

  componentWillUnmount() {
    this.props.setAdminStats(null)
  }

  formatStats = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  renderContent = () => {
    const { t, stats } = this.props

    return (
      <>
        <StatsRow label={t('stats.total_clients')} stats={this.formatStats(stats.no_offices)} />
        <StatsRow
          label={t('stats.total_consultants')}
          stats={this.formatStats(stats.no_consultants)}
        />
        <StatsRow
          label={t('stats.total_beneficiary')}
          stats={this.formatStats(stats.no_beneficiaries)}
        />
      </>
    )
  }

  render() {
    if (!this.props.stats) return null

    const aside = <AdminPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="admin-offices-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
