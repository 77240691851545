import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const get = ({ token, id }) => {
  return api.get(`/messages/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const setRead = ({ token, id }) => {
  return api.put(`/messages/${id}/read`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getAll = ({ token, filter }) => {
  const { type = 'inbox', state, q } = filter
  const filterQuery = _transform({ state, q }, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })

  const query = { ...filterQuery, type }

  return api.get('/messages', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const remove = ({ token, id }) => {
  return api.delete(`/messages/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const send = ({ token, message, subject, recipients }) => {
  const body = {
    subject,
    content: message,
    user_tos: recipients,
  }

  return api.post('/messages', body, { headers: { Authorization: `Bearer ${token}` } })
}
