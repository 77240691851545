import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectors as ModuleTemplateSelectors } from '../../../redux/ModuleTemplateRedux'
import ModuleTextEditDragDropContext from './ModuleTextEditDragDropContext'
import ModuleFormEditDragDropContext from './ModuleFormEditDragDropContext'

const mapStateToProps = (state) => ({
  template: ModuleTemplateSelectors.template(state),
})

@connect(mapStateToProps)
export default class ModuleEditDragDropContext extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  getDragDropContext() {
    switch (this.props.template.type) {
      case 'text':
        return ModuleTextEditDragDropContext
      case 'form':
        return ModuleFormEditDragDropContext
      default:
    }
  }

  render() {
    const Container = this.getDragDropContext()

    return <Container>{this.props.children}</Container>
  }
}
