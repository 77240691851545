import _mapValues from 'lodash/mapValues'
import _pick from 'lodash/pick'
import 'moment/locale/fr'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import Icon from '../../../components/atoms/Icon/Icon'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { history } from '../../../navigation/History'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { actions as NotesActions, selectors as NotesSelectors } from '../../../redux/NotesRedux'
import { actions as UserActions, selectors as UserSelectors } from '../../../redux/UserRedux'
import { selectors as WSSelectors } from '../../../redux/WSRedux'
import BeneficiaryProfileContent from '../../beneficiary/BeneficiaryProfileContent/BeneficiaryProfileContent'
import Page from '../../global/Page/Page'
import { withI18n } from '../../global/Translator/Translator'
import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  consultantNotes: NotesSelectors.consultantNotes(state),
  saveBeneficiaryAction: WSSelectors.action(state, 'beneficiary.saveBeneficiary'),
  saveNotesAction: WSSelectors.action(state, 'notes.save'),
  getNotesAction: WSSelectors.action(state, 'notes.get'),
  initialValues: {
    ...props.beneficiary,
    ..._mapValues(
      _pick(props.beneficiary, [
        'birth_date',
        'accompaniment_start_date',
        'accompaniment_end_date',
      ]),
      (date) => {
        if (date !== null && date !== '') return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return date
      },
    ),
  },
})

const mapDispatchToProps = (dispatch) => ({
  setBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.setBeneficiary(beneficiary)),
  saveBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.saveBeneficiary(beneficiary)),
  saveNotes: () => dispatch(NotesActions.saveNotes(true)),
  resetPassword: (email) => dispatch(UserActions.forgotPassword(email)),
  sendInvitation: (id) => dispatch(UserActions.sendInvitation(id)),
})

const formProperties = {
  form: 'beneficiary-profile',
  asyncBlurFields: profileFieldsForConsultant,
  asyncChangeFields: profileFieldsForConsultant,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(props.beneficiary, values, true)
    return asyncValidate(values, validationRules)
  },
}

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm(formProperties)
export default class ConsultantBeneficiaryProfileContainer extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  handleChange = ({ value }) => {
    const newBeneficiary = {
      ...this.props.beneficiary,
      ...value,
    }

    this.props.setBeneficiary(newBeneficiary)
  }

  isPrimary = () => {
    const { user, beneficiary } = this.props

    if (user.role === 'manager') {
      return true
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    )
  }

  renderContent = () => {
    const { beneficiary, groups, user } = this.props

    const isPrimary = this.isPrimary()

    return (
      <BeneficiaryProfileContent
        user={beneficiary}
        groups={groups}
        onChange={this.handleChange}
        consultantEdition
        readOnly={!isPrimary}
        currentUserRole={user.role}
      />
    )
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  getEducationOptions = () => {
    const { t } = this.props
    const beneficiaryFormations = t('beneficiary_formations')

    return Object.keys(beneficiaryFormations).map((key) => ({
      value: key,
      label: beneficiaryFormations[key],
    }))
  }

  getActivityOptions = () => {
    const { t } = this.props
    const beneficiaryCompanyActivitySectors = t('beneficiary_company_activity_sectors')

    return Object.keys(beneficiaryCompanyActivitySectors).map((key) => ({
      value: key,
      label: beneficiaryCompanyActivitySectors[key],
    }))
  }

  getSituationOptions = () => {
    const { t } = this.props
    const beneficiaryProfessionalSituations = t('beneficiary_professional_situations')

    return Object.keys(beneficiaryProfessionalSituations).map((key) => ({
      value: key,
      label: beneficiaryProfessionalSituations[key],
    }))
  }

  getSocioProfessionalCategoriesOptions = () => {
    const { t } = this.props
    const beneficiarySocioProfessionalCategories = t('beneficiary_socio_professional_categories')

    return Object.keys(beneficiarySocioProfessionalCategories).map((key) => ({
      value: key,
      label: beneficiarySocioProfessionalCategories[key],
    }))
  }

  getAccompanimentTypeOptions = () => {
    const { t } = this.props
    const beneficiaryAccompanimentTypes = t('beneficiary_accompaniment_types')

    return Object.keys(beneficiaryAccompanimentTypes).map((key) => ({
      value: key,
      label: beneficiaryAccompanimentTypes[key],
    }))
  }

  getAccompanimentDurationTypesOptions = () => {
    const { t } = this.props
    const beneficiaryAccompanimentDurationTypes = t('beneficiary_accompaniment_duration_types')

    return Object.keys(beneficiaryAccompanimentDurationTypes).map((key) => ({
      value: key,
      label: beneficiaryAccompanimentDurationTypes[key],
    }))
  }

  getAppraisalTypesOptions = () => {
    const { t } = this.props
    const beneficiaryAppraisalTypes = t('beneficiary_appraisal_types')

    return Object.keys(beneficiaryAppraisalTypes).map((key) => ({
      value: key,
      label: beneficiaryAppraisalTypes[key],
    }))
  }

  getAppraisalAvailabilitiesOptions = () => {
    const { t } = this.props
    const beneficiaryAppraisalAvailabilities = t('beneficiary_appraisal_availabilities')

    return Object.keys(beneficiaryAppraisalAvailabilities).map((key) => ({
      value: key,
      label: beneficiaryAppraisalAvailabilities[key],
    }))
  }

  getFinancerStatesOptions = () => {
    const { t } = this.props
    const beneficiaryFinancerStates = t('beneficiary_financer_states')

    return Object.keys(beneficiaryFinancerStates).map((key) => ({
      value: key,
      label: beneficiaryFinancerStates[key],
    }))
  }

  getFinancerTypesOptions = () => {
    const { t } = this.props
    const beneficiaryFinancerTypes = t('beneficiary_financer_types')

    return Object.keys(beneficiaryFinancerTypes).map((key) => ({
      value: key,
      label: beneficiaryFinancerTypes[key],
    }))
  }

  getFinancerOPACIFTypesOptions = () => {
    const { t } = this.props
    const beneficiaryFinancerOPACIFTypes = t('beneficiary_financer_opacif_types')

    return Object.keys(beneficiaryFinancerOPACIFTypes).map((key) => ({
      value: key,
      label: beneficiaryFinancerOPACIFTypes[key],
    }))
  }

  formatBeneficiary = (beneficiaryToFormat) => {
    let formattedBeneficiary = { ...beneficiaryToFormat }

    formattedBeneficiary = {
      ...formattedBeneficiary,
      birth_date: formattedBeneficiary.birth_date != null ? formattedBeneficiary.birth_date : '',
      cursus_formation:
        formattedBeneficiary.cursus_formation != null
          ? formattedBeneficiary.cursus_formation
          : this.getEducationOptions()[0].value,
      cursus_professional_situation:
        formattedBeneficiary.cursus_professional_situation != null
          ? formattedBeneficiary.cursus_professional_situation
          : this.getSituationOptions()[0].value,
      accompaniment_type:
        formattedBeneficiary.accompaniment_type != null
          ? formattedBeneficiary.accompaniment_type
          : this.getAccompanimentTypeOptions()[0].value,

      image_url: formattedBeneficiary.image_url != null ? formattedBeneficiary.image_url : '',
      phone_mobile:
        formattedBeneficiary.phone_mobile != null ? formattedBeneficiary.phone_mobile : '',
      address: formattedBeneficiary.address != null ? formattedBeneficiary.address : '',
      zip_code: formattedBeneficiary.zip_code != null ? formattedBeneficiary.zip_code : '',
      city: formattedBeneficiary.city != null ? formattedBeneficiary.city : '',
      phone_home: formattedBeneficiary.phone_home != null ? formattedBeneficiary.phone_home : '',
      cursus_socio_professional_category:
        formattedBeneficiary.cursus_socio_professional_category != null
          ? formattedBeneficiary.cursus_socio_professional_category
          : this.getSocioProfessionalCategoriesOptions()[0].value,
      cursus_function:
        formattedBeneficiary.cursus_function != null ? formattedBeneficiary.cursus_function : '',
      cursus_company_activity_sector:
        formattedBeneficiary.cursus_company_activity_sector != null
          ? formattedBeneficiary.cursus_company_activity_sector
          : this.getActivityOptions()[0].value,
      accompaniment_duration:
        formattedBeneficiary.accompaniment_duration != null
          ? formattedBeneficiary.accompaniment_duration
          : 0,
      accompaniment_duration_type:
        formattedBeneficiary.accompaniment_duration_type != null
          ? formattedBeneficiary.accompaniment_duration_type
          : this.getAccompanimentDurationTypesOptions()[0].value,
      accompaniment_survey_count:
        formattedBeneficiary.accompaniment_survey_count != null
          ? formattedBeneficiary.accompaniment_survey_count
          : 0,
      financer_state:
        formattedBeneficiary.financer_state != null
          ? formattedBeneficiary.financer_state
          : this.getFinancerStatesOptions()[0].value,
      financer_type:
        formattedBeneficiary.financer_type != null
          ? formattedBeneficiary.financer_type
          : this.getFinancerTypesOptions()[0].value,
      financer_opacif_type:
        formattedBeneficiary.financer_opacif_type != null
          ? formattedBeneficiary.financer_opacif_type
          : this.getFinancerOPACIFTypesOptions()[0].value,
      financer_name:
        formattedBeneficiary.financer_name != null ? formattedBeneficiary.financer_name : '',
      financer_support_request_file:
        formattedBeneficiary.financer_support_request_file != null
          ? formattedBeneficiary.financer_support_request_file
          : '',
      financer_support_agreement_file:
        formattedBeneficiary.financer_support_agreement_file != null
          ? formattedBeneficiary.financer_support_agreement_file
          : '',
      financial_ht:
        formattedBeneficiary.financial_ht != null ? formattedBeneficiary.financial_ht : 0,
      financial_tva:
        formattedBeneficiary.financial_tva != null ? formattedBeneficiary.financial_tva : 20,
      cursus_handicap_status:
        formattedBeneficiary.cursus_handicap_status != null
          ? formattedBeneficiary.cursus_handicap_status
          : 'no',
      cursus_handicap_file:
        formattedBeneficiary.cursus_handicap_file != null
          ? formattedBeneficiary.cursus_handicap_file
          : '',
      cursus_cv_file:
        formattedBeneficiary.cursus_cv_file != null ? formattedBeneficiary.cursus_cv_file : '',
      source: formattedBeneficiary.source != null ? formattedBeneficiary.source : '',
    }

    // Manage dates
    if (formattedBeneficiary.birth_date === null || formattedBeneficiary.birth_date === '') {
      delete formattedBeneficiary.birth_date
    }

    if (
      formattedBeneficiary.accompaniment_start_date === null ||
      formattedBeneficiary.accompaniment_start_date === ''
    ) {
      delete formattedBeneficiary.accompaniment_start_date
    }

    if (
      formattedBeneficiary.accompaniment_end_date === null ||
      formattedBeneficiary.accompaniment_end_date === ''
    ) {
      delete formattedBeneficiary.accompaniment_end_date
    }

    // Manage Accompaniment linked props : accompaniment_appraisal_type & accompaniment_appraisal_availability
    if (formattedBeneficiary.accompaniment_type === 'appraisal') {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        accompaniment_appraisal_type:
          formattedBeneficiary.accompaniment_appraisal_type != null
            ? formattedBeneficiary.accompaniment_appraisal_type
            : this.getAppraisalTypesOptions()[0].value,
        accompaniment_appraisal_availability:
          formattedBeneficiary.accompaniment_appraisal_availability != null
            ? formattedBeneficiary.accompaniment_appraisal_availability
            : this.getAppraisalAvailabilitiesOptions()[0].value,
      }
    } else {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        accompaniment_appraisal_type: null,
        accompaniment_appraisal_availability: null,
      }
    }

    // Manage group
    if (Number.parseInt(formattedBeneficiary.group_id, 10) === 0) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        group_id: null,
      }
    }

    // Manage cursus non required field
    if (
      !formattedBeneficiary.cursus_company_size ||
      formattedBeneficiary.cursus_company_size === ''
    ) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        cursus_company_size: null,
      }
    }

    if (
      !formattedBeneficiary.cursus_non_company_activity_sector ||
      formattedBeneficiary.cursus_non_company_activity_sector === ''
    ) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        cursus_non_company_activity_sector: null,
      }
    }

    return formattedBeneficiary
  }

  newBeneficiaryIsDifferent = () => {
    const formattedStateBeneficiary = this.formatBeneficiary(this.state.beneficiary)
    const formattedPropsBeneficiary = this.formatBeneficiary(this.props.beneficiary)

    const beneficiaryIsDifferent = [
      'status',
      'last_name',
      'first_name',
      'image_url',
      'gender',
      'email',
      'birth_date',
      'phone_mobile',
      'address',
      'zip_code',
      'city',
      'phone_home',
      'cursus_formation',
      'cursus_professional_situation',
      'cursus_socio_professional_category',
      'cursus_function',
      'cursus_company_activity_sector',
      'cursus_non_company_activity_sector',
      'cursus_company_size',
      'accompaniment_type',
      'accompaniment_duration',
      'accompaniment_duration_type',
      'accompaniment_start_date',
      'accompaniment_end_date',
      'accompaniment_survey_count',
      'accompaniment_appraisal_type',
      'accompaniment_appraisal_availability',
      'financer_state',
      'financer_type',
      'financer_opacif_type',
      'financer_name',
      'financer_support_request_file',
      'financer_support_agreement_file',
      'financial_ht',
      'financial_tva',
      'group_id',
      'cursus_handicap_status',
      'cursus_handicap_file',
      'cursus_cv_file',
      'source',
    ].reduce(
      (res, prop) => res || formattedStateBeneficiary[prop] !== formattedPropsBeneficiary[prop],
      false,
    )

    const consultantNotesState =
      this.state.consultantNotes !== null && this.state.consultantNotes.length > 0
        ? this.state.consultantNotes[0].notes
        : ''
    const consultantNotesProps =
      this.props.consultantNotes !== null && this.props.consultantNotes.length > 0
        ? this.props.consultantNotes[0].notes
        : ''

    return beneficiaryIsDifferent || consultantNotesState !== consultantNotesProps
  }

  disableSave = () => {
    const { saveBeneficiaryAction, saveNotesAction, valid } = this.props

    const { pending: beneficiarySaving } = saveBeneficiaryAction
    const { pending: notesSaving } = saveNotesAction

    const newBeneficiaryIsDifferent = this.newBeneficiaryIsDifferent()

    return beneficiarySaving || notesSaving || !newBeneficiaryIsDifferent || !valid
  }

  handleSave = () => {
    const { valid } = this.props
    if (valid) {
      if (this.isPrimary()) {
        const { beneficiary, saveBeneficiary } = this.props

        const newBeneficiary = this.formatBeneficiary(beneficiary)

        this.setState({
          resetBeneficiary: true,
          resetNotes: true,
        })

        saveBeneficiary(newBeneficiary)
      } else {
        const { saveNotes } = this.props

        this.setState({
          resetNotes: true,
        })

        saveNotes()
      }
    }
  }

  handleResetPassword = () => {
    const { beneficiary, resetPassword } = this.props
    resetPassword(beneficiary.email)
  }

  handleSendInvitation = () => {
    const { beneficiary, sendInvitation } = this.props
    sendInvitation(beneficiary.id)
  }

  renderResetPasswordButton() {
    const { t, beneficiary } = this.props
    const { id, activated_at } = beneficiary

    if (!id || !activated_at) {
      return null
    }

    return (
      <PrimaryButton
        id="reset"
        label={t('user.reset_password')}
        icon={Icon.icon.Key}
        onClick={this.handleResetPassword}
      />
    )
  }

  renderSendInvitationButton() {
    const { t, beneficiary } = this.props
    const { id, activated_at } = beneficiary

    if (!id || activated_at) {
      return null
    }

    return (
      <>
        <PrimaryButton
          id="send-invitation"
          label={t('user.resend_invitation')}
          icon={Icon.icon.Mail}
          onClick={this.handleSendInvitation}
        />

        <span>{t('beneficiary.not_activated_yet')}</span>
      </>
    )
  }

  static getDerivedStateFromProps(
    { beneficiary, saveBeneficiaryAction, getNotesAction, saveNotesAction, consultantNotes },
    state,
  ) {
    let stateUpdates = null

    if (
      beneficiary &&
      (state.beneficiary === null ||
        (state.resetBeneficiary && saveBeneficiaryAction.done === true))
    ) {
      stateUpdates = {
        beneficiary: {
          ...beneficiary,
          status: beneficiary.status || '',
        },
        resetBeneficiary: false,
      }
    }

    if (
      consultantNotes &&
      (state.consultantNotes === null ||
        getNotesAction.done === true ||
        (state.resetNotes && saveNotesAction.done === true))
    ) {
      stateUpdates = stateUpdates !== null ? stateUpdates : {}

      stateUpdates = {
        ...stateUpdates,
        consultantNotes,
        resetNotes: false,
      }
    }

    return stateUpdates
  }

  constructor(props) {
    super(props)

    this.state = {
      beneficiary: null,
      consultantNotes: null,
      resetBeneficiary: false,
      resetNotes: false,
      unblock: null,
    }
  }

  componentDidMount() {
    const unblock = history.block(() => {
      if (!this.disableSave()) {
        return 'true'
      }
    })

    this.setState({
      unblock,
    })
  }

  componentWillUnmount() {
    if (this.state.unblock !== null) {
      this.state.unblock()
    }
  }

  render() {
    return (
      <ConsultantBeneficiary
        renderAside={this.renderAside}
        disableSave={this.disableSave()}
        onSave={this.handleSave}
      >
        <div className="consultant-beneficiary-profile-page">
          <div className="action-button">
            {this.renderResetPasswordButton()}
            {this.renderSendInvitationButton()}
          </div>

          <form noValidate>{this.renderContent()}</form>
        </div>
      </ConsultantBeneficiary>
    )
  }
}
