import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import IconCheckbox from '../../atoms/IconCheckbox/IconCheckbox'

// PropTypes
const propTypes = {
  id: PropTypes.number.isRequired,
  isRead: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  sender: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    month_short_labels: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  onCheckClick: PropTypes.func.isRequired,
}

class MessageRow extends Component {
  onCheckClick = () => {
    this.props.onCheckClick(this.props.id)
  }

  handleClick = () => {
    this.props.onClick({ id: this.props.id })
  }

  render() {
    const { id, isRead, sender, subject, locale, created_at, isChecked = false } = this.props

    const d = new Date(created_at)
    const date = d.getDate()
    const month = d.getMonth()

    return (
      <div className={`message-row ${isRead ? 'read' : 'unread'}`}>
        <label htmlFor={id}>
          <IconCheckbox value={isChecked} onClick={this.onCheckClick} />
        </label>
        <div className="informations" onClick={this.handleClick}>
          <p className="sender">{sender}</p>
          <div className="separator" />
          <p className="subject">{subject}</p>
          <div className="date">
            <p className="day">{date < 10 ? `0${date}` : date}</p>
            <p className="month">{locale.month_short_labels[month]}</p>
          </div>
        </div>
      </div>
    )
  }
}

MessageRow.propTypes = propTypes

export default MessageRow
