import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isDirty, isValid, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'

import { withI18n } from '../../global/Translator/Translator'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import InputField from '../../../components/molecules/InputField/InputFieldContainer'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state) => ({
  valid: isValid('forgot-password')(state),
  touched: isDirty('forgot-password')(state),
})

const formProperties = {
  form: 'forgot-password',
  asyncChangeFields: ['email'],
  asyncValidate: (values) =>
    asyncValidate(values, {
      email: { rule: 'required|email' },
    }),
}

@withI18n
@connect(mapStateToProps)
@reduxForm(formProperties)
export default class ForgotPasswordForm extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    onSubmit: () => undefined,
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(this.props.onSubmit)(values)
  }

  render() {
    const { disabled, t, valid, touched } = this.props

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <InputField name="email" title={t('your_form.email')} type="email" disabled={disabled} />

        <PrimaryButton
          label={t('forgot_password.reset_password')}
          id="submit"
          type="submit"
          disabled={disabled || !touched || !valid}
          onClick={this.handleSubmit}
        />
      </form>
    )
  }
}
