import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isDirty, isValid, reduxForm } from 'redux-form'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import CheckBoxField from '../../../components/molecules/CheckBoxField/CheckBoxFieldContainer'
import InputField from '../../../components/molecules/InputField/InputFieldContainer'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { withI18n } from '../../global/Translator/Translator'
import { I18n } from 'react-redux-i18n'

const mapStateToProps = (state) => ({
  valid: isValid('reset-password')(state),
  touched: isDirty('reset-password')(state),
})

const formProperties = {
  form: 'reset-password',
  asyncChangeFields: ['password', 'terms'],
  validate: (values, { t }) => {
    const errors = {}

    if (!values.password) {
      errors.password = I18n.t('form_errors.required')
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\p{Z}\p{S}\p{P}]).{8,}$/u.test(values.password)
    ) {
      errors.password = I18n.t('form_errors.password')
    }

    return errors
  },
  asyncValidate: (values, dispatch, { t, isNew }) =>
    asyncValidate(values, {
      terms: { rule: isNew ? 'required|accepted' : null },
    }),
}

@withI18n
@connect(mapStateToProps)
@reduxForm(formProperties)
export default class ResetPasswordForm extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    disabled: true,
    isNew: false,
    onSubmit: () => undefined,
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(this.props.onSubmit)(values)
  }

  render() {
    const { disabled, valid, t, touched, termsUrl, terms, isNew } = this.props

    return (
      <form onSubmit={this.handleSubmit} noValidate className="reset-password-form">
        <InputField
          name="password"
          title={t('your_form.password')}
          type="password"
          disabled={disabled}
        />

        {isNew && (
          <div className="field-container reset-password-terms">
            <CheckBoxField name="terms" />

            <a href={termsUrl} target="_blank">
              {terms}
            </a>
          </div>
        )}

        <PrimaryButton
          label={t('reset_password.reset_password')}
          id="submit"
          type="submit"
          disabled={disabled || !touched || !valid}
          onClick={this.handleSubmit}
        />
      </form>
    )
  }
}
