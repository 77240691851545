import { call, put } from 'redux-saga/effects'

import { actions as navigationActions } from '../redux/NavigationRedux'
import { actions as portalActions } from '../redux/PortalRedux'
import { services } from '../services'

export function* officeSettings({ payload }) {
  const { slug } = payload

  yield call(
    services.portal.officeSettings,
    { slug },
    function* ({ ok, data }) {
      if (ok) {
        yield put(portalActions.setSlug({ slug }))
        yield put(portalActions.setTheme({ theme: data.theme }))
        yield put(portalActions.setLabels({ labels: data.labels }))
        yield put(portalActions.setAssets({ assets: data.assets }))
      } else yield put(navigationActions.redirect('/auth/login'))
    },
    true, // set this to true to disable auth on this api service call
  )
}

export function persistTheme({ payload }) {
  const { theme = null } = payload

  localStorage.setItem('_theme', JSON.stringify(theme))
}

export function persistLabels({ payload }) {
  const { labels = null } = payload

  localStorage.setItem('_labels', JSON.stringify(labels))
}

export function persistAssets({ payload }) {
  const { assets = null } = payload

  localStorage.setItem('_assets', JSON.stringify(assets))
}
