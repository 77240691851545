import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import DateField from './DateField'

@FieldContainer({ type: 'text' })
export default class DateFieldContainer extends Component {
  render() {
    return <DateField {...this.props} />
  }
}
