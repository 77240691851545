import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import config from '../../../config/AppConfig'
import { createUploader } from '../../../helpers/uploader'
import { actions as NotificationActions } from '../../../redux/NotificationRedux'
import { actions as UploadActions, selectors as UploadSelectors } from '../../../redux/UploadRedux'
import { selectors as WSSelectors, actions as WSActions } from '../../../redux/WSRedux'
import { withI18n } from '../Translator/Translator'

const mapStateToProps = (state, { id }) => ({
  action: WSSelectors.action(state, `upload.upload.${id}`),
  file: UploadSelectors.file(state, id),
})

const mapDispatchToProps = (dispatch, { id }) => ({
  upload: (uploader) => dispatch(UploadActions.upload(id, uploader)),
  setFile: (file) => dispatch(UploadActions.setFile(id, file)),
  error: (text) => dispatch(NotificationActions.add('error', text)),
  startAction: (id) => dispatch(WSActions.start(id)),
  resolveAction: (id) => dispatch(WSActions.resolve(id)),
})

@connect(mapStateToProps, mapDispatchToProps)
@withI18n
export default class FileUpload extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    accept: PropTypes.string,
    maxFileSize: PropTypes.number,
    onChange: PropTypes.func,
    children: PropTypes.func.isRequired,
  }

  static defaultProps = {
    accept: '*',
    maxFileSize: parseInt(config.API_MAX_FILE_SIZE, 10),
    children: ({ onClick, uploaded, uploading }) => null,
    onChange: ({ id, file: { url, path, name } }) => undefined,
  }

  uploader = createUploader(this.startAction.bind(this), this.resolveAction.bind(this))

  state = {
    file: null,
  }

  startAction() {
    this.props.startAction(`upload.upload.${this.props.id}`)
  }

  resolveAction() {
    this.props.resolveAction(`upload.upload.${this.props.id}`)
  }

  componentDidUpdate(oldProps) {
    const { id, file } = this.props
    const oldFile = oldProps.file || {}
    const newFile = file || {}

    if (oldFile.url !== newFile.url) {
      this.props.onChange({ id, file: newFile })
    }
  }

  componentWillUnmount() {
    this.props.setFile(null)
  }

  handleInputRef = (ref) => {
    this.ref = ref
  }

  handleFile = ({ file }) => {
    const { t, maxFileSize } = this.props

    if (!file) {
      this.props.setFile(null)
    } else if (file.size >= maxFileSize) {
      this.props.error(
        t('form_errors.max_file_size', {
          size: Math.round(maxFileSize / (1024 * 1024)),
        }),
      )
    } else {
      this.uploader.methods.addFiles([file])
      this.props.upload(this.uploader)
    }
  }

  handleClick = () => {
    this.ref.click()
  }

  handleFileChange = (e) => {
    this.handleFile({ file: e.target.files[0] })
    e.target.value = ''
  }

  render() {
    const { accept, children, action } = this.props
    const childrenProps = {
      uploaded: action.resolved,
      uploading: action.pending,
      onClick: this.handleClick,
      onFileChange: this.handleFile,
    }

    const inputStyle = {
      position: 'absolute',
      visibility: 'hidden',
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      flex: 0,
    }

    return (
      <>
        <input
          style={inputStyle}
          type="file"
          ref={this.handleInputRef}
          accept={accept}
          multiple={false}
          onChange={this.handleFileChange}
        />

        {children(childrenProps)}
      </>
    )
  }
}
