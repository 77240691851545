import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'

import ManagerStatistics from '../../containers/manager/ManagerStatistics/ManagerStatistics'
import ManagerChartStatistics from './fragments/ManagerChartStatistics'
import ManagerBeneficiaryByAgeAndBilanStatistics from './fragments/ManagerBeneficiaryByAgeAndBilanStatistics'
import ManagerBeneficiaryCountByActivityStatistics from './fragments/ManagerBeneficiaryCountByActivityStatistics'

import { selectors as StatsSelectors } from '../../redux/StatsRedux'
import SectionTitle from '../../components/molecules/SectionTitle/SectionTitle'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@Page
@withI18n
@connect(mapStateToProps, null)
export default class ManagerStatisticsBeneficiariesPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderChartStats = () => {
    return <ManagerChartStatistics />
  }

  renderBeneficiaryCountByAgeAndBilanStats = () => {
    return <ManagerBeneficiaryByAgeAndBilanStatistics />
  }

  renderBeneficiaryCountByActivityStats = () => {
    return <ManagerBeneficiaryCountByActivityStatistics />
  }

  render() {
    const { managerOfficeStats, t } = this.props

    return (
      <ManagerStatistics>
        <div className="manager-statistics-beneficiaries-page">
          <SectionTitle label={t('statistics.beneficiary_statistics')} />
          {managerOfficeStats !== null && this.renderChartStats()}
          {managerOfficeStats !== null && this.renderBeneficiaryCountByAgeAndBilanStats()}
          {managerOfficeStats !== null && this.renderBeneficiaryCountByActivityStats()}
        </div>
      </ManagerStatistics>
    )
  }
}
