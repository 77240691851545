import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SideMenuLink from '../../molecules/SideMenuLink/SideMenuLink'

export default class SideMenu extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    links: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onClick: ({ id }) => undefined,
  }

  handleClick = ({ id }) => {
    this.props.onClick({ id })
  }

  render() {
    const { links, pathname } = this.props

    const entries = links.map((link, i) => (
      <SideMenuLink
        pathname={pathname}
        href={link.href}
        onClick={link.onClick || this.handleClick}
        options={link.options}
        key={i}
        count={link.count}
        icon={link.icon}
        label={link.label}
        id={link.id}
      />
    ))

    return <div className="side-menu">{entries}</div>
  }
}
