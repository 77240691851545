import { actions as AppActions } from '../redux/AppRedux'
import { createBrowserHistory } from 'history'

let store = null

export const setStore = (createdStore) => {
  store = createdStore
}

export const history = createBrowserHistory({
  getUserConfirmation(manageLeaveFunc, callback) {
    store.dispatch(AppActions.showLeavePopin(callback))
  },
})
