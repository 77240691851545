import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../global/Translator/Translator'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../../redux/ConsultantRedux'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import BeneficiaryAttribPopin from '../../../components/organisms/BeneficiaryAttribPopin/BeneficiaryAttribPopin'
import Popin from '../../global/Popin/Popin'

const mapStateToProps = (state) => ({
  consultant: ConsultantSelectors.consultant(state),
  consultants: ConsultantSelectors.consultants(state),
})

const mapDispatchToProps = (dispatch) => ({
  getConsultants: () => dispatch(ConsultantActions.getConsultants()),
  attribute: (beneficiaryId, consultantId) =>
    dispatch(BeneficiaryActions.attribute(beneficiaryId, consultantId)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerConsultantBeneficiaryAttribute extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    pending: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    consultants: PropTypes.array,
    beneficiary: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    _consultant: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }

  static defaultProps = {
    resolved: false,
    pending: false,
    _consultant: null,
  }

  handleSubmit = ({ id }) => {
    const { beneficiary } = this.props

    this.props.attribute(beneficiary.id, id)

    this.props.onClose()
  }

  componentDidMount() {
    this.props.getConsultants()
  }

  render() {
    const { consultant, _consultant, consultants, onClose, t, pending, isOpen } = this.props

    if (!consultants || (!consultant && !_consultant)) {
      return null
    }

    const currentConsultant = consultant || _consultant

    const options = consultants
      .filter(({ id }) => id !== currentConsultant.id)
      .map(({ id, first_name, last_name }) => ({
        label: `${first_name} ${last_name}`,
        value: id.toString(),
      }))

    if (!options || options.length === 0) {
      return null
    }

    return (
      <Popin onClose={onClose} open={isOpen}>
        <BeneficiaryAttribPopin
          options={options}
          onBack={onClose}
          disabled={pending}
          onSubmit={this.handleSubmit}
          locale={{
            title: t('beneficiary.attribution.title'),
            cta: t('actions.validate'),
            selectLabel: t('beneficiary.attribution.consultants_label'),
          }}
        />
      </Popin>
    )
  }
}
