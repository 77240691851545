import { call, put } from 'redux-saga/effects'

import { services } from '../services'
import { actions as ContactActions } from '../redux/ContactRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'

export function* getContacts({ payload }) {
  const { filter } = payload

  yield call(services.contact.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(ContactActions.setContacts(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}
