import _some from 'lodash/some'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Scrollchor from 'react-scrollchor'
import Icon from '../../../components/atoms/Icon/Icon'
import IconButton from '../../../components/atoms/IconButton/IconButton'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import CheckBoxField from '../../../components/molecules/CheckBoxField/CheckBoxField'
import NotesDisplay from '../../../components/molecules/NotesDisplay/NotesDisplay'
import ParcoursModuleContainer from '../../../components/molecules/ParcoursModuleContainer/ParcoursModuleContainer'
import ParcoursModuleContentDocumentConsultant from '../../../components/molecules/ParcoursModuleContentDocumentConsultant/ParcoursModuleContentDocumentConsultant'
import ParcoursModuleContentFileReturnBeneficiary from '../../../components/molecules/ParcoursModuleContentFileReturnBeneficiary/ParcoursModuleContentFileReturnBeneficiary'
import ParcoursModuleContentFileReturnConsultant from '../../../components/molecules/ParcoursModuleContentFileReturnConsultant/ParcoursModuleContentFileReturnConsultant'
import ParcoursModuleContentLink from '../../../components/molecules/ParcoursModuleContentLink/ParcoursModuleContentLink'
import ParcoursModuleContentVideoLink from '../../../components/molecules/ParcoursModuleContentVideoLink/ParcoursModuleContentVideoLink'
import ParcoursStepQuiz from '../../../components/molecules/ParcoursStepQuiz/ParcoursStepQuiz'
import ParcoursStepSynthese from '../../../components/molecules/ParcoursStepSynthese/ParcoursStepSynthese'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'
import VideoPopin from '../../../components/organisms/VideoPopin/VideoPopin'
import { moduleDone } from '../../../helpers/courseHelper'
import { actions as AppointmentActions } from '../../../redux/AppointmentRedux'
import { actions as CourseTemplateActions } from '../../../redux/CourseTemplateRedux'
import { actions as ModuleActions } from '../../../redux/ModuleRedux'
import { actions as ModuleTemplateActions } from '../../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import {
  actions as VideoCallActions,
  selectors as VideoCallSelectors,
} from '../../../redux/VideocallRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { actions as ResourceActions } from '../../../redux/ResourceRedux'

import AppointmentPostponePopin from '../../course/AppointmentPostponePopin/AppointmentPostponePopin'
import AppointmentConfirmPopin from '../../course/AppointmentConfirmPopin/AppointmentConfirmPopin'
import EditResourcePopin from '../../course/EditResourcePopin/EditResourcePopin'
import RemoveStepModulePopin from '../../course/RemoveStepModulePopin/RemoveStepModulePopin'
import Draggable from '../../dragdrop/Draggable/Draggable'
import Droppable from '../../dragdrop/Droppable/Droppable'
import Popin from '../../global/Popin/Popin'
import { withI18n } from '../../global/Translator/Translator'
import CircleButton from '../../../components/atoms/CircleButton/CircleButton'
import BeneficiaryFormModulePage from '../../../pages/BeneficiaryFormModulePage/BeneficiaryFormModulePage'
import CentralTestModuleConsultant from '../../../components/molecules/CentralTestModuleConsultant/CentralTestModuleConsultant'
import CentralTestModuleBeneficiary from '../../../components/molecules/CentralTestModuleBeneficiary/CentralTestModuleBeneficiary'

const moduleTypes = {
  synthesis: 'synthesis',
  form: 'form',
  appointment: 'appointment',
  text: 'text',
  exercise: 'exercise',
  centraltest: 'centraltest',
}

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  fullPathname: NavigationSelectors.fullPathname(state),
  invite: VideoCallSelectors.invite(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  isBeneficiary: UserSelectors.isBeneficiary(state),
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
  postpone: (id) => dispatch(AppointmentActions.postpone(id)),
  confirm: (id) => dispatch(AppointmentActions.confirm(id)),
  setModuleTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  updateModuleAtIndex: (stepIndex, moduleIndex, module, persist) =>
    dispatch(CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, module, persist)),
  saveModuleCompleteStatus: (module_id, done) =>
    dispatch(ModuleActions.saveModuleCompleteStatus({ module_id, done })),
  saveModule: (id, module) => dispatch(ModuleActions.saveModule(id, module)),
  setResourceUrl: (id, url, type, stepIndex, moduleIndex) =>
    dispatch(ResourceActions.setUrl(id, url, type, stepIndex, moduleIndex)),
  setPrivate: (id, isPrivate, stepIndex, moduleIndex) =>
    dispatch(ResourceActions.setPrivate(id, isPrivate, stepIndex, moduleIndex)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  createInvite: (payload) => dispatch(VideoCallActions.createInvite(payload)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class EditOngoingStepModuleWrapper extends Component {
  static propTypes = {
    t: PropTypes.func,
    stepIndex: PropTypes.number,
    module_id: PropTypes.string,
    locked: PropTypes.bool,
    renderDetails: PropTypes.bool,
    exportView: PropTypes.bool,
  }

  static defaultProps = {
    stepIndex: null,
    module_id: '',
    locked: false,
    renderDetails: false,
    exportView: false,
  }

  state = {
    videoPopin: '',
    videoTitle: '',
    stepIndex: '',
    moduleIndex: '',
    showConfirm: false,
    confirmCompletePopinParams: null,
    confirmReloadPopinParams: null,
    popin: {
      stepIndex: -1,
      moduleIndex: -1,
      remove: false,
      postpone: false,
      confirm: false,
    },
  }

  componentDidMount() {
    if (this.props.module_id && document.getElementById(this.props.module_id)) {
      this._scroll.simulateClick()
    }
  }

  scrollRef = (ref) => {
    this._scroll = ref
  }

  handleWorkViewResultsClick = (id) => {
    this.props.redirect(`${this.props.pathname}/forms/${id}`)
  }

  handleClickCompleted = ({ id, iconProgState }) => {
    this.setState({ confirmCompletePopinParams: { id, iconProgState } })
  }

  handleEditClick = ({ type, moduleIndex }) => {
    const { step, stepIndex, pathname } = this.props
    const { modules } = step

    this.props.setModuleTemplate({ stepIndex, moduleIndex, ...modules[moduleIndex] })
    this.props.redirect(`${pathname}/modules/${type}`)
  }

  handleDeleteClick = ({ moduleIndex }) => {
    const { stepIndex } = this.props

    this.handleRemove({ stepIndex, moduleIndex })
  }

  handleLockClick = ({ stepIndex, moduleIndex, locked }) => {
    const { isBeneficiary } = this.props
    if (!isBeneficiary) {
      this.props.updateModuleAtIndex(stepIndex, moduleIndex, { locked })
    }
  }

  handleClickReload = ({ id, stepIndex, moduleIndex, done }) => {
    this.setState({ confirmReloadPopinParams: { id, stepIndex, moduleIndex, done } })
  }

  toggleShowRemovePopin = () => {
    this.setState(({ popin }) => ({
      popin: { ...popin, remove: !popin.remove },
    }))
  }

  toggleShowPostponePopin = (id) => {
    this.setState(({ popin }) => ({
      popin: { ...popin, moduleId: id, postpone: !popin.postpone },
    }))
  }

  toggleShowConfirmPopin = (id) => {
    this.setState(({ popin }) => ({
      popin: { ...popin, moduleId: id, confirm: !popin.confirm },
    }))
  }

  handleRemove = ({ stepIndex, moduleIndex }) => {
    this.setState(({ popin }) => ({
      popin: { ...popin, stepIndex, moduleIndex, remove: true },
    }))
  }

  handleDocumentUploadChange = ({ id, file }) => {
    const { step, stepIndex } = this.props
    const [moduleIndex, resourceIndex] = id.split('_').map(Number)
    const module = { ...step.modules[moduleIndex] }
    const resource = module.data.resources[resourceIndex]

    this.props.setResourceUrl(resource.id, file.url, stepIndex, moduleIndex)
  }

  handleDocumentTogglePrivate = ({ id, value }) => {
    const { step, stepIndex } = this.props
    const [moduleIndex, resourceIndex] = id.split('_').map(Number)
    const module = { ...step.modules[moduleIndex] }
    const resources = [...module.data.resources]
    const resource = resources[resourceIndex]

    resource.private = value

    if (module.id && resource.id) {
      this.props.setPrivate(resource.id, value, stepIndex, moduleIndex)
    } else {
      this.props.updateModuleAtIndex(stepIndex, moduleIndex, { resources }, true)
    }
  }

  handleClickBeneficiaryUpload = ({ id }) => {
    const matches = id.match(/(\d+)_(\d+)_(\d+)/)
    const stepIndex = parseInt(matches[1], 10)
    const moduleIndex = parseInt(matches[2], 10)
    const resourceIndex = parseInt(matches[3], 10)

    this.setState(({ popin }) => ({
      stepIndex,
      moduleIndex,
      resourceIndex,
      popin: { ...popin, resource: true },
    }))
  }

  handleVideoClicked = (url, title) => {
    this.setState({ videoPopin: url, videoTitle: title })
  }

  handleCloseVideoPopin = () => {
    this.setState({ videoPopin: '', videoTitle: '' })
  }

  handleCloseConfirmCompletePopin = () => {
    this.setState({ confirmCompletePopinParams: null })
  }

  handleConfirmCompleteAction = () => {
    const { id, iconProgState } = this.state.confirmCompletePopinParams

    const { saveModuleCompleteStatus } = this.props

    let done = true

    if (iconProgState === 'finish') {
      done = false
    }

    this.setState({ confirmCompletePopinParams: null }, () => {
      saveModuleCompleteStatus(id, done)
    })
  }

  handleCloseConfirmReloadPopin = () => {
    this.setState({ confirmReloadPopinParams: null })
  }

  handleConfirmReloadAction = () => {
    const { id, stepIndex, moduleIndex, done } = this.state.confirmReloadPopinParams
    const { updateModuleAtIndex, saveModuleCompleteStatus } = this.props

    this.setState({ confirmReloadPopinParams: null }, () => {
      saveModuleCompleteStatus(id, done)
      updateModuleAtIndex(stepIndex, moduleIndex, { done })
    })
  }

  getLockDoneIcon(locked, done, inProgress = false) {
    return done ? 'finish' : inProgress ? 'ongoing' : locked ? 'locked' : 'untouched'
  }

  renderSynthesis({
    id,
    _id,
    type,
    title,
    description,
    data,
    done,
    locked,
    stepIndex,
    moduleIndex,
    lockedState,
  }) {
    const { t, isBeneficiary } = this.props
    const { beneficiary_notes, consultant_notes, consultant_private_notes } = data

    const iconProgState = this.getLockDoneIcon(locked, done)
    const children = isBeneficiary
      ? [
          <NotesDisplay
            key={1}
            isPrivate={false}
            title={t('notes.notes')}
            text={beneficiary_notes}
          />,
          consultant_notes ? (
            <NotesDisplay
              key={2}
              isPrivate={false}
              title={t('notes.consultant_notes')}
              text={consultant_notes}
            />
          ) : null,
        ]
      : [
          beneficiary_notes ? (
            <NotesDisplay
              key={1}
              isPrivate
              title={t('module.synthesis.beneficiary_synthesis')}
              text={beneficiary_notes}
            />
          ) : null,
          consultant_notes ? (
            <NotesDisplay
              key={2}
              isPrivate={false}
              title={t('module.synthesis.my_synthesis')}
              text={consultant_notes}
            />
          ) : null,
          consultant_private_notes ? (
            <NotesDisplay
              key={3}
              isPrivate={true}
              title={t('notes.consultant_private_notes')}
              text={consultant_private_notes}
            />
          ) : null,
        ]

    if (description) {
      const key = `${id}-synthesis-description`
      children.unshift(
        <div key={key} className="module-description">
          {description}
        </div>,
      )
    }

    return (
      <ParcoursStepSynthese
        key={id}
        id={id || _id}
        label={title}
        type={type}
        moduleIndex={moduleIndex}
        stepIndex={stepIndex}
        iconProgState={iconProgState}
        isActivated={!locked}
        text={data.description}
        isConsultant={!isBeneficiary}
        isComplete={iconProgState === 'finish'}
        locale={{
          completed: t('module.synthesis.complete'),
          lockTooltip: t('actions.lock_tooltip'),
        }}
        onClickEdit={this.handleEditClick}
        onClickDelete={this.handleDeleteClick}
        onClickCompleted={this.handleClickCompleted}
        isLocked={lockedState}
        onClickLock={this.handleLockClick}
      >
        {children}
      </ParcoursStepSynthese>
    )
  }

  renderForm({
    id,
    _id,
    title,
    type,
    description,
    data,
    done,
    locked,
    stepIndex,
    moduleIndex,
    lockedState,
  }) {
    const { t, isBeneficiary, exportView } = this.props

    const inProgress = _some(data.questions, (quest) => {
      if (quest.type === 'group') {
        return _some(quest.questions, (subQuest) => subQuest.answer)
      }
      return quest.answer
    })

    const iconProgState = this.getLockDoneIcon(locked, done, inProgress)

    const complete = { done: !!isBeneficiary }
    const multipleChoice = data.questions.reduce(
      (acc, question) => acc + (question.type === 'multiple_choice'),
      0,
    )

    const questionToFill = data.questions.reduce(
      (acc, question) => acc + (question.type === 'text'),
      0,
    )

    return (
      <>
        <ParcoursStepQuiz
          key={id}
          id={id || _id}
          stepIndex={stepIndex}
          moduleIndex={moduleIndex}
          complete={complete}
          type={type}
          iconProgState={iconProgState}
          isActivated={!locked}
          isEditable={
            (iconProgState === 'locked' || iconProgState === 'untouched') && !isBeneficiary
          }
          isLocked={lockedState}
          showProgress
          isBeneficiary={isBeneficiary}
          isChecked={iconProgState === 'finish'}
          onClickDelete={this.handleRemove}
          onClickReload={this.handleClickReload}
          onClickEdit={this.handleEditClick}
          onClickLock={this.handleLockClick}
          onClickCompleted={this.handleClickCompleted}
          onWorkOnClick={this.handleWorkViewResultsClick}
          onResultsClick={this.handleWorkViewResultsClick}
          locale={{
            showResult: t('module.form.show_result'),
            totalQuestion: t('module.form.total_question'),
            totalQuestions: t('module.form.total_questions'),
            multipleChoice: t('module.form.multiple_choice'),
            multipleChoices: t('module.form.multiple_choices'),
            questionToFill: t('module.form.question_to_fill'),
            questionsToFill: t('module.form.questions_to_fill'),
            complete: t('module.synthesis.complete'),
            lockTooltip: t('actions.lock_tooltip'),
          }}
          label={title}
          text={description}
          totalQuestion={data.questions.length}
          multipleChoice={multipleChoice}
          questionToFill={questionToFill}
        />
        {exportView && <BeneficiaryFormModulePage form_id={id} />}
      </>
    )
  }

  handleIframeLoaded(e) {
    this.resizeIFrameToFitContent(e.target)
  }

  handleVideoCallInviteClick = (invite) => {
    window.open(process.env.REACT_APP_VISIO_FRONT_URL + '/' + invite, '_blank')
  }

  resizeIFrameToFitContent(iframe) {
    if (iframe.contentDocument.querySelector('div.beneficiary-form-module-page') === null) {
      window.setTimeout(() => this.resizeIFrameToFitContent(iframe), 1000)
    } else {
      iframe.height = iframe.contentDocument.querySelector('div.page').scrollHeight
    }
  }

  renderAppointment({
    id,
    _id,
    title,
    description,
    type,
    data,
    done,
    locked,
    stepIndex,
    moduleIndex,
    lockedState,
  }) {
    const { t, isBeneficiary } = this.props
    const { private_notes, public_notes, status, is_videocall, call } = data
    const iconProgState = this.getLockDoneIcon(locked, done)

    let date = ''
    let time = ''

    let displayPostponeBtn = false

    if (data.rdv_at) {
      date = moment(data.rdv_at).format('LL')
      const timeAt = moment(data.rdv_at).format('LT')
      const timeTo = moment(data.rdv_to).format('LT')
      time = t('calendar.hours', { at: timeAt, to: timeTo })

      const todayMoment = moment()
      const rdvDateMoment = moment(data.rdv_at)

      if (rdvDateMoment.isAfter(todayMoment)) {
        displayPostponeBtn = true
      }
    }

    const rdv =
      status === 'ko' || (!isBeneficiary && data.rdv_at)
        ? t('module.appointment.redefine')
        : t('module.appointment.define')

    const consultantActions = [
      <div className="rdv-at">
        <p className={`rdv-info ${status === 'ko' ? 'red' : ''}`}>{`${date}`}</p>
        <p className={`rdv-info ${status === 'ko' ? 'red' : ''}`}>{time}</p>
      </div>,
    ]

    if (status === 'rescheduled') {
      consultantActions.push(
        <p className="rdv-info orange">{t('module.appointment.waiting_after_rescheduled')}</p>,
      )
    }

    if (iconProgState !== 'finish') {
      consultantActions.push(
        <p
          onClick={() => this.handleEditClick({ type, moduleIndex })}
          className={`rdv-config ${status === 'ko' ? 'red' : 'blue'}`}
        >
          {rdv}
        </p>,
      )

      if (is_videocall) {
        if (call && call.status) {
          consultantActions.push(<small>Durée visio-conférence: {call.duration} minutes</small>)
        }

        consultantActions.push(
          <CircleButton
            id={id}
            icon={Icon.icon.Visio}
            onClick={() => this.handleVideoCallInviteClick(call.invite)}
          />,
        )
      }

      consultantActions.push(
        moduleIndex > 0 ? (
          <IconButton
            id={id}
            icon={Icon.icon.Lock}
            color={lockedState ? IconButton.color.Accent : IconButton.color.Grey2}
            onClick={() => this.handleLockClick({ stepIndex, moduleIndex, locked: !lockedState })}
          />
        ) : null,
      )

      consultantActions.push(
        <IconButton
          id={id}
          icon={Icon.icon.Edit2}
          color={Icon.color.Grey2}
          onClick={() => this.handleEditClick({ type, moduleIndex })}
        />,
        <IconButton
          id={id}
          icon={Icon.icon.Trash2}
          color={Icon.color.Grey2}
          onClick={() => this.handleRemove({ stepIndex, moduleIndex })}
        />,
      )
    } else {
      consultantActions.push(
        <IconButton
          id={id}
          icon={Icon.icon.Edit2}
          color={Icon.color.Grey2}
          onClick={() => this.handleEditClick({ type, moduleIndex })}
        />,
        <IconButton
          id={id}
          icon={Icon.icon.Trash2}
          color={Icon.color.Grey2}
          onClick={() => this.handleRemove({ stepIndex, moduleIndex })}
        />,
      )
    }

    const beneficiaryActions = []

    if (['ok', 'rescheduled'].includes(data.status)) {
      beneficiaryActions.push(
        <div className="rdv-at">
          <p className={`rdv-info${data.status === 'rescheduled' ? ' orange' : ''}`}>{date}</p>
          <p className={`rdv-info${data.status === 'rescheduled' ? ' orange' : ''}`}>{time}</p>
        </div>,
      )
      if (data.status === 'rescheduled') {
        beneficiaryActions.push(
          <PrimaryButton
            id={id || _id}
            label={t('module.appointment.confirm')}
            onClick={() => this.toggleShowConfirmPopin(id)}
          />,
        )
      }

      if (is_videocall) {
        beneficiaryActions.push(
          <CircleButton
            id={id}
            icon={Icon.icon.Visio}
            onClick={() => this.handleVideoCallInviteClick(call.invite)}
          />,
        )
      }

      if (displayPostponeBtn) {
        beneficiaryActions.push(
          <PrimaryButton
            id={id || _id}
            label={t('module.appointment.postpone')}
            onClick={() => this.toggleShowPostponePopin(id)}
          />,
        )
      }
    } else if (data.status === 'ko') {
      beneficiaryActions.push(
        <div className="rdv-at">
          <p className="rdv-info">{t('module.appointment.waiting_after_postpone')}</p>
        </div>,
      )
    }

    const children = isBeneficiary
      ? [<NotesDisplay key={1} isPrivate={false} title={t('notes.notes')} text={public_notes} />]
      : [
          private_notes ? (
            <NotesDisplay
              key={1}
              isPrivate
              title={t('notes.my_private_notes')}
              text={private_notes}
              synthesisNote
            />
          ) : null,
          <NotesDisplay
            key={2}
            isPrivate={false}
            title={t('notes.my_public_notes')}
            text={public_notes}
          />,
        ]

    if (description) {
      const key = `${id}-appointment-description`
      children.unshift(
        <div key={key} className="module-description">
          {description}
        </div>,
      )
    }

    return (
      <ParcoursModuleContainer
        key={id}
        id={id || _id}
        isActivated={!locked}
        progState={iconProgState}
        moduleType="rdv"
        label={title}
        actions={isBeneficiary ? beneficiaryActions : consultantActions}
        children={children}
        description={description}
      />
    )
  }

  renderText({
    id,
    _id,
    title,
    type,
    data,
    done,
    locked,
    stepIndex,
    moduleIndex,
    lockedState,
    description,
  }) {
    const { t, isBeneficiary } = this.props
    const iconProgState = this.getLockDoneIcon(locked, done)
    const isLocked = iconProgState === 'locked'
    const isCompleted = iconProgState === 'finish'
    const consultantLocale = {
      returnDocument: t('resource.attached_document'),
      completedDocument: t('resource.returned_document'),
    }
    const beneficiaryLocale = {
      returnDocument: t('resource.to_return_document'),
      completedDocument: t('resource.completed_document'),
      dropFile: t('resource.drop_to_return_file'),
    }

    const children = data.resources.map(({ title, url, type_uploaded, url_uploaded, type }, i) => {
      switch (type) {
        case 'link':
          return (
            <ParcoursModuleContentLink key={i} url={url} label={title} link={url} isActivated />
          )

        case 'video':
          return (
            <ParcoursModuleContentVideoLink
              key={i}
              label={title}
              link={url}
              isActivated
              onClick={this.handleVideoClicked}
            />
          )

        case 'to_return_document':
          if (isBeneficiary) {
            return (
              <ParcoursModuleContentFileReturnBeneficiary
                key={i}
                id={`${stepIndex}_${moduleIndex}_${i}`}
                url={url}
                typeUploaded={type_uploaded}
                urlUploaded={url_uploaded}
                consultantFileName={title}
                onUploadClick={this.handleClickBeneficiaryUpload}
                isActivated
                locale={isBeneficiary ? beneficiaryLocale : consultantLocale}
              />
            )
          }
          return (
            <ParcoursModuleContentFileReturnConsultant
              key={i}
              id={`${moduleIndex}_${i}`}
              url={url}
              typeUploaded={type_uploaded}
              urlUploaded={url_uploaded}
              consultantFileName={title}
              beneficiaryFileName={url_uploaded}
              isActivated
              locale={isBeneficiary ? beneficiaryLocale : consultantLocale}
            />
          )

        default: {
          const isPrivate = data.resources[i].private

          if (isBeneficiary && isPrivate) {
            return null
          }

          return (
            <ParcoursModuleContentDocumentConsultant
              key={i}
              url={url}
              fileName={title}
              isActivated
              isPrivate={isPrivate}
              locale={{
                private: t('resource.private'),
                public: t('resource.public'),
              }}
              isFinish={isBeneficiary}
              id={`${moduleIndex}_${i}`}
              onTogglePrivate={this.handleDocumentTogglePrivate}
            />
          )
        }
      }
    })

    const consultantActions =
      iconProgState === 'locked' || iconProgState === 'untouched'
        ? [
            moduleIndex > 0 ? (
              <IconButton
                icon={Icon.icon.Lock}
                color={lockedState ? IconButton.color.Accent : IconButton.color.Grey2}
                onClick={() =>
                  this.handleLockClick({ stepIndex, moduleIndex, locked: !lockedState })
                }
                id="lock"
                tooltip={t('actions.lock_tooltip')}
              />
            ) : null,
            <IconButton
              icon={Icon.icon.Edit2}
              color={Icon.color.Grey2}
              onClick={() => this.handleEditClick({ type, moduleIndex })}
            />,
            <IconButton
              icon={Icon.icon.Trash2}
              color={Icon.color.Grey2}
              onClick={() => this.handleRemove({ stepIndex, moduleIndex })}
            />,
          ]
        : [
            <IconButton
              icon={Icon.icon.Reload}
              color={Icon.color.Grey2}
              onClick={() => this.handleClickReload({ id, stepIndex, moduleIndex, done: false })}
            />,
            <IconButton
              icon={Icon.icon.Trash2}
              color={Icon.color.Grey2}
              onClick={() => this.handleRemove({ stepIndex, moduleIndex })}
            />,
          ]

    const beneficiaryActions =
      !isLocked && !isCompleted
        ? [
            <div className="action-completed">
              <PrimaryButton
                id={id || _id}
                label={t('module.synthesis.complete')}
                onClick={() => this.handleClickCompleted({ id, iconProgState })}
              />
              <CheckBoxField
                id={id || _id}
                name={id || _id}
                iconProgState={iconProgState}
                label={t('module.synthesis.complete')}
                value={iconProgState === 'finish'}
                onChange={() => this.handleClickCompleted({ id, iconProgState })}
              />
            </div>,
          ]
        : null

    return (
      <ParcoursModuleContainer
        key={id}
        id={id || _id}
        isActivated={!locked}
        progState={iconProgState}
        moduleType="free"
        label={title}
        actions={isBeneficiary ? beneficiaryActions : consultantActions}
        children={children.filter((a) => a)}
        description={description}
      />
    )
  }

  renderVideoPopin() {
    const { videoPopin, videoTitle } = this.state

    return (
      <VideoPopin
        title={videoTitle}
        videoId={videoPopin}
        onClose={this.handleCloseVideoPopin}
        open={!!videoPopin}
      />
    )
  }

  renderConfirmCompletePopin() {
    const { t } = this.props
    const { confirmCompletePopinParams } = this.state

    return (
      <Popin
        onClose={this.handleCloseConfirmCompletePopin}
        open={confirmCompletePopinParams !== null}
      >
        <AlertPopin
          label={t('module.synthesis.confirm_complete.label')}
          text={t('module.synthesis.confirm_complete.text')}
          labelCancelButton={t('actions.no')}
          labelConfirmButton={t('actions.yes')}
          onCancelButtonClick={this.handleCloseConfirmCompletePopin}
          onConfirmButtonClick={this.handleConfirmCompleteAction}
        />
      </Popin>
    )
  }

  renderConfirmReloadPopin() {
    const { t } = this.props
    const { confirmReloadPopinParams } = this.state

    return (
      <Popin onClose={this.handleCloseConfirmReloadPopin} open={confirmReloadPopinParams !== null}>
        <AlertPopin
          label={t('actions.confirm_reload.label')}
          text={t('actions.confirm_reload.text')}
          labelCancelButton={t('actions.no')}
          labelConfirmButton={t('actions.yes')}
          onCancelButtonClick={this.handleCloseConfirmReloadPopin}
          onConfirmButtonClick={this.handleConfirmReloadAction}
        />
      </Popin>
    )
  }

  renderCentral(centralTestModule) {
    const { id, _id, done, locked, stepIndex, moduleIndex, lockedState } = centralTestModule

    const iconProgState = this.getLockDoneIcon(locked, done)

    const { isBeneficiary } = this.props

    const isConsultant = !isBeneficiary

    let children = []

    if (isConsultant) {
      children = [<CentralTestModuleConsultant centralTestModule={centralTestModule} />]
    }

    if (isBeneficiary) {
      children = [<CentralTestModuleBeneficiary module={centralTestModule} />]
    }

    const consultantActions = [
      <IconButton
        icon={Icon.icon.Trash2}
        color={Icon.color.Grey2}
        onClick={() => this.handleRemove({ stepIndex, moduleIndex })}
      />,
    ]

    consultantActions.push(
      moduleIndex > 0 ? (
        <IconButton
          id={id}
          icon={Icon.icon.Lock}
          color={lockedState ? IconButton.color.Accent : IconButton.color.Grey2}
          onClick={() => this.handleLockClick({ stepIndex, moduleIndex, locked: !lockedState })}
        />
      ) : null,
    )

    return (
      <ParcoursModuleContainer
        key={id}
        id={id || _id}
        isActivated={!locked}
        progState={iconProgState}
        moduleType="centraltest"
        label="Central Test"
        actions={isConsultant ? consultantActions : []}
        children={children.filter((a) => a)}
      />
    )
  }

  renderModule(module) {
    switch (module.type) {
      case moduleTypes.synthesis:
        return this.renderSynthesis(module)
      case moduleTypes.form:
        return this.renderForm(module)
      case moduleTypes.appointment:
        return this.renderAppointment(module)
      case moduleTypes.text:
        return this.renderText(module)
      case moduleTypes.centraltest:
        return this.renderCentral(module)
      default:
        return this.renderText(module)
    }
  }

  renderStepContent() {
    const { step, stepIndex, isBeneficiary, locked: stepLocked } = this.props
    let prevDone = true

    return step.modules.map((stepModule, i) => {
      const key = (stepModule.id || stepModule._id).toString()
      const locked = (stepModule.locked && !prevDone) || stepLocked

      prevDone = moduleDone(stepModule)

      return (
        <Draggable key={key} id={key} disabled={isBeneficiary} index={i} direction="vertical">
          {this.renderModule({
            ...stepModule,
            done: prevDone,
            stepIndex,
            moduleIndex: i,
            locked,
            lockedState: stepModule.locked,
          })}
        </Draggable>
      )
    })
  }

  renderRemovePopin() {
    return (
      <RemoveStepModulePopin
        open={this.state.popin.remove}
        stepIndex={this.state.popin.stepIndex}
        moduleIndex={this.state.popin.moduleIndex}
        onClose={this.toggleShowRemovePopin}
      />
    )
  }

  renderPostponePopin() {
    return (
      <AppointmentPostponePopin
        id={this.state.popin.moduleId}
        open={this.state.popin.postpone}
        onClose={this.toggleShowPostponePopin}
      />
    )
  }

  renderConfirmPopin() {
    return (
      <AppointmentConfirmPopin
        id={this.state.popin.moduleId}
        open={this.state.popin.confirm}
        onClose={this.toggleShowConfirmPopin}
      />
    )
  }

  renderBeneficiaryResourcePopin() {
    const { stepIndex, moduleIndex, resourceIndex } = this.state

    if (typeof moduleIndex !== 'number' && typeof resourceIndex !== 'number') {
      return null
    }

    const module = this.props.step.modules[moduleIndex]
    const resource = module.data.resources[resourceIndex]
    const { url_uploaded, type_uploaded = 'document' } = resource

    const hideShowResourcePopin = () => {
      this.setState(({ popins }) => ({
        stepIndex: null,
        moduleIndex: null,
        resourceIndex: null,
        popin: { ...popins, resource: false },
      }))
    }

    const setResource = (file) => {
      hideShowResourcePopin()
      this.props.setResourceUrl(resource.id, file.url, file.type, stepIndex, moduleIndex)
    }

    return (
      <EditResourcePopin
        open={this.state.popin.resource}
        canPublish={false}
        resource={{ url: url_uploaded, type: type_uploaded }}
        types={['document', 'link']}
        onSubmit={setResource}
        onClose={hideShowResourcePopin}
        asDocument
      />
    )
  }

  render() {
    const { stepIndex, isBeneficiary, module_id } = this.props

    return (
      <>
        <Scrollchor ref={this.scrollRef} to={module_id} />
        <Droppable
          disabled={isBeneficiary}
          id={`step-${stepIndex}`}
          type="module"
          props={{ stepIndex }}
          direction="vertical"
          className="modules"
        >
          {this.renderStepContent()}
        </Droppable>

        {this.renderPostponePopin()}
        {this.renderConfirmPopin()}
        {this.renderVideoPopin()}
        {this.renderConfirmCompletePopin()}
        {this.renderConfirmReloadPopin()}
        {this.renderRemovePopin()}
        {this.renderBeneficiaryResourcePopin()}
      </>
    )
  }
}
