import React, { Component } from 'react'
import { connect } from 'react-redux'

import Icon from '../../../components/atoms/Icon/Icon'
import { withI18n } from '../../global/Translator/Translator'
import Draggable from '../../dragdrop/Draggable/Draggable'
import ModuleResourceRow from '../../../components/molecules/ModuleResourceRow/ModuleResourceRow'
import Droppable from '../../dragdrop/Droppable/Droppable'
import { selectors as ResourceTemplateSelectors } from '../../../redux/ResourceTemplateRedux'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import EditResourcePopin from '../EditResourcePopin/EditResourcePopin'
import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapStateToProps = (state) => ({
  template: ModuleTemplateSelectors.template(state),
  resources: ResourceTemplateSelectors.templates(state),
})

const mapDispatchToProps = (dispatch) => ({
  setResources: (resources) => dispatch(ModuleTemplateActions.setResources(resources)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ModuleTextEdit extends Component {
  static resourceIcons = {
    document: Icon.icon.Document,
    to_return_document: Icon.icon.FileReturn,
    video: Icon.icon.Video,
    link: Icon.icon.File,
  }

  state = {
    resourceIndex: null,
    deleteIndex: null,
    showConfirmDelete: false,
    isPopinOpen: false,
  }

  // this is maybe not needed, why was it here ? no clue,
  // but it introduces a double click bug to confirm a modal,
  // and the edit popin doesn't target the correct resource
  //
  // static getDerivedStateFromProps(props) {
  //   const { resources = [] } = props.template.data
  //   const resourceIndex = resources.findIndex(({ url }) => !url)
  //
  //   return resourceIndex >= 0 ? { resourceIndex } : null
  // }

  handleRemoveAtIndex = (index) => {
    const { template } = this.props
    const resources = template.data.resources.slice(0)

    resources.splice(index, 1)
    this.props.setResources(resources)
    this.setState({ ...this.state, showConfirmDelete: false })
  }

  handleRemoveClick = (index) => {
    this.setState({
      ...this.state,
      deleteIndex: index,
      resourceIndex: null,
      showConfirmDelete: true,
    })
  }

  renderConfirmDelete = () => {
    const { t } = this.props

    return (
      <Popin onClose={this.removePopin} open={this.state.showConfirmDelete}>
        <AlertPopin
          label={t('module_resource.remove_confirm_title')}
          text={t('module_resource.remove_confirm_text')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={this.removePopin}
          onConfirmButtonClick={() => this.handleRemoveAtIndex(this.state.deleteIndex)}
        />
      </Popin>
    )
  }

  removePopin = () => {
    this.setState({
      ...this.state,
      deleteIndex: null,
      showConfirmDelete: false,
      isPopinOpen: false,
    })
  }

  handleEditAtIndex = (id) => {
    this.setState({ ...this.state, resourceIndex: id, isPopinOpen: true })
  }

  handleChange = ({ id, index, value }) => {
    const { template } = this.props
    const resources = template.data.resources.slice(0)
    const resource = { ...resources[index], [id]: value }

    resources.splice(index, 1, resource)
    this.props.setResources(resources)
  }

  handleActionClick = ({ id: index, action }) => {
    switch (action) {
      case 'remove':
        return this.handleRemoveClick(index)
      case 'edit':
        return this.handleEditAtIndex(index)
      default:
        throw new Error(`Unknown action.id: "${action}"`)
    }
  }

  updateResource = (resource) => {
    const { template } = this.props
    const { resourceIndex: index } = this.state
    const resources = [...template.data.resources]

    resources.splice(index, 1, resource)
    this.props.setResources(resources)
    this.setState({ ...this.state, resourceIndex: null, isPopinOpen: false })
  }

  closeResourceEditPopin = () => {
    this.setState({ ...this.state, resourceIndex: null, isPopinOpen: false })
  }

  renderResourceEditPopin() {
    const { template } = this.props
    const { resources } = template.data
    const { resourceIndex, isPopinOpen } = this.state
    const resource = resources[resourceIndex]

    if (!resource) {
      return null
    }

    return (
      <EditResourcePopin
        open={isPopinOpen}
        resource={resource}
        onSubmit={this.updateResource}
        onClose={this.closeResourceEditPopin}
      />
    )
  }

  render() {
    const { template } = this.props
    const { data } = template
    const { resources } = data

    const rows = resources.map(({ id, _id, title, type }, i) => {
      const key = (id || _id).toString()
      const actions = [
        { id: 'remove', icon: Icon.icon.Trash2 },
        { id: 'edit', icon: Icon.icon.Edit2 },
      ]

      return (
        <Draggable id={key} key={key} index={i}>
          <ModuleResourceRow
            label={title}
            id={i}
            index={i}
            editable
            actions={actions}
            onChange={this.handleChange}
            onActionClick={this.handleActionClick}
            icon={ModuleTextEdit.resourceIcons[type]}
          />
        </Draggable>
      )
    })

    return (
      <Droppable id="resources" className="module-resource-row-list" type="resource">
        {rows}
        {this.renderResourceEditPopin()}
        {this.renderConfirmDelete()}
      </Droppable>
    )
  }
}
