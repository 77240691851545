import { call, put } from 'redux-saga/effects'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { services } from '../services'
import { actions as AppActions } from '../redux/AppRedux'
import { actions as officeActions } from '../redux/OfficeRedux'
import { actions as centralTestActions } from '../redux/CentralTestRedux'
import { actions } from '../redux/CourseTemplateRedux'

const labels = {
  accountCreationSuccess:
    'Votre demande a été transmise à CentralTest, elle sera traitée dans les plus brefs des délais',
  accountCreationError:
    "Une erreur est survenue lors de l'envoi de la demande, veuillez contacter les services MyTalents",
  fetchTestsError:
    'Une erreur est survenue lors de la récupération des évaluations depuis Central Test',
  inviteBeneficiarySuccess: 'Une invitation a été envoyée à votre bénéficiaire avec succès',
  inviteBeneficiaryError:
    "L'invitation n'a pas été envoyée suite à un problème technique ou un solde insuffisant sur votre compte Central Test, veuillez contacter votre gestionnaire de compte",
  getAssessmentsError:
    'Une erreur est survenue lors de la récupération des évaluations Central Test',
}

export function* createAccount() {
  yield put(AppActions.setLoading(true))

  yield call(services.centralTest.createAccount, {}, function* ({ ok, data }) {
    ok
      ? yield put(
        NotificationActions.status({
          ok,
          data: {
            message: labels.accountCreationSuccess,
          },
        }),
      )
      : yield put(
        NotificationActions.status({ ok, data }),
      )
  })

  yield put(officeActions.getCurrentUserOffice())

  yield put(AppActions.setLoading(false))
}

export function* fetchTests() {
  yield call(services.centralTest.fetchTests, {}, function* ({ ok, data }) {
    ok
      ? yield put(centralTestActions.setTests(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* inviteBeneficiary({ payload }) {
  yield put(AppActions.setLoading(true))

  yield put(centralTestActions.setConfirmationModalStatus(false))

  yield call(
    services.centralTest.inviteBeneficiary,
    {
      user_id: payload.user_id,
      module_id: payload.module_id,
      template_id: payload.template_id,
    },
    function* ({ ok, data }) {
      if (ok) {
        yield put(
          NotificationActions.status({
            ok,
            data: {
              message: labels.inviteBeneficiarySuccess,
            },
          }),
        )

        yield put(actions.getTemplate(payload.template_id, payload.user_id))
      } else {
        yield put(NotificationActions.status({ ok, data }))
      }
    },
  )

  yield put(AppActions.setLoading(false))
}

export function* getAssessments({ payload }) {
  yield put(AppActions.setLoading(true))

  yield call(
    services.centralTest.getAssessments,
    {
      user_id: payload.user_id,
    },
    function* ({ ok, data }) {
      if (ok) {
        yield put(centralTestActions.setAssessments(data))
      } else {
        yield put(NotificationActions.status({ ok, data }))
      }
    },
  )

  yield put(AppActions.setLoading(false))
}

export function* toggleReport({ payload }) {
  yield put(AppActions.setLoading(true))

  yield call(
    services.centralTest.toggleReport,
    {
      module_id: payload.module_id,
    },
    function* ({ ok }) {
      yield put(
        NotificationActions.status({
          ok,
        }),
      )
    },
  )

  yield put(AppActions.setLoading(false))
}

export function* manualLink({ payload }) {
  yield put(AppActions.setLoading(true))

  yield call(
    services.centralTest.manualLink,
    {
      office_id: payload.office_id,
      company_id: payload.company_id,
    },
    function* ({ ok, data }) {
      yield put(
        NotificationActions.status({
          ok, data
        }),
      )
    },
  )

  yield put(AppActions.setLoading(false))
}
