import { request } from '../sagas/WSSaga'
import * as appointmentService from './AppointmentService'
import * as authService from './AuthService'
import * as beneficiaryGroupService from './BeneficiaryGroupService'
import * as beneficiaryService from './BeneficiaryService'
import * as consultantService from './ConsultantService'
import * as contactService from './ContactService'
import * as courseService from './CourseService'
import * as courseTemplateService from './CourseTemplateService'
import * as managerService from './ManagerService'
import * as messageService from './MessageService'
import * as moduleService from './ModuleService'
import * as moduleTemplateService from './ModuleTemplateService'
import * as notesService from './NotesService'
import * as officeService from './OfficeService'
import * as resourceService from './ResourceService'
import * as resourceTemplateService from './ResourceTemplateService'
import * as statsService from './StatsService'
import * as translationService from './TranslationService'
import * as uploadService from './UploadService'
import * as userService from './UserService'
import * as videoCallService from './VideocallService'
import * as centralTestService from './CentralTestService'
import * as portalService from './PortalService'

// Add all services here
const classes = {
  auth: authService,
  appointment: appointmentService,
  beneficiaryGroup: beneficiaryGroupService,
  beneficiary: beneficiaryService,
  consultant: consultantService,
  contact: contactService,
  course: courseService,
  courseTemplate: courseTemplateService,
  manager: managerService,
  message: messageService,
  module: moduleService,
  moduleTemplate: moduleTemplateService,
  notes: notesService,
  office: officeService,
  resourceTemplate: resourceTemplateService,
  resource: resourceService,
  stats: statsService,
  translation: translationService,
  upload: uploadService,
  user: userService,
  videocall: videoCallService,
  centralTest: centralTestService,
  portal: portalService,
}

export const services = Object.keys(classes).reduce((accServices, serviceKey) => {
  const service = classes[serviceKey]
  const methods = Object.keys(service).reduce((accMethods, actionKey) => {
    const method = service[actionKey]
    const id = `${serviceKey}.${actionKey}`
    const call = (...args) => request(method, id, ...args)
    
    return { ...accMethods, [actionKey]: call }
  }, {})

  return { ...accServices, [serviceKey]: methods }
}, {})
