import React from 'react'
import PropTypes from 'prop-types'
import CheckboxUnckeckedIcon from './icons/checkbox-unchecked.svg'
import CheckboxCkeckedIcon from './icons/checkbox-checked.svg'

const IconCheckBox = ({ id, value, onClick }) => {
  const handleClick = () => {
    onClick({ id })
  }

  return value ? (
    <CheckboxCkeckedIcon className="icon-checkbox" onClick={handleClick} />
  ) : (
    <CheckboxUnckeckedIcon className="icon-checkbox" onClick={handleClick} />
  )
}

IconCheckBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func,
}

export default IconCheckBox
