import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isValid } from 'redux-form'

import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { actions as UserActions } from '../../../redux/UserRedux'

import { withI18n } from '../../global/Translator/Translator'
import Popin from '../../global/Popin/Popin'
import EditCustomerPopin from '../../../components/organisms/EditCustomerPopin/EditCustomerPopin'

const mapStateToProps = (state) => ({
  valid: isValid('office')(state),
  id: NavigationSelectors.lastParam(state),
  office: OfficeSelectors.office(state),
  saved: WSSelectors.resolved(state, 'office.save'),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: (id) => dispatch(OfficeActions.getOffice(id)),
  setOffice: (office) => dispatch(OfficeActions.setOffice(office)),
  saveOffice: (office, dontSet) => dispatch(OfficeActions.saveOffice(office, dontSet)),
  sendInvitation: (id) => dispatch(UserActions.sendInvitation(id)),
  resetSaveAction: () => dispatch(WSActions.reset('office.save')),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminOfficeEdit extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func,
  }

  state = {
    emailValue: '',
  }

  handleClose = () => {
    this.props.setOffice(null)
    this.props.onClose()
  }

  handleSubmit = (office) => {
    this.props.saveOffice(office, true)
  }

  handleSendInvitation = () => {
    const {
      office: { manager },
    } = this.props

    this.props.sendInvitation(manager.id)
  }

  componentDidMount() {
    const { id, getOffice } = this.props

    if (/\d+/.test(id)) {
      getOffice(id)
    }
  }

  componentDidUpdate() {
    if (this.props.saved) {
      this.props.resetSaveAction()
      this.handleClose()
    }
  }

  render() {
    const { t, id, valid } = this.props
    const office = this.props.office || {
      name: '',
      phone: '',
      email: '',
      region: '',
      no_consultants: 5,
      no_consultants_allocated: 0,
    }

    if (!office.id && /\d+/.test(id)) {
      return null
    }

    return (
      <Popin onClose={this.handleClose} open>
        <EditCustomerPopin
          key={office.id}
          onSubmit={this.handleSubmit}
          onClose={this.handleClose}
          onResendInvitation={this.handleSendInvitation}
          office={office}
          valid={valid}
          locale={{
            title: t(office ? 'customer.edit_customer' : 'customer.new_customer'),
            cta: t('actions.validate'),
            notActivatedYet: t('office.not_activated_yet'),
            presentationTitle: t('office.presentation'),
            subscriptionTitle: t('office.subscription'),
            validateResendInvitation: t('user.validate_resend_invitation'),
            resendInvitation: t('user.resend_invitation'),
            nameTitle: t('office.name'),
            namePlaceholder: t('office.name'),
            emailTitle: t('form.email'),
            emailPlaceholder: t('form.email'),
            phoneTitle: t('form.phone'),
            phonePlaceholder: t('form.phone'),
            regionTitle: t('form.region'),
            regionPlaceholder: t('form.region'),
            regions: Object.values(t('office.regions')),
            noConsultantsAvailable: t('office.no_consultants_label'),
            noConsultantsAllocated: t('office.no_consultants_allocated'),
          }}
        />
      </Popin>
    )
  }
}
