import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as AppSelectors } from '../../../redux/AppRedux'
import Popin from '../Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'
import { useI18n } from '../../../hooks/useI18n'

const LeavePopin = ({ cancel, confirm }) => {
  const displayLeavePopin = useSelector(AppSelectors.displayLeavePopin)
  const { t } = useI18n()

  return (
    <Popin onClose={cancel} open={displayLeavePopin}>
      <AlertPopin
        label={t('save_changes.label')}
        text={t('save_changes.text')}
        labelCancelButton={t('actions.no')}
        labelConfirmButton={t('actions.yes')}
        onCancelButtonClick={cancel}
        onConfirmButtonClick={confirm}
      />
    </Popin>
  )
}

LeavePopin.propTypes = {
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
}

export default LeavePopin
