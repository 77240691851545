import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Popin from '../../global/Popin/Popin'
import { withI18n } from '../../global/Translator/Translator'
import DropFilePopin from '../../../components/organisms/DropFilePopin/DropFilePopin'
import FileUpload from '../../global/FileUpload/FileUpload'
import { actions as NavigationActions } from '../../../redux/NavigationRedux'

const mapDispatchToProps = (dispatch) => ({
  openUrl: (url) => dispatch(NavigationActions.open(url)),
})

@withI18n
@connect(null, mapDispatchToProps)
export default class EditResourcePopin extends Component {
  static propTypes = {
    t: PropTypes.func,
    types: PropTypes.arrayOf(PropTypes.string),
    resource: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      type: PropTypes.string,
      template_published: PropTypes.bool,
    }),
    open: PropTypes.bool,
    asDocument: PropTypes.bool,
    canPublish: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    openUrl: PropTypes.func,
  }

  static defaultProps = {
    open: false,
    asDocument: false,
    canPublish: false,
    types: ['document', 'to_return_document', 'video', 'link'],
    resource: { type: 'document', title: '' },
  }

  static getDerivedStateFromProps({ resource, open }, state) {
    return open && state.resource ? null : { resource }
  }

  state = {
    resource: null,
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.resource)
  }

  handlePreview = ({ value }) => {
    this.props.openUrl(value)
  }

  handleChange = ({ id, value }) => {
    this.setState(({ resource }) => ({
      resource: { ...resource, [id]: value },
    }))
  }

  handleFileChange = ({ file }) => {
    const { title } = this.props.resource
    const { url, name } = file || {}

    this.setState(({ resource }) => ({
      resource: { ...resource, url, title: title || name },
    }))
  }

  handleTogglePublish = () => {
    this.setState(
      ({ resource }) => ({
        resource: { ...resource, template_published: !resource.template_published },
      }),
      this.handleSubmit,
    )
  }

  renderContent() {
    const { onClose, t, canPublish, asDocument, types } = this.props
    const { resource } = this.state
    const resourceTypes = t('resource_types')
    const options = Object.keys(resourceTypes)
      .map((value) => ({ value, label: resourceTypes[value] }))
      .filter(({ value }) => types.includes(value))

    const labelButton = resource.id
      ? t('actions.edit')
      : asDocument
      ? t('actions.validate')
      : t('actions.add')

    const title = resource.id
      ? t('resource.edit_resource')
      : asDocument
      ? t('resource.your_document')
      : t('resource.add_resource')

    const resourceTitle = asDocument ? t('resource.your_document') : resource.title
    const locale = {
      dropZoneLabel: t('resource.drop_file'),
      uploadButtonLabel: t('resource.choose_file'),
      or: t('words.or'),
      titleLabel: t('resource.title_label'),
      titlePlaceholder: t('resource.title_placeholder'),
      linkLabel: t('resource.link_label'),
      linkPlaceholder: t('resource.link_placeholder'),
      selectLabel: t('resource.type'),
      videoLabel: t('resource.video_label'),
      videoPlaceholder: t('resource.video_placeholder'),
      publish: t(resource.template_published ? 'actions.unpublish' : 'actions.publish'),
      title,
      cta: labelButton,
    }

    return (
      <FileUpload id="url" onChange={this.handleFileChange}>
        {({ onClick, onFileChange, uploading }) => (
          <DropFilePopin
            options={options}
            type={resource.type}
            url={resource.url}
            title={resourceTitle}
            published={resource.template_published}
            onClose={onClose}
            onClick={onClick}
            disabled={uploading}
            noTitle={asDocument}
            onFileChange={onFileChange}
            onTogglePublish={canPublish ? this.handleTogglePublish : null}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPreview={this.handlePreview}
            locale={locale}
          />
        )}
      </FileUpload>
    )
  }

  render() {
    const { onClose, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <div className="resource-edit-popin">{open && this.renderContent()}</div>
      </Popin>
    )
  }
}
