// React & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import Icon from '../../atoms/Icon/Icon'
import ToggleButton from '../ToggleButton/ToggleButton'

// Proptypes
const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  isFinish: PropTypes.bool,
  onClick: PropTypes.func,
  onTogglePrivate: PropTypes.func,
  locale: PropTypes.shape({
    private: PropTypes.string.isRequired,
    public: PropTypes.string.isRequired,
  }).isRequired,
}

// defaultProps
const defaultProps = {
  url: '',
  isPrivate: true,
  isFinish: false,
  onTogglePrivate: () => {},
}

// Component
class ParcoursModuleContentDocumentConsultant extends Component {
  handleTogglePrivate = ({ id, value }) => {
    this.props.onTogglePrivate({ id, value })
  }

  render() {
    const { id, fileName, isPrivate, isFinish, url, locale } = this.props

    return (
      <div className="parcours-module-content-document-consultant">
        <IconResourceType
          resourceType={IconResourceType.resourceType.document}
          color={Icon.color.Accent}
        />
        <a href={url} target="_blank" className="file-name">
          {fileName}
        </a>
        {!isFinish && (
          <ToggleButton
            id={id}
            labelOn={locale.private}
            labelOff={locale.public}
            isOn={isPrivate}
            onChange={this.handleTogglePrivate}
          />
        )}
      </div>
    )
  }
}

ParcoursModuleContentDocumentConsultant.propTypes = propTypes
ParcoursModuleContentDocumentConsultant.defaultProps = defaultProps

export default ParcoursModuleContentDocumentConsultant
