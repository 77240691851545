import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'

export default class Popin extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    overrideModalStyles: PropTypes.objectOf(PropTypes.any),
    focusTrapped: PropTypes.bool,
  }

  static defaultProps = {
    open: false,
    onClose: () => undefined,
    overrideModalStyles: {},
    focusTrapped: true,
  }

  render() {
    const { open, onClose, children, overrideModalStyles, focusTrapped } = this.props
    const styles = {
      modal: { padding: 0, borderRadius: 3, ...overrideModalStyles },
    }

    return (
      <Modal
        open={open}
        onClose={onClose}
        showCloseIcon={false}
        styles={styles}
        center
        focusTrapped={focusTrapped}
      >
        {open && children}
      </Modal>
    )
  }
}
