import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModuleRow from '../../../components/molecules/ModuleRow/ModuleRow'
import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'
import { actions as ModuleTemplateActions } from '../../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import Popin from '../../global/Popin/Popin'
import { withI18n } from '../../global/Translator/Translator'

const moduleTypes = {
  synthesis: 'synthesis',
  form: 'form',
  appointment: 'appointment',
  text: 'text',
  exercise: 'exercise',
}

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeTemplate: (id) => dispatch(ModuleTemplateActions.removeTemplate(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  setModuleTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ModuleList extends Component {
  static propTypes = {
    t: PropTypes.func,
    modules: PropTypes.array.isRequired,
    onVideoClicked: PropTypes.func.isRequired,
  }

  state = {
    openRow: '',
    showConfirm: false,
    currentId: '',
  }

  handleChevron = ({ id }) => {
    this.setState(({ openRow }) => ({
      openRow: openRow === id ? '' : id,
    }))
  }

  handleEdit = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  handlePreview = (id) => {
    const { modules } = this.props
    const module = modules.find((module) => module.id === id)
    this.props.setModuleTemplate({ ...module })
    this.props.redirect(`${this.props.pathname}/forms/${id}/preview`)
  }

  removePopin = () => {
    this.setState({ showConfirm: false, currentId: '' })
  }

  handleConfirmRemove = () => {
    this.props.removeTemplate(this.state.currentId)
    this.removePopin()
  }

  isEditable({ template_owner }) {
    if (!template_owner) {
      return false
    }

    const { user } = this.props
    const { role, profile_id } = user

    switch (role) {
      case 'admin':
        return true
      case 'consultant':
        return template_owner.consultant_id === profile_id
      default:
        return false
    }
  }

  handleRemove = ({ id }) => {
    this.setState({ showConfirm: true, currentId: id })
  }

  renderConfirmClose = () => {
    const { t, removing } = this.props

    return (
      <Popin onClose={this.removePopin} open={this.state.showConfirm}>
        <AlertPopin
          disabled={removing}
          label={t('module.remove_title')}
          text={t('module.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={this.removePopin}
          onConfirmButtonClick={this.handleConfirmRemove}
        />
      </Popin>
    )
  }

  renderModules = (modules) => {
    return modules.map((module, i) => {
      const { id, type, title, data } = module
      const typeMap = {
        text: 'free',
        form: 'quiz',
      }

      const moduleType = typeMap[type] || type

      return (
        <ModuleRow
          key={i}
          id={id}
          open={this.state.openRow === id}
          moduleType={moduleType}
          editable={this.isEditable(module)}
          label={title}
          resourcesCount={data.resources && data.resources.length}
          questionsCount={data.questions && data.questions.length}
          options={data.resources}
          onEditClick={this.handleEdit}
          onRemoveClick={this.handleRemove}
          onChevronClick={this.handleChevron}
          onVideoClicked={this.props.onVideoClicked}
          onPreviewClick={({ id }) => this.handlePreview(id)}
        />
      )
    })
  }

  renderResourceCategory = ({ type, resources }) => {
    const { t } = this.props
    const order = resources.length === 0 ? 2 : null

    return (
      <div style={{ order }} key={type}>
        <SectionTitle label={t(`module_types.${type}`)} />
        {this.renderModules(resources)}
      </div>
    )
  }

  renderSections = (types) => {
    return Object.keys(types)
      .reverse()
      .map((type) => this.renderResourceCategory({ type, resources: types[type] }))
  }

  renderModuleCollections = () => {
    const form = []
    const text = []

    this.props.modules.map((mod) => {
      switch (mod.type) {
        case moduleTypes.form:
          return form.push(mod)
        case moduleTypes.text:
        default:
          return text.push(mod)
      }
    })

    return this.renderSections({ form, text })
  }

  render() {
    return (
      <>
        {this.renderConfirmClose()}
        <div className="module-list">{this.renderModuleCollections()}</div>
      </>
    )
  }
}
