import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import InfoField from '../InfoField/InfoField'

export default class ComboField extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ).isRequired,
    clearable: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    prefixNode: PropTypes.node,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => undefined,
    title: '',
    clearable: false,
    placeholder: null,
    disabled: false,
    required: false,
    prefixNode: null,
    readOnly: false,
    value: '',
  }

  handleChange = (e) => {
    this.props.onChange({
      value: e.target.value,
      id: this.props.name,
    })
  }

  renderOptions() {
    const { options, clearable } = this.props
    const rows = options.slice(0)

    if (clearable) {
      rows.unshift({ label: '', value: null })
    }

    return rows.map(({ label, value }) => (
      <option key={value} value={value} dangerouslySetInnerHTML={{ __html: label }} />
    ))
  }

  render() {
    const {
      title,
      value,
      placeholder,
      disabled,
      required,
      prefixNode,
      readOnly,
      options,
    } = this.props
    const { onBlur, onFocus } = this.props
    let selectValue = value

    if (placeholder !== null && selectValue === null) {
      selectValue = ''
    }

    const selectedOption = options.filter((opt) => opt.value === selectValue)

    return (
      <div className="combo-field">
        {!readOnly && title && title !== '' && (
          <div className="common-form-label">
            {title}
            {required && '*'}
          </div>
        )}

        {!readOnly && (
          <div className="select-container">
            {prefixNode !== null && <div className="prefix-node">{prefixNode}</div>}

            <select
              value={selectValue}
              onChange={this.handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              required={required}
              className={placeholder !== null && selectValue === '' ? 'with-placeholder' : ''}
              disabled={disabled}
            >
              {placeholder !== null && (
                <option key="" value="" disabled>
                  {placeholder}
                </option>
              )}

              {this.renderOptions()}
              <optgroup label="" />
            </select>
            <Icon icon={Icon.icon.Combo} color={Icon.color.Accent} />
          </div>
        )}

        {readOnly && selectedOption.length > 0 && (
          <InfoField boldLabel={selectedOption[0].label} lightLabel={title} />
        )}
      </div>
    )
  }
}
