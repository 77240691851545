import React, { Component } from 'react'
import PropTypes from 'prop-types'
import icon from './icons/minus-circle.png'

export default class LogoutButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick()
  }

  render() {
    const { label } = this.props

    return (
      <div className="logout-button" onClick={this.onClick}>
        <img className="icon" src={icon} alt="logout" />
        <div className="label">{label}</div>
      </div>
    )
  }
}
