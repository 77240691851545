import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'

import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { withI18n } from '../../global/Translator/Translator'

import SideResourceSelector from '../../../components/organisms/SideResourceSelector/SideResourceSelector'
import Icon from '../../../components/atoms/Icon/Icon'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  templates: ModuleTemplateSelectors.templates(state),
  office: OfficeSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(ModuleTemplateActions.getTemplates(filter)),
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AsideModuleSelector extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  static resourceIcons = {
    document: Icon.icon.Document,
    to_return_document: Icon.icon.FileReturn,
    video: Icon.icon.Video,
    link: Icon.icon.File,
  }

  static icons = {
    appointment: Icon.icon.Rdv,
    form: Icon.icon.Quiz,
    synthesis: Icon.icon.Report,
    text: Icon.icon.Free,
    centraltest: Icon.icon.CentralTest,
  }

  state = {
    search: '',
  }

  componentDidMount() {
    this.getTemplates()

    if (!this.props.office) {
      this.props.getOffice()
    }
  }

  handleScopeChange = ({ value }) => {
    this.setState({ scope: value }, this.getTemplates)
  }

  handleTypeChange = ({ value }) => {
    this.setState({ type: value }, this.getTemplates)
  }

  handleSearchChange = ({ value }) => {
    this.setState({ search: value }, this.getTemplates)
  }

  getTemplates = _debounce(() => {
    const { search: title, scope, type } = this.state

    this.props.getTemplates({ title, scope, type })
  }, 300)

  renderComboFields() {
    const { t, user } = this.props
    const { scope, type } = this.state
    const fields = [
      {
        value: type,
        name: 'type',
        options: [
          { value: '', label: t('module.all_types') },
          { value: 'text', label: t('module_types.text') },
          { value: 'form', label: t('module_types.form') },
        ],
        onChange: this.handleTypeChange,
      },
    ]

    if (user.role !== 'admin') {
      fields.unshift({
        value: scope,
        name: 'scope',
        options: [
          { value: '', label: t('module.scope_filter.all') },
          { value: 'consultant', label: t('module.scope_filter.consultant') },
          { value: 'office', label: t('module.scope_filter.office') },
          { value: 'mytalents', label: t('module.scope_filter.mytalents') },
        ],
        onChange: this.handleScopeChange,
      })
    }

    return fields
  }

  renderNewResources() {
    const { t, office } = this.props

    return [
      {
        id: 'step',
        label: t('step.new_step'),
        isGroup: true,
        items: [],
      },
      {
        id: 'module',
        label: t('module.new_module'),
        items: [
          {
            id: 'appointment',
            label: t('module_types.appointment'),
            icon: AsideModuleSelector.icons.appointment,
          },
          {
            id: 'form',
            label: t('module_types.form'),
            icon: AsideModuleSelector.icons.form,
          },
          {
            id: 'text',
            label: t('module_types.text'),
            icon: AsideModuleSelector.icons.text,
          },
          {
            id: 'synthesis',
            label: t('module_types.synthesis'),
            icon: AsideModuleSelector.icons.synthesis,
          },
          {
            id: 'centraltest',
            label: 'Central Test',
            icon: AsideModuleSelector.icons.centraltest,
            isDisabled: !office || office.ct_status !== 'connected',
          },
        ],
      },
    ]
  }

  renderExistingResources() {
    const { t, templates } = this.props

    return templates.map(({ id, title, type, data }) => {
      const { resources } = data
      let attachmentLabel = null
      let attachmentIcon = null

      if (type === 'text') {
        const resource = resources && resources.length && resources[0]

        if (resource) {
          attachmentIcon = AsideModuleSelector.resourceIcons[resource.type]
          attachmentLabel = resource.title
        }
      } else if (type === 'form') {
        attachmentLabel = t('module.form.count_questions', {
          count: data.questions.length,
        })
      }

      return {
        id: id.toString(),
        label: title,
        icon: AsideModuleSelector.icons[type],
        attachmentIcon,
        attachmentLabel,
      }
    })
  }

  renderSearchField() {
    const { search } = this.state

    return {
      id: 'search',
      isLoading: false,
      showLine: false,
      activeInput: false,
      reversed: true,
      value: search,
      onChange: this.handleSearchChange,
    }
  }

  render() {
    const { t } = this.props
    const locale = {
      links: [
        { id: 'new', label: t('resource.new') },
        { id: 'existing', label: t('resource.existing') },
      ],
    }

    return (
      <SideResourceSelector
        id="module"
        searchField={this.renderSearchField()}
        comboFields={this.renderComboFields()}
        resources={this.renderExistingResources()}
        items={this.renderNewResources()}
        locale={locale}
      />
    )
  }
}
