import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/fr'

import { withI18n } from '../../global/Translator/Translator'

import { selectors as LocaleSelectors } from '../../../redux/LocaleRedux'
import { selectors as ConsultantSelectors } from '../../../redux/ConsultantRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'

import {
  duringCreation,
  hasFollowUpStatus,
  hasInValidationStatus,
  hasWaitFinancerStatus,
  inProgress,
  isArchived,
  needToCompletePersonnalInfo,
  needToFillFinancerInfo,
  waitingFinancerInfoValidation,
  waitingPersonnalInfoValidation,
} from '../../../helpers/beneficiaryStatus'

import AccompCard from '../../../components/organisms/AccompCard/AccompCard'
import CoordCard from '../../../components/organisms/CoordCard/CoordCard'
import ExperienceCard from '../../../components/organisms/ExperienceCard/ExperienceCard'
import FinancialCard from '../../../components/organisms/FinancialCard/FinancialCard'
import PresentationCard from '../../../components/organisms/PresentationCard/PresentationCard'
import FileUpload from '../../global/FileUpload/FileUpload'
import AccompEditionCard from '../../../components/organisms/AccompEditionCard/AccompEditionCard'
import FinancialEditionCard from '../../../components/organisms/FinancialEditionCard/FinancialEditionCard'
import Card from '../../../components/molecules/Card/Card'
import Icon from '../../../components/atoms/Icon/Icon'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import BeneficiaryConfig from '../../../config/BeneficiaryConfig'
import ComboField from '../../../components/molecules/ComboField/ComboField'
import IconCardTitle from '../../../components/molecules/IconCardTitle/IconCardTitle'
import TextAreaField from '../../../components/molecules/TextAreaField/TextAreaField'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  locale: LocaleSelectors.locale(state),
  consultants: ConsultantSelectors.consultants(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryProfileContent extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    consultantEdition: PropTypes.bool,
    isCreation: PropTypes.bool,
    isManager: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool,
    currentUserRole: PropTypes.string,
  }

  static defaultProps = {
    onChange: null,
    consultantEdition: false,
    isCreation: false,
    isManager: false,
    groups: null,
    readOnly: false,
    currentUserRole: null,
  }

  updateValues = (values, { id, value }) => {
    let newValue = value

    if (
      id === 'birth_date' ||
      id === 'accompaniment_start_date' ||
      id === 'accompaniment_end_date'
    ) {
      const displayFormat = 'DD/MM/YYYY'

      if (value !== null && value.length === displayFormat.length) {
        const dateMoment = moment(value, displayFormat)
        newValue = dateMoment.format('YYYY-MM-DD')
      }
    }

    let newValues = { ...values, [id]: newValue }

    if (id === 'accompaniment_type') {
      newValues = {
        ...newValues,
        accompaniment_duration: 0,
      }
    }

    return newValues
  }

  handleChange = (idValue) => {
    let newValues = this.state.values

    if (Array.isArray(idValue)) {
      idValue.forEach(({ id, value }) => {
        newValues = this.updateValues(newValues, { id, value })
      })
    } else {
      newValues = this.updateValues(newValues, idValue)
    }

    this.setState({ values: newValues }, () => {
      if (this.props.onChange) {
        this.props.onChange({ id: 'beneficiary', value: newValues })
      }
    })
  }

  handleFileChange = ({ id, file }) => {
    this.handleChange({ id, value: file.url })
  }

  handleGoToSynthesis = () => {
    this.props.redirect(this.props.pathname.replace(/[^/]+$/, 'synthesis'))
  }

  getEducationOptions = () => {
    const { t } = this.props
    const beneficiaryFormations = t('beneficiary_formations')

    return Object.keys(beneficiaryFormations).map((key) => ({
      value: key,
      label: beneficiaryFormations[key],
    }))
  }

  getSituationOptions = () => {
    const { t } = this.props
    const beneficiaryProfessionalSituations = t('beneficiary_professional_situations')

    return Object.keys(beneficiaryProfessionalSituations).map((key) => ({
      value: key,
      label: beneficiaryProfessionalSituations[key],
    }))
  }

  getActivityOptions = () => {
    const { t } = this.props
    const beneficiaryCompanyActivitySectors = t('beneficiary_company_activity_sectors')

    return Object.keys(beneficiaryCompanyActivitySectors).map((key) => ({
      value: key,
      label: beneficiaryCompanyActivitySectors[key],
    }))
  }

  getSocioProfessionalCategoriesOptions = () => {
    const { t } = this.props
    const beneficiarySocioProfessionalCategories = t('beneficiary_socio_professional_categories')

    return Object.keys(beneficiarySocioProfessionalCategories).map((key) => ({
      value: key,
      label: beneficiarySocioProfessionalCategories[key],
    }))
  }

  getCompanySizesOptions = () => {
    const { t } = this.props
    const beneficiaryCompanySizes = t('beneficiary_company_sizes')

    return Object.keys(beneficiaryCompanySizes).map((key) => ({
      value: key,
      label: beneficiaryCompanySizes[key],
    }))
  }

  getNonCompanyActivitySectorsOptions = () => {
    const { t } = this.props
    const beneficiaryNonCompanyActivitySectors = t('beneficiary_non_company_activity_sectors')

    return Object.keys(beneficiaryNonCompanyActivitySectors).map((key) => ({
      value: key,
      label: beneficiaryNonCompanyActivitySectors[key],
    }))
  }

  getAccompanimentTypeOptions = () => {
    const { t } = this.props
    const beneficiaryAccompanimentTypes = t('beneficiary_accompaniment_types')

    return Object.keys(beneficiaryAccompanimentTypes).map((key) => ({
      value: key,
      label: beneficiaryAccompanimentTypes[key],
    }))
  }

  getAccompanimentDurationTypesOptions = () => {
    const { t } = this.props
    const beneficiaryAccompanimentDurationTypes = t('beneficiary_accompaniment_duration_types')

    return Object.keys(beneficiaryAccompanimentDurationTypes).map((key) => ({
      value: key,
      label: beneficiaryAccompanimentDurationTypes[key],
    }))
  }

  getAppraisalTypesOptions = () => {
    const { t } = this.props
    const beneficiaryAppraisalTypes = t('beneficiary_appraisal_types')

    return Object.keys(beneficiaryAppraisalTypes).map((key) => ({
      value: key,
      label: beneficiaryAppraisalTypes[key],
    }))
  }

  getAppraisalAvailabilitiesOptions = () => {
    const { t } = this.props
    const beneficiaryAppraisalAvailabilities = t('beneficiary_appraisal_availabilities')

    return Object.keys(beneficiaryAppraisalAvailabilities).map((key) => ({
      value: key,
      label: beneficiaryAppraisalAvailabilities[key],
    }))
  }

  getFinancerStatesOptions = () => {
    const { t } = this.props
    const beneficiaryFinancerStates = t('beneficiary_financer_states')

    return Object.keys(beneficiaryFinancerStates).map((key) => ({
      value: key,
      label: beneficiaryFinancerStates[key],
    }))
  }

  getFinancerTypesOptions = (withState) => {
    const { t } = this.props
    const { previous_financer_type } = withState ? this.state : {}
    const beneficiaryFinancerTypes = t('beneficiary_financer_types')
    const hiddenTypes = BeneficiaryConfig.hidden_financer_types

    return Object.keys(beneficiaryFinancerTypes)
      .filter((key) => key === previous_financer_type || !hiddenTypes.includes(key))
      .map((key) => ({
        value: key,
        label: beneficiaryFinancerTypes[key],
      }))
  }

  getFinancerOPACIFTypesOptions = () => {
    const { t } = this.props
    const beneficiaryFinancerOPACIFTypes = t('beneficiary_financer_opacif_types')

    return Object.keys(beneficiaryFinancerOPACIFTypes).map((key) => ({
      value: key,
      label: beneficiaryFinancerOPACIFTypes[key],
    }))
  }

  getFinancialTVAOptions = () => {
    const { t } = this.props

    return [
      {
        value: 20,
        label: '20%',
      },
      {
        value: 0,
        label: t('financer.no_tva'),
      },
    ]
  }

  renderDescription = () => {
    const { t, user, locale, consultantEdition, groups, readOnly } = this.props
    const { values } = this.state
    const { first_name, last_name, gender, image_url, source } = values
    const displayFormat = 'DD/MM/YYYY'

    moment.locale(locale, {
      parentLocale: locale,
      months: Object.values(t('calendar_labels.month_labels')),
    })

    let cardAttr = {
      title: consultantEdition ? t('beneficiary.his_description') : t('beneficiary.my_description'),
      photoUrl: image_url || user.image_url,
      gender: {
        id: 'gender',
        value: gender,
        inputList: Object.keys(t('beneficiary_genders')).map((genderKey) => ({
          id: genderKey,
          value: genderKey,
          label: t('beneficiary_genders')[genderKey],
        })),
      },
      lastName: { id: 'last_name', value: last_name },
      firstName: { id: 'first_name', value: first_name },
      locale: {
        name: `${t('form.name')} *`,
        namePlaceHolder: t('your_form.name'),
        firstName: `${t('form.first_name')} *`,
        firstNamePlaceHolder: t('your_form.first_name'),
        age: t('form.age'),
        birth: t('form.birthdate'),
        birthPlaceHolder: t('form.birthdate_placeholder'),
        dayShortLabels: Object.values(t('calendar_labels.day_short_labels')),
        monthLabels: Object.values(t('calendar_labels.month_labels')),
        locale,
        title: `${t('form.title')} *`,
        groupLabel: t('form.group'),
        sources: t('beneficiary.sources'),
      },
      onChange: this.handleChange,
      source: { id: 'source', value: source }
    }

    if (
      waitingPersonnalInfoValidation(user) ||
      inProgress(user) ||
      hasFollowUpStatus(user) ||
      isArchived(user)
    ) {
      let ageValue =
        this.state.values.birth_date !== null
          ? moment().diff(moment(this.state.values.birth_date, 'YYYY-MM-DD'), 'years').toString()
          : ''
      let birthdateValue =
        this.state.values.birth_date !== null
          ? moment(this.state.values.birth_date, 'YYYY-MM-DD').format(displayFormat)
          : ''

      if (this.state.values.birth_date === '' || this.state.values.birth_date == null) {
        ageValue = 0
      }
      const date = moment(this.state.values.birth_date, 'YYYY-MM-DD')

      if (
        this.state.values.birth_date !== null &&
        (this.state.values.birth_date.length !== displayFormat.length ||
          date.format() === 'Invalid Date')
      ) {
        ageValue = 0
        birthdateValue = this.state.values.birth_date
      }

      cardAttr = {
        ...cardAttr,
        age: ageValue,
        birth: {
          id: 'birth_date',
          value: birthdateValue,
        },
      }
    }

    if (
      consultantEdition &&
      (hasInValidationStatus(user) ||
        inProgress(user) ||
        hasFollowUpStatus(user) ||
        isArchived(user))
    ) {
      const groupsValues = [{ id: '', name: t('beneficiary_group.no_group') }, ...groups]

      const groupsOptions = groupsValues.map((group) => ({
        value: group.id,
        label: group.name,
      }))

      cardAttr = {
        ...cardAttr,
        group: {
          id: 'group_id',
          value: this.state.values.group_id ? Number.parseInt(this.state.values.group_id, 10) : '',
          options: groupsOptions,
        },
      }
    }

    return (
      <FileUpload accept="image/*" id="image_url" onChange={this.handleFileChange}>
        {({ onClick, uploading }) => (
          <PresentationCard
            {...cardAttr}
            onPictureClick={onClick}
            readOnly={readOnly}
            uploading={uploading}
          />
        )}
      </FileUpload>
    )
  }

  renderContactDetails = () => {
    const { t, user, consultantEdition, isCreation, readOnly } = this.props

    let cardAttr = {
      title: consultantEdition
        ? t('beneficiary.his_contact_details')
        : t('beneficiary.my_contact_details'),
      email: { id: 'email', value: this.state.values.email, readonly: !consultantEdition },
      locale: {
        address: t('form.address'),
        addressPlaceHolder: t('form.address_placeholder'),
        postCode: t('form.zip_code'),
        postCodePlaceHolder: t('form.zip_code_placeholder'),
        city: t('form.city'),
        cityPlaceHolder: t('form.city_placeholder'),
        phone: t('form.phone'),
        phonePlaceHolder: t('form.phone_placeholder'),
        mobile: `${t('form.mobile')}`,
        mobilePlaceHolder: t('form.mobile_placeholder'),
        email: `${t('form.email')} *`,
        emailPlaceHolder: t('form.email_placeholder'),
      },
      onChange: this.handleChange,
    }

    if (!isCreation) {
      cardAttr = {
        ...cardAttr,
        mobile: { id: 'phone_mobile', value: this.state.values.phone_mobile },
      }
    }

    if (
      waitingPersonnalInfoValidation(user) ||
      inProgress(user) ||
      hasFollowUpStatus(user) ||
      isArchived(user)
    ) {
      cardAttr = {
        ...cardAttr,
        address: { id: 'address', value: this.state.values.address },
        postCode: { id: 'zip_code', value: this.state.values.zip_code },
        city: { id: 'city', value: this.state.values.city },
        phone: { id: 'phone_home', value: this.state.values.phone_home },
      }
    }

    return <CoordCard {...cardAttr} readOnly={readOnly} />
  }

  renderExperience = () => {
    const { t, user, consultantEdition, readOnly } = this.props

    if (!duringCreation(user)) {
      let cardAttr = {
        title: consultantEdition ? t('beneficiary.his_experience') : t('beneficiary.my_experience'),
      }

      if (
        waitingPersonnalInfoValidation(user) ||
        inProgress(user) ||
        hasFollowUpStatus(user) ||
        isArchived(user)
      ) {
        const handicapStatusField = {
          options: [
            {
              id: 'no',
              label: t('beneficiary_handicap_statuses.no'),
              needUpload: false,
            },
            {
              id: 'pending',
              label: t('beneficiary_handicap_statuses.pending'),
              needUpload: true,
            },
            {
              id: 'yes',
              label: t('beneficiary_handicap_statuses.yes'),
              needUpload: true,
            },
          ],
          title: t('form.handicap_status_question'),
          needUploadLabel: t('form.need_upload'),
          uploadLabel: t('actions.upload'),
          value: this.state.values.cursus_handicap_status
            ? this.state.values.cursus_handicap_status
            : 'no',
          id: 'cursus_handicap_status',
          fileId: 'cursus_handicap_file',
          fileValue: this.state.values.cursus_handicap_file
            ? this.state.values.cursus_handicap_file
            : '',
        }

        cardAttr = {
          ...cardAttr,
          handicapStatusField,
        }

        const resumeField = {
          label: t('form.resume'),
          id: 'cursus_cv_file',
          value: this.state.values.cursus_cv_file ? this.state.values.cursus_cv_file : '',
        }

        cardAttr = {
          ...cardAttr,
          resumeField,
        }
      }

      if (
        waitingPersonnalInfoValidation(user) ||
        inProgress(user) ||
        hasFollowUpStatus(user) ||
        isArchived(user)
      ) {
        const educationOptions = this.getEducationOptions()

        const educationComboContent = {
          title: `${t('your_form.formation')} *`,
          id: 'cursus_formation',
          placeholder: `${t('actions.choose')} ${t('your_form.formation')}`,
          options: educationOptions,
          value: this.state.values.cursus_formation,
        }

        cardAttr = {
          ...cardAttr,
          educationComboContent,
        }
      }

      const situationOptions = this.getSituationOptions()

      const situationComboContent = {
        title: `${t('your_form.cursus_professional_situation')} *`,
        id: 'cursus_professional_situation',
        placeholder: `${t('actions.choose')} ${t('your_form.cursus_professional_situation')}`,
        options: situationOptions,
        value: this.state.values.cursus_professional_situation,
      }

      cardAttr = {
        ...cardAttr,
        situationComboContent,
      }

      if (situationComboContent.value === 'cdd' || situationComboContent.value === 'cdi') {
        if (
          waitingPersonnalInfoValidation(user) ||
          inProgress(user) ||
          hasFollowUpStatus(user) ||
          isArchived(user)
        ) {
          const socioProfressionalCategories = this.getSocioProfessionalCategoriesOptions()
          const socioProfessionalCategoryComboContent = {
            title: t('your_form.socio_professional_category'),
            id: 'cursus_socio_professional_category',
            placeholder: `${t('actions.choose')} ${t('your_form.socio_professional_category')}`,
            options: socioProfressionalCategories,
            value: this.state.values.cursus_socio_professional_category,
            required: false,
          }

          cardAttr = {
            ...cardAttr,
            socioProfessionalCategoryComboContent,
          }

          const functionInputContent = {
            inputTitle: `${t('your_form.function')} *`,
            placeholder: '',
            inputName: 'cursus_function',
            inputValue: this.state.values.cursus_function,
          }

          cardAttr = {
            ...cardAttr,
            functionInputContent,
          }
        }

        const activityOptions = this.getActivityOptions()
        const activityComboContent = {
          title: t('your_form.cursus_company_activity_sector'),
          id: 'cursus_company_activity_sector',
          placeholder: `${t('actions.choose')} ${t('your_form.cursus_company_activity_sector')}`,
          options: activityOptions,
          value: this.state.values.cursus_company_activity_sector
            ? this.state.values.cursus_company_activity_sector
            : '',
          required: false,
        }

        cardAttr = {
          ...cardAttr,
          activityComboContent,
        }

        if (
          waitingPersonnalInfoValidation(user) ||
          inProgress(user) ||
          hasFollowUpStatus(user) ||
          isArchived(user)
        ) {
          const companySizes = this.getCompanySizesOptions()
          const companySizeComboContent = {
            title: t('your_form.company_size'),
            id: 'cursus_company_size',
            placeholder: t('your_form.company_size'),
            options: companySizes,
            value: this.state.values.cursus_company_size,
            clearable: true,
          }

          cardAttr = {
            ...cardAttr,
            companySizeComboContent,
          }

          const nonCompanyActivitySectors = this.getNonCompanyActivitySectorsOptions()
          const nonCompanyActivitySectorComboContent = {
            title: t('your_form.non_company_activity_sector'),
            id: 'cursus_non_company_activity_sector',
            placeholder: t('your_form.non_company_activity_sector'),
            options: nonCompanyActivitySectors,
            value: this.state.values.cursus_non_company_activity_sector,
            clearable: true,
          }

          cardAttr = {
            ...cardAttr,
            nonCompanyActivitySectorComboContent,
          }
        }
      }

      return <ExperienceCard {...cardAttr} onChange={this.handleChange} readOnly={readOnly} />
    }

    return null
  }

  renderAccompaniment = () => {
    const { t, user, locale, consultantEdition, readOnly } = this.props

    if (!duringCreation(user)) {
      const displayFormat = 'DD/MM/YYYY'

      moment.locale(locale, {
        parentLocale: locale,
        months: Object.values(t('calendar_labels.month_labels')),
      })

      let cardAttr = {
        accompanimentType: user.accompaniment_type
          ? t('beneficiary_accompaniment_types')[user.accompaniment_type]
          : '',
        locale: {
          title: consultantEdition
            ? t('beneficiary.his_accompaniment')
            : t('beneficiary.my_accompaniment'),
          accompanimentTypeLabel: t('accompaniment.accompaniment_type'),
          durationLabel: t('accompaniment.duration'),
          startDateLabel: t('accompaniment.start_date'),
          endDateLabel: t('accompaniment.end_date'),
          bilanTypeLabel: t('accompaniment.appraisal_type'),
          bilanAvailabilityLabel: t('accompaniment.appraisal_availability'),
          dayShortLabels: Object.values(t('calendar_labels.day_short_labels')),
          monthLabels: Object.values(t('calendar_labels.month_labels')),
          accompanimentSurveyCountLabel: t('accompaniment.survey_count'),
          locale,
        },
      }

      if (user.accompaniment_type === 'appraisal') {
        cardAttr = {
          ...cardAttr,
          bilanType: user.accompaniment_appraisal_type
            ? t('beneficiary_appraisal_types')[user.accompaniment_appraisal_type]
            : '',
          bilanAvailability: user.accompaniment_appraisal_availability
            ? t('beneficiary_appraisal_availabilities')[user.accompaniment_appraisal_availability]
            : '',
        }
      }

      if (consultantEdition) {
        cardAttr = {
          ...cardAttr,
          accompanimentType: {
            id: 'accompaniment_type',
            value: this.state.values.accompaniment_type ? this.state.values.accompaniment_type : '',
            options: this.getAccompanimentTypeOptions(),
          },
        }

        if (
          this.state.values.accompaniment_type &&
          this.state.values.accompaniment_type === 'appraisal'
        ) {
          cardAttr = {
            ...cardAttr,
            bilanType: {
              id: 'accompaniment_appraisal_type',
              value: this.state.values.accompaniment_appraisal_type
                ? this.state.values.accompaniment_appraisal_type
                : '',
              options: this.getAppraisalTypesOptions(),
            },
            bilanAvailability: {
              id: 'accompaniment_appraisal_availability',
              value: this.state.values.accompaniment_appraisal_availability
                ? this.state.values.accompaniment_appraisal_availability
                : '',
              options: this.getAppraisalAvailabilitiesOptions(),
            },
          }
        } else {
          delete cardAttr.bilanType
          delete cardAttr.bilanAvailability
        }
      }

      if (inProgress(user) || hasFollowUpStatus(user) || isArchived(user)) {
        cardAttr = {
          ...cardAttr,
          duration: user.accompaniment_duration
            ? Number.parseInt(user.accompaniment_duration, 10)
            : 0,
          durationType: user.accompaniment_duration_type
            ? t('beneficiary_accompaniment_duration_types')[user.accompaniment_duration_type]
            : '',
          startDate: user.accompaniment_start_date
            ? moment(user.accompaniment_start_date).format(displayFormat)
            : '',
          endDate: user.accompaniment_end_date
            ? moment(user.accompaniment_end_date).format(displayFormat)
            : '',
          surveyCount: user.accompaniment_survey_count || 0,
        }

        if (consultantEdition) {
          let accompanimentStartDateValue =
            this.state.values.accompaniment_start_date !== null
              ? moment(this.state.values.accompaniment_start_date, 'YYYY-MM-DD').format(
                displayFormat,
              )
              : ''

          const startDate = moment(this.state.values.accompaniment_start_date, 'YYYY-MM-DD')

          if (
            this.state.values.accompaniment_start_date !== null &&
            (this.state.values.accompaniment_start_date.length !== displayFormat.length ||
              startDate.format() === 'Invalid Date')
          ) {
            accompanimentStartDateValue = this.state.values.accompaniment_start_date
          }

          let accompanimentEndDateValue =
            this.state.values.accompaniment_end_date !== null
              ? moment(this.state.values.accompaniment_end_date, 'YYYY-MM-DD').format(displayFormat)
              : ''

          const endDate = moment(this.state.values.accompaniment_end_date, 'YYYY-MM-DD')

          let accompanimentSurveyCountValue = this.state.values.accompaniment_survey_count || 0

          if (
            this.state.values.accompaniment_end_date !== null &&
            (this.state.values.accompaniment_end_date.length !== displayFormat.length ||
              endDate.format() === 'Invalid Date')
          ) {
            accompanimentEndDateValue = this.state.values.accompaniment_end_date
          }

          cardAttr = {
            ...cardAttr,
            duration: {
              id: 'accompaniment_duration',
              value: this.state.values.accompaniment_duration
                ? Number.parseInt(this.state.values.accompaniment_duration, 10)
                : 0,
            },
            durationType: {
              id: 'accompaniment_duration_type',
              value: this.state.values.accompaniment_duration_type
                ? this.state.values.accompaniment_duration_type
                : '',
              options: this.getAccompanimentDurationTypesOptions(),
            },
            startDate: {
              id: 'accompaniment_start_date',
              value: accompanimentStartDateValue,
            },
            endDate: {
              id: 'accompaniment_end_date',
              value: accompanimentEndDateValue,
            },
            accompanimentSurveyCount: {
              id: 'accompaniment_survey_count',
              value: accompanimentSurveyCountValue,
            }
          }
        }
      }

      if (consultantEdition) {
        return <AccompEditionCard {...cardAttr} onChange={this.handleChange} readOnly={readOnly} />
      }

      return <AccompCard {...cardAttr} />
    }

    return null
  }

  renderFinancer = () => {
    const { t, user, consultantEdition, readOnly } = this.props

    if (
      (consultantEdition && hasWaitFinancerStatus(user)) ||
      needToFillFinancerInfo(user) ||
      waitingFinancerInfoValidation(user) ||
      needToCompletePersonnalInfo(user) ||
      waitingPersonnalInfoValidation(user) ||
      inProgress(user) ||
      hasFollowUpStatus(user) ||
      isArchived(user)
    ) {
      if (consultantEdition) {
        const financialHTValue = this.state.values.financial_ht
          ? Number.parseFloat(this.state.values.financial_ht)
          : 0
        const financialTVAValue = isNaN(Number(this.state.values.financial_tva))
          ? 20
          : Number.parseInt(this.state.values.financial_tva, 10)
        const totalTVA = ((financialHTValue * financialTVAValue) / 100).toFixed(2)

        const totalTTC = (
          Number.parseFloat(financialHTValue) + Number.parseFloat(totalTVA)
        ).toFixed(2)

        const cardAttr = {
          financerState: {
            id: 'financer_state',
            value: this.state.values.financer_state ? this.state.values.financer_state : '',
            options: this.getFinancerStatesOptions(),
          },
          toFillStateOptionId: 'to_fill',
          pendingStateOptionId: 'pending',
          financerStateColor:
            this.state.values.financer_state === 'done'
              ? 'green1'
              : this.state.values.financer_state === 'pending'
                ? 'orange1'
                : 'red1',
          financerType: {
            id: 'financer_type',
            value: this.state.values.financer_type ? this.state.values.financer_type : '',
            options: this.getFinancerTypesOptions(true),
          },
          financerOpacifType: {
            id: 'financer_opacif_type',
            value: this.state.values.financer_opacif_type
              ? this.state.values.financer_opacif_type
              : '',
            options: this.getFinancerOPACIFTypesOptions(),
          },
          financerName: {
            id: 'financer_name',
            value: this.state.values.financer_name ? this.state.values.financer_name : '',
          },
          financerSupportRequestFile: {
            id: 'financer_support_request_file',
            value: this.state.values.financer_support_request_file
              ? this.state.values.financer_support_request_file
              : '',
          },
          financerSupportAgreementFile: user.financer_support_agreement_file
            ? user.financer_support_agreement_file
            : '',
          financialHT: {
            id: 'financial_ht',
            value: financialHTValue,
          },
          financialTVA: {
            id: 'financial_tva',
            value: financialTVAValue,
            options: this.getFinancialTVAOptions(),
          },
          totalTVA: `${totalTVA} €`,
          totalTTC: `${totalTTC} €`,
          locale: {
            title: t('beneficiary.his_financer'),
            financerStateLabel: t('financer.financer_state'),
            financerTypeLabel: t('financer.financer_type'),
            OpacifValue: 'opacif',
            financerOpacifTypeLabel: t('financer.financer_opacif_type'),
            financerNameLabel: t('financer.financer'),
            financerSupportRequestFileLabel: t('financer.initial_paperwork'),
            financerSupportAgreementFileLabel: t('financer.agreement'),
            financialHTLabel: t('financer.financial_ht'),
            financialTVALabel: t('financer.financial_tva'),
            totalTVALabel: t('financer.total_tva'),
            totalTTCLabel: t('financer.total_ttc'),
          },
        }

        return (
          <FinancialEditionCard {...cardAttr} onChange={this.handleChange} readOnly={readOnly} />
        )
      }

      const cardAttr = {
        financerState: user.financer_state
          ? t('beneficiary_financer_states')[user.financer_state]
          : '',
        financerStateColor:
          user.financer_state === 'done'
            ? 'green1'
            : user.financer_state === 'pending'
              ? 'orange1'
              : 'red1',
        financerType: user.financer_type ? t('beneficiary_financer_types')[user.financer_type] : '',
        financerOpacifType: user.financer_opacif_type
          ? t('beneficiary_financer_opacif_types')[user.financer_opacif_type]
          : '',
        financerSupportRequestFile: user.financer_support_request_file
          ? user.financer_support_request_file
          : '',
        financerSupportAgreementFile: {
          id: 'financer_support_agreement_file',
          value: this.state.values.financer_support_agreement_file
            ? this.state.values.financer_support_agreement_file
            : '',
        },
        locale: {
          title: t('beneficiary.my_financer'),
          financerStateLabel: t('financer.financer_state'),
          financerTypeLabel: t('financer.financer_type'),
          OpacifLabel: t('beneficiary_financer_types.opacif'),
          financerOpacifTypeLabel: t('financer.financer_opacif_type'),
          financerNameLabel: t('financer.financer'),
          financerSupportRequestFileLabel: t('financer.initial_paperwork'),
          financerSupportAgreementFileLabel: t('financer.agreement'),
        },
      }

      return <FinancialCard {...cardAttr} onChange={this.handleChange} />
    }

    return null
  }

  renderSynthesis = () => {
    const { user, t, consultantEdition, readOnly } = this.props

    if (
      consultantEdition &&
      !readOnly &&
      (inProgress(user) || hasFollowUpStatus(user) || isArchived(user))
    ) {
      const userAdj = user.gender === 'male' ? 'his' : 'her'

      return (
        <Card
          title={t(`synthesis.${userAdj}_synthesis`)}
          icon={Icon.icon.Book}
          className="synthesis-card"
        >
          <div>{t('synthesis.final_synthesis')}</div>
          <PrimaryButton
            label={t('actions.generate')}
            id="synthesis"
            onClick={this.handleGoToSynthesis}
          />
        </Card>
      )
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      user_id: null,
      values: {
        last_name: '',
        first_name: '',
        gender: '',
        birth_date: null,
        image_url: '',

        group_id: null,

        phone_mobile: '',
        email: '',
        address: '',
        zip_code: '',
        city: '',
        phone_home: '',

        cursus_formation: this.getEducationOptions()[0].value,
        cursus_professional_situation: this.getSituationOptions()[0].value,
        cursus_socio_professional_category: this.getSocioProfessionalCategoriesOptions()[0].value,
        cursus_function: '',
        cursus_company_activity_sector: this.getActivityOptions()[0].value,
        cursus_non_company_activity_sector: '',
        cursus_company_size: '',
        cursus_handicap_status: 'no',
        cursus_handicap_file: '',
        cursus_cv_file: '',

        accompaniment_type: this.getAccompanimentTypeOptions()[0].value,
        accompaniment_duration: null,
        accompaniment_duration_type: this.getAccompanimentDurationTypesOptions()[0].value,
        accompaniment_start_date: null,
        accompaniment_end_date: null,
        accompaniment_survey_count: null,
        accompaniment_appraisal_type: null,
        accompaniment_appraisal_availability: null,

        financer_state: this.getFinancerStatesOptions()[0].value,
        financer_type: this.getFinancerTypesOptions()[0].value,
        financer_opacif_type: this.getFinancerOPACIFTypesOptions()[0].value,
        financer_name: '',
        financer_support_request_file: '',
        financer_support_agreement_file: '',
        financial_ht: 0,
        financial_tva: 20,
        source: null,
      },
    }
  }

  static getDerivedStateFromProps({ user }, state) {
    if (user && state.user_id === null) {
      return {
        user_id: user.id,
        previous_financer_type: state.previous_financer_type || user.financer_type,
        values: {
          last_name: user.last_name ? user.last_name : '',
          first_name: user.first_name ? user.first_name : '',
          gender: user.gender ? user.gender : '',
          birth_date: user.birth_date ? user.birth_date : null,
          image_url: user.image_url ? user.image_url : '',

          group_id: user.group_id ? user.group_id : null,

          phone_mobile: user.phone_mobile ? user.phone_mobile : '',
          email: user.email ? user.email : '',
          address: user.address ? user.address : '',
          zip_code: user.zip_code ? user.zip_code : '',
          city: user.city ? user.city : '',
          phone_home: user.phone_home ? user.phone_home : '',
          comments: user.comments ? user.comments : '',

          cursus_formation: user.cursus_formation || state.values.cursus_formation,
          cursus_professional_situation:
            user.cursus_professional_situation || state.values.cursus_professional_situation,
          cursus_socio_professional_category:
            user.cursus_socio_professional_category ||
            state.values.cursus_socio_professional_category,
          cursus_function: user.cursus_function || '',
          cursus_company_activity_sector:
            user.cursus_company_activity_sector || state.values.cursus_company_activity_sector,
          cursus_non_company_activity_sector: user.cursus_non_company_activity_sector
            ? user.cursus_non_company_activity_sector
            : '',
          cursus_company_size: user.cursus_company_size ? user.cursus_company_size : '',
          cursus_handicap_status: user.cursus_handicap_status ? user.cursus_handicap_status : 'no',
          cursus_handicap_file: user.cursus_handicap_file ? user.cursus_handicap_file : '',
          cursus_cv_file: user.cursus_cv_file ? user.cursus_cv_file : '',

          accompaniment_type: user.accompaniment_type || state.values.accompaniment_type,
          accompaniment_duration: user.accompaniment_duration || null,
          accompaniment_duration_type:
            user.accompaniment_duration_type || state.values.accompaniment_duration_type,
          accompaniment_start_date: user.accompaniment_start_date || null,
          accompaniment_end_date: user.accompaniment_end_date || null,
          accompaniment_survey_count: user.accompaniment_survey_count || null,
          accompaniment_appraisal_type: user.accompaniment_appraisal_type || null,
          accompaniment_appraisal_availability: user.accompaniment_appraisal_availability || null,

          financer_state: user.financer_state || state.values.financer_state,
          financer_type: user.financer_type || state.values.financer_type,
          financer_opacif_type: user.financer_opacif_type || state.values.financer_opacif_type,
          financer_name: user.financer_name ? user.financer_name : '',
          financer_support_request_file: user.financer_support_request_file
            ? user.financer_support_request_file
            : '',
          financer_support_agreement_file: user.financer_support_agreement_file
            ? user.financer_support_agreement_file
            : '',
          financial_ht: user.financial_ht ? user.financial_ht : 0,
          financial_tva: isNaN(Number(user.financial_tva)) ? 20 : user.financial_tva,
          source: user.source,
        },
      }
    }

    return null
  }

  renderConsultantSelector() {
    const { consultants, user, t } = this.props

    const options = consultants.map(({ id, first_name, last_name }) => ({
      label: `${first_name} ${last_name}`,
      value: id.toString(),
    }))

    if (!options || options.length === 0) {
      return null
    }

    return (
      <div className="coord-card">
        <IconCardTitle icon={Icon.icon.People} label="" />
        <div className="form">
          <div className="fields">
            <ComboField
              required
              title={t('consultant.consultant')}
              name="consultant_id"
              options={options}
              onChange={this.handleChange}
              placeholder={t('beneficiary.choose_consultant')}
              value={user.consultant_id || null}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { user, t, isManager, currentUserRole } = this.props

    if (!user) {
      return null
    }

    const seeManagerComment =
      (!duringCreation(user) && currentUserRole === 'consultant') || currentUserRole === 'manager'

    return (
      <div className="beneficiary-profile-content">
        {seeManagerComment && (
          <div className="comments">
            <div className="coord-card">
              <div className="form">
                <div className="fields">
                  <TextAreaField
                    name="comments"
                    title={t('beneficiary.comments')}
                    value={this.state.values.comments}
                    disabled={currentUserRole !== 'manager'}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="beneficiary-profile-content-container">
          <div className="information">
            {this.renderDescription()}
            {this.renderContactDetails()}
            {this.renderExperience()}
          </div>
          <div className="accompaniment-details">
            {duringCreation(user) && isManager && this.renderConsultantSelector()}
            {this.renderAccompaniment()}
            {this.renderFinancer()}
            {this.renderSynthesis()}
          </div>
        </div>
        <div className="required-fields">{t('form.required_fields')}</div>
      </div>
    )
  }
}
