import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ParcoursCard from '../../../components/organisms/ParcoursCard/ParcoursCard'
import CourseTemplateRemove from '../CourseTemplateRemove/CourseTemplateRemove'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../../redux/CourseTemplateRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { useNavigation } from '../../../hooks/useNavigation'

const CourseTemplateList = ({ scope, canAdd, enablePreview, filters }) => {
  const dispatch = useDispatch()
  const { pathname, redirect } = useNavigation()
  const user = useSelector(UserSelectors.user)
  const templates = useSelector(CourseTemplateSelectors.templates)

  const clone = (id, add, filters) => {
    dispatch(CourseTemplateActions.duplicateTemplate(id, add, filters))
  }

  const handleEditClick = ({ id }) => {
    redirect(`${pathname}/${id}`)
  }

  const handleCloneClick = ({ id }) => {
    clone(id, canAdd, filters)
  }

  const handleRemoveClick = ({ id }) => {
    redirect(`${pathname}/${id}/remove`)
  }

  const handlePreviewClick = ({ id }) => {
    redirect(`${pathname}/${id}/preview`)
  }

  const handleClosePopin = () => {
    redirect(pathname.replace(/\/courses\/.+$/, '/courses'))
  }

  const handleTogglePublishClick = (template) => {
    template.template_published = !template.template_published

    dispatch(CourseTemplateActions.saveTemplate(template))
  }

  const removePopin = () => {
    if (!/\/\d+\/remove/.test(pathname)) {
      return null
    }

    return <CourseTemplateRemove onClose={handleClosePopin} />
  }

  const hasPermission = ({ template_owner }) => {
    const { role, profile_id } = user

    switch (role) {
      case 'admin':
        return true
      case 'consultant':
        return template_owner.consultant_id === profile_id
      default:
        return false
    }
  }

  const templateCards = () => {
    const cards = templates.map((template) => {
      const { id, title, cover_url, steps, template_published } = template
      const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

      const isAdmin = user.role === 'admin'

      if (!template_published && scope !== 'consultant' && !isAdmin) {
        return null
      }

      return (
        <ParcoursCard
          course={{
            id,
            coverUrl: cover_url,
            title,
            stepsCount: steps.length,
            modulesCount: moduleCount,
            published: template_published,
            isEditable: hasPermission(template),
            isRemovable: hasPermission(template),
            isCloneable: true,
            isPublishable: hasPermission(template),
            canPreview: enablePreview && user.role === 'consultant',
            canPublish: scope === 'office',
          }}
          key={id}
          onCloneClick={handleCloneClick}
          onEditClick={handleEditClick}
          onRemoveClick={handleRemoveClick}
          onPreviewClick={handlePreviewClick}
          onTogglePublishClick={() => handleTogglePublishClick(template)}
        />
      )
    })

    return <div className="course-templates">{cards}</div>
  }

  return (
    <div className="course-template-list">
      {templateCards()}
      {removePopin()}
    </div>
  )
}

CourseTemplateList.propTypes = {
  canAdd: PropTypes.bool,
  enablePreview: PropTypes.bool,
  filters: PropTypes.object,
}

CourseTemplateList.defaultProps = {
  canAdd: true,
  enablePreview: false,
  filters: {},
}

export default CourseTemplateList
