import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withI18n } from '../../containers/global/Translator/Translator'

import { actions as UserActions, selectors as UserSelectors } from '../../redux/UserRedux'

import LogoutButton from '../../components/atoms/LogoutButton/LogoutButton'

import Logo from '../../../public/static/logos/logo-my-talents.svg'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class DeviceOptimizationPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  render() {
    const { t, user } = this.props

    return (
      <div className="device-optimization-page">
        <Logo className="logo" />

        <div className="content">
          <div className="title">
            {t('device_optimization.hello', { name: `${user.first_name} ${user.last_name}` })}
          </div>
          <div className="message">{t('device_optimization.message')}</div>
          <LogoutButton label={t('actions.logout')} onClick={this.props.logout} />
        </div>
      </div>
    )
  }
}
