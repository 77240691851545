import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import moment from 'moment/moment'

//
// Initial state
//
const initialState = Map({
  admin: null,
  managerOfficeStats: null,
  managerOfficeStatsYear: moment().year(),
  consultantStats: null,
  consultants: null,
  consultantStatsCurrentConsultant: null,
})

//
// Actions
//
export const actions = {
  getAdminStats: createAction('getAdminStats'),
  setAdminStats: createAction('setAdminStats', (admin) => ({ admin })),

  getManagerOfficeStats: createAction('getManagerOfficeStats'),
  setManagerOfficeStats: createAction('setManagerOfficeStats', (managerOfficeStats) => ({
    managerOfficeStats,
  })),

  setManagerOfficeStatsYear: createAction(
    'setManagerOfficeStatsYear',
    (managerOfficeStatsYear) => ({ managerOfficeStatsYear }),
  ),

  initConsultantStats: createAction('initConsultantStats'),
  getConsultantStats: createAction('getConsultantStats'),
  setConsultantStats: createAction('setConsultantStats', (consultantStats) => ({
    consultantStats,
  })),
  setConsultants: createAction('setConsultants', (consultants) => ({ consultants })),
  setConsultantStatsCurrentConsultant: createAction(
    'setConsultantStatsCurrentConsultant',
    (consultantStatsCurrentConsultant) => ({ consultantStatsCurrentConsultant }),
  ),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setAdminStats]: (state, { admin }) => state.merge({ admin }),

    [actions.setManagerOfficeStats]: (state, { managerOfficeStats }) =>
      state.merge({ managerOfficeStats }),

    [actions.setManagerOfficeStatsYear]: (state, { managerOfficeStatsYear }) =>
      state.merge({ managerOfficeStatsYear }),

    [actions.setConsultantStats]: (state, { consultantStats }) => state.merge({ consultantStats }),
    [actions.setConsultants]: (state, { consultants }) => state.merge({ consultants }),
    [actions.setConsultantStatsCurrentConsultant]: (state, { consultantStatsCurrentConsultant }) =>
      state.merge({ consultantStatsCurrentConsultant }),
  },
  initialState,
)

//
// Selectors
//
const admin = (state) => state.stats.get('admin')
const managerOfficeStatsYear = (state) => state.stats.get('managerOfficeStatsYear')
const managerOfficeStats = (state) => state.stats.get('managerOfficeStats')
const consultantStats = (state) => state.stats.get('consultantStats')
const consultants = (state) => state.stats.get('consultants')
const consultantStatsCurrentConsultant = (state) =>
  state.stats.get('consultantStatsCurrentConsultant')

export const selectors = {
  admin,
  managerOfficeStats,
  managerOfficeStatsYear,
  consultantStats,
  consultants,
  consultantStatsCurrentConsultant,
}
