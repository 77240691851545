import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../atoms/IconButton/IconButton'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import Icon from '../../atoms/Icon/Icon'

// Proptypes
const propTypes = {
  lightTitle: PropTypes.string,
  boldTitle: PropTypes.string,
  children: PropTypes.node,
  components: PropTypes.arrayOf(PropTypes.node),
  editable: PropTypes.bool,
  iconButton: PropTypes.oneOf(Object.values(IconButton.icon)),
  labelButton: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon.icon)),
  color: PropTypes.oneOf(Object.values(Icon.color)),
  disabled: PropTypes.bool,
  onIconClick: PropTypes.func,
  onLabelButton: PropTypes.func,
  onTitleChange: PropTypes.func,
}

// Default props
const defaultProps = {
  lightTitle: '',
  boldTitle: '',
  disabled: false,
  editable: false,
  color: Icon.color.Accent,
  components: [],
  onIconClick: (e) => {},
  onLabelButton: (e) => {},
  onTitleChange: ({ id, value }) => undefined,
}

class ActionPopinHeader extends Component {
  static defaultProps = {
    children: null,
  }

  onClick = ({ id }) => {
    switch (id) {
      case 'icon':
        this.props.onIconClick()
        break
      case 'label':
        this.props.onLabelButton()
        break
      default:
        break
    }
  }

  handleTitleChange = (e) => {
    this.props.onTitleChange({
      id: this.props.id,
      value: e.target.value,
    })
  }

  renderTitle() {
    const { boldTitle, editable, disabled } = this.props

    if (editable) {
      if (disabled) {
        return (
          <input
            className="bold"
            value=""
            placeholder={boldTitle}
            autoComplete="off"
            onChange={this.handleTitleChange}
          />
        )
      }
      return (
        <input
          className="bold"
          value={boldTitle}
          autoComplete="off"
          onChange={this.handleTitleChange}
        />
      )
    }

    return <span className="bold">{boldTitle}</span>
  }

  render() {
    const {
      lightTitle,
      iconButton,
      labelButton,
      icon,
      color,
      disabled,
      children,
      components,
    } = this.props

    return (
      <div className="action-popin-header">
        <div className="label-part">
          {iconButton && (
            <IconButton icon={iconButton} color={color} id="icon" onClick={this.onClick} className="icon"/>
          )}

          {icon && <Icon icon={icon} className="icon"/>}

          <div className="label">
            {lightTitle && <span className="light">{lightTitle}</span>}

            {this.renderTitle()}
          </div>
        </div>

        <div className="actions-part">
          {components}

          {labelButton && (
            <PrimaryButton
              disabled={disabled}
              label={labelButton}
              id="label"
              onClick={this.onClick}
            />
          )}

          {children}
        </div>
      </div>
    )
  }
}

ActionPopinHeader.propTypes = propTypes
ActionPopinHeader.defaultProps = defaultProps

export default ActionPopinHeader
