import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import NotesArea from './NotesArea'

@FieldContainer({ type: 'text' })
export default class NotesAreaContainer extends Component {
  render() {
    return <NotesArea {...this.props} />
  }
}
