import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withI18n } from '../../global/Translator/Translator'

import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

@withI18n
export default class RemoveQuestionFromFormModulePopin extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handleConfirm = () => {
    this.props.onConfirm()
    this.props.onClose()
  }

  render() {
    const { onClose, t, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={t('question.remove_title')}
          text={t('question.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleConfirm}
        />
      </Popin>
    )
  }
}
