import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../containers/global/Translator/Translator'

import Logo from '../../../public/static/logos/logo-my-talents.svg'
import Icon from '../../components/atoms/Icon/Icon'

import { actions as NavigationActions } from '../../redux/NavigationRedux'

const mapDispatchToProps = (dispatch) => ({
  backLogin: () => dispatch(NavigationActions.push('/auth/login')),
})

@withI18n
@connect(null, mapDispatchToProps)
export default class DisableAccountPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    backLogin: PropTypes.func.isRequired,
  }

  handleBackLogin = () => {
    const { backLogin } = this.props

    backLogin()
  }

  render() {
    const { t } = this.props

    return (
      <div className="disable-account-page">
        <Logo className="logo" />

        <div className="content">
          <div className="title">{t('disable_account.hello')}</div>
          <div className="message">{t('disable_account.message')}</div>
          <div className="back-login-button" onClick={this.handleBackLogin}>
            <Icon icon={Icon.icon.Back} color={Icon.color.Grey1} />
            <div className="label">{t('disable_account.back_login')}</div>
          </div>
        </div>
      </div>
    )
  }
}
