import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import IconFormQuestionType from '../../../components/atoms/IconFormQuestionType/IconFormQuestionType'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import FormMultipleChoiceConfigurationRow from '../../../components/molecules/FormMultipleChoiceConfigurationRow/FormMultipleChoiceConfigurationRow'
import InputField from '../../../components/molecules/InputField/InputField'
import QuizCombo from '../../../components/molecules/QuizCombo/QuizCombo'
import QuizDate from '../../../components/molecules/QuizDate/QuizDate'
import QuizGroup from '../../../components/molecules/QuizGroup/QuizGroup'
import QuizOpinionScale from '../../../components/molecules/QuizOpinionScale/QuizOpinionScale'
import QuizQcm from '../../../components/molecules/QuizQcm/QuizQcm'
import QuizQcmRow from '../../../components/molecules/QuizQcmRow/QuizQcmRow'
import QuizStars from '../../../components/molecules/QuizStars/QuizStars'
import QuizTextArea from '../../../components/molecules/QuizTextArea/QuizTextArea'
import QuizYesNo from '../../../components/molecules/QuizYesNo/QuizYesNo'
import TextAreaField from '../../../components/molecules/TextAreaField/TextAreaField'
import ToggleButton from '../../../components/molecules/ToggleButton/ToggleButton'
import LeftAsideLayout from '../../../layouts/LeftAsideLayout/LeftAsideLayout'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import Draggable from '../../dragdrop/Draggable/Draggable'
import Droppable from '../../dragdrop/Droppable/Droppable'
import Popin from '../../global/Popin/Popin'
import { withI18n } from '../../global/Translator/Translator'

const mapStateToProps = (state) => ({
  template: ModuleTemplateSelectors.template(state),
  question: ModuleTemplateSelectors.question(state),
})

const mapDispatchToProps = (dispatch) => ({
  setQuestion: (question) => dispatch(ModuleTemplateActions.setQuestion(question)),
  setQuestions: (questions) => dispatch(ModuleTemplateActions.setQuestions(questions)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class EditQuestionFormModulePopin extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    questionKey: PropTypes.string,
  }

  static defaultProps = {
    open: false,
    questionKey: null,
  }

  static getDerivedStateFromProps({ template, questionKey: id }) {
    if (!id) {
      return {
        question: null,
        results: null,
      }
    }

    return null
  }

  handleSaveQuestion = () => {
    const { template, questionKey: id, onClose, question: questionFromProps } = this.props
    const { data } = template

    const saveQuestion = (questions, prefix = '') => {
      return questions.map((question, questionKey) => {
        let currentQuestion = question

        if (`${prefix}${questionKey}` === id) {
          currentQuestion = {
            ...questionFromProps,
          }
        } else if (currentQuestion.type === 'group') {
          currentQuestion = {
            ...currentQuestion,
            questions: saveQuestion(currentQuestion.questions, `${questionKey}_`),
          }
        }

        return currentQuestion
      })
    }

    const newQuestions = saveQuestion(data.questions)

    this.props.setQuestions(newQuestions)
    onClose()
  }

  handleTitleChange = ({ value }) => {
    const { question, setQuestion } = this.props

    const newQuestion = {
      ...question,
      title: value,
    }

    setQuestion(newQuestion)
  }

  handleConfigurationChange = (update) => {
    const { question, setQuestion } = this.props

    if (Array.isArray(update)) {
      const newOptions = update.reduce(
        (res, newOpt) => ({
          ...res,
          [newOpt.id]: newOpt.value,
        }),
        {},
      )

      const newQuestion = {
        ...question,
        options: {
          ...question.options,
          ...newOptions,
        },
      }

      setQuestion(newQuestion)
    } else {
      const { id, value } = update

      const newQuestion = {
        ...question,
        options: {
          ...question.options,
          [id]: value,
        },
      }

      setQuestion(newQuestion)
    }
  }

  handleAnswer = (answer) => {
    this.setState({
      results: answer.value,
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      question: null,
      results: null,
    }
  }

  render() {
    const { onClose, t, open, question, questionKey } = this.props

    if (!question) {
      return null
    }

    let questionTypeContainerNode = null
    let questionTypeSpecificNode = null
    let questionConfigurationNode = null
    let questionOverviewNode = null
    switch (question.type) {
      case 'text': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Text} />
            <div className="title">{t('question.types.text')}</div>
          </div>
        )

        const handleEnableMaxLength = ({ id, value }) => {
          if (value === false) {
            this.handleConfigurationChange([
              { id, value },
              { id: 'max_length', value: 0 },
            ])
          } else {
            this.handleConfigurationChange({ id, value })
          }
        }

        const handleMaxLengthConfiguration = ({ id, value }) => {
          if (value === '') {
            this.handleConfigurationChange({ id, value: 0 })
          } else {
            this.handleConfigurationChange({ id, value: parseInt(value, 10) })
          }
        }

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
            <div className="configuration-prop-container-with-details">
              <div className="configuration-prop-container">
                <div className="configuration-prop-description">
                  {t('question.text_max_length')}
                </div>
                <div className="configuration-prop-action">
                  <ToggleButton
                    id="max_length_enabled"
                    onChange={handleEnableMaxLength}
                    labelOff={t('actions.no')}
                    labelOn={t('actions.yes')}
                    isOn={
                      (question.options.max_length_enabled != null &&
                        question.options.max_length_enabled) ||
                      (question.options.max_length != null &&
                        question.options.max_length !== '' &&
                        parseInt(question.options.max_length, 10) > 0)
                    }
                  />
                </div>
              </div>
              {((question.options.max_length_enabled != null &&
                question.options.max_length_enabled) ||
                (question.options.max_length != null &&
                  question.options.max_length !== '' &&
                  parseInt(question.options.max_length, 10) > 0)) && (
                <div className="configuration-prop-details">
                  <InputField
                    name="max_length"
                    onChange={handleMaxLengthConfiguration}
                    title={t('question.text_max_length_number')}
                    value={
                      question.options.max_length != null && question.options.max_length !== ''
                        ? `${parseInt(question.options.max_length, 10)}`
                        : '0'
                    }
                    type="number"
                    min={0}
                  />
                </div>
              )}
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizTextArea
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            name={question.type}
            value={this.state.results ? this.state.results : ''}
            onChange={this.handleAnswer}
            placeholder={t('form.module_text_placeholder')}
            maxLength={
              question.options.max_length != null && question.options.max_length
                ? parseInt(question.options.max_length, 10)
                : 0
            }
          />
        )
        break
      }
      case 'select': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Select} />
            <div className="title">{t('question.types.select')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        const handleChoicesChange = ({ value }) => {
          const newChoices = value.split('\n')

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        const choicesValue = question.options.choices.join('\n')

        questionTypeSpecificNode = (
          <div className="question-specific">
            <TextAreaField
              name="question-choices"
              onChange={handleChoicesChange}
              value={choicesValue}
              title={t('question.select_choice')}
              placeholder={t('question.select_choice_placeholder')}
            />
          </div>
        )

        const comboFieldProps = {
          name: questionKey,
          options: question.options.choices.map((opt) => ({ value: opt, label: opt })),
          onChange: this.handleAnswer,
          value: this.state.results
            ? this.state.results
            : question.options.required != null && question.options.required
            ? question.options.choices[0]
            : '',
          placeholder: t('form.module_select_placeholder'),
          clearable: question.options.required == null || !question.options.required,
        }
        questionOverviewNode = (
          <QuizCombo
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            comboFieldProps={comboFieldProps}
          />
        )
        break
      }
      case 'multiple_choice': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.MultipleChoice} />
            <div className="title">{t('question.types.multiple_choice')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.allow_other')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="allow_other"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.allow_other != null && question.options.allow_other}
                />
              </div>
            </div>
          </div>
        )

        const handleChoiceChange = ({ id, value }) => {
          const newChoices = question.options.choices.map((choice, choiceKey) => {
            if (choiceKey.toString() === id) return value
            return choice
          })

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        const handleAdd = ({ id, value }) => {
          const newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            res.push(choice)
            if (choiceKey.toString() === id) {
              res.push('')
            }
            return res
          }, [])

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        const handleRemove = ({ id, value }) => {
          let newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            if (choiceKey.toString() === id) {
              return res
            }
            res.push(choice)
            return res
          }, [])

          if (newChoices.length === 0) {
            newChoices = ['']
          }

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        questionTypeSpecificNode = (
          <div className="question-specific question-specific-multiple-choice">
            <div className="question-specific-multiple-choice-title">
              {t('question.multiple_choice_list_title')}
            </div>
            <Droppable
              id={`${questionKey}_choices`}
              className="question-specific-multiple-choice-list"
              type="multiple-choice-list"
            >
              {question.options.choices.map((choice, choiceKey) => (
                <Draggable id={choiceKey.toString()} key={choiceKey} index={choiceKey}>
                  <FormMultipleChoiceConfigurationRow
                    key={choiceKey}
                    id={choiceKey.toString()}
                    alphaKey={choiceKey}
                    value={choice}
                    onChange={handleChoiceChange}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                  />
                </Draggable>
              ))}
            </Droppable>
          </div>
        )

        const choices = question.options.choices.map((choice) => ({
          label: choice,
          isSelected:
            this.state.results && this.state.results[choice] ? this.state.results[choice] : false,
        }))

        if (question.options.allow_other != null && question.options.allow_other) {
          choices.push({
            label: t('form.multiple_choice_other'),
            isSelected:
              this.state.results && this.state.results[t('form.multiple_choice_other')]
                ? this.state.results[t('form.multiple_choice_other')]
                : false,
          })
        }

        const handleMultipleChoiceChange = (value) => {
          const newState = choices.reduce(
            (res, choice) => ({
              ...res,
              [choice.label]: value.id === choice.label ? value.value : choice.isSelected,
            }),
            {},
          )

          this.handleAnswer({ id: questionKey, value: newState })
        }

        questionOverviewNode = (
          <QuizQcm
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            options={choices}
            onChange={handleMultipleChoiceChange}
          />
        )

        break
      }
      case 'group': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Group} />
            <div className="title">{t('question.types.group')}</div>
          </div>
        )

        questionOverviewNode = (
          <QuizGroup label={question.title}>
            <QuizQcmRow
              id="group_1"
              stepLabel="1"
              label={t('question.group_subquestion_label', { number: '1' })}
              stepOutline
              isSelected={false}
              onClick={() => null}
            />
            <QuizQcmRow
              id="group_2"
              stepLabel="2"
              label={t('question.group_subquestion_label', { number: '2' })}
              stepOutline
              isSelected={false}
              onClick={() => null}
            />
            <div className="other-questions">...</div>
          </QuizGroup>
        )
        break
      }
      case 'yes_no': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.YesNo} />
            <div className="title">{t('question.types.yes_no')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizYesNo
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            id={questionKey}
            value={this.state.results ? this.state.results : ''}
            yesLabel={t('actions.yes')}
            noLabel={t('actions.no')}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'rating': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Rating} />
            <div className="title">{t('question.types.rating')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizStars
            id={questionKey}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            starsCount={5}
            selectedStars={this.state.results ? this.state.results : 0}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'opinion_scale': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.OpinionScale} />
            <div className="title">{t('question.types.opinion_scale')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizOpinionScale
            id={questionKey}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            scale={question.options.scale ? question.options.scale : 10}
            value={this.state.results ? this.state.results : -1}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'date': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Date} />
            <div className="title">{t('question.types.date')}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{t('question.required')}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={t('actions.no')}
                  labelOn={t('actions.yes')}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizDate
            id={questionKey}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            monthLabels={Object.values(t('calendar_labels.month_labels'))}
            dayTitle={t('calendar_labels.day')}
            monthTitle={t('calendar_labels.month')}
            yearTitle={t('calendar_labels.year')}
            value={this.state.results ? this.state.results : ''}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      default: {
        throw new Error(`Incompatible question type: "${question.type}"`)
      }
    }

    if (!questionTypeContainerNode || !questionOverviewNode) {
      throw new Error(`Incompatible question type: "${question.type}"`)
    }

    const asideNode = (
      <div className="edit-question-aside">
        {questionTypeContainerNode}
        <div className="question-title">
          <TextAreaField
            name="question-title"
            onChange={this.handleTitleChange}
            value={question.title}
            maxLength={255}
            title={t('question.question')}
            focus={Boolean(!question.title)}
          />
        </div>
        {questionTypeSpecificNode}
        <div className="question-configuration">
          {questionConfigurationNode !== null && (
            <div className="question-configuration-title">
              {t('question.configuration')}
              {questionConfigurationNode}
            </div>
          )}
        </div>
        <div className="actions">
          <PrimaryButton label={t('actions.cancel')} id="cancel-button" onClick={onClose} outline />

          <PrimaryButton
            label={t('actions.save')}
            id="save-button"
            onClick={this.handleSaveQuestion}
          />
        </div>
      </div>
    )

    const contentNode = (
      <div className="question-overview">
        <header />
        <div className="overview">{questionOverviewNode}</div>
      </div>
    )

    return (
      <Popin onClose={onClose} open={open} overrideModalStyles={{ maxWidth: '90vw' }}>
        <div className="edit-question-form-module-popin">
          <LeftAsideLayout aside={asideNode} content={contentNode} />
        </div>
      </Popin>
    )
  }
}
