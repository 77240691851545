import PropTypes from 'prop-types'
import React, { Component } from 'react'
import FileUpload from '../../../containers/global/FileUpload/FileUpload'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'
import InfoField from '../InfoField/InfoField'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'

export default class FileUploadField extends Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    handleDeleteFile: PropTypes.func,
    handleFileChange: PropTypes.func,
  }

  static defaultProps = {
    id: null,
    value: null,
    label: '',
    readOnly: false,
    handleDeleteFile: () => {},
    handleFileChange: () => {},
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 45
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  render() {
    return (
      <div className="file-upload-field">
        <div className="file-name">
          <InfoField
            lightLabel={this.props.label}
            boldLabel={this.truncateString(this.props.value)}
          />
          {this.props.value !== '' && (
            <>
              {!this.propsreadOnly && (
                <div className="delete-icon">
                  <IconButton
                    id={this.props.id}
                    icon={Icon.icon.Close}
                    onClick={this.props.handleDeleteFile}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="actions">
          {this.props.value !== '' && (
            <div className="download-icon">
              <a href={this.props.value} target="_blank" rel="noreferrer noopener">
                <CircleButton icon={Icon.icon.Download} />
              </a>
            </div>
          )}
          <FileUpload id={this.props.id} onChange={this.props.handleFileChange} className="actions">
            {({ onClick, uploading }) => (
              <div className="upload-input">
                {!this.props.readOnly && !uploading && (
                  <div className="upload-icon">
                    <CircleButton icon={Icon.icon.Upload} onClick={onClick} />
                  </div>
                )}
                {uploading && <LoadingSpinner />}
              </div>
            )}
          </FileUpload>
        </div>
      </div>
    )
  }
}
