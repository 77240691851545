import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import AgendaRow from '../../components/molecules/AgendaRow/AgendaRow'
import InfoPage from '../../components/molecules/InfoPage/InfoPage'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as AppointmentActions,
  selectors as AppointmentSelectors,
} from '../../redux/AppointmentRedux'
import { actions as CourseTemplateActions } from '../../redux/CourseTemplateRedux'
import { selectors as LocaleSelectors } from '../../redux/LocaleRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import Icon from '../../components/atoms/Icon/Icon'
import AppointmentPostponePopin from '../../containers/course/AppointmentPostponePopin/AppointmentPostponePopin'
import AppointmentConfirmPopin from '../../containers/course/AppointmentConfirmPopin/AppointmentConfirmPopin'
import { actions as VideoCallActions } from '../../redux/VideocallRedux'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { useNavigation } from '../../hooks/useNavigation'
import { useI18n } from '../../hooks/useI18n'

const AgendaPage = () => {
  const dispatch = useDispatch()
  const appointments = useSelector(AppointmentSelectors.appointments)
  const locale = useSelector(LocaleSelectors.locale)
  const user = useSelector(UserSelectors.user)
  const isBeneficiary = useSelector(UserSelectors.isBeneficiary)
  const { redirect } = useNavigation()
  const { t } = useI18n()

  const [q, setQ] = useState()

  const [popin, setPopin] = useState({
    moduleIndex: -1,
    postpone: false,
    confirm: false,
  })

  const handleSearch = (q) => {
    setQ(q)
  }

  const toggleShowPostponePopin = (id) => {
    setPopin({
      ...popin,
      moduleId: id,
      postpone: !popin.postpone,
    })
  }

  const toggleShowConfirmPopin = (id) => {
    setPopin({
      ...popin,
      moduleId: id,
      confirm: !popin.confirm,
    })
  }

  const handleEditClick = ({ id, user_id, course_id, step_index }) => {
    if (isBeneficiary) {
      return redirect(`/beneficiary/courses/${course_id}?module_id=${id}&step_index=${step_index}`)
    }

    redirect(`/consultant/beneficiaries/${user_id}/courses/${course_id}/modules/appointment/${id}`)
  }

  const handleSyncAgendaClick = () => {
    redirect(`/consultant/profile`)
  }

  const handleExport = () => {
    dispatch(AppointmentActions.exportAppointments({ q }))
  }

  useEffect(() => {
    dispatch(AppointmentActions.getAppointments({ q }))
  }, [dispatch, q])

  useEffect(() => {
    dispatch(CourseTemplateActions.setTemplate(null))
    dispatch(AppointmentActions.getAppointments())
  }, [dispatch])

  const extractDate = (rdv_at) => {
    const rdvDate = moment(rdv_at).locale(locale)

    const day = rdvDate.weekday()
    const date = rdvDate.date()
    const month = rdvDate.month()

    return { day, date, month }
  }

  const renderCircleButton = () => {
    if (!isBeneficiary) {
      return <CircleButton icon="export" onClick={handleExport} />
    }
  }

  const renderProfileLink = () => {
    if (!isBeneficiary) {
      if (user.cronofy_account) {
        return null
      }

      return (
        <div className="sync-agenda-container">
          <p>{t('consultant.can_sync_agenda')}</p>
          <button className="primary-button" onClick={handleSyncAgendaClick} type="button">
            {t('user.cronofy_connect')}
            <Icon icon="calendar" color={Icon.color.White} />
          </button>
        </div>
      )
    }
  }

  const renderTabs = () => {
    const links = [{ id: 'inbox', label: t('calendar_labels.my_calendar') }]

    return (
      <LineMenu
        value={links[0].id}
        links={links}
        components={<TopSearch onSearch={handleSearch} />}
      />
    )
  }

  const renderLabels = () => {
    const profileType = isBeneficiary ? t('consultant.consultant') : t('beneficiary.beneficiary')

    return (
      <div className="calendar-labels">
        <p>{t('calendar_labels.hours')}</p>
        <p>{t('calendar_labels.title')}</p>
        <p>{profileType}</p>
        <p>{t('calendar_labels.subject')}</p>
      </div>
    )
  }

  const renderAppointments = () => {
    const locale = {
      dayLabels: Object.values(t('calendar_labels.day_full_labels')),
      monthLabels: Object.values(t('calendar_labels.month_labels')),
      proposeDate: t('actions.propose_date'),
      postpone: t('actions.postpone'),
      confirm: t('actions.confirm'),
      addToCalendar: t('actions.add_to_calendar'),
    }

    return appointments.map(
      (
        {
          id,
          title,
          course_id,
          status,
          course_title,
          step_count,
          step_index,
          step_title,
          user_from,
          user_to,
          primary_consultant,
          rdv_at,
          rdv_to,
          is_videocall,
          call,
        },
        i,
      ) => {
        const { day, month, date } = extractDate(rdv_at)
        let name = `${user_to.first_name} ${user_to.last_name}`
        let photoUrl = user_to.image_url
        let user_id = user_to.id
        const isPostponed = status === 'ko'
        const hoursAt = moment(rdv_at).format('LT')
        const hoursTo = moment(rdv_to).format('LT')
        const hours = t('calendar.hours', { at: hoursAt, to: hoursTo })

        const eventToCalendar = {
          title: t('calendar_labels.title_calendar'),
          description: t('calendar_labels.description_calendar', { name }),
          startTime: moment(rdv_at),
          endTime: moment(rdv_to),
        }

        if (isBeneficiary) {
          name = `${user_from.first_name} ${user_from.last_name}`
          photoUrl = user_from.image_url
          user_id = user_from.id
        }

        return (
          <AgendaRow
            key={i}
            id={id}
            title={title}
            status={isPostponed}
            textStatus={status}
            user_id={user_id}
            course_id={course_id}
            course_title={course_title}
            step_count={step_count}
            step_index={step_index}
            step_title={step_title}
            user_from={user_from}
            primaryConsultantPhotoUrl={primary_consultant ? primary_consultant.image_url : null}
            name={name}
            onEditClick={handleEditClick}
            onPostponeClick={() => toggleShowPostponePopin(id)}
            onConfirmClick={() => toggleShowConfirmPopin(id)}
            day={day}
            hours={hours}
            month={month}
            date={date}
            locale={locale}
            photoUrl={photoUrl}
            isConsultant={!isBeneficiary}
            displayAddToCalender={!isBeneficiary && !user.cronofy_account}
            event={eventToCalendar}
            isVideoCall={is_videocall}
            call={call}
          />
        )
      },
    )
  }

  const handleVideoCallInviteClick = () => {
    const participant = user.consultants[0].id

    dispatch(VideoCallActions.createInvite({ user_id: participant }))
  }

  const cta = isBeneficiary && (
    <div className="appointments-cta">
      <div className="appointment-cta__title">{t('calendar_labels.cta_title')}</div>
      <div className="appointment-cta__button">
        <PrimaryButton
          label={t('calendar_labels.cta_button')}
          onClick={handleVideoCallInviteClick}
        />
      </div>
    </div>
  )

  const renderContent = () => {
    if (!appointments.length) {
      return (
        <>
          <InfoPage text={t('calendar_labels.is_empty')} />
          {cta}
        </>
      )
    }

    return (
      <>
        <div className="header-actions">
          {renderCircleButton()}
          {renderProfileLink()}
        </div>
        {renderLabels()}
        {renderAppointments()}
        {cta}
      </>
    )
  }

  const renderPageAside = () => {
    return isBeneficiary ? <BeneficiaryPageAside /> : <ConsultantPageAside />
  }

  const renderPostponePopin = () => {
    return (
      <AppointmentPostponePopin
        id={popin.moduleId}
        open={popin.postpone}
        onClose={toggleShowPostponePopin}
      />
    )
  }

  const renderConfirmPopin = () => {
    return (
      <AppointmentConfirmPopin
        id={popin.moduleId}
        open={popin.confirm}
        onClose={toggleShowConfirmPopin}
      />
    )
  }
  const aside = renderPageAside()

  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} />

  return (
    <div className="page">
      <div className="calendar-page">
        <LeftAsideLayout aside={aside} content={content} />
        {renderPostponePopin()}
        {renderConfirmPopin()}
      </div>
    </div>
  )
}

export default AgendaPage
