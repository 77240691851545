import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withI18n } from '../../global/Translator/Translator'

import Popin from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

@withI18n
export default class BeneficiaryCloseNewMessage extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    this.props.onClose()
    this.props.remove()
  }

  render() {
    const { onClose, t, removing, open, remove } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          disabled={removing}
          label={t('message.remove_title')}
          text={t('message.remove_confirmation')}
          labelCancelButton={t('actions.cancel')}
          labelConfirmButton={t('actions.remove')}
          onCancelButtonClick={remove}
          onConfirmButtonClick={this.handleSubmit}
        />
      </Popin>
    )
  }
}
