import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'

import {
  actions as ResourceTemplateActions,
  selectors as ResourceTemplateSelectors,
} from '../../../redux/ResourceTemplateRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { withI18n } from '../../global/Translator/Translator'

import SideResourceSelector from '../../../components/organisms/SideResourceSelector/SideResourceSelector'
import Icon from '../../../components/atoms/Icon/Icon'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  resources: ResourceTemplateSelectors.templates(state),
})

const mapDispatchToProps = (dispatch) => ({
  getResources: (filter) => dispatch(ResourceTemplateActions.getTemplates(filter)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AsideResourceSelector extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  static icons = {
    document: Icon.icon.Document,
    to_return_document: Icon.icon.FileReturn,
    video: Icon.icon.Video,
    link: Icon.icon.File,
  }

  state = {
    search: '',
  }

  handleScopeChange = ({ value }) => {
    this.setState({ scope: value }, this.getResources)
  }

  handleTypeChange = ({ value }) => {
    this.setState({ type: value }, this.getResources)
  }

  handleSearchChange = ({ value }) => {
    this.setState({ search: value }, this.getResources)
  }

  getResources = _debounce(() => {
    const { search: title, scope, type } = this.state

    this.props.getResources({ scope, title, type })
  })

  componentDidMount() {
    this.getResources()
  }

  renderComboFields() {
    const { t, user } = this.props
    const { scope, type } = this.state
    const fields = [
      {
        value: type,
        name: 'type',
        options: [
          { value: '', label: t('module_resource.all_types') },
          { value: 'document', label: t('resource_types.document') },
          { value: 'to_return_document', label: t('resource_types.to_return_document') },
          { value: 'video', label: t('resource_types.video') },
          { value: 'link', label: t('resource_types.link') },
        ],
        onChange: this.handleTypeChange,
      },
    ]

    if (user.role !== 'admin') {
      fields.unshift({
        value: scope,
        name: 'scope',
        options: [
          { value: '', label: t('resource.scope_filter.all') },
          { value: 'consultant', label: t('resource.scope_filter.consultant') },
          { value: 'office', label: t('resource.scope_filter.office') },
          { value: 'mytalents', label: t('resource.scope_filter.mytalents') },
        ],
        onChange: this.handleScopeChange,
      })
    }

    return fields
  }

  renderNewResources() {
    const { t } = this.props

    return [
      {
        id: 'resource',
        items: [
          {
            id: 'document',
            label: t('resource_types.document'),
            icon: AsideResourceSelector.icons.document,
          },
          {
            id: 'to_return_document',
            label: t('resource_types.to_return_document'),
            icon: AsideResourceSelector.icons.to_return_document,
          },
          {
            id: 'video',
            label: t('resource_types.video'),
            icon: AsideResourceSelector.icons.video,
          },
          {
            id: 'link',
            label: t('resource_types.link'),
            icon: AsideResourceSelector.icons.link,
          },
        ],
      },
    ]
  }

  renderExistingResources() {
    const { resources } = this.props

    return resources.map(({ id, title, type }) => {
      return {
        id: id.toString(),
        label: title,
        icon: AsideResourceSelector.icons[type],
      }
    })
  }

  renderSearchField() {
    const { search } = this.state

    return {
      id: 'search',
      isLoading: false,
      showLine: false,
      activeInput: false,
      reversed: true,
      value: search,
      onChange: this.handleSearchChange,
    }
  }

  render() {
    const { t } = this.props
    const locale = {
      links: [
        { id: 'new', label: t('resource.new') },
        { id: 'existing', label: t('resource.existing') },
      ],
    }

    return (
      <SideResourceSelector
        id="resource"
        searchField={this.renderSearchField()}
        comboFields={this.renderComboFields()}
        resources={this.renderExistingResources()}
        items={this.renderNewResources()}
        locale={locale}
      />
    )
  }
}
