import { validateAll, validations } from 'indicative'
import { I18n } from 'react-redux-i18n'
import _forEach from 'lodash/forEach'

import { dateAfterFn, dateAfterNowFn, dateWithFormatFn } from './rules'

validations.dateWithFormat = dateWithFormatFn
validations.dateAfter = dateAfterFn
validations.dateAfterNow = dateAfterNowFn

export const asyncValidate = (values, options) => {
  const rules = {}
  const messages = {}

  _forEach(options, ({ rule, message }, key) => {
    if (rule) {
      rules[key] = rule
    }

    if (message) {
      messages[key] = message
    }
  })

  return validateAll(values, rules, messages)
    .then(() => undefined)
    .catch((errors) => {
      throw errors.reduce(
        (acc, { field, validation }) => ({
          ...acc,
          [field]: messages[field] || I18n.t(`form_errors.${validation}`),
        }),
        {},
      )
    })
}
