import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'

import ManagerStatistics from '../../containers/manager/ManagerStatistics/ManagerStatistics'

import ManagerGlobalStatistics from './fragments/ManagerGlobalStatistics'
import ManagerAppraisalTypesStatistics from './fragments/ManagerAppraisalTypesStatistics'
import ManagerOutOfAnnualStatistics from './fragments/ManagerOutOfAnnualStatistics'
import ManagerConsultantAppointmentTime from './fragments/ManagerConsultantAppointmentTime'

import { actions as StatsActions, selectors as StatsSelectors } from '../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
  consultantStats: StatsSelectors.consultantStats(state),
})

const mapDispatchToProps = (dispatch) => ({
  initConsultantStats: () => dispatch(StatsActions.initConsultantStats()),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerStatisticsOfficePage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
    consultantStats: PropTypes.arrayOf(PropTypes.object),

    initConsultantStats: PropTypes.func.isRequired,
  }

  static defaultProps = {
    managerOfficeStats: null,
    consultantStats: null,
  }

  componentDidMount() {
    const { consultantStats, initConsultantStats } = this.props

    if (consultantStats === null) {
      initConsultantStats()
    }
  }

  renderGlobalStatistics = () => {
    return <ManagerGlobalStatistics />
  }

  renderAppraisalTypesStatistics = () => {
    return <ManagerAppraisalTypesStatistics />
  }

  renderOutOfAnnualStatistics = () => {
    return <ManagerOutOfAnnualStatistics />
  }

  renderConsultantAppointmentTime = () => {
    return <ManagerConsultantAppointmentTime />
  }

  render() {
    const { managerOfficeStats, consultantStats } = this.props

    return (
      <ManagerStatistics>
        <div className="manager-statistics-office-page">
          {managerOfficeStats !== null && this.renderGlobalStatistics()}
          {managerOfficeStats !== null && this.renderAppraisalTypesStatistics()}
          {managerOfficeStats !== null && this.renderOutOfAnnualStatistics()}
          {consultantStats !== null && this.renderConsultantAppointmentTime()}
        </div>
      </ManagerStatistics>
    )
  }
}
