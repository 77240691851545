import { eventChannel } from 'redux-saga'
import { call, delay, put, take } from 'redux-saga/effects'
import { actions as UploadActions } from '../redux/UploadRedux'
import * as WSSagas from './WSSaga'

export function* upload({ payload }) {
  const { id, uploader } = payload
  const token = yield call(WSSagas.getToken)

  if (token) {
    uploader.methods.setCustomHeaders({
      Authorization: `Bearer ${token.token}`,
    })

    const channel = eventChannel((emit) => {
      uploader.on('complete', (...args) => {
        const { response } = args[3]
        const body = JSON.parse(response)

        emit(body)
      })

      return () => uploader.off('complete')
    })

    yield delay(500)
    uploader.methods.uploadStoredFiles()

    const data = yield take(channel)
    yield put(UploadActions.setFile(id, data))
  }
}
