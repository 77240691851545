import React from 'react'
import PropTypes from 'prop-types'
// Icon
import Icon from '../../atoms/Icon/Icon'

// propTypes
const propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

const IconCardTitle = ({ icon, label }) => (
  <div className="icon-card-title">
    <Icon icon={icon} color={Icon.color.Accent} />
    <p className="label">{label}</p>
  </div>
)

IconCardTitle.propTypes = propTypes

export default IconCardTitle
