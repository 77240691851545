import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'

//
// Initial state
//
const initialState = Map({
  contacts: {},
})

//
// Actions
//
export const actions = {
  setContacts: createAction('setContacts', (contacts) => ({ contacts: _mapKeys(contacts, 'id') })),
  getContacts: createAction('getContacts', (filter) => ({ filter })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setContacts]: (state, { contacts }) => state.merge({ contacts }),
  },
  initialState,
)

//
// Selectors
//
const contactsRefs = (state) => state.contact.get('contacts')
const contacts = createSelector(contactsRefs, Object.values)

export const selectors = {
  contacts,
}
