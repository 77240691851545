import React, { Component } from 'react'
import DropDown from 'react-dropdown'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'

export default class IconCombo extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        className: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  _onSelect = (value) => {
    this.props.onChange({
      value,
      id: this.props.id,
    })
  }

  render() {
    const { title, value, options, placeholderClassName } = this.props
    const selected = options.find((option) => value === option.value)
    const placeholder = selected && selected.label

    return (
      <div className="combo-icon">
        <div className="select-container">
          <div className="common-form-label">{title}</div>
          <DropDown
            options={options}
            onChange={this._onSelect}
            value={placeholder}
            placeholderClassName={placeholderClassName}
          />
          <Icon icon={Icon.icon.Combo} color={Icon.color.Accent} />
        </div>
      </div>
    )
  }
}
