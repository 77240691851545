import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../redux/UserRedux'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ children, component: Component, ...rest }) => {
  const user = useSelector(selectors.user)
  const isLoggedIn = !!user

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn ? (
          children || <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
