import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'

export default class ComboFilterField extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }

  handleChange = (e) => {
    this.props.onChange({
      value: e.target.value,
      id: this.props.id,
    })
  }

  renderOptions() {
    return this.props.options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))
  }

  render() {
    const { title, value } = this.props

    return (
      <div className="combo-filter-field">
        <div className="title">{title}</div>
        <div className="select-container">
          <select value={value} onChange={this.handleChange}>
            {this.renderOptions()}
          </select>
          <Icon icon={Icon.icon.ChevronDown} color={Icon.color.Accent} />
        </div>
      </div>
    )
  }
}
