import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'

//
// Initial state
//
const initialState = Map({
  beneficiary: null,
  cvs: null,
  maestros: null,
  beneficiaries: [],
  displayWaitingPage: true,
  loadingCvDesignrLink: false,
  cvDesignrExternalLink: null,
  loadingCvDesignrPdfLink: false,
  cvDesignrPdfExternalLink: null,
  loadingCvDesignrEditLink: false,
  cvDesignrEditExternalLink: null,
  loadingCvDesignrCommentLink: false,
  cvDesignrCommentExternalLink: null,
})

//
// Actions
//
export const actions = {
  setBeneficiaries: createAction('setBeneficiaries', (beneficiaries) => ({ beneficiaries })),
  setBeneficiary: createAction('setBeneficiary', (beneficiary) => ({ beneficiary })),
  setCvs: createAction('setCvs', (cvs) => ({ cvs })),
  setExportBlob: createAction('setExportBlob', (exportBlob) => ({ exportBlob })),
  setMaestros: createAction('setMaestros', (maestros) => ({ maestros })),
  getCvDesignrLink: createAction('getCvDesignrLink', (type) => ({ type })),
  getCvDesignrPdfLink: createAction('getCvDesignrPdfLink', (cvId) => ({ cvId })),
  getCvDesignrEditLink: createAction('getCvDesignrEditLink', (cvId) => ({ cvId })),
  getCvDesignrCommentLink: createAction('getCvDesignrCommentLink', (cvId) => ({ cvId })),
  getBeneficiaries: createAction('getBeneficiaries', (filter) => ({ filter })),
  exportBeneficiaries: createAction('exportBeneficiaries', (filter) => ({ filter })),
  getBeneficiary: createAction('getBeneficiary', (id) => ({ id })),
  attribute: createAction('attribute', (beneficiary_id, consultant_id) => ({
    beneficiary_id,
    consultant_id,
  })),
  share: createAction('share', (beneficiary_id, consultant_id, disableNotification = false) => ({
    beneficiary_id,
    consultant_id,
    disableNotification,
  })),
  unshare: createAction(
    'unshare',
    (beneficiary_id, consultant_id, disableNotification = false) => ({
      beneficiary_id,
      consultant_id,
      disableNotification,
    }),
  ),
  manageStatus: createAction('manageStatus'),
  disableWaitingPage: createAction('Disable waiting page'),
  saveCurrentBeneficiary: createAction('saveCurrentBeneficiary', (beneficiary) => ({
    ...beneficiary,
  })),
  saveBeneficiary: createAction('saveBeneficiary', (beneficiary) => ({ beneficiary })),
  removeBeneficiary: createAction('saveBeneficiary', (id) => ({ id })),
  getMyConsultant: createAction('getMyConsultant'),
  onBack: createAction('onBack', (disableSave) => ({ disableSave })),
  setLoadingCvDesignrLink: createAction('setLoadingCvDesignrLink', (loadingCvDesignrLink) => ({
    loadingCvDesignrLink,
  })),
  setCvDesignrExternalLink: createAction('setCvDesignrExternalLink', (cvDesignrExternalLink) => ({
    cvDesignrExternalLink,
  })),
  setCvDesignrPdfExternalLink: createAction(
    'setCvDesignrPdfExternalLink',
    (cvDesignrPdfExternalLink) => ({
      cvDesignrPdfExternalLink,
    }),
  ),
  setLoadingCvDesignrPdfLink: createAction(
    'setLoadingCvDesignrPdfLink',
    (loadingCvDesignrPdfLink) => ({
      loadingCvDesignrPdfLink,
    }),
  ),
  setCvDesignrEditExternalLink: createAction(
    'setCvDesignrEditExternalLink',
    (cvDesignrEditExternalLink) => ({
      cvDesignrEditExternalLink,
    }),
  ),
  setLoadingCvDesignrEditLink: createAction(
    'setLoadingCvDesignrEditLink',
    (loadingCvDesignrEditLink) => ({
      loadingCvDesignrEditLink,
    }),
  ),
  setCvDesignrCommentExternalLink: createAction(
    'setCvDesignrCommentExternalLink',
    (cvDesignrCommentExternalLink) => ({
      cvDesignrCommentExternalLink,
    }),
  ),
  setLoadingCvDesignrCommentLink: createAction(
    'setLoadingCvDesignrCommentLink',
    (loadingCvDesignrCommentLink) => ({
      loadingCvDesignrCommentLink,
    }),
  ),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.disableWaitingPage]: (state) => state.set('displayWaitingPage', false),
    [actions.setBeneficiaries]: (state, { beneficiaries }) => state.merge({ beneficiaries }),
    [actions.setBeneficiary]: (state, { beneficiary }) => state.set('beneficiary', beneficiary),
    [actions.setCvs]: (state, { cvs }) => state.set('cvs', cvs),
    [actions.setExportBlob]: (state, { exportBlob }) => {
      if (window && exportBlob) {
        const fileUrl = URL.createObjectURL(exportBlob)
        window.open(fileUrl)
      }

      return state
    },
    [actions.setLoadingCvDesignrLink]: (state, { loadingCvDesignrLink }) =>
      state.set('loadingCvDesignrLink', loadingCvDesignrLink),
    [actions.setCvDesignrExternalLink]: (state, { cvDesignrExternalLink }) =>
      state.set('cvDesignrExternalLink', cvDesignrExternalLink),
    [actions.setCvDesignrPdfExternalLink]: (state, { cvDesignrPdfExternalLink }) =>
      state.set('cvDesignrPdfExternalLink', cvDesignrPdfExternalLink),
    [actions.setLoadingCvDesignrPdfLink]: (state, { loadingCvDesignrPdfLink }) =>
      state.set('loadingCvDesignrPdfLink', loadingCvDesignrPdfLink),
    [actions.setCvDesignrCommentExternalLink]: (state, { cvDesignrCommentExternalLink }) =>
      state.set('cvDesignrCommentExternalLink', cvDesignrCommentExternalLink),
    [actions.setLoadingCvDesignrCommentLink]: (state, { loadingCvDesignrCommentLink }) =>
      state.set('loadingCvDesignrCommentLink', loadingCvDesignrCommentLink),
    [actions.setCvDesignrEditExternalLink]: (state, { cvDesignrEditExternalLink }) =>
      state.set('cvDesignrEditExternalLink', cvDesignrEditExternalLink),
    [actions.setLoadingCvDesignrEditLink]: (state, { loadingCvDesignrEditLink }) =>
      state.set('loadingCvDesignrEditLink', loadingCvDesignrEditLink),
    [actions.setCvs]: (state, { cvs }) => state.set('cvs', cvs),
    [actions.setMaestros]: (state, { maestros }) => state.set('maestros', maestros),
    [actions.removeBeneficiary]: (state, { id }) =>
      state.set(
        'beneficiaries',
        state.get('beneficiaries').filter((beneficiary) => beneficiary.id !== id),
      ),
  },
  initialState,
)

//
// Selectors
//
const beneficiaries = (state) => state.beneficiary.get('beneficiaries')
const beneficiary = (state) => state.beneficiary.get('beneficiary')
const cvs = (state) => state.beneficiary.get('cvs')
const maestros = (state) => state.beneficiary.get('maestros')
const displayWaitingPage = (state) => state.beneficiary.get('displayWaitingPage')
const cvDesignrExternalLink = (state) => state.beneficiary.get('cvDesignrExternalLink')
const loadingCvDesignrLink = (state) => state.beneficiary.get('loadingCvDesignrLink')
const cvDesignrPdfExternalLink = (state) => state.beneficiary.get('cvDesignrPdfExternalLink')
const loadingCvDesignrPdfLink = (state) => state.beneficiary.get('loadingCvDesignrPdfLink')
const cvDesignrEditExternalLink = (state) => state.beneficiary.get('cvDesignrEditExternalLink')
const loadingCvDesignrEditLink = (state) => state.beneficiary.get('loadingCvDesignrEditLink')
const cvDesignrCommentExternalLink = (state) =>
  state.beneficiary.get('cvDesignrCommentExternalLink')
const loadingCvDesignrCommentLink = (state) => state.beneficiary.get('loadingCvDesignrCommentLink')

export const selectors = {
  beneficiaries,
  beneficiary,
  cvs,
  maestros,
  displayWaitingPage,
  cvDesignrExternalLink,
  loadingCvDesignrLink,
  loadingCvDesignrPdfLink,
  cvDesignrPdfExternalLink,
  cvDesignrEditExternalLink,
  loadingCvDesignrEditLink,
  cvDesignrCommentExternalLink,
  loadingCvDesignrCommentLink,
}
