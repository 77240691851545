import { createAction } from 'redux-act'

//
// Actions
//
export const actions = {
  setUrl: createAction('setUrl', (id, url, type, stepIndex, moduleIndex) => ({
    id,
    url,
    type,
    stepIndex,
    moduleIndex,
  })),
  setPrivate: createAction('setPrivate', (id, isPrivate, stepIndex, moduleIndex) => ({
    id,
    isPrivate,
    stepIndex,
    moduleIndex,
  })),
}
