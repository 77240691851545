import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import ComboField from '../../components/molecules/ComboField/ComboField'
import Icon from '../../components/atoms/Icon/Icon'
import SyntheseStep from '../../components/molecules/SyntheseStep/SyntheseStep'
import InfoPage from '../../components/molecules/InfoPage/InfoPage'
import { useI18n } from '../../hooks/useI18n'
import { useNavigation } from '../../hooks/useNavigation'
import { useParams } from 'react-router'

const ConsultantBeneficiaryFinalSynthesisPage = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const { redirect, pathname } = useNavigation()
  const [stepIndex, setStepIndex] = useState(0)
  const [stepsModules, setStepsModules] = useState([])
  const course = useSelector(CourseTemplateSelectors.template)
  const courses = useSelector(CourseSelectors.courses)
  const hasChanged = useSelector(CourseTemplateSelectors.hasChanged)
  const beneficiary = useSelector(BeneficiarySelectors.beneficiary)
  const { user_id } = useParams()

  const handleBack = () => {
    redirect(pathname.replace(/[^/]+$/, 'profile'))
  }

  const handleExport = () => {
    dispatch(CourseActions.exportSynthesis(course.id))
  }

  const handleSave = () => {
    dispatch(CourseTemplateActions.saveTemplate(course))
  }

  const handleCourseChange = useCallback(
    ({ value }) => {
      const selectedCourse = courses.find(({ id }) => id.toString() === value.toString())

      setStepIndex(0)

      dispatch(CourseTemplateActions.setTemplate(selectedCourse))
    },
    [courses, dispatch],
  )

  const handleSynthesisChange = (stepIndex, moduleIndex, stepId, moduleId, key, value) => {
    const stepModule = stepsModules.find((s) => s.key.toString() === stepId.toString())

    const step = stepModule.step

    if (!step) {
      return
    }

    const module = step.modules.find((m) => m.id.toString() === moduleId.toString())

    if (!module) {
      return
    }

    const data = {
      ...module.data,
      [key]: value,
    }

    let title = module.title

    if (key === 'title') {
      title = value
    }

    dispatch(
      CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, {
        title,
        data,
      }),
    )
  }

  const getHeaderCourseOptions = () => {
    return courses.map(({ id, title }) => ({
      label: title,
      value: id.toString(),
    }))
  }

  useEffect(() => {
    dispatch(CourseTemplateActions.setTemplate(null))
    dispatch(BeneficiaryActions.getBeneficiary(user_id))
    dispatch(CourseActions.getCoursesForBeneficiary(user_id))

    return () => {
      dispatch(CourseTemplateActions.setTemplate(null))
    }
  }, [dispatch, user_id])

  useEffect(() => {
    if (!course && courses.length) {
      handleCourseChange({ value: courses[0].id })
    }
  }, [courses, course, handleCourseChange])

  useEffect(() => {
    setStepsModules([])

    if (course && course.steps) {
      course.steps.forEach((step) => {
        const modules = []

        step.modules.forEach((module, moduleIndex) => {
          if (module.type === 'synthesis') {
            modules.push(moduleIndex)
          }
        })

        if (modules.length > 0) {
          setStepsModules((previous) => [
            ...previous,
            {
              key: step.id,
              step,
              modules,
            },
          ])
        }
      })
    }
  }, [course])
  const renderContent = () => {
    if (!course) {
      return null
    }

    if (Object.keys(stepsModules).length === 0) {
      return <InfoPage text={t('synthesis.empty')} />
    }

    return course.steps.map((step, i) => {
      if (stepsModules.find((steps) => steps.key === step.id)) {
        const syntheseContents = stepsModules
          .find((steps) => steps.key === step.id)
          .modules.map((moduleIndex) => {
            const module = step.modules[moduleIndex]

            return {
              id: moduleIndex,
              module_id: module.id,
              title: module.title,
              consultant_notes: module.data.consultant_notes,
              consultant_private_notes: module.data.consultant_private_notes,
            }
          })

        return (
          <SyntheseStep
            key={`step-${i}`}
            stepId={step.id}
            id={i}
            subjectId="title"
            open={stepIndex === i}
            stepTitle={step.title}
            contents={syntheseContents}
            onClick={({ id }) => setStepIndex(id)}
            onChange={handleSynthesisChange}
          />
        )
      }

      return null
    })
  }

  const renderHeader = () => {
    if (!beneficiary) {
      return null
    }

    const components = [
      <ComboField
        key="course"
        name="course"
        title={t('course.courses')}
        value={course ? course.id.toString() : null}
        options={getHeaderCourseOptions()}
        onChange={handleCourseChange}
      />,
      <PrimaryButton
        key="save"
        id="save"
        label={t('actions.save')}
        disabled={!hasChanged}
        onClick={handleSave}
      />,
      <PrimaryButton
        key="export"
        id="export"
        label={t('actions.export')}
        disabled={courses.length === 0}
        onClick={handleExport}
      />,
    ]

    return (
      <ActionPopinHeader
        lightTitle={t('synthesis.final_synthesis')}
        boldTitle={`${beneficiary.first_name} ${beneficiary.last_name}`}
        components={components}
        onIconClick={handleBack}
        iconButton={Icon.icon.Back}
      />
    )
  }

  return (
    <div className="page">
      <div className="consultant-beneficiary-final-synthesis-page">
        <PopinLayout content={renderContent()} header={renderHeader()} />
      </div>
    </div>
  )
}

export default ConsultantBeneficiaryFinalSynthesisPage
