import { Map } from 'immutable'
import _isEqual from 'lodash/isEqual'
import _mapKeys from 'lodash/mapKeys'
import _omit from 'lodash/omit'
import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  template: null,
  template_: null,
  templates: {},
})

//
// Actions
//
export const actions = {
  addTemplates: createAction('addCourseTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  duplicateTemplate: createAction('duplicateCourseTemplate', (id, add, filters) => ({
    id,
    add,
    filters,
  })),
  getTemplate: createAction('getCourseTemplate', (id, user_id = null) => ({ id, user_id })),
  getTemplates: createAction('getCourseTemplates', (filter) => ({ filter })),
  removeModuleAtIndex: createAction('removeModuleAtIndex', (step, module, persist = false) => ({
    step,
    module,
    persist,
  })),
  removeStepAtIndex: createAction('removeStepAtIndex', (step) => ({ step })),
  removeTemplate: createAction('removeCourseTemplate', (id) => ({ id })),
  saveTemplate: createAction('saveCourseTemplate', (template = null) => ({ template })),
  setTemplate: createAction('setCourseTemplate', (template) => ({ template })),
  setTemplates: createAction('setCourseTemplates', (templates) => ({
    templates: _mapKeys(templates, 'id'),
  })),
  setTemplateSteps: createAction('setCourseTemplateSteps', (steps) => ({ steps })),
  updateStepAtIndex: createAction('updateStepAtIndex', (index, step) => ({ index, step })),
  updateModuleAtIndex: createAction(
    'updateModuleAtIndex',
    (stepIndex, moduleIndex, module, persist, reloadData = false, optionalData = null) => ({
      module,
      stepIndex,
      moduleIndex,
      persist,
      reloadData,
      optionalData,
    }),
  ),
  updateTemplate: createAction('updateCourseTemplate', (template) => ({ template })),
  updateTemplateValue: createAction('updateCourseTemplate', (key, value) => ({ key, value })),
  assignTemplate: createAction('assignCourseTemplate', (ids) => ({ ids })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.addTemplates]: (state, { templates }) => state.mergeDeep({ templates }),
    [actions.removeModuleAtIndex]: (state, { step, module }) =>
      state.deleteIn(['template', 'steps', step, 'modules', module]),
    [actions.removeStepAtIndex]: (state, { step }) => state.deleteIn(['template', 'steps', step]),
    [actions.removeTemplate]: (state, { id }) => state.deleteIn(['templates', id]),
    [actions.setTemplate]: (state, { template }) => state.merge({ template, template_: template }),
    [actions.setTemplates]: (state, { templates }) => state.merge({ templates }),
    [actions.setTemplateSteps]: (state, { steps }) => state.mergeIn(['template'], { steps }),
    [actions.updateStepAtIndex]: (state, { step, index }) =>
      state.mergeIn(['template', 'steps', index], step),
    [actions.updateModuleAtIndex]: (state, { module, stepIndex, moduleIndex }) =>
      state.mergeIn(['template', 'steps', stepIndex, 'modules', moduleIndex], module),
    [actions.updateTemplate]: (state, { template }) => state.mergeIn(['template'], template),
    [actions.updateTemplateValue]: (state, { key, value }) => state.setIn(['template', key], value),
  },
  initialState,
)

//
// Selectors
//
const template = (state) =>
  state.courseTemplate.get('template') && { ...state.courseTemplate.get('template') }
const template_ = (state) => state.courseTemplate.get('template_')
const templates = (state) => state.courseTemplate.get('templates')
const ignoredFields = () => ['openStep']
const stepAtIndex = (state, step) => state.courseTemplate.getIn(['template', 'steps', step])
const modulesAtIndex = (state, step) =>
  state.courseTemplate.getIn(['template', 'steps', step, 'modules'])
const moduleAtIndex = (state, step, module) =>
  state.courseTemplate.getIn(['template', 'steps', step, 'modules', module])

const hasChanged = createSelector(
  template_,
  template,
  ignoredFields,
  (a, b, fields) => !_isEqual(_omit(a, fields), _omit(b, fields)),
)
const templatesArray = createSelector([templates], Object.values)

export const selectors = {
  template,
  templates: templatesArray,
  ignoredFields,
  stepAtIndex,
  moduleAtIndex,
  modulesAtIndex,
  hasChanged,
}
