import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token }) => {
  return api.get('/beneficiary-groups/me', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const get = ({ token, id }) => {
  return api.get(`/beneficiary-groups/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const remove = ({ token, id }) => {
  return api.delete(`/beneficiary-groups/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const save = ({ token, id, ...data }) => {
  const body = {
    name: data.name,
  }

  return id
    ? api.patch(`/beneficiary-groups/${id}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
    : api.post(`/beneficiary-groups`, body, { headers: { Authorization: `Bearer ${token}` } })
}
