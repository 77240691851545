import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Transition from 'react-transition-group/Transition'
import Step from '../../atoms/Step/Step'
import SideMenuLinkPanel from './SideMenuLinkPanel'
import Icon from '../../atoms/Icon/Icon'

export default class SideMenuLink extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    count: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func.isRequired,
    href: PropTypes.string,
  }

  static defaultProps = {
    count: 0,
    href: null,
    options: [],
  }

  state = {
    open: false,
    pathname: null,
  }

  handleClick = () => {
    const { id, pathname, href } = this.props

    if (href) {
      window.open(href)
      return
    }
    this.props.onClick({ id })

    if (!pathname.startsWith(id)) {
      this.setState(({ open }) => ({ open: !open }))
    }
  }

  handleClickOption = ({ id }) => {
    this.props.onClick({ id })
  }

  static getDerivedStateFromProps({ pathname, id }, state) {
    if (state.pathname) {
      return null
    }

    return {
      pathname,
      open: pathname.startsWith(id),
    }
  }

  getArrowIcon() {
    const { open } = this.state
    const icon = open ? Icon.icon.ChevronUp : Icon.icon.ChevronDown
    const color = open ? Icon.color.Grey2 : Icon.color.Accent

    return <Icon icon={icon} color={color} />
  }

  selectClasses(base) {
    return classnames(base, {
      selected: this.state.open,
    })
  }

  countLabel() {
    const { count } = this.props

    return count < 10 ? count.toString() : '9+'
  }

  renderOptions() {
    const { options, pathname } = this.props
    const { open } = this.state

    return (
      <Transition in={open} timeout={250}>
        {(status) => (
          <SideMenuLinkPanel
            onClick={this.handleClickOption}
            options={options}
            pathname={pathname}
            status={status}
          />
        )}
      </Transition>
    )
  }

  render() {
    const { label, count, options, icon } = this.props
    const { open } = this.state

    return (
      <div className={this.selectClasses('side-menu-link')} onClick={this.handleClick}>
        <div className="row">
          {open && <div className="indicator" />}

          <Icon icon={icon} />
          <div className={this.selectClasses('label')}>{label}</div>
          <div className={this.selectClasses('actions')}>
            {count > 0 && <Step label={this.countLabel()} />}
          </div>
          <div className="arrow">{options.length > 0 && this.getArrowIcon()}</div>
        </div>

        {options.length > 0 && this.renderOptions()}
      </div>
    )
  }
}
