import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'

export default class InputTagField extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ).isRequired,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    values: [],
    disabled: false,
    required: false,
    onChange: ({ id, values }) => undefined,
    readOnly: false,
  }

  handleDelete = (index) => {
    const { id, values } = this.props

    this.props.onChange({
      id,
      values: values.filter((v, i) => i !== index),
    })
  }

  handleAdd = (value) => {
    const { id, values } = this.props

    this.props.onChange({
      id,
      values: [...values, { value: value.id, label: value.text }],
    })
  }

  handleFilterSuggestions = (value, suggestions) => {
    const query = (value.text || value).toString().toLowerCase()

    return suggestions.filter(({ text }) => text.toLowerCase().includes(query))
  }

  render() {
    const { options, title, values, placeholder, required, readOnly } = this.props
    const suggestions = options
      .filter((option) => !values.some((value) => `${value.value}` === `${option.value}`))
      .map(({ value, label }) => ({ id: value.toString(), text: label }))

    const tags = options
      .filter((option) => values.some((value) => `${value.value}` === `${option.value}`))
      .map(({ value, label }) => ({ id: value.toString(), text: label }))

    return (
      <div className="input-tag-field">
        {title && (
          <div className="common-form-label">
            {title}
            {required && '*'}
          </div>
        )}

        <ReactTags
          tags={tags}
          placeholder={placeholder}
          suggestions={suggestions}
          handleAddition={this.handleAdd}
          handleDelete={this.handleDelete}
          handleFilterSuggestions={this.handleFilterSuggestions}
          minQueryLength={1}
          readOnly={readOnly}
          autocomplete
        />
      </div>
    )
  }
}
