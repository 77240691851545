import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../../containers/global/Translator/Translator'

import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps)
export default class ManagerOutOfAnnualStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderBeneficiaryCount = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'beneficiary_count',
        title: t('statistics.beneficiaryCount.beneficiary_count'),
        selector: 'beneficiary_count',
      },
      {
        id: 'rate_created_wait',
        title: t('statistics.beneficiaryCount.rate_created_wait'),
        selector: (item) => (item.rate_created_wait * 100).toFixed(2),
      },
      {
        id: 'rate_wait_in_progress',
        title: t('statistics.beneficiaryCount.rate_wait_in_progress'),
        selector: (item) => (item.rate_wait_in_progress * 100).toFixed(2),
      },
    ]

    const defaultData = {
      beneficiary_count: 0,
      rate_created_wait: 0,
      rate_wait_in_progress: 0,
    }

    let data = []
    if (managerOfficeStats.beneficiaryCount.length > 0) {
      data = managerOfficeStats.beneficiaryCount.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return <StatsTable columns={columns} data={data} dataIdSelector="id" />
  }

  render() {
    const { t } = this.props

    return (
      <div className="statistics-section">
        <SectionTitle label={t('statistics.out_of_annual_statistics')} />
        {this.renderBeneficiaryCount()}
      </div>
    )
  }
}
