import { actions as portalActions, selectors as portalSelectors } from '../redux/PortalRedux'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

export function usePortalCustomization({ slug } = { slug: null }) {
  const theme = useSelector(portalSelectors.theme)
  const labels = useSelector(portalSelectors.labels)
  const assets = useSelector(portalSelectors.assets)

  const dispatch = useDispatch()

  useEffect(() => {
    if (slug) {
      dispatch(portalActions.getOfficeSettings({ slug }))
    }
  }, [dispatch, slug])

  useEffect(() => {
    if (theme) {
      Object.entries(theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value)
      })
    }

    if (assets) {
      document.documentElement.style.setProperty(
        '--login-background-image-url',
        `url(${assets?.authCover})`,
      )
    }
  }, [theme, assets])

  return useMemo(
    () => ({
      slug,
      theme,
      labels,
      assets,
    }),
    [slug, theme, labels, assets],
  )
}
