import { call, put, select } from 'redux-saga/effects'

import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { selectors as BeneficiarySelectors } from '../redux/BeneficiaryRedux'
import { selectors as UserSelectors } from '../redux/UserRedux'
import { actions as NotesActions, selectors as NotesSelectors } from '../redux/NotesRedux'
import { actions as WSActions } from '../redux/WSRedux'

export function* getNotes() {
  const beneficiary = yield select(BeneficiarySelectors.beneficiary)

  if (beneficiary.id) {
    yield call(services.notes.get, { id: beneficiary.id }, function* ({ ok, data }) {
      if (ok) {
        const notes = ok ? data : []
        yield put(NotesActions.setNotes(notes))
      }
    })
  }

  yield put(WSActions.reset('notes.get'))
}

export function* saveNotes({ payload }) {
  const beneficiary = yield select(BeneficiarySelectors.beneficiary)
  const consultantNotes = yield select(NotesSelectors.consultantNotes)
  const newNotes = consultantNotes && consultantNotes.length > 0 ? consultantNotes[0].notes : ''

  if (beneficiary.id) {
    yield call(services.notes.save, { id: beneficiary.id, notes: newNotes }, function* ({
      ok,
      data,
    }) {
      if (ok) {
        const notes = yield select(NotesSelectors.consultantNotes)
        const user = yield select(UserSelectors.user)

        const newAllNotes = notes.map((note) => {
          if (note.consultant.id === user.id) {
            return {
              ...note,
              notes: newNotes,
            }
          }

          return { ...note }
        })

        yield put(NotesActions.setNotes(newAllNotes))
        yield put(WSActions.reset('notes.save'))

        if (payload.performNotification) {
          yield put(NotificationActions.status({ ok, data }))
        }
      } else {
        yield put(NotificationActions.status({ ok, data }))
      }
    })
  }
}
