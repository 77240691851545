import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'
import classnames from 'classnames'

import { withI18n } from '../../../containers/global/Translator/Translator'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'
import StatsBarChart from '../../../components/organisms/StatsBarChart/StatsBarChart'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps, null)
export default class ManagerChartStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderBeneficiaryCountByGenderChart = () => {
    const { t, managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByGender.length > 0) {
      data = managerOfficeStats.beneficiaryCountByGender.map((d) => ({
        xValue: t(`statistics.beneficiaryCountByGender.${d.label}`),
        yValue: d.count,
      }))
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-gender')}
        title={t('statistics.beneficiaryCountByGender.title')}
        displayYPercents
        data={data}
        ratio={{
          width: 280,
          height: 275,
        }}
      />
    )
  }

  renderBeneficiaryCountByFormationChart = () => {
    const { t, managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByFormation.length > 0) {
      const beneficiaryFormations = t(
        'statistics.beneficiaryCountByFormation.beneficiary_formations',
      )
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByFormation, 'label')
      const defaultData = {
        yValue: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            yValue: dataValues[beneficiaryFormationKey].count,
          }
        }

        return {
          ...values,
          xValue: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-formation')}
        title={t('statistics.beneficiaryCountByFormation.title')}
        data={data}
        ratio={{
          width: 440,
          height: 275,
        }}
      />
    )
  }

  renderBeneficiaryCountByFinancerChart = () => {
    const { t, managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByFinancer.length > 0) {
      const beneficiaryTypes = t('beneficiary_financer_types')
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByFinancer, 'label')
      const defaultData = {
        yValue: 0,
      }
      data = Object.keys(beneficiaryTypes).map((beneficiaryTypeKey) => {
        let values = defaultData
        if (dataValues[beneficiaryTypeKey]) {
          values = {
            ...values,
            yValue: dataValues[beneficiaryTypeKey].count,
          }
        }

        return {
          ...values,
          xValue: beneficiaryTypes[beneficiaryTypeKey],
        }
      })
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-financer')}
        title={t('statistics.beneficiaryCountByFinancer.title')}
        data={data}
        ratio={{
          width: 745,
          height: 275,
        }}
      />
    )
  }

  render() {
    return (
      <div className="charts-wrapper">
        {this.renderBeneficiaryCountByGenderChart()}
        {this.renderBeneficiaryCountByFormationChart()}
        {this.renderBeneficiaryCountByFinancerChart()}
      </div>
    )
  }
}
