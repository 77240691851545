import { call, put, select } from 'redux-saga/effects'

import { actions as ConsultantActions } from '../redux/ConsultantRedux'
import { actions as UserActions, selectors as UserSelectors } from '../redux/UserRedux'
import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as OfficeActions } from '../redux/OfficeRedux'

export function* getConsultants() {
  yield call(services.consultant.getAll, null, function* ({ ok, data, payload }) {
    if (ok) {
      if (payload.notMe) {
        const user = yield select(UserSelectors.user)
        data.data = data.data.filter(({ id }) => id !== user.id)
      }

      yield put(ConsultantActions.setConsultants(data.data))
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}

export function* getOfficeConsultants() {
  const user = yield select(UserSelectors.user)
  if (user.role === 'beneficiary') {
    return
  }
  yield call(services.manager.getAllConsultants, null, function* ({ ok, data, payload }) {
    if (ok) {
      if (payload.notMe) {
        const user = yield select(UserSelectors.user)
        data.data = data.data.filter(({ id }) => id !== user.id)
      }

      yield put(ConsultantActions.setOfficeConsultants(data.data))
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}

export function* getConsultant({ payload }) {
  const { id } = payload

  yield call(services.consultant.get, { id }, function* ({ ok, data }) {
    ok
      ? yield put(ConsultantActions.setConsultant(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveConsultant({ payload }) {
  const { consultant, isCurrent } = payload

  yield call(services.consultant.save, consultant, function* ({ ok, data }) {
    if (ok) {
      yield put(ConsultantActions.setConsultants([data]))
      yield put(ConsultantActions.setConsultant(data))

      if (isCurrent) {
        yield put(UserActions.setUser(data))
      }
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeConsultant({ payload }) {
  const { id } = payload
  const result = yield call(services.consultant.remove, { id })

  yield put(NotificationActions.status(result))
}

export function* activateConsultant({ payload }) {
  const { id } = payload
  const result = yield call(services.consultant.activate, { id })

  yield put(NotificationActions.status(result))

  if (!result.ok) {
    yield put(OfficeActions.getCurrentUserOffice())
  }

  yield call(getOfficeConsultants)
}

export function* archiveConsultant({ payload }) {
  const { id } = payload
  const result = yield call(services.consultant.archive, { id })

  yield put(NotificationActions.status(result))

  yield call(getOfficeConsultants)
}

export function* syncCronofy({ payload }) {
  const { code, consultant } = payload

  yield put(ConsultantActions.setSyncInProgress())
  yield call(services.consultant.syncCronofy, { id: consultant.id, code }, function* ({
    ok,
    data,
  }) {
    if (ok) {
      yield put(UserActions.setUser(data))
      yield put(ConsultantActions.setConsultant(data))
    }
  })
  yield put(ConsultantActions.setSyncIsFinished())
}
