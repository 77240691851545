import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'

const propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  inputList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
}

const defaultProps = {
  title: null,
  value: null,
  readOnly: false,
  onChange: ({ id, value }) => {},
}

class RadioField extends Component {
  onRadioChange = (value) => {
    if (!this.props.readOnly) {
      this.props.onChange({ id: this.props.name, value })
    }
  }

  render() {
    const { title, name, inputList, value, readOnly } = this.props
    return (
      <div className="radio-field">
        {title && <div className="common-form-label">{title}</div>}
        {inputList.map((input, i) => {
          if (readOnly && value !== input.value) {
            return null
          }

          const icon = value === input.value ? Icon.icon.RadioChecked : Icon.icon.RadioUnchecked
          return (
            <div className="radio-container" key={i}>
              <input
                type="radio"
                name={name}
                id={input.id}
                onChange={() => this.onRadioChange(input.value)}
                value={input.value}
              />
              <label htmlFor={input.id}>
                <Icon icon={icon} />
                <span className="label">{input.label}</span>
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

RadioField.propTypes = propTypes
RadioField.defaultProps = defaultProps

export default RadioField
