import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class DescriptionTextArea extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
  }

  static defaultProps = {
    value: '',
    readonly: false,
    onChange: ({ id, value }) => undefined,
  }

  handleChange = (e) => {
    this.props.onChange({
      id: this.props.name,
      value: e.target.value,
    })
  }

  render() {
    const { id, value, placeholder, maxLength, readonly } = this.props

    return (
      <label className="description-text-area">
        <textarea
          value={value}
          name={id}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={this.handleChange}
          readOnly={readonly}
        />

        <span className="remaining">
          {value.length}/{maxLength}
        </span>
      </label>
    )
  }
}
