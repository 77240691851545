import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// Icon
import Icon from '../Icon/Icon'

export default class CircleButton extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    isActivated: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    color: Icon.color.White,
    backgroundColor: Icon.color.Accent,
    isActivated: true,
    id: '',
    disabled: false,
    onClick: () => {},
  }

  handleClick = (e) => {
    this.props.onClick(e, this.props.id)
  }

  render() {
    const { icon, color, backgroundColor, isActivated, disabled, ...rest } = this.props

    return (
      <a
        className={classNames('circle-button', { disabled })}
        data-background-color={backgroundColor}
        onClick={isActivated ? this.handleClick : undefined}
        {...rest}
      >
        <Icon icon={icon} color={color} />
      </a>
    )
  }
}
