import ApiService from './ApiService'

const api = ApiService

export const createAccount = ({ token }) => {
  return api.get(
    '/services/centraltest/createAccount',
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const fetchTests = ({ token }) => {
  return api.get(
    '/services/centraltest/tests',
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const inviteBeneficiary = ({ token, user_id, module_id }) => {
  return api.post(
    '/services/centraltest/invite',
    {
      user_id,
      module_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const getAssessments = ({ token, user_id }) => {
  return api.post(
    '/services/centraltest/assessments',
    {
      user_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const toggleReport = ({ token, module_id }) => {
  return api.post(
    '/services/centraltest/modules/toggleReport',
    {
      module_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const manualLink = ({ token, office_id, company_id }) => {
  return api.post(
    '/services/centraltest/manualLink',
    {
      office_id,
      company_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}
