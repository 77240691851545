import _pick from 'lodash/pick'
import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })

  query.limit = 200

  return api.get('/module-text-resource-templates/me', query, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const remove = ({ token, id }) => {
  return api.delete(`/module-text-resource-templates/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const prepareForSave = (template) => {
  const clone = JSON.parse(JSON.stringify(template))
  const fields = ['type', 'url', 'title', 'template_published']

  return _pick(clone, fields)
}

export const save = ({ token, id, ...template }) => {
  const prepared = prepareForSave(template)

  return id
    ? api.patch(`/module-text-resource-templates/${id}`, prepared, {
        headers: { Authorization: `Bearer ${token}` },
      })
    : api.post(`/module-text-resource-templates`, prepared, {
        headers: { Authorization: `Bearer ${token}` },
      })
}
