import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'

export default class SearchField extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allowReset: PropTypes.bool,
    reversed: PropTypes.bool,
    showLine: PropTypes.bool,
    value: PropTypes.string.isRequired,
  }

  static defaultProps = {
    placeholder: '',
    reversed: false,
    showLine: true,
    activeInput: false,
    onChange: ({ value }) => undefined,
  }

  renderIcon = () => {
    return this.props.allowReset ? (
      <IconButton icon="close" color="grey2" onClick={this.handleReset} />
    ) : (
      <Icon icon="search" color="grey2" />
    )
  }

  handleChange = (e) => {
    this.props.onChange({ value: e.currentTarget.value })
  }

  handleReset = () => {
    this.props.onChange({ value: '' })
  }

  render() {
    const { showLine, placeholder, reversed, value } = this.props

    return (
      <label className={classNames('search-field', { showLine })}>
        <input
          type="text"
          name="search-bar"
          placeholder={placeholder}
          onChange={this.handleChange}
          className={classNames({ reversed })}
          value={value}
        />
        {this.renderIcon()}
      </label>
    )
  }
}
