import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Step from '../../atoms/Step/Step'

// PropTypes
const propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  countIndicator: PropTypes.number,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sublabel: PropTypes.string,
  withSublabel: PropTypes.bool,
}

// DefaultProps
const defaultProps = {
  countIndicator: 0,
  sublabel: '',
  withSublabel: false,
}

class LineMenuLink extends Component {
  onClick = (e) => {
    this.props.onClick(e, this.props.id)
  }

  countLabel() {
    const { countIndicator } = this.props
    if (countIndicator < 10) return countIndicator.toString()
    return '9+'
  }

  render() {
    const { id, isSelected, countIndicator, label, sublabel, withSublabel } = this.props
    return (
      <div
        className={classnames('line-menu-link', withSublabel && 'with-sublabel')}
        id={id}
        onClick={this.onClick}
      >
        <div className={`label-container ${isSelected ? 'selected' : 'default'}`}>
          <div className={`label ${isSelected ? 'selected' : 'default'}`}>
            {label}
            {countIndicator > 0 && <Step label={this.countLabel()} />}
          </div>
          {withSublabel && <p className="sublabel">{sublabel}</p>}
        </div>
        {isSelected && <div className="selector" />}
      </div>
    )
  }
}

LineMenuLink.propTypes = propTypes
LineMenuLink.defaultProps = defaultProps

export default LineMenuLink
