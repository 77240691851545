import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import Icon from '../../atoms/Icon/Icon'

export default class ModuleRowContent extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    url: '',
    color: Icon.color.Grey3,
    onClick: () => {},
  }

  handleClick = () => {
    const { url, label } = this.props

    this.props.onClick(url, label)
  }

  render() {
    const { icon, label, color, url } = this.props
    return (
      <a
        className="module-row-content"
        target="_blank"
        href={icon !== 'video' ? url : null}
        onClick={icon === 'video' ? this.handleClick : null}
      >
        <Icon icon={icon} color={color} />
        <p className="label">{label}</p>
      </a>
    )
  }
}
