import React, { Fragment } from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import config from '../config/AppConfig'

class BugsnagClient {
  constructor() {
    const apiKey = config.BUGSNAG_API_KEY
    const bugsnagConfig = { apiKey }

    if (apiKey) {
      this.client = bugsnag(bugsnagConfig).use(bugsnagReact, React)
    }
  }

  notify(error, opts) {
    if (this.client) {
      this.client.notify(error, opts)
    }

    console.error(error, opts)
  }

  setUser(user) {
    if (this.client) {
      this.client.user = user
    }
  }

  getWrapper() {
    return this.client ? this.client.getPlugin('react') : Fragment
  }
}

export default new BugsnagClient()
