import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment/moment'

import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'

import Icon from '../../components/atoms/Icon/Icon'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as ModuleActions, selectors as ModuleSelectors } from '../../redux/ModuleRedux'
import QuizCombo from '../../components/molecules/QuizCombo/QuizCombo'
import QuizGroup from '../../components/molecules/QuizGroup/QuizGroup'
import QuizQcm from '../../components/molecules/QuizQcm/QuizQcm'
import QuizStars from '../../components/molecules/QuizStars/QuizStars'
import QuizTextArea from '../../components/molecules/QuizTextArea/QuizTextArea'
import QuizYesNo from '../../components/molecules/QuizYesNo/QuizYesNo'
import QuizOpinionScale from '../../components/molecules/QuizOpinionScale/QuizOpinionScale'
import QuizDate from '../../components/molecules/QuizDate/QuizDate'

const mapStateToProps = (state, props) => ({
  formId: Number(props.match.params.form_id) || null,
  pathname: NavigationSelectors.pathname(state),
  form: ModuleSelectors.module(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getModule: (module_id) => dispatch(ModuleActions.getModule(module_id)),
  setModule: (module) => dispatch(ModuleActions.setModule(module)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantBeneficiaryFormModulePage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  handleBack = () => {
    const { pathname, redirect, setModule } = this.props
    setModule(null)
    redirect(pathname.replace(/\/forms\/\w+$/, ''))
  }

  renderHeader = () => {
    const { form } = this.state
    const { title } = form

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={`${title}`}
        onIconClick={this.handleBack}
      />
    )
  }

  renderQuestion = (key, question, prefix_id = '') => {
    const { t } = this.props

    switch (question.type) {
      case 'text': {
        return (
          <QuizTextArea
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            name={`${prefix_id}${question.id}`}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : ''
            }
            placeholder={t('form.module_text_placeholder')}
            maxLength={
              question.options.max_length != null && question.options.max_length
                ? parseInt(question.options.max_length, 10)
                : 0
            }
            disabled
          />
        )
      }
      case 'select': {
        let comboFieldValue = this.state.results[`${prefix_id}${question.id}`]
          ? this.state.results[`${prefix_id}${question.id}`]
          : null
        if (comboFieldValue === null) {
          comboFieldValue =
            question.options.required != null && question.options.required
              ? question.options.choices[0]
              : ''
        }

        const comboFieldProps = {
          name: `${prefix_id}${question.id}`,
          options: question.options.choices.map((opt) => ({ value: opt, label: opt })),
          value: comboFieldValue,
          placeholder: t('form.module_select_placeholder'),
          clearable: question.options.required == null || !question.options.required,
          readOnly: true,
        }

        return (
          <QuizCombo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            comboFieldProps={comboFieldProps}
          />
        )
      }
      case 'multiple_choice': {
        const choices = question.options.choices.map((choice) => ({
          label: choice,
          isSelected:
            this.state.results[`${prefix_id}${question.id}`] &&
            this.state.results[`${prefix_id}${question.id}`][choice]
              ? this.state.results[`${prefix_id}${question.id}`][choice]
              : false,
        }))

        if (question.options.allow_other != null && question.options.allow_other) {
          choices.push({
            label: t('form.multiple_choice_other'),
            isSelected:
              this.state.results && this.state.results[t('form.multiple_choice_other')]
                ? this.state.results[t('form.multiple_choice_other')]
                : false,
          })
        }

        return (
          <QuizQcm
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            options={choices}
            disabled
          />
        )
      }
      case 'group': {
        return (
          <QuizGroup
            key={key}
            label={question.title}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
          >
            {question.questions
              .sort((q1, q2) => q1.order - q2.order)
              .map((itemQuestion, key) =>
                this.renderQuestion(key + 1, itemQuestion, `${question.id}_`),
              )}
          </QuizGroup>
        )
      }
      case 'yes_no': {
        return (
          <QuizYesNo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            id={`${prefix_id}${question.id}`}
            value={
              this.state.results[`${prefix_id}${question.id}`] !== undefined
                ? this.state.results[`${prefix_id}${question.id}`]
                : null
            }
            yesLabel={t('actions.yes')}
            noLabel={t('actions.no')}
            disabled
          />
        )
      }
      case 'rating': {
        return (
          <QuizStars
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            starsCount={5}
            selectedStars={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : 0
            }
            disabled
          />
        )
      }
      case 'opinion_scale': {
        return (
          <QuizOpinionScale
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            scale={question.options.scale ? question.options.scale : 10}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : -1
            }
            disabled
          />
        )
      }
      case 'date': {
        return (
          <QuizDate
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            monthLabels={Object.values(t('calendar_labels.month_labels'))}
            dayTitle={t('calendar_labels.day')}
            monthTitle={t('calendar_labels.month')}
            yearTitle={t('calendar_labels.year')}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : ''
            }
            disabled
          />
        )
      }
      default: {
        return null
      }
    }
  }

  renderContent = () => {
    const { t } = this.props

    const { form } = this.state
    const { data } = form
    const { questions } = data

    return (
      <div className="questions">
        {questions
          .sort((q1, q2) => q1.order - q2.order)
          .map((question, key) => this.renderQuestion(key + 1, question))}
        <div>{t('form.required_fields')}</div>
      </div>
    )
  }

  static getDerivedStateFromProps(props, state) {
    const { formId: id, form } = props

    if (id) {
      if (form && state.results === null) {
        const results = {}

        const buildResults = (question, prefix_id = '') => {
          if (question.type === 'group') {
            question.questions.map((itemQuestion) => buildResults(itemQuestion, `${question.id}_`))
          } else if (question.answer != null) {
            results[`${prefix_id}${question.id}`] = question.answer
            if (
              (results[`${prefix_id}${question.id}`] === null ||
                results[`${prefix_id}${question.id}`] === '') &&
              question.options.required != null &&
              question.options.required &&
              question.type === 'select'
            ) {
              results[`${prefix_id}${question.id}`] = question.options.choices[0]
            }
            if (question.type === 'date' && results[`${prefix_id}${question.id}`] === '') {
              results[`${prefix_id}${question.id}`] = moment().format('DD/MM/YYYY')
            }
            if (question.type === 'multiple_choice') {
              const newState = question.options.choices.reduce(
                (res, choice) => ({
                  ...res,
                  [choice]: question.answer.includes(choice),
                }),
                {},
              )

              results[`${prefix_id}${question.id}`] = newState
            }
          }
        }

        form.data.questions.map((question) => buildResults(question))

        return {
          form: form || null,
          results,
        }
      }

      return null
    }

    return {
      form: {},
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      form: null,
      results: null,
    }
  }

  componentDidMount() {
    const { formId } = this.props

    return this.props.getModule(formId)
  }

  componentWillUnmount() {
    this.props.setModule(null)
  }

  render() {
    const { form, results } = this.state

    if (form === null || results === null) {
      return null
    }

    const header = this.renderHeader()
    const contentLayout = this.renderContent()

    return (
      <div className="consultant-beneficiary-form-module-page">
        <PopinLayout header={header} content={contentLayout} />
      </div>
    )
  }
}
