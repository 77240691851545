import React, { useState } from 'react'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import Icon from '../../atoms/Icon/Icon'
import ModuleCentralTestSelectPopin from '../ModuleCentralTestSelectPopin/ModuleCentralTestSelectPopin'

const labels = {
  noTestSelected: 'Aucune évaluation séléctionnée',
  status: {
    pending: 'Invitation à envoyer',
    invited: 'Invitation envoyée',
    completed: 'Module terminé',
  },
}

export const statusTypes = {
  pending: 'pending',
  invited: 'invited',
  done: 'done',
}

const CentralTestModuleWrapper = ({ _module, stepIndex, moduleIndex }) => {
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false)

  const openSelectionModal = () => {
    setIsSelectionModalOpen(true)
  }

  const closeSelectionModal = () => {
    setIsSelectionModalOpen(false)
  }

  const renderSelectionModal = () =>
    isSelectionModalOpen && (
      <ModuleCentralTestSelectPopin
        centralTestModule={_module}
        stepIndex={stepIndex}
        moduleIndex={moduleIndex}
        isModalOpen={isSelectionModalOpen}
        onClose={closeSelectionModal}
        isTemplate={true}
      />
    )

  return (
    <>
      <div className="centraltest-module-consultant">
        <div className="selection">
          <div className="details">
            <CircleButton icon={Icon.icon.CentralTest} color={Icon.color.White} />
            <span className="label">{_module.data.test_name || labels.noTestSelected}</span>
          </div>
          <PrimaryButton
            className="trigger"
            title="Modifier"
            outline
            id="edit-centraltest"
            label={_module.data.test_name ? 'Modifier' : 'Sélectionner'}
            onClick={openSelectionModal}
          />
        </div>
        <div className="status">
          <div className="details">
            <Icon icon={Icon.icon.RoundMinus} />
            <span className="label--pending">Invitation à envoyer</span>
          </div>
          <PrimaryButton
            className="trigger"
            title="Envoyer l'invitation"
            id="confirm-centraltest"
            label="Envoyer l'invitation"
            onClick={() => {}}
            icon={Icon.icon.PersonPlus}
            disabled={true}
          />
        </div>
      </div>

      {renderSelectionModal()}
    </>
  )
}

export default CentralTestModuleWrapper
