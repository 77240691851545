import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import EditStepWrapper from '../../containers/course/EditStepWrapper/EditStepWrapper'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'

const mapStateToProps = (state, props) => ({
  course_id: props.match.params.course_id,
  template: CourseTemplateSelectors.template(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplate: (id) => dispatch(CourseTemplateActions.getTemplate(id)),
  setTemplate: (template) => dispatch(CourseTemplateActions.setTemplate(template)),
  back: (prefix) => dispatch(NavigationActions.back(prefix, true)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantCourseTemplatesPreviewPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  handleBackClick = () => {
    this.props.setTemplate(null)
    this.props.back(`/${this.props.course_id}`)
  }

  renderHeader() {
    const { template } = this.props
    const backgroundImage =
      template && template.cover_url
        ? `url("${template.cover_url}")`
        : 'url("/static/images/course-default-background.jpg")'

    const style = {
      backgroundImage,
    }

    return (
      <header style={style}>
        <div>
          <CircleButton icon={Icon.icon.Back} onClick={this.handleBackClick} />
        </div>
      </header>
    )
  }

  renderTemplateInformations() {
    const { t, template } = this.props
    const { title, description, steps } = template
    const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

    return (
      <>
        <div className="title">{title}</div>

        <div className="informations">
          <div className="entry">
            <div className="count">{steps.length}</div>
            <div className="label">{t('step.steps')}</div>
          </div>
          <div className="entry">
            <div className="count">{moduleCount}</div>
            <div className="label">{t('module.modules')}</div>
          </div>
        </div>

        <div className="description">{description}</div>
      </>
    )
  }

  renderTemplateSteps() {
    const cards = this.props.template.steps.map(({ id, _id, title, modules }, i) => {
      const key = (id || _id).toString()

      return <EditStepWrapper key={key} stepIndex={i} readOnly />
    })

    return <div className="steps">{cards}</div>
  }

  renderTemplate() {
    return (
      <section>
        {this.renderTemplateInformations()}
        {this.renderTemplateSteps()}
      </section>
    )
  }

  renderContent() {
    return (
      <>
        {this.renderHeader()}
        {this.renderTemplate()}
      </>
    )
  }

  componentDidMount = () => {
    const { course_id } = this.props

    this.props.getTemplate(course_id)
  }

  render() {
    const { template } = this.props

    if (!template) return null

    return (
      <div className="consultant-course-templates-preview-page">
        <RightAsideLayout aside={null} content={this.renderContent()} />
      </div>
    )
  }
}
