import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isValid } from 'redux-form'

import config from '../../config/AppConfig'

import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import FileUpload from '../../containers/global/FileUpload/FileUpload'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'

import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import AvatarField from '../../components/molecules/AvatarField/AvatarField'
import UserCard from '../../components/organisms/UserCard/UserCard'
import CheckBoxField from '../../components/molecules/CheckBoxField/CheckBoxField'
import CronofyConnect from '../../components/molecules/CronofyConnect/CronofyConnect'

import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'

import { selectors as UserSelectors } from '../../redux/UserRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../redux/ConsultantRedux'
import { selectors as OfficeSelectors } from '../../redux/OfficeRedux'

// TO DO upload new image

const mapStateToProps = (state) => ({
  valid: isValid('user-card')(state),
  user: UserSelectors.user(state),
  consultant: ConsultantSelectors.consultant(state),
  hasChanged: ConsultantSelectors.hasChanged(state),
  office: OfficeSelectors.office(state),
  syncInProgress: ConsultantSelectors.syncInProgress(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveConsultant: (consultant, isCurrent) =>
    dispatch(ConsultantActions.saveConsultant(consultant, isCurrent)),
  getConsultant: (id) => dispatch(ConsultantActions.getConsultant(id)),
  setConsultant: (consultant) => dispatch(ConsultantActions.setConsultant(consultant)),
  updateConsultant: (consultant) => dispatch(ConsultantActions.updateConsultant(consultant)),
  syncCronofy: (code, user) => dispatch(ConsultantActions.syncCronofy({ code, user })),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantModulesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  handleFileChange = ({ file }) => {
    this.handleUserChange({ id: 'image_url', value: file.url })
  }

  handleUserChange = ({ id, value }) => {
    const map = {
      name: 'last_name',
      firstName: 'first_name',
    }
    const update = {}

    update[map[id] || id] = value
    this.props.updateConsultant(update)
  }

  handleSyncAgenda = (data) => {
    const { code } = data
    if (code) {
      this.props.syncCronofy(data.code, this.props.user)
    }
  }

  handleSave = () => {
    const { consultant } = this.props
    const { id, first_name, last_name, image_url, phone, email, optin_email } = consultant
    const data = {
      id,
      first_name,
      last_name,
      image_url,
      phone,
      email,
      optin_email,
      function: consultant.function,
    }

    this.props.saveConsultant(data, true)
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.getConsultant(this.props.user.id)
    }
  }

  componentWillUnmount() {
    this.props.setConsultant(null)
  }

  renderSaveButton = () => {
    const { t, hasChanged, valid } = this.props

    return (
      <PrimaryButton
        disabled={!hasChanged || !valid}
        id="profile-save"
        label={t('actions.save')}
        onClick={this.handleSave}
      />
    )
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'stats', label: t('user.my_profile') }]

    return <LineMenu value={links[0].id} links={links} components={this.renderSaveButton()} />
  }

  renderContent = () => {
    const { t, consultant, office, syncInProgress } = this.props

    const encodedRedirectUri = encodeURIComponent(config.CRONOFY_REDIRECT_URI)
    const cronofySyncUrl = `${config.CRONOFY_BASE_URL}?client_id=${config.CRONOFY_CLIENT}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=read_write`

    if (!office) return null

    const {
      first_name,
      last_name,
      phone,
      email,
      optin_email,
      image_url,
      cronofy_account,
    } = consultant
    const locale = {
      name: `${t('form.name')}`,
      namePlaceHolder: t('form.name_placeholder'),
      firstName: `${t('form.first_name')}`,
      firstNamePlaceHolder: t('form.first_name_placeholder'),
      function: t('form.function'),
      functionPlaceholder: t('form.function_placeholder'),
      company: t('form.company'),
      email: `${t('form.email')}`,
      emailPlaceHolder: t('form.email_placeholder'),
      phone: `${t('form.phone')}`,
      phonePlaceHolder: t('form.phone_placeholder'),
    }

    const cronofyButtonLabel = syncInProgress
      ? t('user.cronofy_loading')
      : cronofy_account
      ? t('user.cronofy_connect_new')
      : t('user.cronofy_connect')

    return (
      <div className="profile-page-content">
        <div className="avatar-wrapper">
          <FileUpload accept="image/*" id="image_url" onChange={this.handleFileChange}>
            {({ onClick, uploading }) => (
              <AvatarField photoUrl={image_url} onClick={onClick} uploading={uploading} />
            )}
          </FileUpload>
        </div>
        <UserCard
          onChange={this.handleUserChange}
          firstName={first_name}
          name={last_name}
          phone={phone}
          email={email}
          company={office.name}
          function={consultant.function}
          hideAvatarAndHeader
          locale={locale}
        />
        <div className="preference-wrapper">
          <div className="preference-label">{t('user.preference')}</div>
          <CheckBoxField
            name="email-preference"
            id="optin_email"
            value={!!optin_email}
            label={t('user.email_preference')}
            onChange={this.handleUserChange}
          />

          <div className="preference-label">{t('user.agenda')}</div>
          <div className="cronofy-sync-wrapper">
            {cronofy_account && <div className="cronofy-account">{t('user.cronofy_account')}</div>}
            {!cronofy_account && (
              <div>
                <div className="cronofy-no-account">{t('user.cronofy_no_account')}</div>
              </div>
            )}
            <CronofyConnect
              buttonLabel={cronofyButtonLabel}
              disabled={syncInProgress}
              onChange={this.handleSyncAgenda}
              url={cronofySyncUrl}
              popupTitle={t('user.sync_your_agenda')}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { consultant } = this.props

    if (!consultant) return null

    const aside = <ConsultantPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="consultant-profile-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
