import _pick from 'lodash/pick'
import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const get = ({ token, id }) => {
  return api.get(`/course-templates/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })
  return api.get('/course-templates', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const duplicate = ({ token, id }) => {
  return api.post(`/course-templates/${id}/duplicate`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const remove = ({ token, id }) => {
  return api.delete(`/course-templates/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const save = ({ token, id, ...data }) => {
  const prepared = prepareForSave(data)

  return id
    ? api.patch(`/course-templates/${id}`, prepared, {
        headers: { Authorization: `Bearer ${token}` },
      })
    : api.post(`/course-templates`, prepared, { headers: { Authorization: `Bearer ${token}` } })
}

export const prepareForSave = (template) => {
  const clone = JSON.parse(JSON.stringify(template))
  const fields = {
    course: ['title', 'description', 'tags', 'cover_url', 'steps', 'template_published'],
    step: ['id', 'title', 'order', 'modules', 'is_visible'],
    module: ['id', 'type', 'title', 'description', 'order', 'data', 'locked'],
    resource: ['type', 'order', 'title', 'url', 'private', 'url_uploaded'],
    appointment: ['rdv_at', 'rdv_to', 'status', 'user_from', 'user_to', 'is_videocall'],
    question: ['type', 'order', 'title', 'options'],
    questionOptions: ['required', 'max_length', 'choices', 'allow_other', 'questions', 'scale'],
  }

  if (clone.id) {
    fields.step.push('id')
    fields.module.push('id')
    fields.question.push('id')
    fields.resource.push('id')
  }

  return Object.assign(_pick(clone, fields.course), {
    steps: clone.steps.map((step) =>
      Object.assign(_pick(step, fields.step), {
        modules: step.modules.map((module) => {
          const { data } = module
          const { questions, resources } = data
          const isPatch = !!module.id
          const _fields = {
            ...fields,
            module: fields.module.concat(isPatch ? ['done'] : []),
            resource: fields.resource.concat(isPatch ? ['id'] : []),
            question: fields.question.concat(isPatch ? ['id', 'done'] : []),
          }

          const clone = Object.assign(_pick(module, _fields.module))

          if (questions) {
            clone.data.questions = module.data.questions.map((question) => {
              const questions =
                question.type === 'group'
                  ? (question.questions || []).map((question) => _pick(question, _fields.question))
                  : undefined

              return Object.assign(_pick(question, _fields.question), {
                questions,
                options: _pick(question.options, _fields.questionOptions),
              })
            })
          }

          if (resources) {
            clone.data.resources = module.data.resources.map((resource) =>
              _pick(resource, _fields.resource),
            )
          }

          if (clone.type === 'appointment') {
            clone.data = Object.assign(_pick(clone.data, fields.appointment))
          }

          return clone
        }),
      }),
    ),
  })
}

export const assign = ({ token, ids }) => {
  return api.post('consultants/assign-course', ids, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
