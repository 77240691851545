import { call, put } from 'redux-saga/effects'

import { services } from '../services'
import { actions as ResourceTemplateActions } from '../redux/ResourceTemplateRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'

export function* getTemplates({ payload }) {
  const { filter } = payload

  yield call(services.resourceTemplate.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(ResourceTemplateActions.setTemplates(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveTemplate({ payload }) {
  const { template } = payload

  yield call(services.resourceTemplate.save, template, function* ({ ok, data }) {
    if (ok) {
      yield put(ResourceTemplateActions.addTemplates([data]))
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeTemplate({ payload }) {
  const { id } = payload

  const result = yield call(services.resourceTemplate.remove, { id })
  yield put(NotificationActions.status(result))
}
