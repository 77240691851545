import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import QuizStep from '../QuizStep/QuizStep'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'

class QuizYesNo extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    value: PropTypes.bool,
    yesLabel: PropTypes.string.isRequired,
    noLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => undefined,
    stepLabel: null,
    disabled: false,
    value: null,
  }

  handleYesClick = () => {
    const { id, value, onChange, disabled } = this.props

    if (!disabled) {
      let newValue = true

      if (value === true) {
        newValue = null
      }

      onChange({ id, value: newValue })
    }
  }

  handleNoClick = () => {
    const { id, value, onChange, disabled } = this.props

    if (!disabled) {
      let newValue = false

      if (value === false) {
        newValue = null
      }

      onChange({ id, value: newValue })
    }
  }

  render() {
    const { stepLabel, label, stepOutline, value, id, yesLabel, noLabel, disabled } = this.props

    return (
      <div className={classnames('quiz-yes-no', disabled && 'disabled')}>
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <div className="buttons">
          <PrimaryButton
            label={yesLabel}
            id={`${id}_yes`}
            onClick={this.handleYesClick}
            outline={value === null || value === false}
          />
          <PrimaryButton
            label={noLabel}
            id={`${id}_no`}
            onClick={this.handleNoClick}
            outline={value === null || value === true}
          />
        </div>
      </div>
    )
  }
}

export default QuizYesNo
