import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../../atoms/Icon/Icon'

export default class Card extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    icon: PropTypes.oneOf(Object.values(Icon.icon)),
  }

  render() {
    const { children, title, icon, className } = this.props

    return (
      <div className={classnames('card', className)}>
        {title && (
          <div className="title">
            <Icon icon={icon} color={Icon.color.Accent} />
            {title}
          </div>
        )}

        <div className="content">{children}</div>
      </div>
    )
  }
}
