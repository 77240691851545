import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  user: null,
  cvdesignr_link: null,
  token: null,
  isBeneficiary: null,
  isLoading: false,
})

//
// Actions
//
export const actions = {
  login: createAction('login'),
  logout: createAction('logout', (force) => ({ force })),
  loginSuccess: createAction('loginSuccess', (user, token, persist) => ({ user, token, persist })),
  loginFailure: createAction('loginFailure', (data) => ({ data })),
  logoutSuccess: createAction('logoutSuccess'),
  forgotPassword: createAction('forgotPassword', (email) => ({ email })),
  resetPassword: createAction('resetPassword', (user_id, code, password) => ({
    user_id,
    code,
    password,
  })),
  sendInvitation: createAction('sendInvitation', (user_id, token) => ({ user_id, token })),
  setUser: createAction('setUser', (user) => ({ user })),
  setCvDesignrLink: createAction('setCvDesignrLink', (cvdesignr_link) => ({ cvdesignr_link })),
  getToken: createAction('getToken'),
  setToken: createAction('setToken', (token) => ({ token })),
  autoRefreshToken: createAction('autoRefreshToken'),
  refreshToken: createAction('refreshToken'),
  getCurrentUser: createAction('getCurrentUser'),
  exportAll: createAction('exportAll'),
  setIsLoading: createAction('setIsLoading'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setUser]: (state, { user }) => state.merge({ user }),
    [actions.setCvDesignrLink]: (state, { cvdesignr_link }) => state.merge({ cvdesignr_link }),
    [actions.setToken]: (state, { token }) => state.merge({ token }),
    [actions.loginSuccess]: (state, { user, token }) => state.merge({ user, token }),
    [actions.logoutSuccess]: (state) => state.merge({ user: null }),
    [actions.setIsLoading]: (state, isLoading) => state.merge({ isLoading }),
  },
  initialState,
)

//
// Selectors
//
const user = (state) => state.user.get('user')
const cvdesignr_link = (state) => state.user.get('cvdesignr_link')
const token = (state) => state.user.get('token')
const isBeneficiary = createSelector([user], (user) => (user ? user.role === 'beneficiary' : false))
const isLoading = (state) => state.user.get('isLoading')

export const selectors = {
  user,
  cvdesignr_link,
  token,
  isBeneficiary,
  isLoading,
}
