import ApiService from './ApiService'

const api = ApiService

export const get = ({ token, id }) => {
  return api.get(`/beneficiaries/${id}/notes`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const save = ({ token, id, ...data }) => {
  const body = {
    notes: data.notes || null,
  }

  return api.put(`/beneficiaries/${id}/notes`, body, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
