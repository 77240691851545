import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import ComboField from '../../../components/molecules/ComboField/ComboField'

import ActionPopin from '../../../components/organisms/ActionPopin/ActionPopin'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../../redux/BeneficiaryRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../../redux/ConsultantRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import Popin from '../../global/Popin/Popin'

import { withI18n } from '../../global/Translator/Translator'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  consultants: ConsultantSelectors.consultants(state),
  actionShare: WSSelectors.action(state, 'beneficiary.share'),
  actionUnshare: WSSelectors.action(state, 'beneficiary.unshare'),
})

const mapDispatchToProps = (dispatch) => ({
  getConsultants: () => dispatch(ConsultantActions.getConsultants({ notMe: true })),
  shareBeneficiary: (beneficiaryId, consultantId) =>
    dispatch(BeneficiaryActions.share(beneficiaryId, consultantId)),
  unshareBeneficiary: (beneficiaryId, consultantToUnshareId) =>
    dispatch(BeneficiaryActions.unshare(beneficiaryId, consultantToUnshareId)),
  resetActionShare: () => dispatch(WSActions.reset('beneficiary.share')),
  resetActionUnshare: () => dispatch(WSActions.reset('beneficiary.unshare')),
})

const formOptions = {
  form: 'share-beneficiary',
}

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm(formOptions)
export default class ConsultantShareBeneficiary extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    beneficiary: PropTypes.shape({ id: PropTypes.number }),
    consultants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }),
    ),
  }

  static getDerivedStateFromProps({ user, beneficiary, consultants }, state) {
    if (!beneficiary || !beneficiary.id) {
      return null
    }

    if (state.consultantId || state.consultantToUnshareId || consultants.length === 0) {
      return null
    }

    const userId = user.id
    const ids = beneficiary.consultants.map(({ id }) => id)
    const idsToShare = ids.slice(0).concat([userId])
    const idsToUnshare = ids.slice(0).filter((id) => id !== userId)

    const shareable = consultants.filter(({ id }) => !idsToShare.includes(id))
    const unshareable = consultants.filter(({ id }) => idsToUnshare.includes(id))

    return {
      consultants: shareable,
      alreadyShared: unshareable,
    }
  }

  state = {
    consultants: [],
    alreadyShared: [],
    consultantId: null,
    consultantToUnshareId: null,
    shareAction: null,
  }

  handleChangeShare = ({ id, value }) => {
    this.setState({
      consultantId: value,
      consultantToUnshareId: null,
      shareAction: 'share',
    })
  }

  handleChangeUnshare = ({ id, value }) => {
    this.setState({
      consultantId: null,
      consultantToUnshareId: value,
      shareAction: 'unshare',
    })
  }

  handleSubmit = () => {
    const { beneficiary } = this.props
    const { shareAction, consultantId, consultantToUnshareId } = this.state

    if (shareAction === 'share') {
      this.props.shareBeneficiary(beneficiary.id, consultantId)
    } else if (shareAction === 'unshare') {
      this.props.unshareBeneficiary(beneficiary.id, consultantToUnshareId)
    }
  }

  renderShareComboBox() {
    const { t } = this.props
    const { consultantId, consultants } = this.state
    const options = [{ value: 0, label: '' }].concat(
      consultants.map(({ id, first_name, last_name }) => ({
        value: id,
        label: `${first_name} ${last_name}`,
      })),
    )

    return (
      <ComboField
        name="consultant"
        title={t('beneficiary_sharing.share_to_consultant')}
        options={options}
        value={consultantId || 0}
        onChange={this.handleChangeShare}
      />
    )
  }

  renderUnshareComboBox() {
    const { t } = this.props
    const { consultantToUnshareId, alreadyShared } = this.state
    const options = [{ value: 0, label: '' }].concat(
      alreadyShared.map(({ id, first_name, last_name }) => ({
        value: id,
        label: `${first_name} ${last_name}`,
      })),
    )

    return (
      <ComboField
        name="consultant"
        title={t('beneficiary_sharing.unshare_consultant')}
        options={options}
        value={consultantToUnshareId || 0}
        onChange={this.handleChangeUnshare}
      />
    )
  }

  componentDidMount() {
    if (!this.props.consultants.length) {
      this.props.getConsultants()
    }
  }

  close() {
    this.props.onClose()
    this.setState({
      consultants: [],
      alreadyShared: [],
      consultantId: null,
      consultantToUnshareId: null,
    })
  }

  componentDidUpdate() {
    const { actionShare, actionUnshare, resetActionShare, resetActionUnshare } = this.props

    if (actionShare.resolved) {
      resetActionShare()
      this.close()
    }

    if (actionUnshare.resolved) {
      resetActionUnshare()
      this.close()
    }
  }

  render() {
    const { consultantId, consultantToUnshareId } = this.state
    const { t, onClose, open } = this.props
    const valid = consultantId || consultantToUnshareId

    return (
      <form className="consultant-share-beneficiary" noValidate>
        <Popin onClose={onClose} open={open}>
          <ActionPopin
            disabled={!valid}
            onSubmit={this.handleSubmit}
            onBack={onClose}
            title={t('beneficiary_sharing.share_my_beneficiary')}
            labelButton={t('actions.validate')}
          >
            {this.renderShareComboBox()}
            {this.renderUnshareComboBox()}
          </ActionPopin>
        </Popin>
      </form>
    )
  }
}
