import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import AdminCourseTemplatesPage from '../pages/AdminCourseTemplatesPage/AdminCourseTemplatesPage'
import AdminFormModulePreviewPage from '../pages/AdminFormModulePreviewPage/AdminFormModulePreviewPage'
import AdminImpersonatePage from '../pages/AdminImpersonatePage/AdminImpersonatePage'
import AdminModuleTemplatesPage from '../pages/AdminModuleTemplatesPage/AdminModuleTemplatesPage'
import AdminOfficesPage from '../pages/AdminOfficesPage/AdminOfficesPage'
import AdminResourcesPage from '../pages/AdminResourcesPage/AdminResourcesPage'
import AdminStatsPage from '../pages/AdminStatsPage/AdminStatsPage'
import AgendaPage from '../pages/AgendaPage/AgendaPage'
import BeneficiaryCoursePage from '../pages/BeneficiaryCoursePage/BeneficiaryCoursePage'
import BeneficiaryCoursesPage from '../pages/BeneficiaryCoursesPage/BeneficiaryCoursesPage'
import BeneficiaryCreationPage from '../pages/BeneficiaryCreationPage/BeneficiaryCreationPage'
import BeneficiaryExportCoursePage from '../pages/BeneficiaryExportCoursePage/BeneficiaryExportCoursePage'
import BeneficiaryFormModulePage from '../pages/BeneficiaryFormModulePage/BeneficiaryFormModulePage'
import BeneficiaryInValidationPage from '../pages/BeneficiaryInValidationPage/BeneficiaryInValidationPage'
import BeneficiaryProfileConsultantPage
  from '../pages/BeneficiaryProfileConsultantPage/BeneficiaryProfileConsultantPage'
import BeneficiaryWaitingPage from '../pages/BeneficiaryWaitingPage/BeneficiaryWaitingPage'
import ChangeBrowserPage from '../pages/ChangeBrowserPage/ChangeBrowserPage'
import {ConnectedRouter} from 'connected-react-router'
import ConsultantBeneficiaryAssessmentsPage
  from '../pages/ConsultantBeneficiaryAssessmentsPage/ConsultantBeneficiaryAssessmentsPage'
import ConsultantBeneficiaryCourseEditPage
  from '../pages/ConsultantBeneficiaryCourseEditPage/ConsultantBeneficiaryCourseEditPage'
import ConsultantBeneficiaryCoursesPage
  from '../pages/ConsultantBeneficiaryCoursesPage/ConsultantBeneficiaryCoursesPage'
import ConsultantBeneficiaryCvsPage from '../pages/ConsultantBeneficiaryCvsPage/ConsultantBeneficiaryCvsPage'
import ConsultantBeneficiaryFinalSynthesisPage
  from '../pages/ConsultantBeneficiaryFinalSynthesisPage/ConsultantBeneficiaryFinalSynthesisPage'
import ConsultantBeneficiaryFormModulePage
  from '../pages/ConsultantBeneficiaryFormModulePage/ConsultantBeneficiaryFormModulePage'
import ConsultantBeneficiaryMaestrosPage
  from '../pages/ConsultantBeneficiaryMaestrosPage/ConsultantBeneficiaryMaestrosPage'
import ConsultantBeneficiaryNewPage from '../pages/ConsultantBeneficiaryNewPage/ConsultantBeneficiaryNewPage'
import ConsultantBeneficiaryProfilePage
  from '../pages/ConsultantBeneficiaryProfilePage/ConsultantBeneficiaryProfilePage'
import ConsultantCourseTemplatesPage from '../pages/ConsultantCourseTemplatesPage/ConsultantCourseTemplatesPage'
import ConsultantCourseTemplatesPreviewPage
  from '../pages/ConsultantCourseTemplatesPreviewPage/ConsultantCourseTemplatesPreviewPage'
import ConsultantFormModulePreviewPage from '../pages/ConsultantFormModulePreviewPage/ConsultantFormModulePreviewPage'
import ConsultantModulesPage from '../pages/ConsultantModulesPage/ConsultantModulesPage'
import ConsultantMyBeneficiariesPage from '../pages/ConsultantMyBeneficiariesPage/ConsultantMyBeneficiariesPage'
import ConsultantProfilePage from '../pages/ConsultantProfilePage/ConsultantProfilePage'
import ConsultantResourcesPage from '../pages/ConsultantResourcesPage/ConsultantResourcesPage'
import CourseEditPage from '../pages/CourseEditPage/CourseEditPage'
import DeviceOptimizationPage from '../pages/DeviceOptimizationPage/DeviceOptimizationPage'
import DisableAccountPage from '../pages/DisableAccountPage/DisableAccountPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage'
import LoginPage from '../pages/LoginPage/LoginPage'
import LogoutPage from '../pages/LogoutPage/LogoutPage'
import ManagerBeneficiaryGroupsPage from '../pages/ManagerBeneficiaryGroupsPage/ManagerBeneficiaryGroupsPage'
import ManagerConsultantEditPage from '../pages/ManagerConsultantEditPage/ManagerConsultantEditPage'
import ManagerConsultantsPage from '../pages/ManagerConsultantsPage/ManagerConsultantsPage'
import ManagerOfficePage from '../pages/ManagerOfficePage/ManagerOfficePage'
import ManagerStatisticsBeneficiariesPage
  from '../pages/ManagerStatisticsBeneficiariesPage/ManagerStatisticsBeneficiariesPage'
import ManagerStatisticsOfficePage from '../pages/ManagerStatisticsOfficePage/ManagerStatisticsOfficePage'
import MessagePage from '../pages/MessagePage/MessagePage'
import MessagesPage from '../pages/MessagesPage/MessagesPage'
import ModuleAppointmentEditPage from '../pages/ModuleAppointmentEditPage/ModuleAppointmentEditPage'
import ModuleEditPage from '../pages/ModuleEditPage/ModuleEditPage'
import ModuleSynthesisEditPage from '../pages/ModuleSynthesisEditPage/ModuleSynthesisEditPage'
import OAuthCallbackPage from '../pages/OAuthCallbackPage/OAuthCallbackPage'
import PortalForgotPasswordPage from '../pages/PortalForgotPasswordPage/PortalForgotPasswordPage'
import PortalLoginPage from '../pages/PortalLoginPage/PortalLoginPage'
import PrivateRoute from './PrivateRoute'
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage'
import {history} from './History'

const NavigationRouter = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/device-optimization" component={DeviceOptimizationPage}/>
        
        <Route exact path="/change-browser" component={ChangeBrowserPage}/>

        <Route exact path="/oauth_callback" component={OAuthCallbackPage}/>

        <Route exact path="/auth/login" component={LoginPage}/>

        <Route exact path="/auth/logout" component={LogoutPage}/>

        <Route exact path="/auth/forgot-password" component={ForgotPasswordPage}/>

        <Route exact path="/auth/reset-password" component={ResetPasswordPage}/>

        <Route exact path="/auth/disable-account" component={DisableAccountPage}/>

        <Redirect exact from="/admin" to="/admin/offices"/>

        <PrivateRoute exact path="/admin/offices" component={AdminOfficesPage}/>

        <PrivateRoute exact path="/admin/offices/:office_id" component={AdminOfficesPage}/>

        <PrivateRoute exact path="/admin/templates/courses" component={AdminCourseTemplatesPage}/>

        <PrivateRoute exact path="/admin/templates/courses/:course_id" component={CourseEditPage}/>

        <PrivateRoute exact path="/admin/templates/courses/:course_id/remove" component={AdminCourseTemplatesPage}/>

        <PrivateRoute
          exact
          path="/admin/templates/courses/:course_id/modules/:module_id"
          component={ModuleEditPage}
        />

        <PrivateRoute exact path="/admin/templates/modules" component={AdminModuleTemplatesPage}/>

        <PrivateRoute exact path="/admin/templates/modules/:module_id" component={ModuleEditPage}/>

        <PrivateRoute
          exact
          path="/admin/templates/modules/forms/:form_id/preview"
          component={AdminFormModulePreviewPage}
        />

        <PrivateRoute exact path="/admin/templates/resources" component={AdminResourcesPage}/>

        <PrivateRoute exact path="/admin/stats" component={AdminStatsPage}/>

        <PrivateRoute exact path="/admin/impersonate" component={AdminImpersonatePage}/>

        <Redirect exact from="/manager" to="/manager/consultants"/>

        <Redirect exact from="/manager/statistics" to="/manager/statistics/office"/>

        <PrivateRoute exact path="/manager/consultants" component={ManagerConsultantsPage}/>

        <PrivateRoute
          exact
          path="/manager/consultants/:consultant_id"
          component={ManagerConsultantEditPage}
        />
        <PrivateRoute exact path="/manager/groups" component={ManagerBeneficiaryGroupsPage}/>

        <PrivateRoute
          exact
          path="/manager/groups/:group_id"
          component={ManagerBeneficiaryGroupsPage}
        />

        <PrivateRoute
          exact
          path="/manager/groups/:group_id/remove"
          component={ManagerBeneficiaryGroupsPage}
        />
        <PrivateRoute exact path="/manager/office" component={ManagerOfficePage}/>

        <PrivateRoute
          exact
          path="/manager/statistics/office"
          component={ManagerStatisticsOfficePage}
        />

        <PrivateRoute
          exact
          path="/manager/statistics/beneficiaries"
          component={ManagerStatisticsBeneficiariesPage}
        />

        <Redirect exact from="/consultant" to="/consultant/beneficiaries"/>

        <Redirect exact from="/consultant/beneficiaries" to="/consultant/beneficiaries/me"/>

        <Redirect exact from="/manager/beneficiaries" to="/manager/beneficiaries/me"/>

        <PrivateRoute exact path="/(consultant|manager)/beneficiaries/me" component={ConsultantMyBeneficiariesPage}/>

        <PrivateRoute
          exact
          path="/(consultant|manager)/beneficiaries/new"
          component={ConsultantBeneficiaryNewPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id',
          ]}
          component={ConsultantBeneficiaryCoursesPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses',
          ]}
          component={ConsultantBeneficiaryCoursesPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id/remove',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id/remove',
          ]}
          component={ConsultantBeneficiaryCoursesPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id',
          ]}
          component={ConsultantBeneficiaryCourseEditPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id/forms/:form_id',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id/forms/:form_id',
          ]}
          component={ConsultantBeneficiaryFormModulePage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id/modules/appointment/:module_id?',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id/modules/appointment/:module_id?',
          ]}
          component={ModuleAppointmentEditPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id/modules/synthesis',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id/modules/synthesis',
          ]}
          component={ModuleSynthesisEditPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/courses/:course_id/modules/:type',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/courses/:course_id/modules/:type',
          ]}
          component={ModuleEditPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/profile',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/profile',
          ]}
          component={ConsultantBeneficiaryProfilePage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/assessments',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/assessments',
          ]}
          component={ConsultantBeneficiaryAssessmentsPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/cvs',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/cvs',
          ]}
          component={ConsultantBeneficiaryCvsPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/maestros',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/maestros',
          ]}
          component={ConsultantBeneficiaryMaestrosPage}
        />

        <PrivateRoute
          exact
          path={[
            '/(manager|consultant)/beneficiaries/:user_id/synthesis',
            '/manager/consultants/:consultant_id/beneficiaries/:user_id/synthesis',
          ]}
          component={ConsultantBeneficiaryFinalSynthesisPage}
        />

        <PrivateRoute exact path="/consultant/profile" component={ConsultantProfilePage}/>
        <PrivateRoute exact path="/consultant/messages" component={MessagesPage}/>
        <PrivateRoute exact path="/consultant/messages/new" component={MessagesPage}/>
        <PrivateRoute exact path="/consultant/messages/:message_id" component={MessagePage}/>
        <PrivateRoute exact path="/consultant/calendar" component={AgendaPage}/>

        <PrivateRoute
          exact
          path="/consultant/templates/courses"
          component={ConsultantCourseTemplatesPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id"
          component={CourseEditPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id/remove"
          component={ConsultantCourseTemplatesPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id/preview"
          component={ConsultantCourseTemplatesPreviewPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id/preview/forms/:form_id"
          component={ConsultantFormModulePreviewPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id/modules/appointment"
          component={ModuleAppointmentEditPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/courses/:course_id/modules/:module_id"
          component={ModuleEditPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/modules"
          component={ConsultantModulesPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/modules/:module_id"
          component={ModuleEditPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/modules/:module_id"
          component={ModuleEditPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/modules/forms/:form_id/preview"
          component={ConsultantFormModulePreviewPage}
        />

        <PrivateRoute
          exact
          path="/consultant/templates/resources"
          component={ConsultantResourcesPage}
        />

        <Redirect exact from="/beneficiary" to="/beneficiary/courses"/>

        <PrivateRoute exact path="/beneficiary/creation" component={BeneficiaryCreationPage}/>

        <PrivateRoute exact path="/beneficiary/waiting" component={BeneficiaryWaitingPage}/>

        <PrivateRoute
          exact
          path="/beneficiary/invalidation"
          component={BeneficiaryInValidationPage}
        />

        <PrivateRoute
          exact
          path="/beneficiary/profile"
          component={BeneficiaryProfileConsultantPage}
        />

        <PrivateRoute
          exact
          path="/beneficiary/profile/consultant"
          component={BeneficiaryProfileConsultantPage}
        />

        <PrivateRoute exact path="/beneficiary/courses" component={BeneficiaryCoursesPage}/>

        <PrivateRoute
          exact
          path="/beneficiary/courses/:course_id"
          component={BeneficiaryCoursePage}
        />

        <PrivateRoute
          exact
          path="/beneficiary/courses/:course_id/export"
          component={BeneficiaryExportCoursePage}
        />

        <PrivateRoute
          exact
          path="/beneficiary/courses/:course_id/forms/:form_id"
          component={BeneficiaryFormModulePage}
        />

        <PrivateRoute
          exact
          path="/beneficiary/courses/:course_id/modules/synthesis"
          component={ModuleSynthesisEditPage}
        />

        <PrivateRoute exact path="/beneficiary/messages" component={MessagesPage}/>

        <PrivateRoute exact path="/beneficiary/messages/new" component={MessagesPage}/>

        <PrivateRoute exact path="/beneficiary/messages/:message_id" component={MessagePage}/>

        <PrivateRoute exact path="/beneficiary/calendar" component={AgendaPage}/>

        <Route exact path="/:office/auth/login" component={PortalLoginPage}/>

        <Route exact path="/:office/auth/forgot-password" component={PortalForgotPasswordPage}/>

        <Redirect to="/auth/login"/>
      </Switch>
    </ConnectedRouter>
  )
}

export default NavigationRouter
