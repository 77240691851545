import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'

import { withI18n } from '../../global/Translator/Translator'
import Popin from '../../global/Popin/Popin'

import ParcoursAttribPopin from '../../../components/organisms/ParcoursAttribPopin/ParcoursAttribPopin'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  templates: CourseTemplateSelectors.templates(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(CourseTemplateActions.getTemplates(filter)),
  assignTemplate: (ids) => dispatch(CourseTemplateActions.assignTemplate(ids)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AttributeCoursePopin extends Component {
  static propTypes = {
    t: PropTypes.func,
    open: PropTypes.bool.isRequired,
  }

  state = {
    title: '',
    scope: 'consultant',
    course_id: null,
  }

  handleAddComboChange = ({ value }) => {
    this.setState({ scope: value }, this.getTemplates)
  }

  handleSearchChange = ({ value }) => {
    this.setState({ title: value }, this.getTemplates)
  }

  handleClosePopin = () => {
    this.setState({ title: '', scope: 'consultant', course_id: '' }, this.props.onClose)
  }

  handleSubmitAttribute = () => {
    const { beneficiary } = this.props
    const { course_id } = this.state

    this.props.assignTemplate({
      course_id,
      beneficiary_id: beneficiary.id,
    })

    this.handleClosePopin()
  }

  handleOnCourseSelect = (id) => {
    this.setState({ course_id: id })
  }

  getTemplates = () => {
    const { title, scope } = this.state

    this.props.getTemplates({ title, scope })
  }

  componentDidMount() {
    this.getTemplates()
  }

  render() {
    const { t, open } = this.props

    const options = [
      { label: t('course_template.scope_filter.consultant'), value: 'consultant' },
      { label: t('course_template.scope_filter.office'), value: 'office' },
      { label: t('course_template.scope_filter.mytalents'), value: 'mytalents' },
    ]

    const locale = {
      title: t('consultant.attribute_course'),
      cta: t('actions.attribute'),
      step: { step: t('step.step'), steps: t('step.steps') },
      module: { module: t('module.module'), modules: t('module.modules') },
    }

    return (
      <Popin onClose={this.handleClosePopin} open={open}>
        <div className="attribute-course-popin">
          <ParcoursAttribPopin
            options={options}
            onSubmit={this.handleSubmitAttribute}
            onBack={this.handleClosePopin}
            locale={locale}
            searchValue={this.state.title}
            onSearchChange={this.handleSearchChange}
            courses={this.props.templates}
            onComboChange={this.handleAddComboChange}
            comboValue={this.state.scope}
            onCourseSelect={this.handleOnCourseSelect}
            selectedCourse={this.state.course_id}
            disableButton={!this.state.course_id}
          />
        </div>
      </Popin>
    )
  }
}
