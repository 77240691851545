import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PasswordPopin from '../PasswordPopin/PasswordPopin'
import ResetPasswordForm from '../../../containers/auth/ResetPasswordForm/ResetPasswordForm'

export default class ResetPasswordPopin extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    termsUrl: PropTypes.string.isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    disabled: false,
    onSubmit: ({ password }) => undefined,
  }

  handleSubmit = (values) => {
    this.props.onSubmit(values)
  }

  render() {
    const { locale, disabled, termsUrl, isNew } = this.props

    return (
      <PasswordPopin
        onClose={this.props.onClose}
        title={locale.title}
        description={locale.description}
      >
        <ResetPasswordForm
          disabled={disabled}
          terms={locale.terms}
          isNew={isNew}
          termsUrl={termsUrl}
          onSubmit={this.handleSubmit}
        />
      </PasswordPopin>
    )
  }
}
