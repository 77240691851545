import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CandidatCard from '../../../components/organisms/CandidatCard/CandidatCard'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import ManagerConsultantBeneficiaryAttribute from '../../manager/ManagerConsultantBeneficiaryAttribute/ManagerConsultantBeneficiaryAttribute'
import { selectors as WSSelectors } from '../../../redux/WSRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../../redux/ConsultantRedux'
import { useI18n } from '../../../hooks/useI18n'

const financerState = {
  to_fill: 'to_fill',
  pending: 'pending',
  done: 'done',
}

const BeneficiaryList = ({ onDeleteClick, beneficiaries, isPrimary }) => {
  const dispatch = useDispatch()
  const [beneficiary, setBeneficiary] = useState(null)
  const [consultant, setConsultant] = useState(null)
  const [popinOpen, setPopinOpen] = useState(false)
  const { t } = useI18n()
  const user = useSelector(UserSelectors.user)
  const pending = useSelector((state) => WSSelectors.pending(state, 'beneficiary.attribute'))
  const consultants = useSelector(ConsultantSelectors.consultants)
  const pathname = useSelector(NavigationSelectors.pathname)

  useEffect(() => {
    dispatch(ConsultantActions.getConsultants())
  }, [dispatch])

  const redirect = (destination) => {
    dispatch(NavigationActions.push(destination))
  }

  const checkAccompanimentType = (type) => {
    return t(`beneficiary_accompaniment_types.${type}`)
  }

  const checkFinancerState = (financer_state) => {
    let color

    switch (financer_state) {
      case financerState.to_fill:
        color = 'red1'
        break
      case financerState.pending:
        color = 'orange1'
        break
      case financerState.done:
      default:
        color = 'green1'
    }

    return {
      label: t(`beneficiary_financer_states.${financer_state}`),
      color,
    }
  }

  const handleBeneficiaryClick = (id) => {
    redirect(pathname.replace(/\w+$/, `${id}/courses`))
  }

  const handleNotificationsClick = () => {
    redirect('/consultant/messages?tab=notifications')
  }

  const handleMessagesClick = () => {
    redirect('/consultant/messages')
  }

  const handleDeleteClick = ({ id }) => {
    onDeleteClick({ id })
  }

  const handleSelectBeneficiary = (value) => {
    setPopinOpen(true)

    const selectedId = value && Number(value.id)
    const _beneficiary = value ? beneficiaries.find(({ id }) => id === selectedId) : null
    const primary = _beneficiary
      ? _beneficiary.consultants.find(({ is_primary }) => is_primary)
      : null

    const _consultant = primary ? consultants.find(({ id }) => id === primary.id) : null

    const primaryConsultant = _consultant || primary

    if (primaryConsultant && !primaryConsultant.beneficiaries) {
      primaryConsultant.beneficiaries = []
    }

    setBeneficiary(_beneficiary)
    setConsultant(primaryConsultant)
  }

  const renderAttributePopin = () => {
    const close = () => {
      setPopinOpen(false)
    }

    return (
      <ManagerConsultantBeneficiaryAttribute
        isOpen={popinOpen}
        beneficiary={beneficiary}
        _consultant={consultant}
        onClose={close}
        pending={pending}
      />
    )
  }

  const renderBeneficiaries = () => {
    const locale = {
      financeStateLabel: t('financer.financer_state'),
      lengthLabel: t('accompaniment.duration'),
      reasonLabel: t('beneficiary_card.reason'),
      typeOfAccompanimentLabel: t('accompaniment.accompaniment_type'),
      programFollowedLabel: t('beneficiary_card.enrolled_course'),
      nextAppointmentLabel: t('beneficiary_card.next_appointment'),
    }

    return beneficiaries.map((beneficiary, i) => {
      const {
        status,
        first_name,
        last_name,
        email,
        phone_mobile,
        image_url,
        accompaniment_duration,
        accompaniment_duration_type,
        financer_state,
        accompaniment_type,
        phone_home,
        archived_reason = null,
        id,
        next_rdv,
        course,
        count_notifications,
        count_messages,
      } = beneficiary

      const courseLength = `${Number.parseInt(accompaniment_duration, 10)} ${t(
        `beneficiary_accompaniment_duration_types.${accompaniment_duration_type}`,
      )}`
      const typeOfAccompaniment = checkAccompanimentType(accompaniment_type)
      const financerState = checkFinancerState(financer_state)
      const primaryConsultant = beneficiary.consultants.find(({ is_primary }) => is_primary)

      return (
        <CandidatCard
          key={i}
          id={id}
          status={{ status, label: t(`beneficiary.statuses.${status}`) }}
          name={`${first_name} ${last_name}`}
          email={email}
          phone={phone_mobile || phone_home || ''}
          avatar={image_url || ''}
          locale={locale}
          color={financerState.color}
          length={courseLength}
          accompaniment_type={typeOfAccompaniment}
          financer_state={financerState.label}
          percentage={course && course.percentage_doneAll}
          percentageNb={course && course.percentage_doneAllNb}
          programFollowed={course && course.title}
          mail={!!count_messages}
          notification={!!count_notifications}
          reason={archived_reason ? t(`beneficiary_archived_reasons.${archived_reason}`) : ''}
          onClick={handleBeneficiaryClick}
          onNotificationsClick={handleNotificationsClick}
          onMessagesClick={handleMessagesClick}
          onDeleteClick={handleDeleteClick}
          nextAppointment={next_rdv}
          isDeletable={isPrimary}
          isManager={user.role === 'manager'}
          onAttributeClick={handleSelectBeneficiary}
          attributeLabelButton={t('beneficiary.attribute')}
          attributeReadonly
          primaryConsultant={primaryConsultant}
          primaryConsultantLabel={t(`beneficiary.primary_consultant`)}
          noPrimaryConsultantLabel={t(`beneficiary.no_primary_consultant`)}
          primaryConsultantDeleted={!primaryConsultant || !!primaryConsultant.deleted_at}
          primaryConsultantName={
            primaryConsultant
              ? `${primaryConsultant.first_name} ${primaryConsultant.last_name}`
              : t('beneficiary.orphan')
          }
        />
      )
    })
  }

  return (
    <div className="beneficiary-list">
      {renderBeneficiaries()}
      {renderAttributePopin()}
    </div>
  )
}

BeneficiaryList.propTypes = {
  t: PropTypes.func,
  beneficiaries: PropTypes.array.isRequired,
  isPrimary: PropTypes.bool,
  onDeleteClick: PropTypes.func,
}

BeneficiaryList.defaultProps = {
  onDeleteClick: () => undefined,
  isPrimary: false,
}

export default BeneficiaryList
