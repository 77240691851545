import { Map } from 'immutable'
import { createAction, createReducer } from 'redux-act'

//
// Initial state
//
const initialState = Map({
  module: null,
})

//
// Actions
//
export const actions = {
  getModule: createAction('getModule', (module_id) => ({ module_id })),
  setModule: createAction('setModule', (module) => ({ module })),
  saveModuleCompleteStatus: createAction('saveModuleCompleteStatus', ({ module_id, done }) => ({
    module_id,
    done,
  })),
  saveSynthesisNotes: createAction('saveSynthesisNotes', ({ template, notes }) => ({
    template,
    notes,
  })),
  saveModuleFormAnswers: createAction('saveModuleFormAnswers', ({ module_id, answers }) => ({
    module_id,
    answers,
  })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.getModule]: (state) => state.merge({ module: null }),
    [actions.setModule]: (state, { module }) => state.merge({ module }),
  },
  initialState,
)

//
// Selectors
//
const getModule = (state) => state.module.get('module')

export const selectors = {
  module: getModule,
}
