import {put} from 'redux-saga/effects'
import {I18n} from 'react-redux-i18n'

import {actions as NotificationActions} from '../redux/NotificationRedux'

export function* status({payload}) {
  const {ok} = payload
  const type = ok ? 'success' : 'error'
  const text = getStatusMessage(payload)

  yield put(NotificationActions.add(type, text))
}

export function getStatusMessage({data, ok}) {
  if (data && data.message) {
    return data.message
  }

  return ok
    ? I18n.t(`notifications.success`)
    : getErrorMessage(data) || I18n.t(`notifications.error`)
}

export function getErrorMessage(data) {
  const [error] = Array.isArray(data) ? data : []
  const {validation, field, message} = error || {}

  if (validation) {
    switch (validation) {
      case 'unique':
        return field === 'email' && I18n.t(`form_errors.email_already_used`)
      default:
        return I18n.t(`notifications.error`)
    }
  }

  if (message) {
    if(message.startsWith('centraltest')){
      return I18n.t(message)
    }

    switch (message) {
      case 'manager/too-many-consultants':
        return I18n.t(`notifications.errors.too_many_consultants`)
      case 'module/module-already-done':
        return I18n.t(`notifications.errors.module_already_done`)
      case 'upload/forbidden':
        return I18n.t(`notifications.errors.upload_forbidden`)
      case 'module-template/not-a-template':
        return I18n.t(`notifications.errors.not_a_template`)
      case 'auth/revoked':
        return I18n.t('notifications.errors.auth_revoked')
      case 'beneficiary/forbidden':
        return I18n.t('notifications.errors.beneficiary_forbidden')
      case 'file/forbidden':
        return I18n.t('notifications.errors.file_forbidden')
      case 'course-template/forbidden':
        return I18n.t('notifications.errors.course_template_forbidden')
      case 'module-done/remain-required-questions':
        return I18n.t('notifications.errors.module_done_remain_required_questions')
      default:
        return I18n.t(`notifications.error`)
    }
  }
}
