import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change as changeValue, isValid, reduxForm } from 'redux-form'
import { TimePicker } from 'antd'
import ComboField from '../../components/molecules/ComboField/ComboFieldContainer'
import DateField from '../../components/molecules/DateField/DateFieldContainer'
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextAreaContainer'
import InputField from '../../components/molecules/InputField/InputFieldContainer'
import NotesArea from '../../components/molecules/NotesArea/NotesAreaContainer'
import { withI18n } from '../../containers/global/Translator/Translator'
import { asyncValidate } from '../../helpers/form/FormValidator/FormValidator'

import 'antd/dist/antd.css'
import CheckBoxField from '../../components/molecules/CheckBoxField/CheckBoxFieldContainer'

const mapStateToProps = (state, props) => ({
  valid: isValid('module-appointment')(state),
  initialValues: {
    title: props.module.title,
    description: props.module.description,
    user_from: props.module.user_from,
    date: props.module.rdv_at
      ? moment(props.module.rdv_at).format('DD/MM/YYYY')
      : moment().format('DD/MM/YYYY'),
    rdv_at: props.module.rdv_at ? moment(props.module.rdv_at).seconds(0) : moment().seconds(0),
    rdv_to: props.module.rdv_to
      ? moment(props.module.rdv_to).seconds(0)
      : moment().seconds(0).add(2, 'hours'),
    private_notes: props.module.private_notes,
    public_notes: props.module.public_notes,
    isNew: props.module.isNew || false,
    is_videocall: props.module.is_videocall,
  },
})

const mapDispatchToProps = (dispatch) => ({
  changeValue: (field, value) => dispatch(changeValue('module-appointment', field, value)),
})

const formOptions = {
  form: 'module-appointment',
  asyncChangeFields: ['title', 'date', 'rdv_at', 'rdv_to'],
  asyncValidate: (values, dispatch, { t }) =>
    asyncValidate(values, {
      title: { rule: 'required' },
      date: { rule: 'required|dateWithFormat:DD/MM/YYYY|dateAfterNow:DD/MM/YYYY' },
      rdv_at: { rule: 'required' },
      rdv_to: {
        rule: `required|above:${values.rdv_at}`,
        message: t('form_errors.dateAfter'),
      },
    }),
}

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm(formOptions)
export default class ModuleAppointmentEditForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    consultants: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isTemplate: PropTypes.bool,
    module: PropTypes.shape({
      status: PropTypes.string,
      is_videocall: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      user_from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rdv_at: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      rdv_to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      private_notes: PropTypes.string,
      public_notes: PropTypes.string,
      isNew: PropTypes.bool,
    }),
    changeValue: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onChange: ({ id, value }) => undefined,
  }

  state = {}

  componentDidMount() {
    this.setState(this.props.initialValues)
  }

  handleDateChange = ({ id, value: _value }) => {
    const dateFormat = 'DD/MM/YYYY'
    let value = _value

    if (id === 'date') {
      value = moment(value, dateFormat)

      if (value.toDate().toString() === 'Invalid Date') {
        return
      }
    }

    const rdvAt = moment(value).hours(moment(this.state.rdv_at).hours())
    const rdvTo = moment(value).hours(moment(this.state.rdv_to).hours())

    this.setState({
      date: value,
      rdv_at: rdvAt,
      rdv_to: rdvTo,
    })
    this.handleChange({
      id: 'rdv_at',
      value: rdvAt,
    })
    this.handleChange({
      id: 'rdv_to',
      value: rdvTo,
    })
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  renderDescription() {
    const { t } = this.props

    return (
      <div className="description">
        <DescriptionTextArea
          maxLength={500}
          name="description"
          placeholder={t('module.description')}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderModuleInformations() {
    const { t, consultants, module, isTemplate } = this.props

    return (
      <div className="informations">
        <InputField
          onChange={this.handleChange}
          name="title"
          title={t('module.appointment.title')}
          required
        />

        {!isTemplate && (
          <ComboField
            onChange={this.handleChange}
            name="user_from"
            title={t('consultant.consultant')}
            type="number"
            options={consultants}
            value={module.user_from}
          />
        )}
      </div>
    )
  }

  onRdvAtTimePickerChange(time) {
    this.setState({
      rdv_at: time,
    })
    this.handleChange({
      id: 'rdv_at',
      value: time.toISOString(),
    })
  }

  openChangeRdvAt(open) {
    if (!open) {
      this.onRdvToTimePickerChange(moment(this.state.rdv_at).add(2, 'hours'))
    }
  }

  onRdvToTimePickerChange(time) {
    this.setState({
      rdv_to: time,
    })
    this.handleChange({
      id: 'rdv_to',
      value: time.toISOString(),
    })
  }

  openChangeRdvTo(open) {
    if (
      !open &&
      this.state.rdv_at.minutes() + this.state.rdv_at.hours() * 60 >=
        this.state.rdv_to.minutes() + this.state.rdv_to.hours() * 60
    ) {
      this.onRdvAtTimePickerChange(moment(this.state.rdv_to).subtract(2, 'hours'))
    }
  }

  renderDateInformations() {
    const { t, isTemplate } = this.props

    if (isTemplate) {
      return
    }

    const calendarLabels = {
      dayShortLabels: Object.values(t('calendar_labels.day_short_labels')),
      monthLabels: Object.values(t('calendar_labels.month_labels')),
    }

    return (
      <div className="dates">
        <DateField
          required
          onChange={this.handleDateChange}
          name="date"
          title={t('module.appointment.date')}
          calendarLabels={calendarLabels}
          disablePreviousToday
        />

        <div className="combo-field">
          <div className="common-form-label">Heure de début *</div>
          <TimePicker
            value={moment(this.state.rdv_at)}
            format="HH:mm"
            minuteStep={5}
            disabledHours={() => {
              return [0, 1, 2, 3, 4, 5, 22, 23]
            }}
            onChange={this.onRdvAtTimePickerChange.bind(this)}
            allowClear={false}
            suffixIcon={null}
            onOpenChange={this.openChangeRdvAt.bind(this)}
          />
        </div>

        <div className="combo-field">
          <div className="common-form-label">Heure de fin *</div>
          <TimePicker
            value={moment(this.state.rdv_to)}
            format="HH:mm"
            minuteStep={5}
            disabledHours={() => {
              return [0, 1, 2, 3, 4, 5, 22, 23]
            }}
            onChange={this.onRdvToTimePickerChange.bind(this)}
            allowClear={false}
            suffixIcon={null}
            onOpenChange={this.openChangeRdvTo.bind(this)}
          />
        </div>
      </div>
    )
  }

  renderNotes() {
    const { t, isTemplate } = this.props

    if (isTemplate) {
      return
    }

    return (
      <div className="notes">
        <NotesArea
          onChange={this.handleChange}
          isPrivate
          title={t('notes.private_notes')}
          placeholder={t('notes.write_your_notes')}
          name="private_notes"
        />

        <NotesArea
          onChange={this.handleChange}
          title={t('notes.public_notes')}
          placeholder={t('notes.write_your_notes')}
          name="public_notes"
        />
      </div>
    )
  }

  renderControls() {
    const { isTemplate } = this.props

    if (isTemplate) {
      return
    }

    return (
      <div className="dates">
        <div className="combo-field">
          <CheckBoxField
            label="Rendez-vous en visio-conférence"
            name="is_videocall"
            onChange={this.handleChange}
            id="is_videocall"
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <form className="module-appointment-edit-form" noValidate>
        {this.renderDescription()}
        {this.renderModuleInformations()}
        {this.renderDateInformations()}
        {this.renderControls()}
        {this.renderNotes()}
      </form>
    )
  }
}
