import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Scrollchor from 'react-scrollchor'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import BigCombo from '../../components/molecules/BigCombo/BigCombo'
import InfoPage from '../../components/molecules/InfoPage/InfoPage'
import SectionTitle from '../../components/molecules/SectionTitle/SectionTitle'
import BeneficiaryList from '../../containers/beneficiary/BeneficiaryList/BeneficiaryList'
import ConsultantBeneficiaryRemove from '../../containers/consultant/ConsultantBeneficiaryRemove/ConsultantBeneficiaryRemove'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelector,
} from '../../redux/ConsultantRedux'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { useI18n } from '../../hooks/useI18n'
import { useNavigation } from '../../hooks/useNavigation'

const ConsultantModulesPage = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const { pathname, redirect } = useNavigation()
  const hash = useSelector(NavigationSelectors.hash)
  const beneficiaries = useSelector(BeneficiarySelectors.beneficiaries)
  const beneficiariesFilter = useSelector(ConsultantSelector.beneficiariesFilter)
  const user = useSelector(UserSelectors.user)
  const [beneficiaryToDelete, setBeneficiariesToDelete] = useState()

  const sortOptions = [
    {
      label: t('beneficiaries.sort.alpha_desc'),
      value: 'first_name_desc',
    },
    {
      label: t('beneficiaries.sort.alpha_asc'),
      value: 'first_name_asc',
    },
  ]

  const sortQuery = {
    first_name_asc: {
      direction: 'asc',
      attribute: 'first_name',
    },
    first_name_desc: {
      direction: 'desc',
      attribute: 'first_name',
    },
  }

  const scrollRef = useRef()

  const handleAddNew = () => {
    redirect(pathname?.replace('/me', '/new'))
  }

  const handleExport = () => {
    dispatch(BeneficiaryActions.exportBeneficiaries(beneficiariesFilter))
  }

  const handleSearch = (value) => {
    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        q: value,
      }),
    )
  }

  const handleBigComboChange = ({ value }) => {
    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        status: value.value,
      }),
    )
  }

  const handleDeleteBeneficiary = ({ id }) => {
    setBeneficiariesToDelete(id)
  }

  const handleCloseConfirmRemovePopin = () => {
    setBeneficiariesToDelete(null)
  }

  const handleSortChange = ({ value }) => {
    const { attribute, direction } = sortQuery[value?.value]

    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        orderAttribute: attribute,
        orderDirection: direction,
      }),
    )
  }

  useEffect(() => {
    dispatch(BeneficiaryActions.getBeneficiaries(beneficiariesFilter))
  }, [beneficiariesFilter, dispatch])

  useEffect(() => {
    if (hash && document.getElementById(hash)) {
      // eslint-disable-next-line no-unused-expressions
      scrollRef.current?.simulateClick()
    }
  }, [hash])

  const renderHeader = () => {
    const statuses = { '': t('beneficiary.status_all'), ...t('beneficiary.statuses') }

    const options = Object.keys(statuses).map((key) => ({
      value: key,
      label: statuses[key],
    }))

    const selected = options.find(({ value }) => value === beneficiariesFilter.status)

    return (
      <div className="header">
        <BigCombo value={selected} options={options} onChange={handleBigComboChange} />
        <BigCombo
          options={sortOptions}
          onChange={handleSortChange}
          dropDownProps={{
            placeholder: t('beneficiaries.sort.placeholder'),
          }}
        />

        <TopSearch value={beneficiariesFilter.q} onSearch={handleSearch} debounceDuration={0} />
        <span className="separator" />
      </div>
    )
  }

  const renderRemovePopin = () => {
    if (!beneficiaryToDelete) {
      return null
    }

    return (
      <ConsultantBeneficiaryRemove
        id={beneficiaryToDelete}
        onClose={handleCloseConfirmRemovePopin}
      />
    )
  }

  const renderContent = () => {
    const myBeneficiaries = []
    const sharedBeneficiaries = []

    beneficiaries.forEach((benef) => {
      if (user.role === 'manager') {
        myBeneficiaries.push(benef)
        return true
      }
      const isPrimary = benef.consultants.some(({ id, is_primary }) => is_primary && id === user.id)

      if (isPrimary) {
        myBeneficiaries.push(benef)
      } else {
        sharedBeneficiaries.push(benef)
      }
    })

    return (
      <>
        <div className="header-actions">
          <CircleButton icon="plus" onClick={handleAddNew} />
          {myBeneficiaries.length > 0 && (
            <PrimaryButton
              id="beneficiaries-list-export"
              label={t('actions.export')}
              onClick={handleExport}
            />
          )}
        </div>

        {renderRemovePopin()}

        <div id="primary" className="my-beneficiaries">
          {myBeneficiaries.length > 0 && (
            <>
              {user.role === 'consultant' && (
                <SectionTitle label={t('consultant.my_beneficiaries')} />
              )}
              <BeneficiaryList
                beneficiaries={myBeneficiaries}
                onDeleteClick={handleDeleteBeneficiary}
                isPrimary
              />
            </>
          )}
        </div>

        {sharedBeneficiaries.length > 0 && (
          <div id="shared" className="shared-beneficiaries">
            <SectionTitle label={t('consultant.shared_beneficiaries')} />

            {sharedBeneficiaries.length > 0 && (
              <BeneficiaryList beneficiaries={sharedBeneficiaries} />
            )}

            {sharedBeneficiaries.length === 0 && (
              <InfoPage text={t('consultant.no_shared_beneficiaries')} />
            )}
          </div>
        )}
      </>
    )
  }

  const aside = user.role === 'consultant' ? <ConsultantPageAside /> : <ManagerPageAside />

  const content = <TabbedContentLayout header={renderHeader()} content={renderContent()} />

  if (!user) {
    return <></>
  }

  return (
    <div className="page">
      <div className="consultant-my-beneficiaries-page">
        <Scrollchor ref={scrollRef} to={hash} />
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    </div>
  )
}

export default ConsultantModulesPage
