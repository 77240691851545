import {call, put, select} from 'redux-saga/effects'
import {actions as AppActions} from '../redux/AppRedux'
import {actions as CourseActions, selectors as CourseSelectors} from '../redux/CourseRedux'
import {actions as CourseTemplateActions, selectors as CourseTemplateSelectors,} from '../redux/CourseTemplateRedux'
import {actions as NavigationActions} from '../redux/NavigationRedux'
import {actions as NotificationActions} from '../redux/NotificationRedux'
import {services} from '../services'
import {getCoursesForBeneficiary} from './CourseSaga'

export function* duplicateTemplate({payload}) {
  const {id, add = true, filters = {}} = payload

  yield put(AppActions.setLoading(true))
  yield call(services.courseTemplate.duplicate, {id}, function* ({ok, data}) {
    if (ok && add) {
      yield put(CourseTemplateActions.addTemplates([data]))
    }

    yield put(AppActions.setLoading(false))
    yield put(NotificationActions.status({ok, data}))
    yield put(CourseTemplateActions.getTemplates(filters))
  })
}

export function* getTemplateCall(id) {
  yield call(services.courseTemplate.get, {id}, function* ({ok, data}) {
    if (ok) {
      yield put(CourseTemplateActions.setTemplate(data))
    }
  })
}

export function* getTemplate({payload}) {
  const {id, user_id} = payload
  const checkBeneficiaryCourseTemplateLink = user_id !== null

  if (checkBeneficiaryCourseTemplateLink) {
    let beneficiaryCourses = yield select(CourseSelectors.coursesObj)
    if (Object.values(beneficiaryCourses).length === 0) {
      yield call(getCoursesForBeneficiary, {payload: {id: user_id}})
      beneficiaryCourses = yield select(CourseSelectors.coursesObj)
    }

    if (beneficiaryCourses[id]) {
      yield call(getTemplateCall, id)
    } else {
      yield put(NavigationActions.push('/'))
    }
  } else {
    yield call(getTemplateCall, id)
  }
}

export function* getTemplates({payload}) {
  const {filter} = payload

  yield call(services.courseTemplate.getAll, {filter}, function* ({ok, data}) {
    ok
      ? yield put(CourseTemplateActions.setTemplates(data.data))
      : yield put(NotificationActions.status({ok, data}))
  })
}

export function* removeTemplate({payload}) {
  const {id} = payload
  const result = yield call(services.courseTemplate.remove, {id})

  yield put(NotificationActions.status(result))
}

export function* saveTemplate({payload}) {
  let {template} = payload

  if (template === null) {
    template = yield select(CourseTemplateSelectors.template)
  }

  yield put(AppActions.setLoading(true))
  yield call(services.courseTemplate.save, template, function* ({ok, data}) {
    if (ok) {
      const additionalData = {openStep: template.openStep}
      const mergedData = {...data, ...additionalData}

      yield put(CourseTemplateActions.setTemplate(mergedData))
      yield put(CourseTemplateActions.addTemplates([mergedData]))
    }

    yield put(AppActions.setLoading(false))
    yield put(NotificationActions.status({ok, data}))
  })
}

export function* assignTemplate({payload}) {
  const {ids} = payload

  yield put(AppActions.setLoading(true))
  yield call(services.courseTemplate.assign, {ids}, function* ({ok, data}) {
    if (ok) {
      yield put(CourseActions.addCourses([data]))
    }

    yield put(AppActions.setLoading(false))
    yield put(NotificationActions.status({ok, data}))
  })
}

export function* updateModuleAtIndex({payload}) {
  const {persist, reloadData, optionalData} = payload
  const template = yield select(CourseTemplateSelectors.template)

  if (persist) {
    yield put(AppActions.setLoading(true))
    const result = yield call(services.courseTemplate.save, template)

    if (reloadData && optionalData) {
      yield put(CourseTemplateActions.getTemplate(optionalData.course_id, optionalData.user_id))
    }

    yield put(AppActions.setLoading(false))
    yield put(NotificationActions.status(result))
  }
}

export function* removeModuleAtIndex({payload}) {
  const {persist} = payload
  const template = yield select(CourseTemplateSelectors.template)

  if (persist) {
    yield call(saveTemplate, {payload: {template}})
  }
}
