import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import ClientCard from '../../components/organisms/ClientCard/ClientCard'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import AdminOfficeEdit from '../../containers/admin/AdminOfficeEdit/AdminOfficeEdit'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import AdminOfficeRemove from '../../containers/consultant/AdminOfficeRemove/AdminOfficeRemove'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import { withI18n } from '../../containers/global/Translator/Translator'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../redux/OfficeRedux'
import { actions as UserActions } from '../../redux/UserRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  offices: OfficeSelectors.offices(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffices: (filter) => dispatch(OfficeActions.getOffices(filter)),
  exportUsers: () => dispatch(UserActions.exportAll()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminOfficesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    exportUsers: PropTypes.func.isRequired,
  }

  state = {
    officeToDelete: null,
  }

  getOffices = (name) => {
    this.props.getOffices({ name })
  }

  handleCreateClick = () => {
    this.props.redirect(`${this.props.pathname}/new`)
  }

  handleOfficeEditClick = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  handleClosePopin = () => {
    this.props.redirect(this.props.pathname.replace(/\/offices\/.+$/, '/offices'))
  }

  handleDeleteOffice = ({ id }) => {
    this.setState({ officeToDelete: id })
  }

  handleCloseConfirmRemovePopin = () => {
    this.setState({ officeToDelete: null })
  }

  handleExportUsers = () => {
    this.props.exportUsers()
  }

  componentDidMount() {
    this.getOffices()
  }

  renderOffices() {
    const { t, offices } = this.props

    const cards = offices.map(
      ({ id, image_url, no_consultants_allocated, no_beneficiaries, region, name }) => {
        return (
          <ClientCard
            id={id}
            key={id}
            label={name}
            region={region}
            imageUrl={image_url}
            consultantCount={no_consultants_allocated || 0}
            beneficiaryCount={no_beneficiaries || 0}
            onClick={this.handleOfficeEditClick}
            onClickRemove={this.handleDeleteOffice}
            locale={{
              beneficiariesLabel: t('office.beneficiaries'),
              consultantsLabel: t('office.consultants'),
            }}
          />
        )
      },
    )

    return <div className="offices">{cards}</div>
  }

  renderEditPopin() {
    if (!/\/offices\/(new|\d+)/.test(this.props.pathname)) {
      return null
    }

    return <AdminOfficeEdit onClose={this.handleClosePopin} />
  }

  renderSearchBar() {
    return <TopSearch onSearch={this.getOffices} />
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'customers', label: t('customer.my_customers') }]

    return <LineMenu value={links[0].id} links={links} components={this.renderSearchBar()} />
  }

  renderRemovePopin() {
    const { officeToDelete } = this.state

    return (
      !!officeToDelete && (
        <AdminOfficeRemove id={officeToDelete} onClose={this.handleCloseConfirmRemovePopin} />
      )
    )
  }

  renderContent() {
    return (
      <>
        <div className="header-circle-buttons">
          <CircleButton icon={Icon.icon.Plus} onClick={this.handleCreateClick} />

          <CircleButton icon={Icon.icon.Export} onClick={this.handleExportUsers} />
        </div>

        {this.renderOffices()}
        {this.renderEditPopin()}
        {this.renderRemovePopin()}
      </>
    )
  }

  render() {
    const aside = <AdminPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="admin-offices-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
