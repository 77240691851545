import { all, debounce, fork, put, take, takeLatest, takeLeading } from 'redux-saga/effects'

import { actions as AppActions } from '../redux/AppRedux'
import { actions as AppointmentActions } from '../redux/AppointmentRedux'
import { actions as BeneficiaryActions } from '../redux/BeneficiaryRedux'
import { actions as BeneficiaryGroupActions } from '../redux/BeneficiaryGroupRedux'
import { actions as ConsultantActions } from '../redux/ConsultantRedux'
import { actions as ContactActions } from '../redux/ContactRedux'
import { actions as CourseActions } from '../redux/CourseRedux'
import { actions as CourseTemplateActions } from '../redux/CourseTemplateRedux'
import { actions as ImpersonateActions } from '../redux/ImpersonateRedux'
import { actions as LocaleActions } from '../redux/LocaleRedux'
import { actions as MessageActions } from '../redux/MessageRedux'
import { actions as ModuleActions } from '../redux/ModuleRedux'
import { actions as ModuleTemplateActions } from '../redux/ModuleTemplateRedux'
import { actions as NavigationActions } from '../redux/NavigationRedux'
import { actions as NotesActions } from '../redux/NotesRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as ResourceTemplateActions } from '../redux/ResourceTemplateRedux'
import { actions as ResourceActions } from '../redux/ResourceRedux'
import { actions as OfficeActions } from '../redux/OfficeRedux'
import { actions as StatsActions } from '../redux/StatsRedux'
import { actions as UploadActions } from '../redux/UploadRedux'
import { actions as UserActions } from '../redux/UserRedux'
import { actions as VideoCallActions } from '../redux/VideocallRedux'
import { actions as CentralTestActions } from '../redux/CentralTestRedux'
import { actions as PortalActions } from '../redux/PortalRedux'

import * as AuthSaga from './AuthSaga'
import * as AppointmentSaga from './AppointmentSaga'
import * as BeneficiarySaga from './BeneficiarySaga'
import * as BeneficiaryGroupSaga from './BeneficiaryGroupSaga'
import * as ConsultantSaga from './ConsultantSaga'
import * as ContactSaga from './ContactSaga'
import * as CourseSaga from './CourseSaga'
import * as CourseTemplateSaga from './CourseTemplateSaga'
import * as ImpersonateSaga from './ImpersonateSaga'
import * as LocaleSaga from './LocaleSaga'
import * as MessageSaga from './MessageSaga'
import * as ModuleSaga from './ModuleSaga'
import * as ModuleTemplateSaga from './ModuleTemplateSaga'
import * as NavigationSaga from './NavigationSaga'
import * as NotesSaga from './NotesSaga'
import * as NotificationSaga from './NotificationSaga'
import * as OfficeSaga from './OfficeSaga'
import * as ResourceTemplateSaga from './ResourceTemplateSaga'
import * as ResourceSaga from './ResourceSaga'
import * as StatsSaga from './StatsSaga'
import * as UploadSaga from './UploadSaga'
import * as UserSaga from './UserSaga'
import * as VideoCallSaga from './VideocallSaga'
import * as CentralTestSaga from './CentralTestSaga'
import * as PortalSaga from './PortalSaga'

function* rootSaga() {
  yield fork(loop)
  yield init()
}

function* init() {
  yield put(AppActions.updateSize())
  yield put(LocaleActions.getTranslations('fr'))
  yield put(UserActions.getToken())
  yield put(UserActions.getCurrentUser())
  yield wait()
  yield put(AppActions.start())
}

function* wait() {
  yield all([take(LocaleActions.setLocale.getType()), take(UserActions.setUser.getType())])
}

function* loop() {
  yield all([
    // Auth
    takeLatest(UserActions.forgotPassword.getType(), AuthSaga.forgotPassword),
    takeLatest(UserActions.login.getType(), AuthSaga.login),
    takeLatest(UserActions.loginSuccess.getType(), AuthSaga.loginSuccess),
    takeLatest(UserActions.loginFailure.getType(), AuthSaga.loginFailure),
    takeLatest(UserActions.logout.getType(), AuthSaga.logout),
    takeLatest(UserActions.resetPassword.getType(), AuthSaga.resetPassword),
    takeLeading(UserActions.autoRefreshToken.getType(), AuthSaga.autoRefreshToken),
    takeLatest(UserActions.refreshToken.getType(), AuthSaga.refreshToken),
    takeLatest(UserActions.sendInvitation.getType(), AuthSaga.sendInvitation),

    // Appointment
    takeLatest(AppointmentActions.getAppointments.getType(), AppointmentSaga.getAppointments),
    takeLatest(AppointmentActions.exportAppointments.getType(), AppointmentSaga.exportAppointments),
    takeLatest(AppointmentActions.postpone.getType(), AppointmentSaga.postpone),
    takeLatest(AppointmentActions.confirm.getType(), AppointmentSaga.confirm),

    // Beneficiary
    takeLatest(BeneficiaryActions.attribute.getType(), BeneficiarySaga.attribute),
    takeLatest(BeneficiaryActions.share.getType(), BeneficiarySaga.share),
    takeLatest(BeneficiaryActions.unshare.getType(), BeneficiarySaga.unshare),
    takeLatest(BeneficiaryActions.manageStatus.getType(), BeneficiarySaga.manageStatus),
    takeLatest(
      BeneficiaryActions.saveCurrentBeneficiary.getType(),
      BeneficiarySaga.saveCurrentBeneficiary,
    ),
    takeLatest(BeneficiaryActions.saveBeneficiary.getType(), BeneficiarySaga.saveBeneficiary),
    takeLatest(BeneficiaryActions.removeBeneficiary.getType(), BeneficiarySaga.removeBeneficiary),
    takeLatest(BeneficiaryActions.getMyConsultant.getType(), BeneficiarySaga.getMyConsultant),
    takeLatest(BeneficiaryActions.getBeneficiaries.getType(), BeneficiarySaga.getBeneficiaries),
    takeLatest(
      BeneficiaryActions.exportBeneficiaries.getType(),
      BeneficiarySaga.exportBeneficiaries,
    ),
    takeLatest(BeneficiaryActions.getBeneficiary.getType(), BeneficiarySaga.getBeneficiary),
    takeLatest(BeneficiaryActions.onBack.getType(), BeneficiarySaga.onBack),
    takeLatest(BeneficiaryActions.getCvDesignrLink.getType(), BeneficiarySaga.getCvDesignrLink),
    takeLatest(
      BeneficiaryActions.getCvDesignrPdfLink.getType(),
      BeneficiarySaga.getCvDesignrPdfLink,
    ),
    takeLatest(
      BeneficiaryActions.getCvDesignrEditLink.getType(),
      BeneficiarySaga.getCvDesignrEditLink,
    ),
    takeLatest(
      BeneficiaryActions.getCvDesignrCommentLink.getType(),
      BeneficiarySaga.getCvDesignrCommentLink,
    ),

    // BeneficiaryGroup
    takeLatest(
      BeneficiaryGroupActions.getCurrentUserGroups.getType(),
      BeneficiaryGroupSaga.getCurrentUserGroups,
    ),
    takeLatest(BeneficiaryGroupActions.getGroup.getType(), BeneficiaryGroupSaga.getGroup),
    takeLatest(BeneficiaryGroupActions.removeGroup.getType(), BeneficiaryGroupSaga.removeGroup),
    takeLatest(BeneficiaryGroupActions.saveGroup.getType(), BeneficiaryGroupSaga.saveGroup),

    // Consultant
    takeLatest(ConsultantActions.archiveConsultant.getType(), ConsultantSaga.archiveConsultant),
    takeLatest(ConsultantActions.getConsultant.getType(), ConsultantSaga.getConsultant),
    takeLatest(ConsultantActions.getConsultants.getType(), ConsultantSaga.getConsultants),
    takeLatest(
      ConsultantActions.getOfficeConsultants.getType(),
      ConsultantSaga.getOfficeConsultants,
    ),
    takeLatest(ConsultantActions.removeConsultant.getType(), ConsultantSaga.removeConsultant),
    takeLatest(ConsultantActions.activateConsultant.getType(), ConsultantSaga.activateConsultant),
    takeLatest(ConsultantActions.saveConsultant.getType(), ConsultantSaga.saveConsultant),
    takeLatest(ConsultantActions.syncCronofy.getType(), ConsultantSaga.syncCronofy),
    debounce(
      300,
      ConsultantActions.setBeneficiariesFilter.getType(),
      BeneficiarySaga.getBeneficiaries,
    ),

    // Contact
    takeLatest(ContactActions.getContacts.getType(), ContactSaga.getContacts),

    // Course
    takeLatest(CourseActions.getCourse.getType(), CourseSaga.getCourse),
    takeLatest(CourseActions.exportCourse.getType(), CourseSaga.exportCourse),
    takeLatest(CourseActions.getCourses.getType(), CourseSaga.getCourses),
    takeLatest(
      CourseActions.getCoursesForBeneficiary.getType(),
      CourseSaga.getCoursesForBeneficiary,
    ),
    takeLatest(CourseActions.removeCourse.getType(), CourseSaga.removeCourse),
    takeLatest(CourseActions.exportSynthesis.getType(), CourseSaga.exportSynthesis),

    // CourseTemplate
    takeLatest(
      CourseTemplateActions.duplicateTemplate.getType(),
      CourseTemplateSaga.duplicateTemplate,
    ),
    takeLatest(CourseTemplateActions.getTemplate.getType(), CourseTemplateSaga.getTemplate),
    takeLatest(CourseTemplateActions.getTemplates.getType(), CourseTemplateSaga.getTemplates),
    takeLatest(CourseTemplateActions.removeTemplate.getType(), CourseTemplateSaga.removeTemplate),
    takeLatest(CourseTemplateActions.saveTemplate.getType(), CourseTemplateSaga.saveTemplate),
    takeLatest(CourseTemplateActions.assignTemplate.getType(), CourseTemplateSaga.assignTemplate),
    takeLatest(
      CourseTemplateActions.updateModuleAtIndex.getType(),
      CourseTemplateSaga.updateModuleAtIndex,
    ),
    takeLatest(
      CourseTemplateActions.removeModuleAtIndex.getType(),
      CourseTemplateSaga.removeModuleAtIndex,
    ),

    // Impersonate
    takeLatest(
      ImpersonateActions.searchImpersonateUsers.getType(),
      ImpersonateSaga.searchImpersonateUsers,
    ),
    takeLatest(ImpersonateActions.impersonate.getType(), ImpersonateSaga.impersonate),

    // Locale
    takeLatest(LocaleActions.getTranslations.getType(), LocaleSaga.getTranslations),

    // Message
    takeLatest(MessageActions.getMessage.getType(), MessageSaga.getMessage),
    takeLatest(MessageActions.getMessages.getType(), MessageSaga.getMessages),
    takeLatest(MessageActions.getUnreadMessages.getType(), MessageSaga.getUnreadMessages),
    takeLatest(MessageActions.removeMessages.getType(), MessageSaga.removeMessages),
    takeLatest(MessageActions.sendMessage.getType(), MessageSaga.sendMessage),
    takeLatest(MessageActions.setRead.getType(), MessageSaga.setRead),
    takeLatest(
      MessageActions.getMessageAndMarkAsRead.getType(),
      MessageSaga.getMessageAndMarkAsRead,
    ),

    // Module
    takeLatest(ModuleActions.getModule.getType(), ModuleSaga.getModule),
    takeLatest(ModuleActions.saveModuleFormAnswers.getType(), ModuleSaga.saveModuleFormAnswers),
    takeLatest(ModuleActions.saveSynthesisNotes.getType(), ModuleSaga.saveSynthesisNotes),
    takeLatest(
      ModuleActions.saveModuleCompleteStatus.getType(),
      ModuleSaga.saveModuleCompleteStatus,
    ),

    // Module Templates
    takeLatest(ModuleTemplateActions.getTemplate.getType(), ModuleTemplateSaga.getTemplate),
    takeLatest(ModuleTemplateActions.getTemplates.getType(), ModuleTemplateSaga.getTemplates),
    takeLatest(ModuleTemplateActions.saveTemplate.getType(), ModuleTemplateSaga.saveTemplate),
    takeLatest(ModuleTemplateActions.removeTemplate.getType(), ModuleTemplateSaga.removeTemplate),

    // Navigation
    takeLatest(NavigationActions.back.getType(), NavigationSaga.back),
    takeLatest(NavigationActions.evaluate.getType(), NavigationSaga.evaluate),
    takeLatest(NavigationActions.open.getType(), NavigationSaga.open),
    takeLatest(NavigationActions.push.getType(), NavigationSaga.push),
    takeLatest(NavigationActions.redirect.getType(), NavigationSaga.redirect),
    takeLatest(NavigationActions.replace.getType(), NavigationSaga.replace),

    // Notes
    takeLatest(NotesActions.getNotes.getType(), NotesSaga.getNotes),
    takeLatest(NotesActions.saveNotes.getType(), NotesSaga.saveNotes),

    // Notification
    takeLatest(NotificationActions.status.getType(), NotificationSaga.status),

    // Office
    takeLatest(OfficeActions.contactMyTalents.getType(), OfficeSaga.contactMyTalents),
    takeLatest(OfficeActions.getCurrentUserOffice.getType(), OfficeSaga.getCurrentUserOffice),
    takeLatest(OfficeActions.getOffice.getType(), OfficeSaga.getOffice),
    takeLatest(OfficeActions.getOffices.getType(), OfficeSaga.getOffices),
    takeLatest(OfficeActions.saveCurrentUserOffice.getType(), OfficeSaga.saveCurrentUserOffice),
    takeLatest(OfficeActions.saveOffice.getType(), OfficeSaga.saveOffice),
    takeLatest(OfficeActions.removeOffice.getType(), OfficeSaga.removeOffice),

    // Resources
    takeLatest(ResourceActions.setUrl.getType(), ResourceSaga.setUrl),
    takeLatest(ResourceActions.setPrivate.getType(), ResourceSaga.setPrivate),

    // Resource Templates
    takeLatest(ResourceTemplateActions.getTemplates.getType(), ResourceTemplateSaga.getTemplates),
    takeLatest(
      ResourceTemplateActions.removeTemplate.getType(),
      ResourceTemplateSaga.removeTemplate,
    ),
    takeLatest(ResourceTemplateActions.saveTemplate.getType(), ResourceTemplateSaga.saveTemplate),

    // Stats
    takeLatest(StatsActions.getAdminStats.getType(), StatsSaga.getAdminStats),
    takeLatest(StatsActions.getManagerOfficeStats.getType(), StatsSaga.getManagerOfficeStats),
    takeLatest(
      StatsActions.setManagerOfficeStatsYear.getType(),
      StatsSaga.setManagerOfficeStatsYear,
    ),
    takeLatest(StatsActions.initConsultantStats.getType(), StatsSaga.initConsultantStats),
    takeLatest(StatsActions.getConsultantStats.getType(), StatsSaga.getConsultantStats),
    takeLatest(
      StatsActions.setConsultantStatsCurrentConsultant.getType(),
      StatsSaga.setConsultantStatsCurrentConsultant,
    ),

    // Upload
    takeLatest(UploadActions.upload.getType(), UploadSaga.upload),

    // User
    takeLatest(UserActions.getCurrentUser.getType(), UserSaga.getCurrentUser),
    takeLatest(UserActions.exportAll.getType(), UserSaga.exportAll),
    takeLatest(UserActions.getToken.getType(), UserSaga.getToken),
    takeLatest(UserActions.setToken.getType(), UserSaga.setToken),

    // Videocall
    takeLatest(VideoCallActions.createInvite.getType(), VideoCallSaga.createInvite),

    // CentralTest
    takeLatest(CentralTestActions.createAccount.getType(), CentralTestSaga.createAccount),
    takeLatest(CentralTestActions.fetchTests.getType(), CentralTestSaga.fetchTests),
    takeLatest(CentralTestActions.inviteBeneficiary.getType(), CentralTestSaga.inviteBeneficiary),
    takeLatest(CentralTestActions.getAssessments.getType(), CentralTestSaga.getAssessments),
    takeLatest(CentralTestActions.toggleReport.getType(), CentralTestSaga.toggleReport),
    takeLatest(CentralTestActions.manualLink.getType(), CentralTestSaga.manualLink),

    // Portal
    takeLatest(PortalActions.getOfficeSettings.getType(), PortalSaga.officeSettings),
    takeLatest(PortalActions.setTheme.getType(), PortalSaga.persistTheme),
    takeLatest(PortalActions.setLabels.getType(), PortalSaga.persistLabels),
    takeLatest(PortalActions.setAssets.getType(), PortalSaga.persistAssets),
  ])
}

export default rootSaga
