// React & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import Icon from '../../atoms/Icon/Icon'

// Proptypes
const propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isActivated: PropTypes.bool,
  onClick: PropTypes.func,
}

// defaultProps
const defaultProps = {
  isActivated: false,
  onClick: () => undefined,
}

// Component
class ParcoursModuleContentVideoLink extends Component {
  handleClick = () => {
    const { link, label } = this.props

    this.props.onClick(link, label)
  }

  render() {
    const { label, isActivated } = this.props

    return (
      <div
        className={`parcours-module-content-video-link ${isActivated ? 'activated' : ''}`}
        onClick={isActivated ? this.handleClick : undefined}
      >
        <IconResourceType
          resourceType={IconResourceType.resourceType.video}
          color={isActivated ? Icon.color.Accent : Icon.color.Grey3}
        />
        <p className="label">{label}</p>
      </div>
    )
  }
}

ParcoursModuleContentVideoLink.propTypes = propTypes
ParcoursModuleContentVideoLink.defaultProps = defaultProps

export default ParcoursModuleContentVideoLink
