import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icons
import IconProgState from '../../atoms/IconProgState/IconProgState'
import IconChevron from '../../atoms/IconChevron/IconChevron'

export default class ParcoursHeader extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    progState: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    noChevron: PropTypes.bool,
  }

  static defaultProps = {
    noChevron: false,
    editable: false,
    node: <div />,
    onClick: (id) => {},
    onChange: ({ id, value }) => {},
  }

  handleChange = (e) => {
    this.props.onChange({
      id: 'title',
      value: e.currentTarget.value,
    })
  }

  onClick = () => {
    this.props.onClick(this.props.id)
  }

  render() {
    const { open, progState, title, children, noChevron, editable, placeholder } = this.props

    return (
      <div className="parcours-header">
        <div className="parcours-header-bar" onClick={this.onClick}>
          {progState && <IconProgState icon={progState} />}
          <p className={`parcours-title-text ${open ? 'expanded' : 'collapsed'}`}>
            {editable && (
              <input
                type="text"
                value={title}
                name="title"
                placeholder={placeholder}
                onChange={this.handleChange}
                autoComplete="off"
                className="course-title"
              />
            )}

            {!editable && title}
          </p>
          {!noChevron && <IconChevron open={open} />}
        </div>
        {open ? children : null}
      </div>
    )
  }
}
