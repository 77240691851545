import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import { createSelector } from 'reselect'
import _sortBy from 'lodash/sortBy'

let size = 0

//
// Initial state
//
const initialState = Map({
  notifications: {},
  ttl: 5 * 1000,
})

//
// Actions
//
export const actions = {
  remove: createAction('removeNotification', (id) => ({ id })),
  add: createAction('addNotification', (type, text) => ({ type, text, id: ++size })),
  status: createAction('addStatusNotification', ({ ok, status, data }) => ({ ok, status, data })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.remove]: (state, { id }) => state.deleteIn(['notifications', id]),
    [actions.add]: (state, value) => state.setIn(['notifications', value.id], value),
  },
  initialState,
)

//
// Selectors
//

const ttl = (state) => state.notification.get('ttl')
const notifications = (state) => state.notification.get('notifications')
const sortedNotifications = createSelector([notifications], (notifications) =>
  _sortBy(notifications, 'id'),
)

export const selectors = {
  notifications: sortedNotifications,
  ttl,
}
