import { useCallback, useEffect } from 'react'
import { takeEvery, takeLatest } from 'redux-saga/effects'
import { sagaMiddleware } from '../redux/index'

export const useSaga = (generatorFn) => {
  useEffect(() => {
    const task = sagaMiddleware.run(function* fn() {
      yield generatorFn()
    })
    return () => {
      task.cancel()
    }
  }, [generatorFn])
}

export const useSagaEffect = (effect, action, callback) => {
  const callbackFn = useCallback(
    function* fn() {
      yield effect(action, callback)
    },
    [effect, action, callback],
  )
  useSaga(callbackFn)
}

export const useTakeEvery = (action, callback) => {
  useSagaEffect(takeEvery, action, callback)
}

export const useTakeLatest = (action, callback) => {
  useSagaEffect(takeLatest, action, callback)
}
