// Rect & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Icon from '../Icon/Icon'

const { icon } = Icon
const { color } = Icon

// Component
export default class IconButton extends Component {
  static propTypes = {
    icon: PropTypes.oneOf(Object.values(icon)).isRequired,
    color: PropTypes.oneOf(Object.values(color)),
    onClick: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tooltip: PropTypes.string,
  }

  static defaultProps = {
    color: color.Accent,
    onClick: ({ id }) => undefined,
    id: '',
    tooltip: null,
  }

  static icon = icon

  static color = color

  handleClick = (e) => {
    e.stopPropagation()

    const { id } = this.props
    this.props.onClick({ id })
  }

  render() {
    const { icon, color, tooltip, id } = this.props

    let tooltipProp = {}
    let tooltipNode = null
    if (tooltip !== null && id !== '') {
      tooltipProp = {
        'data-tip': tooltip,
        'data-for': id,
      }
      tooltipNode = (
        <ReactTooltip id={id} place="top" type="dark" effect="solid" className="tooltip" />
      )
    }

    return (
      <div className={`icon-button ${icon}`} onClick={this.handleClick} {...tooltipProp}>
        <Icon icon={icon} color={color} />
        {tooltipNode}
      </div>
    )
  }
}
