import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LabelRow from '../../molecules/LabelRow/LabelRow'
import Icon from '../../atoms/Icon/Icon'

class RowList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onAction: PropTypes.func,
  }

  static defaultProps = {
    onAction: ({ id, action }) => undefined,
  }

  handleActionClick = ({ id, action }) => {
    this.props.onAction({ id, action })
  }

  renderList = () => {
    const actions = [
      { value: 'edit', icon: Icon.icon.Edit },
      { value: 'delete', icon: Icon.icon.Trash },
    ]

    return this.props.items.map(({ id, label }, i) => {
      return (
        <LabelRow
          key={i}
          id={id}
          label={label}
          actions={actions}
          onAction={this.handleActionClick}
        />
      )
    })
  }

  render() {
    return <div className="row-list">{this.renderList()}</div>
  }
}

export default RowList
