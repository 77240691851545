import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextArea'
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin'
import AsideModuleSelector from '../../containers/course/AsideModuleSelector/AsideModuleSelector'
import CourseEditDragDropContext from '../../containers/course/CourseEditDragDropContext/CourseEditDragDropContext'
import EditStepWrapper from '../../containers/course/EditStepWrapper/EditStepWrapper'
import Draggable from '../../containers/dragdrop/Draggable/Draggable'
import Droppable from '../../containers/dragdrop/Droppable/Droppable'
import FileUpload from '../../containers/global/FileUpload/FileUpload'
import Page from '../../containers/global/Page/Page'
import Popin from '../../containers/global/Popin/Popin'
import { withI18n } from '../../containers/global/Translator/Translator'
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as NotificationActions } from '../../redux/NotificationRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../redux/WSRedux'
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner'

const mapStateToProps = (state, props) => ({
  id: props.match.params.course_id,
  pathname: NavigationSelectors.pathname(state),
  template: CourseTemplateSelectors.template(state),
  saveAction: WSSelectors.action(state, 'courseTemplate.save'),
  uploadAction: WSSelectors.action(state, 'upload.upload'),
  hasChanged: CourseTemplateSelectors.hasChanged(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveTemplate: (template) => dispatch(CourseTemplateActions.saveTemplate(template)),
  getTemplate: (id) => dispatch(CourseTemplateActions.getTemplate(id)),
  setTemplate: (template) => dispatch(CourseTemplateActions.setTemplate(template)),
  setTemplateSteps: (steps) => dispatch(CourseTemplateActions.setTemplateSteps(steps)),
  updateTemplateValue: (key, value) =>
    dispatch(CourseTemplateActions.updateTemplateValue(key, value)),
  redirect: (pathname) => dispatch(NavigationActions.redirect(pathname)),
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, true)),
  addErrorNotification: (text) => dispatch(NotificationActions.add('error', text)),
  resetSave: () => dispatch(WSActions.reset('courseTemplate.save')),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class CourseEditPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    search: '',
    confirmBack: false,
  }

  handleBack = () => {
    this.props.back()
    this.props.setTemplate(null)
  }

  handleContentChange = ({ target }) => {
    this.handleChange({ id: target.name, value: target.value })
  }

  handleChange = ({ id, value }) => {
    this.props.updateTemplateValue(id, value)
  }

  handleTogglePublish = () => {
    this.props.updateTemplateValue('template_published', !this.props.template.template_published)
    this.forceUpdate(this.handleSave)
  }

  handleConfirmLeaveWithoutSave = () => {
    this.handleBack()
  }

  handleConfirmPopinClick = () => {
    this.props.hasChanged
      ? this.setState(({ confirmBack }) => ({ confirmBack: !confirmBack }))
      : this.handleConfirmLeaveWithoutSave()
  }

  renderConfirmClose = () => {
    const { t, removing } = this.props

    return (
      <Popin onClose={this.handleConfirmPopinClick} open={this.state.confirmBack}>
        <AlertPopin
          disabled={removing}
          label={t('save_changes.label')}
          text={t('save_changes.text')}
          labelCancelButton={t('actions.no')}
          labelConfirmButton={t('actions.yes')}
          onCancelButtonClick={this.handleConfirmPopinClick}
          onConfirmButtonClick={this.handleConfirmLeaveWithoutSave}
        />
      </Popin>
    )
  }

  handleSave = () => {
    const { template, saveTemplate, addErrorNotification, t } = this.props
    const { title } = template
    const cancelSave = !title

    if (cancelSave) {
      addErrorNotification(t('course.is_empty'))
    } else {
      saveTemplate(template)
    }
  }

  handleCoverUrlChange = ({ file }) => {
    this.props.updateTemplateValue('cover_url', file.url)
  }

  handleOrderStepsChange = (steps) => {
    this.props.setTemplateSteps(steps)
  }

  handleOrderModulesChange = (index, modules) => {
    const { template } = this.props
    const steps = template.steps.slice(0)

    steps[index] = { ...steps[index], modules }

    this.props.setTemplateSteps(steps)
  }

  componentDidMount() {
    const { id, template } = this.props

    this.props.resetSave()

    if (!template) {
      ;/\d+/.test(id)
        ? this.props.getTemplate(id)
        : this.props.setTemplate({ title: '', steps: [] })
    }
  }

  componentDidUpdate() {
    const { id, template, pathname, saveAction } = this.props
    const hasTemplate = template && template.id
    const { resolved } = saveAction

    if (resolved && hasTemplate && !/\d+/.test(id)) {
      this.props.redirect(pathname.replace(/\w+$/, template.id))
    }
  }

  renderHeader() {
    const { t, template, saveAction, uploadAction } = this.props
    const published = template && template.template_published
    const { title } = template
    const disabled = uploadAction.pending || saveAction.pending || !title
    const backgroundImage =
      template && template.cover_url
        ? `url("${this.props.template.cover_url}")`
        : 'url("/static/images/course-default-background.jpg")'

    const style = {
      backgroundImage,
    }

    return (
      <header style={style}>
        <div>
          <CircleButton icon={Icon.icon.Back} onClick={this.handleConfirmPopinClick} />
        </div>

        <div className="right-components">
          <PrimaryButton
            label={published ? t('actions.unpublish') : t('actions.publish')}
            id="publish"
            icon={Icon.icon.Publish}
            disabled={disabled}
            onClick={this.handleTogglePublish}
          />

          <PrimaryButton
            label={t('actions.save')}
            id="save"
            disabled={disabled}
            onClick={this.handleSave}
          />

          <FileUpload id="cover_url" accept="image/*" onChange={this.handleCoverUrlChange}>
            {({ onClick, uploading }) => (
              <>
                {!uploading && (
                  <CircleButton icon={Icon.icon.Image} disabled={disabled} onClick={onClick} />
                )}
                {uploading && <LoadingSpinner />}
              </>
            )}
          </FileUpload>
        </div>
      </header>
    )
  }

  renderTemplateInformations() {
    const { t, template } = this.props
    const { title, description, steps } = template
    const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

    return (
      <>
        <input
          type="text"
          value={title}
          name="title"
          placeholder={t('course.title')}
          onChange={this.handleContentChange}
          autoComplete="off"
          className="title"
        />

        <div className="informations">
          <div className="entry">
            <div className="count">{steps.length}</div>
            <div className="label">{t('step.steps')}</div>
          </div>
          <div className="entry">
            <div className="count">{moduleCount}</div>
            <div className="label">{t('module.modules')}</div>
          </div>
        </div>

        <DescriptionTextArea
          maxLength={500}
          name="description"
          placeholder={t('course.description')}
          onChange={this.handleChange}
          value={description}
        />
      </>
    )
  }

  renderTemplateSteps() {
    const cards = this.props.template.steps.map(({ id, _id, title, modules }, i) => {
      const key = (id || _id).toString()

      return (
        <Draggable key={key} id={key} index={i}>
          <EditStepWrapper stepIndex={i} />
        </Draggable>
      )
    })

    return (
      <Droppable id="course-steps" className="steps" type="step">
        {cards}
      </Droppable>
    )
  }

  renderTemplate() {
    return (
      <section>
        {this.renderTemplateInformations()}
        {this.renderTemplateSteps()}
      </section>
    )
  }

  renderContent() {
    return (
      <>
        {this.renderHeader()}
        {this.renderTemplate()}
        {this.renderConfirmClose()}
      </>
    )
  }

  renderAside() {
    return <AsideModuleSelector />
  }

  render() {
    if (!this.props.template) {
      return null
    }

    return (
      <CourseEditDragDropContext
        onChangeSteps={this.handleOrderStepsChange}
        onChangeModules={this.handleOrderModulesChange}
      >
        <div className="course-edit-page">
          <RightAsideLayout aside={this.renderAside()} content={this.renderContent()} />
        </div>
      </CourseEditDragDropContext>
    )
  }
}
