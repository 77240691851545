export default {
  normalize(date) {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    return d
  },

  getFirstDayOfMonth(date) {
    const d = new Date(date)
    d.setDate(1)
    return d
  },

  getFirstMondayBefore(date) {
    const d = new Date(date)
    let limit = 8
    while (d.getDay() !== 1 && limit > 0) {
      d.setDate(d.getDate() - 1)
      limit--
    }
    return d
  },
}
