import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value || value === false) {
      query[`filter[${key}]`] = value
    }
  })

  return api.get('/offices', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const get = ({ token, id }) => {
  return api.get(`/offices/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const remove = ({ token, id }) => {
  return api.delete(`/offices/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const save = ({ token, id, ...data }) => {
  return id
    ? api.patch(`/offices/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })
    : api.post(`/offices`, data, { headers: { Authorization: `Bearer ${token}` } })
}

export const getMine = ({ token }) => {
  return api.get('/offices/me', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const saveMine = ({ token, ...data }) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const body = {
    email: data.email,
    emails: data.emails,
    name: data.name,
    phone: data.phone,
    region: data.region,
    no_consultants: data.no_consultants,
    image_url: data.image_url,
  }

  return api.patch('/offices/me', body, config)
}
