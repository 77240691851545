import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../../redux/BeneficiaryRedux'
import BeneficiaryProfileContent from '../../beneficiary/BeneficiaryProfileContent/BeneficiaryProfileContent'
import Page from '../../global/Page/Page'
import { withI18n } from '../../global/Translator/Translator'
import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'
import moment from 'moment'
import Icon from '../../../components/atoms/Icon/Icon'

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  loadingCvDesignrPdfLink: BeneficiarySelectors.loadingCvDesignrPdfLink(state),
  cvDesignrPdfExternalLink: BeneficiarySelectors.cvDesignrPdfExternalLink(state),
  loadingCvDesignrCommentLink: BeneficiarySelectors.loadingCvDesignrCommentLink(state),
  cvDesignrCommentExternalLink: BeneficiarySelectors.cvDesignrCommentExternalLink(state),
  loadingCvDesignrEditLink: BeneficiarySelectors.loadingCvDesignrEditLink(state),
  cvDesignrEditExternalLink: BeneficiarySelectors.cvDesignrEditExternalLink(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCvDesignrPdfLink: (cvId) => dispatch(BeneficiaryActions.getCvDesignrPdfLink(cvId)),
  getCvDesignrCommentLink: (cvId) => dispatch(BeneficiaryActions.getCvDesignrCommentLink(cvId)),
  getCvDesignrEditLink: (cvId) => dispatch(BeneficiaryActions.getCvDesignrEditLink(cvId)),
})

const formProperties = {
  form: 'beneficiary-profile',
  asyncBlurFields: profileFieldsForConsultant,
  asyncChangeFields: profileFieldsForConsultant,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(props.beneficiary, values, true)
    return asyncValidate(values, validationRules)
  },
}

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm(formProperties)
export default class ConsultantBeneficiaryCvsContainer extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  isPrimary = () => {
    const { user, beneficiary } = this.props

    if (user.role === 'manager') {
      return true
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    )
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.loadingCvDesignrPdfLink &&
      this.props.loadingCvDesignrPdfLink &&
      nextProps.cvDesignrPdfExternalLink
    ) {
      window.open(nextProps.cvDesignrPdfExternalLink)
    }
    if (
      !nextProps.loadingCvDesignrCommentLink &&
      this.props.loadingCvDesignrCommentLink &&
      nextProps.cvDesignrCommentExternalLink
    ) {
      window.location = nextProps.cvDesignrCommentExternalLink
    }
    if (
      !nextProps.loadingCvDesignrEditLink &&
      this.props.loadingCvDesignrEditLink &&
      nextProps.cvDesignrEditExternalLink
    ) {
      window.location = nextProps.cvDesignrEditExternalLink
    }
  }

  handlePdfClick(cvId) {
    this.props.getCvDesignrPdfLink(cvId)
  }

  handleEditClick(cvId) {
    this.props.getCvDesignrEditLink(cvId)
  }

  handleCommentClick(cvId) {
    this.props.getCvDesignrCommentLink(cvId)
  }

  renderContent = () => {
    const { beneficiary, groups, user } = this.props

    const isPrimary = this.isPrimary()

    return (
      <BeneficiaryProfileContent
        user={beneficiary}
        groups={groups}
        onChange={this.handleChange}
        consultantEdition
        readOnly={!isPrimary}
        currentUserRole={user.role}
      />
    )
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  constructor(props) {
    super(props)

    this.state = {
      beneficiary: null,
      consultantNotes: null,
      resetBeneficiary: false,
      resetNotes: false,
      unblock: null,
    }
  }

  renderCvLink(cv) {
    const date = cv.updated_at
      ? moment.unix(cv.updated_at).format('DD/MM/YYYY HH:MM')
      : moment.unix(cv.created_at).format('DD/MM/YYYY HH:MM')
    return (
      <tr key={cv.id}>
        <td>{cv.name}</td>
        <td>{cv.job_sought}</td>
        <td>{date}</td>
        <td className={'action'}>
          <span onClick={() => this.handleCommentClick(cv.id)}>
            <Icon icon={Icon.icon.Pencil} />
          </span>
        </td>
        <td className={'action'}>
          <span onClick={() => this.handleEditClick(cv.id)}>
            <Icon icon={Icon.icon.Edit} />
          </span>
        </td>
        <td className={'action'}>
          <span onClick={() => this.handlePdfClick(cv.id)}>
            <Icon icon={Icon.icon.Preview} />
          </span>
        </td>
      </tr>
    )
  }

  render() {
    const { cvs, t } = this.props
    return (
      <ConsultantBeneficiary renderAside={this.renderAside} disableSave>
        <div className="consultant-beneficiary-cvs-page">
          <table>
            <thead>
              <tr>
                <th>{t('consultant.cv_list.name')}</th>
                <th>{t('consultant.cv_list.job')}</th>
                <th>{t('consultant.cv_list.last_edition')}</th>
                <th className="action">{t('consultant.cv_list.comment')}</th>
                <th className="action">{t('consultant.cv_list.edit')}</th>
                <th className="action">{t('consultant.cv_list.view')}</th>
              </tr>
            </thead>
            <tbody>{cvs.map((cv) => this.renderCvLink(cv))}</tbody>
          </table>
        </div>
      </ConsultantBeneficiary>
    )
  }
}
