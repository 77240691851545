import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../global/Translator/Translator'

import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'

import Popin from '../../global/Popin/Popin'
import Icon from '../../../components/atoms/Icon/Icon'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import ActionPopinHeader from '../../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PopinLayout from '../../../layouts/PopinLayout/PopinLayout'
import ComboField from '../../../components/molecules/ComboField/ComboField'

const mapStateToProps = (state) => ({
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

@withI18n
@connect(mapStateToProps, null)
export default class EditQuestionFormModulePopin extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onReasonChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    reasonsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handleValidate = () => {
    const { onValidate, onClose } = this.props

    onValidate()
    onClose()
  }

  renderHeader = () => {
    const { t, onClose } = this.props

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={t('consultant.archive_my_beneficiary')}
        onIconClick={onClose}
      >
        <PrimaryButton id="validate" label={t('actions.validate')} onClick={this.handleValidate} />
      </ActionPopinHeader>
    )
  }

  handleChange = (newValue) => {
    const { onReasonChange } = this.props

    onReasonChange(newValue)
  }

  renderContent = () => {
    const { t, reasonsOptions } = this.props

    return (
      <ComboField
        value={this.state.reason}
        title={t('consultant.beneficiary_archived_reason')}
        name="archived_reason"
        options={reasonsOptions}
        onChange={this.handleChange}
      />
    )
  }

  static getDerivedStateFromProps({ beneficiary }, state) {
    if (beneficiary) {
      return {
        reason:
          beneficiary.archived_reason != null && beneficiary.archived_reason !== ''
            ? beneficiary.archived_reason
            : state.reason,
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      reason: props.reasonsOptions[0].value,
    }
  }

  render() {
    const { onClose, open, beneficiary } = this.props

    if (!beneficiary) {
      return null
    }

    const header = this.renderHeader()
    const content = this.renderContent()

    return (
      <Popin onClose={onClose} open={open}>
        <div className="beneficiary-archived-reason-popin">
          <PopinLayout header={header} content={content} />
        </div>
      </Popin>
    )
  }
}
