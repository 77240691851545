import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import ParcoursCard from '../../components/organisms/ParcoursCard/ParcoursCard'
import InfoPage from '../../components/molecules/InfoPage/InfoPage'

import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'

const mapStateToProps = (state) => ({
  courses: CourseSelectors.courses(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCourses: (filter) => dispatch(CourseActions.getCourses(filter)),
  resetCurrentCourse: () => dispatch(CourseActions.setCourse(null)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryCoursesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  renderTabs = () => {
    const { t } = this.props
    const links = [{ id: 'my_courses', label: t('course.my_courses') }]

    return <LineMenu value="my_courses" links={links} />
  }

  handleCardClick = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  renderCards = () => {
    const { t, courses } = this.props
    if (courses.length === 0) {
      return (
        <div className="no-courses-wrapper">
          <InfoPage text={t('beneficiary.no_courses')} />
        </div>
      )
    }

    return courses.map(({ id, title, cover_url, steps }) => {
      const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

      return (
        <ParcoursCard
          course={{
            id,
            coverUrl: cover_url,
            title,
            stepsCount: steps.length,
            modulesCount: moduleCount,
          }}
          key={id}
          onCardClick={this.handleCardClick}
        />
      )
    })
  }

  renderContent = () => <div className="course-card-list">{this.renderCards()}</div>

  getCourses = () => {
    this.props.getCourses()
  }

  componentDidMount() {
    this.getCourses()
    this.props.resetCurrentCourse()
  }

  render() {
    const aside = <BeneficiaryPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="beneficiary-courses-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
