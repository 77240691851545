import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import InfoField from '../../molecules/InfoField/InfoField'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import IconButton from '../../atoms/IconButton/IconButton'
import Icon from '../../atoms/Icon/Icon'
import FileUpload from '../../../containers/global/FileUpload/FileUpload'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'

class FinancialCard extends Component {
  static propTypes = {
    financerState: PropTypes.string.isRequired,
    financerStateColor: PropTypes.string.isRequired,
    financerType: PropTypes.string.isRequired,
    financerOpacifType: PropTypes.string.isRequired,
    financerSupportRequestFile: PropTypes.string,
    financerSupportAgreementFile: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      financerStateLabel: PropTypes.string.isRequired,
      financerTypeLabel: PropTypes.string.isRequired,
      OpacifLabel: PropTypes.string.isRequired,
      financerOpacifTypeLabel: PropTypes.string.isRequired,
      financerSupportRequestFileLabel: PropTypes.string.isRequired,
      financerSupportAgreementFileLabel: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    financerSupportRequestFile: '',
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 40
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  handleChange = (newValue) => {
    this.props.onChange(newValue)
  }

  handleDeleteFile = ({ id }) => {
    this.handleChange({ id, value: '' })
  }

  handleFileChange = ({ id, file }) => {
    this.handleChange({ id, value: file.url })
  }

  render() {
    const {
      financerState,
      financerType,
      financerOpacifType,
      locale,
      financerStateColor,
      financerSupportRequestFile,
      financerSupportAgreementFile,
    } = this.props
    const {
      title,
      financerStateLabel,
      financerTypeLabel,
      OpacifLabel,
      financerOpacifTypeLabel,
      financerSupportRequestFileLabel,
      financerSupportAgreementFileLabel,
    } = locale
    return (
      <div className="financial-card">
        <IconCardTitle label={title} icon="card" />
        <div className="content">
          <InfoField
            color={financerStateColor}
            lightLabel={financerStateLabel}
            boldLabel={financerState}
          />
          <InfoField lightLabel={financerTypeLabel} boldLabel={financerType} />
          {financerType === OpacifLabel && (
            <InfoField lightLabel={financerOpacifTypeLabel} boldLabel={financerOpacifType} />
          )}
          <div className="file-input-container">
            <div className="file-info">
              <InfoField
                lightLabel={financerSupportRequestFileLabel}
                boldLabel={this.truncateString(financerSupportRequestFile)}
              />
            </div>
            <div className="actions">
              {financerSupportRequestFile !== '' && (
                <div className="download-icon">
                  <a href={financerSupportRequestFile} target="_blank" rel="noreferrer noopener">
                    <CircleButton icon={Icon.icon.Download} />
                  </a>
                </div>
              )}
            </div>
          </div>
          <FileUpload id={financerSupportAgreementFile.id} onChange={this.handleFileChange}>
            {({ onClick, uploading }) => (
              <div className="file-input-container">
                <div className="file-info">
                  <InfoField
                    lightLabel={financerSupportAgreementFileLabel}
                    boldLabel={this.truncateString(financerSupportAgreementFile.value)}
                  />
                  {financerSupportAgreementFile.value !== '' && (
                    <div className="delete-icon">
                      <IconButton
                        id={financerSupportAgreementFile.id}
                        icon={Icon.icon.Close}
                        onClick={this.handleDeleteFile}
                      />
                    </div>
                  )}
                </div>
                <div className="actions">
                  {financerSupportAgreementFile.value !== '' && (
                    <div className="download-icon">
                      <a
                        href={financerSupportAgreementFile.value}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <CircleButton icon={Icon.icon.Download} />
                      </a>
                    </div>
                  )}
                  {!uploading && (
                    <div className="upload-icon">
                      <CircleButton icon={Icon.icon.Upload} onClick={onClick} />
                    </div>
                  )}
                  {uploading && <LoadingSpinner />}
                </div>
              </div>
            )}
          </FileUpload>
        </div>
      </div>
    )
  }
}

export default FinancialCard
