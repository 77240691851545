import React, { PureComponent } from 'react'
import { I18n } from 'react-redux-i18n'

export const withI18n = (WrappedComponent) => {
  const translate = I18n.t.bind(I18n)
  const localize = I18n.l.bind(I18n)

  return class WithI18n extends PureComponent {
    render() {
      return <WrappedComponent {...this.props} t={translate} l={localize} />
    }
  }
}
