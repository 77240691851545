import { call, put } from 'redux-saga/effects'

import { actions as BeneficiaryGroupActions } from '../redux/BeneficiaryGroupRedux'
import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'

export function* getCurrentUserGroups() {
  yield call(services.beneficiaryGroup.getAll, null, function* ({ ok, data }) {
    ok
      ? yield put(BeneficiaryGroupActions.setGroups(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getGroup({ payload }) {
  const { id } = payload

  yield call(services.beneficiaryGroup.get, { id }, function* ({ ok, data }) {
    ok
      ? yield put(BeneficiaryGroupActions.setGroup(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeGroup({ payload }) {
  const { id } = payload
  const result = yield call(services.beneficiaryGroup.remove, { id })

  yield put(NotificationActions.status(result))
}

export function* saveGroup({ payload }) {
  const { group } = payload

  yield call(services.beneficiaryGroup.save, group, function* ({ ok, data }) {
    if (ok) {
      yield put(BeneficiaryGroupActions.addGroups([data]))
      yield put(BeneficiaryGroupActions.setGroup(data))
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}
