import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'

export default class SideResourceButton extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    attachmentLabel: PropTypes.string,
    attachmentIcon: PropTypes.string,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    attachmentLabel: '',
    attachmentIcon: '',
  }

  render() {
    const { id, label, icon, attachmentIcon, attachmentLabel, isDisabled } = this.props
    return (
      <div className={`side-resource-item ${icon} ${isDisabled ? 'disabled' : ''}`} id={id}>
        <div className="main">
          <Icon icon={icon} color={Icon.color.Grey3} />
          <p className="label">{label}</p>
        </div>

        {attachmentLabel && (
          <div className="attachment">
            {attachmentIcon && <Icon icon={attachmentIcon} color={Icon.color.Grey3} />}

            <p>{attachmentLabel}</p>
          </div>
        )}
      </div>
    )
  }
}
