import ApiService from './ApiService'

const api = ApiService

export const get = ({ token, module_id }) => {
  const url = `/modules/${module_id}`

  return api.get(url, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const saveModuleFormAnswers = ({ token, module_id, answers }) => {
  const url = `/module-forms/${module_id}/questions/answers`

  return api.put(url, answers, { headers: { Authorization: `Bearer ${token}` } })
}

export const saveSynthesisNotes = ({ token, module_id, notes, description }) => {
  return api.put(
    `/module-syntheses/${module_id}/beneficiary-notes`,
    { notes, description },
    { headers: { Authorization: `Bearer ${token}` } },
  )
}

export const saveModuleCompleteStatus = ({ token, module_id, done }) => {
  return api.put(
    `/modules/${module_id}/done`,
    { done },
    { headers: { Authorization: `Bearer ${token}` } },
  )
}
