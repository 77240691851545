import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withI18n } from '../../containers/global/Translator/Translator'
import Logo from '../../../public/static/logos/logo-my-talents.svg'

@withI18n
export default class ChangeBrowserPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  render() {
    const { t } = this.props

    return (
      <div className="change-browser-page">
        <Logo className="logo" />

        <div className="content">
          <div className="message">{t('change_browser.message')}</div>
        </div>
      </div>
    )
  }
}
