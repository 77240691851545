import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import DescriptionTextArea from './DescriptionTextArea'

@FieldContainer({ type: 'text' })
export default class DescriptionTextAreaContainer extends Component {
  render() {
    return <DescriptionTextArea {...this.props} />
  }
}
