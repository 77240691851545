import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../atoms/IconButton/IconButton'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'

export default class AboCard extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    available: PropTypes.number.isRequired,
    allocated: PropTypes.number.isRequired,
    isNew: PropTypes.bool,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      noConsultantsAvailable: PropTypes.string.isRequired,
      noConsultantsAllocated: PropTypes.string.isRequired,
    }),
  }

  static defaultProps = {
    isNew: false,
  }

  handleMinusClick = () => {
    const { allocated } = this.props
    this.props.onChange({ value: allocated && allocated - 1 })
  }

  handlePlusClick = () => {
    this.props.onChange({ value: this.props.allocated + 1 })
  }

  render() {
    const { allocated, available, isNew, locale } = this.props

    return (
      <div className="abo-card">
        <IconCardTitle label={locale.title} icon="card" />

        {!isNew && (
          <div className="total-places card">
            <div className="card-title">{locale.noConsultantsAllocated}</div>
            <div className="card-count">{available}</div>
          </div>
        )}

        <div className="total-places card">
          <div className="card-title">{locale.noConsultantsAvailable}</div>
          <div className="card-content">
            <IconButton
              icon="round-minus"
              color={allocated ? 'green1' : 'grey1'}
              onClick={this.handleMinusClick}
            />
            <div className="card-count">{allocated}</div>
            <IconButton icon="round-plus" color="green1" onClick={this.handlePlusClick} />
          </div>
        </div>
      </div>
    )
  }
}
