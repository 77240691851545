import { useMemo } from 'react'
import { I18n } from 'react-redux-i18n'

export function useI18n() {
  return useMemo(
    () => ({
      t: I18n.t.bind(I18n),
      l: I18n.l.bind(I18n),
    }),
    [],
  )
}
