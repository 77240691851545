import { call, put } from 'redux-saga/effects'

import * as I18nActions from 'react-redux-i18n'
import { actions as LocaleActions } from '../redux/LocaleRedux'
import { services } from '../services'

export function* getTranslations({ payload }) {
  const { locale } = payload

  yield call(
    services.translation.getAll,
    { locale },
    function* ({ ok, data }) {
      yield put({ type: I18nActions.LOAD_TRANSLATIONS, translations: { [locale]: data } })
      yield put({ type: I18nActions.SET_LOCALE, locale })
      yield put(LocaleActions.setLocale(locale))
    },
    true,
  )
}
