// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icons
import Icon from '../Icon/Icon'

// moduleType
const moduleType = {
  rdv: Icon.icon.Rdv,
  quiz: Icon.icon.Quiz,
  free: Icon.icon.Free,
  freeActive: Icon.icon.FreeActive,
  exercice: Icon.icon.Exercice,
  report: Icon.icon.Report,
  document: Icon.icon.Document,
  video: Icon.icon.Video,
  to_return_document: Icon.icon.FileReturn,
  file: Icon.icon.File,
  centraltest: Icon.icon.CentralTest,
}

// Proptypes
const propTypes = {
  moduleType: PropTypes.oneOf(Object.values(moduleType)).isRequired,
  color: PropTypes.oneOf(Object.values(Icon.color)),
}

const defaultProps = {
  color: null,
}

class IconModuleType extends Component {
  icon() {
    switch (this.props.moduleType) {
      case moduleType.rdv:
        return <Icon icon={moduleType.rdv} color={this.props.color} />
      case moduleType.quiz:
        return <Icon icon={moduleType.quiz} color={this.props.color} />
      case moduleType.free:
        return <Icon icon={moduleType.free} color={this.props.color} />
      case moduleType.freeActive:
        return <Icon icon={moduleType.freeActive} color={this.props.color} />
      case moduleType.exercice:
        return <Icon icon={moduleType.exercice} color={this.props.color} />
      case moduleType.report:
        return <Icon icon={moduleType.report} color={this.props.color} />
      case moduleType.document:
        return <Icon icon={moduleType.document} color={this.props.color} />
      case moduleType.video:
        return <Icon icon={moduleType.video} color={this.props.color} />
      case moduleType.to_return_document:
        return <Icon icon={moduleType.to_return_document} color={this.props.color} />
      case moduleType.file:
        return <Icon icon={moduleType.file} color={this.props.color} />
      case moduleType.centraltest:
        return <Icon icon={moduleType.centraltest} color={this.props.color} />
      default:
        break
    }
  }

  render() {
    const { moduleType } = this.props
    return <div className={`icon-module-type-${moduleType}`}>{this.icon()}</div>
  }
}

IconModuleType.propTypes = propTypes
IconModuleType.moduleType = moduleType
IconModuleType.defaultProps = defaultProps

export default IconModuleType
