import { call, put, select } from 'redux-saga/effects'

import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as CourseActions, selectors as CourseSelectors } from '../redux/CourseRedux'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../redux/CourseTemplateRedux'

export function* setUrl({ payload }) {
  const { id, url, type, stepIndex, moduleIndex } = payload

  yield call(services.resource.setUrl, { id, type, url }, function* ({ ok, data }) {
    if (!ok) {
      yield put(NotificationActions.status({ ok, data }))
    } else {
      const course = yield select(CourseSelectors.course)
      const step = course.steps[stepIndex]
      const module = step.modules[moduleIndex]

      const newModule = {
        ...module,
        data: {
          ...data,
          resources: module.data.resources.map((res) => (res.id === id ? data : res)),
        },
      }

      const newStep = {
        ...step,
        modules: step.modules.map((mod, modIndex) => {
          if (modIndex === moduleIndex) {
            return newModule
          }
          return mod
        }),
      }

      const newCourse = {
        ...course,
        steps: course.steps.map((ste, steIndex) => {
          if (steIndex === stepIndex) {
            return newStep
          }
          return ste
        }),
      }

      yield put(CourseActions.setCourse(newCourse))
    }
  })
}

export function* setPrivate({ payload }) {
  const { id, isPrivate, stepIndex, moduleIndex } = payload
  const course = yield select(CourseTemplateSelectors.template)
  const step = course.steps[stepIndex]
  const module = step.modules[moduleIndex]
  const { resources } = module.data

  const resourceIndex = resources.findIndex((res) => res.id === id)
  const resource = resources[resourceIndex]
  const newResource = { ...resource, private: isPrivate }

  yield call(services.resource.save, newResource, function* ({ ok, data }) {
    if (!ok) {
      return yield put(NotificationActions.status({ ok, data }))
    }

    yield put(
      CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, {
        ...module,
        resources: resources.splice(resourceIndex, 1, newResource),
      }),
    )
  })
}
