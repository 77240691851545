import { Map } from 'immutable'
import { createAction, createReducer } from 'redux-act'

//
// Initial state
//
const initialState = Map({
  isModalOpen: false,
  selectedInModal: null,
  tests: null,
  isLoading: false,
  isConfirmationModalOpen: false,
  assessments: null,
})

//
// Actions
//
export const actions = {
  createAccount: createAction('createAccount'),
  openModal: createAction('openModal'),
  closeModal: createAction('closeModal'),
  setSelected: createAction('setSelected'),
  fetchTests: createAction('fetchTests'),
  setTests: createAction('setTests'),
  inviteBeneficiary: createAction('inviteBeneficiary'),
  setLoading: createAction('setLoading'),
  setConfirmationModalStatus: createAction('setConfirmationModalStatus'),
  getAssessments: createAction('getAssessments', (user_id) => ({ user_id })),
  setAssessments: createAction('setAssessments'),
  toggleReport: createAction('toggleReport', (module_id) => ({ module_id })),
  manualLink: createAction('manualLink'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.openModal]: (state) => state.set('isModalOpen', true),
    [actions.closeModal]: (state) => state.set('isModalOpen', false),
    [actions.setSelected]: (state, { id, name }) => state.set('selectedInModal', { id, name }),
    [actions.setTests]: (state, tests) => state.set('tests', tests),
    [actions.setLoading]: (state, loading) => state.set('isLoading', loading),
    [actions.setConfirmationModalStatus]: (state, status) =>
      state.set('isConfirmationModalOpen', status),
    [actions.setAssessments]: (state, assessments) => state.set('assessments', assessments),
  },
  initialState,
)

//
// Selectors
//

export const selectors = {
  isModalOpen: (state) => state.centraltest.get('isModalOpen'),
  selectedInModal: (state) => state.centraltest.get('selectedInModal'),
  tests: (state) => state.centraltest.get('tests'),
  isLoading: (state) => state.centraltest.get('isLoading'),
  isConfirmationModalOpen: (state) => state.centraltest.get('isConfirmationModalOpen'),
  assessments: (state) => state.centraltest.get('assessments'),
}
