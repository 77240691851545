import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircleButton from '../../atoms/CircleButton/CircleButton'

class ClosePopin extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    isEnabled: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
  }

  static defaultProps = {
    onClose: () => {},
    hasCloseButton: true,
    isEnabled: true,
  }

  constructor(props) {
    super(props)
    this.onClickClose = this.onClickClose.bind(this)
  }

  onClickClose() {
    this.props.onClose()
  }

  render() {
    return (
      <div className="close-popin">
        <div className="close-popin-content">
          {this.props.hasCloseButton && this.props.isEnabled ? (
            <CircleButton id="btn-close" icon="close" onClick={this.onClickClose} />
          ) : null}
          {this.props.children}
          {!this.props.isEnabled ? <div className="close-popin-overlay" /> : null}
        </div>
      </div>
    )
  }
}

export default ClosePopin
