import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary'
import CourseList from '../../containers/course/CourseList/CourseList'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import AttributeCoursePopin from '../../containers/popins/AttributeCoursePopin/AttributeCoursePopin'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  courses: CourseSelectors.courses(state),
  pathname: NavigationSelectors.pathname(state),
  user: UserSelectors.user(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getCourses: (id) => dispatch(CourseActions.getCoursesForBeneficiary(id)),
  back: () => dispatch(NavigationActions.back()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),

  resetCourses: () => dispatch(CourseActions.resetCourses()),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantBeneficiaryCoursesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    attribOpen: false,
  }

  handleAttribPopin = () => {
    this.setState(({ attribOpen }) => ({ attribOpen: !attribOpen }))
  }

  handleCardClick = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  componentDidMount() {
    if (!/\/(profile|courses)$/.test(this.props.pathname)) {
      this.props.redirect(`${this.props.pathname}/courses`)
    }

    this.props.getBeneficiary(this.props.id)
    this.props.getCourses(this.props.id)
  }

  componentWillUnmount() {
    this.props.resetCourses()
  }

  renderAttribPopin() {
    if (!this.state.attribOpen) {
      return null
    }

    return <AttributeCoursePopin open onClose={this.handleAttribPopin} />
  }

  renderContent() {
    const { user, beneficiary } = this.props
    const { consultants = [] } = beneficiary

    const me = consultants.find((consultant) => consultant.id === user.id)
    const isPrimaryConsultant = me ? me.is_primary : false
    return (
      <>
        {isPrimaryConsultant && (
          <div className="components">
            <CircleButton icon="plus" onClick={this.handleAttribPopin} />
          </div>
        )}

        <CourseList onCardClick={this.handleCardClick} />

        {this.renderAttribPopin()}
      </>
    )
  }

  render() {
    const { beneficiary } = this.props

    if (!beneficiary) {
      return null
    }

    return (
      <ConsultantBeneficiary>
        <div className="consultant-beneficiary-courses-page">{this.renderContent()}</div>
      </ConsultantBeneficiary>
    )
  }
}
