import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withI18n } from '../../global/Translator/Translator'
import Popin from '../../global/Popin/Popin'

import ActionPopin from '../../../components/organisms/ActionPopin/ActionPopin'
import ComboField from '../../../components/molecules/ComboField/ComboField'

import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  moduleTemplate: ModuleTemplateSelectors.templates(state),
})

const mapDispatchToProps = (dispatch) => ({
  setModuleTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class AddModulePopin extends Component {
  static propTypes = {
    t: PropTypes.func,
    open: PropTypes.bool.isRequired,
  }

  state = {
    type: 'form',
  }

  handleAddComboChange = ({ value }) => {
    this.setState({ type: value })
  }

  createModuleByType(type) {
    const data = {
      text: { resources: [] },
      form: { questions: [] },
    }

    return {
      type,
      title: '',
      data: data[type] || {},
    }
  }

  handleCreate = () => {
    const { type } = this.state
    const module = this.createModuleByType(type)

    this.props.setModuleTemplate(module)
    this.props.redirect(`${this.props.pathname}/edit`)
  }

  render() {
    const { t, open, onClose } = this.props
    const { type } = this.state

    return (
      <Popin onClose={onClose} open={open}>
        <ActionPopin
          onSubmit={this.handleCreate}
          onBack={onClose}
          title={t('module.add_module')}
          labelButton={t('actions.create')}
        >
          <div className="add-module-popin">
            <ComboField
              name="module_type"
              title={t('module.module_type')}
              value={type}
              options={[
                { label: t('module_types.form'), value: 'form' },
                { label: t('module_types.text'), value: 'text' },
              ]}
              onChange={this.handleAddComboChange}
            />
          </div>
        </ActionPopin>
      </Popin>
    )
  }
}
