import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Proptypes
const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelOn: PropTypes.string.isRequired,
  labelOff: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isOn: PropTypes.bool,
}

const defaultProps = {
  isOn: false,
}

// Component
class ToggleButton extends Component {
  onToggle = () => {
    this.props.onChange({
      id: this.props.id,
      value: !this.props.isOn,
    })
  }

  render() {
    const { labelOn, labelOff, isOn } = this.props

    return (
      <div className={classnames('toggle-button', isOn ? 'on' : '')} onClick={this.onToggle}>
        <span className="dot active-dot" />
        <span className="dot white-dot" />
        <p className="label label-on">{labelOn}</p>
        <p className="label label-off">{labelOff}</p>
      </div>
    )
  }
}

ToggleButton.propTypes = propTypes
ToggleButton.defaultProps = defaultProps

export default ToggleButton
