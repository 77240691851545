import React from 'react'
import PropTypes from 'prop-types'
// Icon
import Icon from '../../atoms/Icon/Icon'

const InfoField = ({ color, lightLabel, boldLabel }) => (
  <div className="info-field">
    <div className="light-label-container">
      <p className="light-label">{lightLabel}</p>
    </div>
    <div className="bold-label-container">
      {color && <Icon icon="dot" color={color} />}
      <p className="bold-label">{boldLabel}</p>
    </div>
  </div>
)

InfoField.propTypes = {
  color: PropTypes.string,
  lightLabel: PropTypes.string.isRequired,
  boldLabel: PropTypes.string,
}

InfoField.defaultProps = {
  boldLabel: '',
}

export default InfoField
