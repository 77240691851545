import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import MessagePopin from '../../../components/organisms/MessagePopin/MessagePopin'
import {
  actions as ContactActions,
  selectors as ContactSelectors,
} from '../../../redux/ContactRedux'
import { actions as MessageActions } from '../../../redux/MessageRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import BeneficiaryCloseNewMessage from '../../beneficiary/BeneficiaryCloseNewMessage/BeneficiaryCloseNewMessage'
import { withI18n } from '../Translator/Translator'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  action: WSSelectors.action(state, 'message.send'),
  contacts: ContactSelectors.contacts(state),
})

const mapDispatchToProps = (dispatch) => ({
  getContacts: () => dispatch(ContactActions.getContacts()),
  sendMessage: (message) => dispatch(MessageActions.sendMessage(message)),
  resetAction: () => dispatch(WSActions.reset('message.send')),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class NewMessage extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    isBeneficiary: PropTypes.bool,
  }

  static defaultProps = {
    isBeneficiary: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.isBeneficiary) {
      return null
    }

    if (state.recipients.length || !props.user) {
      return null
    }

    const consultant = props.user.consultants[0]
    const recipients = [
      {
        value: consultant.id,
        label: `${consultant.first_name} ${consultant.last_name}`,
      },
    ]

    return { recipients }
  }

  state = {
    showConfirm: false,
    newMessage: false,
    recipients: [],
    subject: '',
    message: '',
  }

  handleSubmit = () => {
    this.props.onClose()
    this.props.remove()
  }

  handleConfirmPopin = () => {
    const { subject, message } = this.state

    subject || message
      ? this.setState(({ showConfirm }) => ({ showConfirm: !showConfirm }))
      : this.handleClose()
  }

  handleClose = () => {
    this.setState({ recipient: [], subject: '', message: '' }, this.props.onClose)
  }

  handleChangeSubject = ({ value }) => {
    this.setState({ subject: value })
  }

  handleChangeMessage = ({ value }) => {
    this.setState({ message: value })
  }

  handleChangeRecipients = ({ values }) => {
    this.setState({ recipients: values })
  }

  messageHasBeenTouched = () => {
    this.props.handleTouchedMessage(this.state.recipients, this.state.subject, this.state.message)
  }

  handleSendMessage = () => {
    const { subject, message, recipients } = this.state

    this.props.sendMessage({
      subject,
      message,
      recipients: recipients.map(({ value }) => value),
    })
  }

  formatContacts = () => {
    const { contacts } = this.props

    return contacts.map(({ id, first_name, last_name }) => ({
      value: id,
      label: `${first_name} ${last_name}`,
    }))
  }

  renderConfirmClosePopin = () => {
    return (
      <BeneficiaryCloseNewMessage
        onClose={this.handleClose}
        remove={this.handleConfirmPopin}
        open={this.state.showConfirm}
      />
    )
  }

  componentDidMount() {
    if (!this.props.contacts.length) {
      this.props.getContacts()
    }
  }

  componentDidUpdate() {
    this.messageHasBeenTouched()
    if (this.props.action.resolved) {
      this.handleClose()
      this.props.resetAction()
    }
  }

  render() {
    const { t, isBeneficiary, action } = this.props

    const locale = {
      recipientTitle: t('message.recipient'),
      subjectTitle: t('message.subject_label'),
      messageTitle: t('message.message'),
      send: t('actions.send'),
      recipientPlaceholder: t('message.recipient_placeholder'),
      subjectPlaceholder: t('message.subject_placeholder'),
      messagePlaceholder: t('message.message_placeholder'),
    }

    return (
      <>
        <MessagePopin
          onClosePopin={this.handleConfirmPopin}
          onChangeSubject={this.handleChangeSubject}
          onChangeMessage={this.handleChangeMessage}
          onClickSend={this.handleSendMessage}
          onChangeRecipients={this.handleChangeRecipients}
          isEnabled={!action.pending}
          isBeneficiary={isBeneficiary}
          locale={locale}
          content={this.state}
          contacts={this.formatContacts()}
        />
        {this.renderConfirmClosePopin()}
      </>
    )
  }
}
