import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import _mapKeys from 'lodash/mapKeys'
import _sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

//
// Initial state
//
const initialState = Map({
  office: null,
  offices: {},
})

//
// Actions
//
export const actions = {
  addOffices: createAction('addOffices', (offices) => ({ offices: _mapKeys(offices, 'id') })),
  getOffice: createAction('getOffice', (id) => ({ id })),
  getOffices: createAction('getOffices', (filter) => ({ filter })),
  getCurrentUserOffice: createAction('getCurrentUserOffice'),
  saveOffice: createAction('saveOffice', (office, dontSet) => ({ office, dontSet })),
  saveCurrentUserOffice: createAction('saveCurrentUserOffice', (office) => ({ office })),
  setOffice: createAction('setOffice', (office) => ({ office })),
  removeOffice: createAction('removeOffice', (id) => ({ id })),
  setOffices: createAction('setOffices', (offices) => ({ offices: _mapKeys(offices, 'id') })),
  contactMyTalents: createAction('contactMyTalents'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setOffice]: (state, { office }) => state.merge({ office }),
    [actions.addOffices]: (state, { offices }) => state.mergeDeep({ offices }),
    [actions.setOffices]: (state, { offices }) => state.merge({ offices }),
    [actions.removeOffice]: (state, { id }) => state.deleteIn(['offices', id]),
  },
  initialState,
)

//
// Selectors
//
const office = (state) => state.office.get('office')
const officesRefs = (state) => state.office.get('offices')
const offices = createSelector(officesRefs, (offices) => _sortBy(offices, 'name'))

export const selectors = {
  office,
  offices,
}
