import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import EditResourcePopin from '../../containers/course/EditResourcePopin/EditResourcePopin'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import { withI18n } from '../../containers/global/Translator/Translator'
import ResourceList from '../../containers/module/ResourceList/ResourceList'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import {
  actions as ResourceTemplateActions,
  selectors as ResourceTemplateSelectors,
} from '../../redux/ResourceTemplateRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../redux/WSRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  templates: ResourceTemplateSelectors.templates(state),
  saveAction: WSSelectors.action(state, 'resourceTemplate.save'),
  currentTab: NavigationSelectors.query(state, 'tab') || 'consultant',
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  saveTemplate: (template) => dispatch(ResourceTemplateActions.saveTemplate(template)),
  getTemplates: (filter) => dispatch(ResourceTemplateActions.getTemplates(filter)),
  resetSave: () => dispatch(WSActions.reset('resourceTemplate.save')),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsultantResourcesPage extends Component {
  static propTypes = {
    t: PropTypes.func,
  }

  state = {
    scope: 'consultant',
    query: '',
    resource: null,
    videoPopin: '',
    videoTitle: '',
  }

  handleSearch = (query) => {
    this.setState({ query }, this.getTemplates)
  }

  handleTabChange = (scope) => {
    this.setState({ scope }, this.getTemplates)
    this.props.redirect(`${this.props.location.pathname}?tab=${scope}`)
  }

  renderTabs = () => {
    const { t } = this.props
    const links = [
      { id: 'consultant', label: t('consultant.my_resources') },
      { id: 'office', label: t('consultant.our_resources') },
      { id: 'mytalents', label: t('consultant.my_talents') },
    ]

    return (
      <LineMenu
        value={this.state.scope}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  handleAddClick = () => {
    this.setState({
      resource: {
        type: 'document',
      },
    })
  }

  handleEditPopinClose = () => {
    this.setState({ resource: null })
  }

  handleEditClick = (resource) => {
    this.setState({ resource })
  }

  handleEditSubmit = (resource) => {
    this.props.saveTemplate(resource)
    this.handleEditPopinClose()
  }

  renderVideo = () => {
    const { videoPopin, videoTitle } = this.state

    return (
      <VideoPopin
        title={videoTitle}
        videoId={videoPopin}
        open={!!videoPopin}
        onClose={this.handleCloseVideoPopin}
      />
    )
  }

  handleVideoClicked = (url, title) => {
    this.setState({ videoPopin: url, videoTitle: title })
  }

  handleCloseVideoPopin = () => {
    this.setState({ videoPopin: '', videoTitle: '' })
  }

  renderContent = () => (
    <>
      <div className="add-button-wrapper">
        {this.state.scope === 'consultant' && (
          <CircleButton icon="plus" onClick={this.handleAddClick} />
        )}
      </div>

      <ResourceList
        resources={this.props.templates}
        editable={this.state.scope === 'consultant'}
        onEditClick={this.handleEditClick}
        onVideoClicked={this.handleVideoClicked}
      />

      <EditResourcePopin
        open={!!this.state.resource}
        resource={this.state.resource}
        canPublish
        onSubmit={this.handleEditSubmit}
        onClose={this.handleEditPopinClose}
      />
      {this.renderVideo()}
    </>
  )

  renderSearchBar() {
    return <TopSearch onSearch={this.handleSearch} />
  }

  getTemplates = () => {
    const { scope, query } = this.state
    this.props.getTemplates({ scope, title: query })
  }

  componentDidMount() {
    const { currentTab } = this.props

    this.getTemplates()

    if (this.state.scope !== currentTab) {
      this.handleTabChange(currentTab)
    }
  }

  componentDidUpdate() {
    const { saveAction } = this.props
    const { resolved } = saveAction

    if (resolved) {
      this.handleEditPopinClose()
      this.props.resetSave()
    }
  }

  render() {
    const aside = <ConsultantPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="consultant-resources-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
