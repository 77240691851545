import { call, put, select } from 'redux-saga/effects'
import _pick from 'lodash/pick'

import { services } from '../services'
import { actions as CourseActions, selectors as CourseSelectors } from '../redux/CourseRedux'
import { selectors as CourseTemplateSelectors } from '../redux/CourseTemplateRedux'
import { actions as NavigationActions } from '../redux/NavigationRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { selectors as UserSelectors } from '../redux/UserRedux'
import { I18n } from 'react-redux-i18n'

export function* getCourses({ payload }) {
  const { filter } = payload

  //Check if user is beneficiary
  const user = yield select(UserSelectors.user)
  if (user && user.role !== 'beneficiary') {
    return
  }

  yield call(services.course.getAll, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(CourseActions.setCourses(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getCourse({ payload }) {
  const { id } = payload

  let userCourses = yield select(CourseSelectors.coursesObj)

  if (Object.values(userCourses).length === 0) {
    yield call(getCourses, { payload: { filter: undefined } })
    userCourses = yield select(CourseSelectors.coursesObj)
  }

  if (userCourses[id]) {
    const course = yield select(CourseSelectors.course)
    const ignoredFields = yield select(CourseTemplateSelectors.ignoredFields)
    const dataToMerge = course && id === course.id ? _pick(course, ignoredFields) : {}

    yield call(services.course.get, { id }, function* ({ ok, data }) {
      ok
        ? yield put(CourseActions.setCourse({ ...data, ...dataToMerge }))
        : yield put(NotificationActions.status({ ok, data }))
    })
  } else {
    yield put(NavigationActions.push('/'))
  }
}

export function* exportCourse({ payload }) {
  const { id } = payload

  yield call(services.course.exportCourse, { id }, function* ({ ok, data }) {
    ok
      ? yield put(NotificationActions.add('success', I18n.t(`beneficiary.export_in_progress`)))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getCoursesForBeneficiary({ payload }) {
  const { id } = payload

  yield call(services.course.getAllForBeneficiary, { id }, function* ({ ok, data }) {
    ok
      ? yield put(CourseActions.setCourses(data.data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeCourse({ payload }) {
  const { id } = payload
  const result = yield call(services.course.remove, { id })

  yield put(NotificationActions.status(result))
}

export function* exportSynthesis({ payload }) {
  const { id } = payload
  const { ok, data } = yield call(services.course.exportSynthesis, { id })

  ok
    ? yield put(NavigationActions.open(data.url))
    : yield put(NotificationActions.status({ ok, data }))
}
