import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmptyCard from '../../components/molecules/EmptyCard/EmptyCard'
import SectionTitle from '../../components/molecules/SectionTitle/SectionTitle'
import ConsultantCard from '../../components/organisms/ConsultantCard/ConsultantCard'
import DisabledConsultantCard from '../../components/organisms/DisabledConsultantCard/DisabledConsultantCard'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import Page from '../../containers/global/Page/Page'
import { withI18n } from '../../containers/global/Translator/Translator'
import ManagerConsultantActivate from '../../containers/manager/ManagerConsultantActivate/ManagerConsultantActivate'
import ManagerConsultantRemove from '../../containers/manager/ManagerConsultantRemove/ManagerConsultantRemove'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../redux/ConsultantRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as CabinetSelectors } from '../../redux/OfficeRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  consultants: ConsultantSelectors.officeConsultants(state),
  office: CabinetSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  getConsultants: () => dispatch(ConsultantActions.getOfficeConsultants()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

@Page
@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerConsultantsPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    getOffice: PropTypes.func,
    getConsultants: PropTypes.func,
    redirect: PropTypes.func,
  }

  state = {
    consultantToDelete: null,
    consultantToActivate: null,
  }

  handleCreateConsultantClick = () => {
    this.props.redirect(`${this.props.pathname}/new`)
  }

  handleEditConsultantClick = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  handleToggleShowConfirmRemovePopin = ({ id } = {}) => {
    this.setState({ consultantToDelete: id ? Number(id) : null })
  }

  handleToggleShowConfirmActivatePopin = ({ id } = {}) => {
    this.setState({ consultantToActivate: id ? Number(id) : null })
  }

  componentDidMount() {
    this.props.getOffice()
    this.props.getConsultants()
  }

  renderTabs() {
    const { t } = this.props
    const links = [{ id: 'consultants', label: t('consultant.consultants') }]

    return <LineMenu value={links[0].id} links={links} />
  }

  renderRemovePopin() {
    const { consultantToDelete } = this.state

    if (!consultantToDelete) {
      return null
    }

    return (
      <ManagerConsultantRemove
        id={consultantToDelete}
        onClose={this.handleToggleShowConfirmRemovePopin}
      />
    )
  }

  renderActivatePopin() {
    const { consultantToActivate } = this.state

    if (!consultantToActivate) {
      return null
    }

    return (
      <ManagerConsultantActivate
        id={consultantToActivate}
        onClose={this.handleToggleShowConfirmActivatePopin}
      />
    )
  }

  renderCards(consultants) {
    const { t, office } = this.props
    const { no_consultants = consultants.length } = office || {}

    const emptyCards = Array.from({ length: no_consultants - consultants.length }, (v, i) => (
      <EmptyCard key={`empty-${i}`} onClick={this.handleCreateConsultantClick} />
    ))

    const cards = consultants.map((consultant) => {
      const statuses = t('beneficiary.statuses')
      const beneficiariesByStatuses = Object.keys(statuses).map((statusKey) => {
        return {
          count: consultant.beneficiaries.filter(
            ({ status, is_primary }) => is_primary && status === statusKey,
          ).length,
          statusKey,
          statusLabel: statuses[statusKey],
        }
      })

      return (
        <ConsultantCard
          key={consultant.id}
          id={consultant.id.toString()}
          firstName={consultant.first_name}
          lastName={consultant.last_name}
          email={consultant.email}
          phone={consultant.phone}
          avatar={consultant.image_url}
          beneficiariesByStatuses={beneficiariesByStatuses}
          onEdit={this.handleEditConsultantClick}
          onDelete={this.handleToggleShowConfirmRemovePopin}
          isDeletable
          locale={{
            beneficiariesLabel: t('manager.beneficiaries'),
            beneficiariesInStatus: t('manager.beneficiaries_in_status'),
          }}
          assessments={{
            pending: consultant.pending_assessments_count,
            completed: consultant.completed_assessments_count,
          }}
        />
      )
    })

    return cards.concat(emptyCards)
  }

  renderDisabledCards(consultants, enabledConsultantsLength) {
    const { office } = this.props
    const { no_consultants } = office
    const remainingSlots = no_consultants - enabledConsultantsLength

    const disabled = !(remainingSlots > 0)

    const cards = consultants.map((consultant) => {
      return (
        <DisabledConsultantCard
          key={consultant.id}
          id={consultant.id.toString()}
          firstName={consultant.first_name}
          lastName={consultant.last_name}
          email={consultant.email}
          phone={consultant.phone}
          avatar={consultant.image_url}
          onActivate={this.handleToggleShowConfirmActivatePopin}
          disabled={disabled}
        />
      )
    })

    return cards
  }

  renderConsultants() {
    const { t, office, consultants } = this.props

    if (!office) {
      return null
    }

    const enabledConsultants = []
    const disabledConsultants = []

    consultants.forEach((consultant) => {
      if (consultant.disabled_at) {
        disabledConsultants.push(consultant)
      } else {
        enabledConsultants.push(consultant)
      }
    })

    const enabledConsultantsNode = (
      <>
        <div
          className="informationsNoConsultants"
          dangerouslySetInnerHTML={{
            __html: t('office.no_consultants', { count: office.no_consultants }),
          }}
        />

        <div className="cards">{this.renderCards(enabledConsultants)}</div>
      </>
    )

    if (disabledConsultants.length > 0) {
      const disabledConsultantsNode = (
        <div className="cards">
          {this.renderDisabledCards(disabledConsultants, enabledConsultants.length)}
        </div>
      )

      return (
        <div className="consultants-wrapper">
          <SectionTitle label={t('consultant.enabled_consultants')} />
          <div className="enabled-consultants">{enabledConsultantsNode}</div>
          <SectionTitle label={t('consultant.disabled_consultants')} />
          <div className="disabled-consultants">{disabledConsultantsNode}</div>
        </div>
      )
    }

    return enabledConsultantsNode
  }

  renderContent() {
    return (
      <>
        {this.renderConsultants()}
        {this.renderRemovePopin()}
        {this.renderActivatePopin()}
      </>
    )
  }

  render() {
    const aside = <ManagerPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="manager-consultants-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
