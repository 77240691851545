import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isValid, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import _pick from 'lodash/pick'
import { withI18n } from '../../../containers/global/Translator/Translator'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import CheckBoxField from '../../molecules/CheckBoxField/CheckBoxFieldContainer'
import InputField from '../../molecules/InputField/InputFieldContainer'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state, props) => ({
  valid: isValid('login')(state),
  initialValues: _pick(props, ['email', 'password', 'persist']),
})

const formProperties = {
  form: 'login',
  asyncChangeFields: ['email', 'password'],
  asyncValidate: (values) =>
    asyncValidate(values, {
      email: { rule: 'required|email' },
      password: { rule: 'required' },
    }),
}

@withI18n
@connect(mapStateToProps)
@reduxForm(formProperties)
export default class LoginForm extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    password: PropTypes.string,
    persist: PropTypes.bool,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      email: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      passwordPlaceholder: PropTypes.string.isRequired,
      persist: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    persist: false,
    disabled: false,
    onSubmit: () => undefined,
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(this.props.onSubmit)(values)
  }

  render() {
    const { disabled, valid, locale } = this.props

    return (
      <form onSubmit={this.handleSubmit} className="login-form" noValidate>
        <InputField
          name="email"
          placeholder={locale.emailPlaceholder}
          title={locale.email}
          type="email"
          required
          disabled={disabled}
        />

        <InputField
          name="password"
          placeholder={locale.passwordPlaceholder}
          title={locale.password}
          type="password"
          required
          disabled={disabled}
        />

        <Link className="forgot-password" to="/auth/forgot-password">
          {locale.forgotPassword}
        </Link>

        <CheckBoxField name="persist" label={locale.persist} disabled={disabled} />

        <PrimaryButton
          label={locale.cta}
          id="connect"
          type="submit"
          onClick={this.handleSubmit}
          disabled={disabled || !valid}
        />
      </form>
    )
  }
}
