import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import { useI18n } from '../../../hooks/useI18n'

  const ParcoursCard = ({
                        course,
                        onEditClick,
                        onCloneClick,
                        onRemoveClick,
                        onCardClick,
                        onPreviewClick,
                        onTogglePublishClick,
                      }) => {
  const { t } = useI18n()
  const { id, title, coverUrl, stepsCount, modulesCount } = course

  const locale = {
    steps: t('step.steps'),
    modules: t('module.modules'),
    edit: t('actions.edit'),
    clone: t('actions.clone'),
    remove: t('actions.remove'),
    preview: t('actions.preview'),
    publish: t('actions.publish'),
    unpublish: t('actions.unpublish'),
  }

  const handleEditClick = () => {
    onEditClick({ id })
  }

  const handleCloneClick = () => {
    onCloneClick({ id })
  }

  const handleRemoveClick = () => {
    onRemoveClick({ id })
  }

  const handlePreviewClick = () => {
    onPreviewClick({ id })
  }

  const handleCardClick = () => {
    onCardClick({ id })
  }

  const truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 95
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen

    return fullStr.substr(0, charsToShow) + separator
  }

  const renderActions = () => {
    const {
      isEditable,
      isCloneable,
      isRemovable,
      canPreview,
      canPublish,
      published,
      isPublishable,
    } = course

    const options = [
      canPreview && { key: 'preview', icon: Icon.icon.Preview2, handler: handlePreviewClick },
      isEditable && { key: 'edit', icon: Icon.icon.Edit2, handler: handleEditClick },
      isCloneable && { key: 'clone', icon: Icon.icon.Copy, handler: handleCloneClick },
      isRemovable &&
      !canPublish && { key: 'remove', icon: Icon.icon.Trash2, handler: handleRemoveClick },
      isPublishable &&
      canPublish &&
      !published && {
        key: 'publish',
        icon: Icon.icon.Publish,
        handler: onTogglePublishClick,
        color: Icon.color.Accent,
      },
      isPublishable &&
      canPublish &&
      published && {
        key: 'unpublish',
        icon: Icon.icon.FreeActive,
        handler: onTogglePublishClick,
        color: Icon.color.Orange1,
      },
    ]

    const actions = options
      .filter((action) => action)
      .map(({ key, icon, handler }) => (
        <div className="action-button" key={key} onClick={handler}>
          <Icon icon={icon} color={icon.color || Icon.color.Grey2} />
          <span>{locale[key]}</span>
        </div>
      ))

    if (actions.length === 0) {
      return null
    }

    return <div className="actions">{actions}</div>
  }

  const backgroundImageUrl = coverUrl || '/static/images/course-default-background.jpg'

  const { percentage_done } = course

  return (
    <div className="parcours-card">
      <div className="parcours-card-inner" onClick={handleCardClick}>
        <div
          className="parcours-image"
          style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        />

        <div className="bottom">
          <div className="label">{truncateString(title)}</div>

          <div className="course-informations">
            <div className="entry">
              <div className="entry-label">{locale.steps}</div>
              <div className="entry-count">{stepsCount}</div>
            </div>

            <div className="entry">
              <div className="entry-label">{locale.modules}</div>
              <div className="entry-count">{modulesCount}</div>
            </div>

            {percentage_done !== undefined && (
              <div className="entry">
                <div className="entry-label">Progression</div>
                <div className="entry-count">{percentage_done} %</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {renderActions()}
    </div>
  )
}

ParcoursCard.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coverUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    stepsCount: PropTypes.number.isRequired,
    modulesCount: PropTypes.number.isRequired,
    published: PropTypes.bool,
    isEditable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isCloneable: PropTypes.bool,
    isPublishable: PropTypes.bool,
    canPreview: PropTypes.bool,
    canPublish: PropTypes.bool,
    percentage_done: PropTypes.number,
  }),
  onEditClick: PropTypes.func,
  onCloneClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onCardClick: PropTypes.func,
}

ParcoursCard.defaultProps = {
  isRemovable: false,
  isCloneable: false,
  isPublishable: false,
  published: false,
  canPreview: false,
  canPublish: false,
  onEditClick: ({ id }) => undefined,
  onCloneClick: ({ id }) => undefined,
  onRemoveClick: ({ id }) => undefined,
  onPreviewClick: ({ id }) => undefined,
  onCardClick: () => {
  },
}
export default ParcoursCard
