import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as UserActions } from '../../../redux/UserRedux'
import { useNavigation } from '../../../hooks/useNavigation'
import { useI18n } from '../../../hooks/useI18n'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'

const ManagerPageAside = () => {
  const dispatch = useDispatch()
  const office = useSelector(OfficeSelectors.office)
  const { pathname, redirect } = useNavigation()
  const { t } = useI18n()
  const isPortalActive = useSelector(portalSelectors.isActive)

  const logout = () => {
    dispatch(UserActions.logout())
  }

  const handleMenuChange = ({ id }) => {
    redirect(id)
  }

  const getLinks = () => {
    return [
      {
        id: '/manager/consultants',
        label: t('consultant.consultants'),
        icon: Icon.icon.People,
      },
      {
        id: '/manager/groups',
        label: t('accompaniment.accompaniments'),
        icon: Icon.icon.Paperboard,
      },
      {
        id: '/manager/beneficiaries/me',
        label: t('beneficiary.beneficiaries'),
        icon: Icon.icon.Free,
      },
      {
        id: '/manager/statistics',
        label: t('statistics.statistics'),
        icon: Icon.icon.Piechart,
      },
      {
        id: '/manager/office',
        label: t('office.office'),
        icon: Icon.icon.Building,
      },
    ]
  }

  const renderHeader = () => {
    if (!office) {
      return null
    }

    return (
      <>
        {!isPortalActive && (
          <>
            <img
              className="mytalents-logo"
              alt="Logo"
              src="/static/logos/logo-my-talents-dark.png"
            />
            <div className="separator" />
          </>
        )}
        <Link to="/manager/office">
          <img className="office-logo" src={office.image_url} alt={office.name} />
          <span className="office-name">{office.name}</span>
        </Link>
        {renderTutorialButton()}
      </>
    )
  }

  const renderTutorialButton = () => {
    return (
      <div className="tutorial">
        <Icon icon="help" color="accent" />
        <a href={t('manager_tutorial.url')} target="_blank">
          {t('manager_tutorial.tutorial')}
        </a>
      </div>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return <SideMenu links={links} pathname={pathname} onClick={handleMenuChange} />
  }

  const renderFooter = () => {
    return <LogoutButton label={t('actions.logout')} onClick={logout} />
  }

  return (
    <div className="manager-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()} />
    </div>
  )
}

export default ManagerPageAside
