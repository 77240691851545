import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Draggable from '../../../containers/dragdrop/Draggable/Draggable'
import Droppable from '../../../containers/dragdrop/Droppable/Droppable'

import LineMenu from '../LineMenu/LineMenu'
import SideQuestionTypeItem from '../../molecules/SideQuestionTypeItem/SideQuestionTypeItem'

export default class SideQuestionTypeSelector extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    questionTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  renderQuestionTypes = () => {
    const { questionTypes } = this.props
    const rows = questionTypes.map(({ id, icon, label, type }, i) => (
      <Draggable id={id} key={i} index={i} props={{ type }}>
        <SideQuestionTypeItem id={id} label={label} icon={icon} />
      </Draggable>
    ))

    return (
      <div className="question-types-container">
        <Droppable id="question-types-list" type="question-list" isDropDisabled>
          {rows}
        </Droppable>
      </div>
    )
  }

  render() {
    const { title } = this.props

    return (
      <div className="side-question-type-selector">
        <LineMenu links={[{ id: 'toolbox', label: title }]} value="toolbox" />
        {this.renderQuestionTypes()}
      </div>
    )
  }
}
