import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const InputField = ({
  title,
  placeholder,
  name,
  value,
  type,
  readonly,
  required,
  disabled,
  autoComplete,
  autoFocus,
  onBlur,
  onFocus,
  onChange,
  inputProps,
  error,
}) => {
  const className = classnames('input-field', name, readonly && 'read-only')

  const handleChange = (e) => {
    return inputProps
      ? inputProps.onChange(e)
      : onChange({ id: name, value: e.currentTarget.value })
  }

  return (
    <div className={className}>
      {title && (
        <div className="common-form-label">
          {title}
          {required && <>*</>}
        </div>
      )}

      <input
        value={value ?? inputProps?.value}
        name={name}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        type={type}
        autoComplete={autoComplete ? 'on' : 'off'}
        autoFocus={autoFocus}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        {...inputProps}
      />

      <div className="common-form-separator" />

      {error && (
        <div className="error">
          <span className="error">{error.message}</span>
        </div>
      )}
    </div>
  )
}

InputField.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel', 'url']),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  focus: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  error: PropTypes.object,
}

export default InputField
