import { createAction, createReducer } from 'redux-act'

const initialState = {
  slug: null,
  theme: null,
  labels: null,
  assets: null,
  isActive: false,
}

export const actions = {
  getOfficeSettings: createAction('getOfficeSettings'),
  setSlug: createAction('setSlug'),
  setTheme: createAction('setTheme'),
  setLabels: createAction('setLabels'),
  setAssets: createAction('setAssets'),
}

export const reducer = createReducer(
  {
    [actions.setSlug]: (state, { slug }) => ({ ...state, slug }),
    [actions.setTheme]: (state, { theme }) => ({ ...state, theme }),
    [actions.setLabels]: (state, { labels }) => ({ ...state, labels }),
    [actions.setAssets]: (state, { assets }) => ({ ...state, assets }),
  },
  initialState,
)

const slug = (state) => state.portal.slug
const theme = (state) => state.portal.theme || load('_theme')
const labels = (state) => state.portal.labels || load('_labels')
const assets = (state) => state.portal.assets || load('_assets')
const isActive = (state) => theme(state) && labels(state) && assets(state)

export const selectors = {
  slug,
  theme,
  labels,
  assets,
  isActive,
}

const decode = (payload) => {
  try {
    return JSON.parse(payload)
  } catch (e) {
    return {}
  }
}

const load = (name) => {
  const payload = localStorage.getItem(name)

  if(!payload){
    return null
  }

  return decode(payload)
}
