import _pick from 'lodash/pick'
import ApiService from './ApiService'

const api = ApiService

export const prepareForSave = (template) => {
  const clone = JSON.parse(JSON.stringify(template))
  const fields = ['order', 'type', 'url', 'url_uploaded', 'title', 'private']

  return _pick(clone, fields)
}

export const save = ({ token, id, ...resource }) => {
  const prepared = prepareForSave(resource)

  return api.patch(`/module-text-resources/${id}`, prepared, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const setUrl = ({ token, id, type, url }) => {
  const body = {
    url_uploaded: url,
    type_uploaded: type || 'document',
  }

  return api.put(`/module-text-resources/${id}/url-uploaded`, body, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
