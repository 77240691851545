import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../redux/NavigationRedux'

export function useNavigation() {
  const dispatch = useDispatch()

  const redirect = useMemo(
    () => (destination) => {
      dispatch(NavigationActions.push(destination))
    },
    [dispatch],
  )

  const pathname = useSelector(NavigationSelectors.pathname)

  const paths = useSelector(NavigationSelectors.paths)

  return useMemo(
    () => ({
      redirect: redirect,
      pathname,
      paths,
    }),
    [paths, pathname, redirect],
  )
}
