import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

import { withI18n } from '../../../containers/global/Translator/Translator'

import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps, null)
export default class ManagerBeneficiaryCountByActivityStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  render() {
    const { t, managerOfficeStats } = this.props

    const socioProfessionalCategories = t('beneficiary_socio_professional_categories')
    const socioProfessionalCategoriesColumns = Object.keys(socioProfessionalCategories).map(
      (socioProfessionalCategoryKey) => ({
        id: socioProfessionalCategoryKey,
        title: socioProfessionalCategories[socioProfessionalCategoryKey],
        selector: `professional_category_${socioProfessionalCategoryKey}`,
      }),
    )

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      ...socioProfessionalCategoriesColumns,
      {
        id: 'total',
        title: t('statistics.beneficiaryCountByActivity.total'),
        selector: 'total',
      },
    ]

    let data = []
    if (managerOfficeStats.beneficiaryCountByActivity.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByActivity, 'label')
      const socioProfessionalCategoriesDefaultData = Object.keys(
        socioProfessionalCategories,
      ).reduce(
        (res, socioProfessionalCategoryKey) => ({
          ...res,
          [`professional_category_${socioProfessionalCategoryKey}`]: 0,
        }),
        {},
      )
      const defaultData = {
        ...socioProfessionalCategoriesDefaultData,
        total: 0,
      }
      const companyActivitySectors = t('beneficiary_company_activity_sectors')
      data = Object.keys(companyActivitySectors).map((companyActivitySectorKey, index) => {
        let values = defaultData
        if (dataValues[companyActivitySectorKey]) {
          values = {
            ...values,
            ...dataValues[companyActivitySectorKey],
          }
        }

        return {
          ...values,
          id: index,
          label: companyActivitySectors[companyActivitySectorKey],
        }
      })
      if (dataValues.total) {
        const values = {
          ...defaultData,
          ...dataValues.total,
        }
        data.push({
          ...values,
          id: 'total',
          label: t('statistics.beneficiaryCountByActivity.total'),
        })
      }
    }

    return (
      <StatsTable
        className="statsTable"
        title={t('statistics.beneficiaryCountByActivity.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }
}
