import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as NotesActions, selectors as NotesSelectors } from '../../../redux/NotesRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'

import { withI18n } from '../../global/Translator/Translator'
import SharedNotes from '../../../components/organisms/SharedNotes/SharedNotes'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  notes: NotesSelectors.notes(state),
  otherNotes: NotesSelectors.otherNotes(state),
  consultantNotes: NotesSelectors.consultantNotes(state),
})

const mapDispatchToProps = (dispatch) => ({
  getNotes: () => dispatch(NotesActions.getNotes()),
  setNotes: (notes) => dispatch(NotesActions.setNotes(notes)),
})

@withI18n
@connect(mapStateToProps, mapDispatchToProps)
export default class BeneficiaryPageAside extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
  }

  handleHeaderClick = ({ id }) => {
    if (this.state.expandedId === id) {
      this.setState({
        expandedId: null,
      })
    } else {
      this.setState({
        expandedId: id,
      })
    }
  }

  handleChange = ({ value }) => {
    let updated = false

    const newNotes = this.props.notes.map((note) => {
      if (note.consultant.id === this.props.user.id) {
        updated = true
        return {
          ...note,
          notes: value,
        }
      }

      return { ...note }
    })

    if (!updated) {
      newNotes.push({
        consultant: {
          id: this.props.user.id,
        },
        notes: value,
      })
    }

    this.props.setNotes(newNotes)
  }

  constructor(props) {
    super(props)

    this.state = {
      expandedId: 'consultant-notes',
    }
  }

  componentDidMount() {
    this.props.getNotes()
  }

  render() {
    const { consultantNotes, otherNotes, t, user } = this.props

    if (!consultantNotes || !otherNotes) {
      return null
    }

    const notes =
      !this.props.newBeneficiary && consultantNotes && consultantNotes.length > 0
        ? consultantNotes[0]
        : { notes: '' }
    const additionalNotes = this.props.newBeneficiary ? [] : otherNotes

    return (
      <div className="consultant-beneficiary-aside">
        {user.role === 'consultant' && (
          <SharedNotes
            id="consultant-notes"
            label={t('consultant.my_notes')}
            isExpanded={this.state.expandedId === 'consultant-notes'}
            hasPhoto={false}
            value={notes.notes}
            onHeaderClick={this.handleHeaderClick}
            editable
            onChange={this.handleChange}
            placeholder={t('notes.write_your_notes')}
          />
        )}
        {additionalNotes.length > 0 &&
          additionalNotes.map((otherNote) => (
            <SharedNotes
              key={otherNote.consultant.id}
              id={`consultant-notes-${otherNote.consultant.id}`}
              label={
                otherNote.is_primary ? t('consultant.primary_notes') : t('consultant.shared_notes')
              }
              isExpanded={this.state.expandedId === `consultant-notes-${otherNote.consultant.id}`}
              hasPhoto
              photoUrl={otherNote.consultant.image_url}
              value={otherNote.notes}
              onHeaderClick={this.handleHeaderClick}
            />
          ))}
      </div>
    )
  }
}
