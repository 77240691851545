import React, { useState } from 'react'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import Icon from '../../atoms/Icon/Icon'
import InputField from '../../molecules/InputField/InputField'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../../redux/CentralTestRedux'

const CentralTestOfficeManagerCard = ({ centraltestId = null, officeId }) => {
  const [id, setId] = useState(centraltestId)
  const dispatch = useDispatch()

  const handleChange = ({_name, value}) => {
    setId(value)
  }

  const onSubmit = () => {
    if (id === null) {
      return
    }

    dispatch(actions.manualLink({ office_id: officeId, company_id: id }))
  }

  return (
    <div className="centraltest-office-manager-card">
      <IconCardTitle label="Central Test" icon={Icon.icon.CentralTest} />

      <div className="card">
        <div className="card-title">Association compte</div>
        <div className="card-content">
          <InputField
            name="name"
            placeholder="Identifiant Central Test"
            onChange={handleChange}
            value={id}
          />
          <PrimaryButton
            outline
            id="office_centraltest_id"
            label="Lier le compte"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default CentralTestOfficeManagerCard
