import React, { PureComponent } from 'react'

export default (Component) => {
  return class Wrapper extends PureComponent {
    render() {
      return (
        <div className="page">
          <Component {...this.props} />
        </div>
      )
    }
  }
}
