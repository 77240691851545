import React, { Component } from 'react'
import CheckBoxField from './CheckBoxField'
import FieldContainer from '../FieldContainer/FieldContainer'

@FieldContainer({ type: 'checkbox' })
export default class CheckBoxFieldContainerFieldContainer extends Component {
  render() {
    return <CheckBoxField {...this.props} value={!!this.props.value} />
  }
}
