import { createAction, createReducer } from 'redux-act'
import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { selectors as UserSelectors } from './UserRedux'

//
// Initial state
//
const initialState = Map({
  notes: [],
})

//
// Actions
//
export const actions = {
  getNotes: createAction('getNotes'),
  setNotes: createAction('setNotes', (notes) => ({ notes })),
  saveNotes: createAction('saveNotes', (performNotification = false) => ({ performNotification })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setNotes]: (state, { notes }) => state.merge({ notes }),
  },
  initialState,
)

//
// Selectors
//
const notes = (state) => state.notes.get('notes')

const consultantNotes = createSelector(notes, UserSelectors.user, (notes, user) =>
  notes.filter((note) => note.consultant.id === user.id),
)

const otherNotes = createSelector(notes, UserSelectors.user, (notes, user) =>
  notes.filter((note) => note.consultant.id !== user.id),
)

export const selectors = {
  notes,
  consultantNotes,
  otherNotes,
}
