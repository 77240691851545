import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

class AvatarCircle extends Component {
  static propTypes = {
    photoUrl: PropTypes.string,
  }

  render() {
    const { photoUrl } = this.props
    const style = {
      backgroundImage: `url('${photoUrl}')`,
    }

    return (
      <div className="avatar-circle">
        {photoUrl ? (
          <div className="picture" style={style} />
        ) : (
          <div className="no-picture">
            <Icon icon={Icon.icon.Person} />
          </div>
        )}
      </div>
    )
  }
}

export default AvatarCircle
