import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import IconButton from '../../atoms/IconButton/IconButton'
import Icon from '../../atoms/Icon/Icon'

export default class ConsultantCard extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    avatar: PropTypes.string,
    beneficiariesByStatuses: PropTypes.array,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    isDeletable: PropTypes.bool,
    assessments: PropTypes.shape({
      pending: PropTypes.number,
      completed: PropTypes.number,
    }),
  }

  static defaultProps = {
    onDelete: ({ id }) => {},
    onEdit: ({ id }) => {},
    isDeletable: true,
  }

  handleEdit = () => {
    this.props.onEdit({ id: this.props.id })
  }

  handleDelete = () => {
    this.props.onDelete({ id: this.props.id })
  }

  renderBeneficiariesByStatus(status) {
    if (status.count === 0) {
      return null
    }

    const { locale } = this.props

    return (
      <div className="beneficiary-status-container" key={status.statusLabel}>
        <p>
          {locale.beneficiariesInStatus}&nbsp;
          <span>{status.statusLabel.toUpperCase()}</span>
        </p>
        <span className="status-count">{status.count}</span>
      </div>
    )
  }

  renderBeneficiariesStatuses() {
    return this.props.beneficiariesByStatuses.map(this.renderBeneficiariesByStatus.bind(this))
  }

  render() {
    const { firstName, lastName, email, phone, avatar, isDeletable, assessments } = this.props

    return (
      <div className="consultant-card">
        <div className="top-container" onClick={this.handleEdit}>
          <div className="avatar-container">
            <AvatarCircle photoUrl={avatar || ''} />
          </div>
          <div className="informations">
            <div className="fullname">
              {firstName} {lastName}
            </div>
            <div className="small-informations">
              <div className="entry email">{email}</div>
              <div className="entry phone">{phone}</div>
            </div>
            <div className="actions-button">
              <IconButton
                icon="edit"
                id="edit"
                onClick={this.handleEdit}
                color={Icon.color.Grey2}
              />

              {isDeletable && (
                <IconButton
                  icon="trash"
                  onClick={this.handleDelete}
                  id="delete"
                  color={Icon.color.Grey2}
                />
              )}
            </div>
          </div>
        </div>
        {this.props.beneficiariesByStatuses && (
          <div className="bottom-container">
            <div className="beneficiaries-statuses">
              {this.renderBeneficiariesStatuses()}
              <div className="beneficiary-status-container" key="pending-assessments">
                <p>
                  {'CENTRAL TEST'} <span>{'en attente'.toUpperCase()}</span>
                </p>
                <span className="status-count">{assessments.pending}</span>
              </div>
              <div className="beneficiary-status-container" key="completed-assessments">
                <p>
                  {'CENTRAL TEST'} <span>{'terminées'.toUpperCase()}</span>
                </p>
                <span className="status-count">{assessments.completed}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
