import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActionPopinHeader from '../../molecules/ActionPopinHeader/ActionPopinHeader'
import Icon from '../../atoms/Icon/Icon'

export default class ActionPopin extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    labelButton: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onBack: PropTypes.func,
    disabled: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.node),
  }

  static defaultProps = {
    disable: false,
    components: [],
    onBack: () => {},
    onSubmit: () => {},
  }

  render() {
    const { title, labelButton, onSubmit, onBack, disabled, components } = this.props

    return (
      <div className="action-popin">
        <ActionPopinHeader
          disabled={disabled}
          labelButton={labelButton}
          boldTitle={title}
          iconButton={Icon.icon.Back}
          onIconClick={onBack}
          components={components}
          onLabelButton={onSubmit}
        />

        <div className="content">{this.props.children}</div>
      </div>
    )
  }
}
