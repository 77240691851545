import _keyBy from 'lodash/keyBy'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'
import { withI18n } from '../../../containers/global/Translator/Translator'
import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

@withI18n
@connect(mapStateToProps)
export default class ManagerAppraisalTypesStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderAppraisalTypePoleEmploi = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'tax_excluded',
        title: t('statistics.bilanPoleEmploi.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanPoleEmploi.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanPoleEmploi.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanPoleEmploi.length > 0) {
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }

      data = managerOfficeStats.bilanPoleEmploi.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return (
      <StatsTable
        title={t('statistics.bilanPoleEmploi.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeFormationPlanBeneficiaryCount = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: t('statistics.bilanFormationPlan.label'),
        selector: 'label',
        highlight: true,
      },
      {
        id: 'cursus_formation_I',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_I`),
        selector: 'cursus_formation_I',
      },
      {
        id: 'cursus_formation_II',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_II`),
        selector: 'cursus_formation_II',
      },
      {
        id: 'cursus_formation_III',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_III`),
        selector: 'cursus_formation_III',
      },
      {
        id: 'cursus_formation_IV',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_IV`),
        selector: 'cursus_formation_IV',
      },
      {
        id: 'cursus_formation_V',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_V`),
        selector: 'cursus_formation_V',
      },
      {
        id: 'cursus_formation_Vbis',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_Vbis`),
        selector: 'cursus_formation_Vbis',
      },
      {
        id: 'cursus_formation_VI',
        title: t(`statistics.bilanFormationPlanBeneficiaryCount.formations.cursus_formation_VI`),
        selector: 'cursus_formation_VI',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanFormationPlanBeneficiaryCount.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.bilanFormationPlanBeneficiaryCount, 'label')
      const defaultData = {
        cursus_formation_I: 0,
        cursus_formation_II: 0,
        cursus_formation_III: 0,
        cursus_formation_IV: 0,
        cursus_formation_V: 0,
        cursus_formation_Vbis: 0,
        cursus_formation_VI: 0,
      }

      const companySizes = t('beneficiary_company_sizes')
      const companySizesData = Object.keys(companySizes).map((companySizeKey, index) => {
        let values = defaultData
        if (dataValues[companySizeKey]) {
          values = {
            ...values,
            ...dataValues[companySizeKey],
          }
        }

        return {
          ...values,
          id: `companySizeKey_${index}`,
          label: companySizes[companySizeKey],
        }
      })

      const nonCompanyActivitySectors = t('beneficiary_non_company_activity_sectors')
      const nonCompanyActivitySectorsData = Object.keys(nonCompanyActivitySectors).map(
        (nonCompanyActivitySectorKey, index) => {
          let values = defaultData
          if (dataValues[nonCompanyActivitySectorKey]) {
            values = {
              ...values,
              ...dataValues[nonCompanyActivitySectorKey],
            }
          }

          return {
            ...values,
            id: `nonCompanyActivitySectorKey_${index}`,
            label: nonCompanyActivitySectors[nonCompanyActivitySectorKey],
          }
        },
      )

      data = companySizesData.concat(nonCompanyActivitySectorsData)
    }

    return (
      <StatsTable
        className="collapseTable"
        title={t('statistics.bilanFormationPlanBeneficiaryCount.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeFormationPlan = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: t('statistics.bilanFormationPlan.label'),
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanFormationPlan.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanFormationPlan.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanFormationPlan.hour_count'),
        selector: 'hour_count',
      },
      {
        id: 'men',
        title: t('statistics.bilanFormationPlan.men'),
        selector: 'men',
      },
      {
        id: 'women',
        title: t('statistics.bilanFormationPlan.women'),
        selector: 'women',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanFormationPlan.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.bilanFormationPlan, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
        men: 0,
        women: 0,
      }

      const companySizes = t('beneficiary_company_sizes')
      const companySizesData = Object.keys(companySizes).map((companySizeKey, index) => {
        let values = defaultData
        if (dataValues[companySizeKey]) {
          values = {
            ...values,
            ...dataValues[companySizeKey],
          }
        }

        return {
          ...values,
          id: `companySizeKey_${index}`,
          label: companySizes[companySizeKey],
        }
      })

      const nonCompanyActivitySectors = t('beneficiary_non_company_activity_sectors')
      const nonCompanyActivitySectorsData = Object.keys(nonCompanyActivitySectors).map(
        (nonCompanyActivitySectorKey, index) => {
          let values = defaultData
          if (dataValues[nonCompanyActivitySectorKey]) {
            values = {
              ...values,
              ...dataValues[nonCompanyActivitySectorKey],
            }
          }

          return {
            ...values,
            id: `nonCompanyActivitySectorKey_${index}`,
            label: nonCompanyActivitySectors[nonCompanyActivitySectorKey],
          }
        },
      )

      data = companySizesData.concat(nonCompanyActivitySectorsData)
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={t('statistics.bilanFormationPlan.title')}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeFormationPlanBeneficiaryCount()}
      </>
    )
  }

  renderAppraisalTypeLeaveOfAbsenceByOpacif = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanLeaveOfAbsence.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanLeaveOfAbsence.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanLeaveOfAbsence.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsenceByOpacif.length > 0) {
      const opacifTypes = t('beneficiary_financer_opacif_types')
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsenceByOpacif, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(opacifTypes).map((opacifTypeKey, index) => {
        let values = defaultData
        if (dataValues[opacifTypeKey]) {
          values = {
            ...values,
            ...dataValues[opacifTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: opacifTypes[opacifTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={t('statistics.bilanLeaveOfAbsenceByOpacif.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeLeaveOfAbsenceByFormation = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanLeaveOfAbsence.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanLeaveOfAbsence.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanLeaveOfAbsence.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsenceByFormation.length > 0) {
      const beneficiaryFormations = t(
        'statistics.bilanLeaveOfAbsenceByFormation.beneficiary_formations',
      )
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsenceByFormation, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey, index) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            ...dataValues[beneficiaryFormationKey],
          }
        }

        return {
          ...values,
          id: index,
          label: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={t('statistics.bilanLeaveOfAbsenceByFormation.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeLeaveOfAbsence = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanLeaveOfAbsence.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanLeaveOfAbsence.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanLeaveOfAbsence.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsence.length > 1) {
      const bilanLeaveOfAbsenceLabels = t('statistics.bilanLeaveOfAbsence.labels')
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsence, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(bilanLeaveOfAbsenceLabels).map((bilanLeaveOfAbsenceLabelKey, index) => {
        let values = defaultData
        if (dataValues[bilanLeaveOfAbsenceLabelKey]) {
          values = {
            ...values,
            ...dataValues[bilanLeaveOfAbsenceLabelKey],
          }
        }

        return {
          ...values,
          id: index,
          label: bilanLeaveOfAbsenceLabels[bilanLeaveOfAbsenceLabelKey],
        }
      })
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={t('statistics.bilanLeaveOfAbsence.title')}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeLeaveOfAbsenceByOpacif()}
        {this.renderAppraisalTypeLeaveOfAbsenceByFormation()}
      </>
    )
  }

  renderAppraisalTypeCPFByFormation = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanCPF.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanCPF.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanCPF.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanCPFByFormation.length > 0) {
      const beneficiaryFormations = t('statistics.bilanCPFByFormation.beneficiary_formations')
      const dataValues = _keyBy(managerOfficeStats.bilanCPFByFormation, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey, index) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            ...dataValues[beneficiaryFormationKey],
          }
        }

        return {
          ...values,
          id: index,
          label: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={t('statistics.bilanCPFByFormation.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeCPF = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: (item) => t(`statistics.bilanCPF.labels.${item.label}`),
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: t('statistics.bilanCPF.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanCPF.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanCPF.hour_count'),
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanCPF.length > 1) {
      const bilanCPFLabels = t('statistics.bilanCPF.labels')
      const dataValues = _keyBy(managerOfficeStats.bilanCPF, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(bilanCPFLabels).map((bilanCPFLabelKey, index) => {
        let values = defaultData
        if (dataValues[bilanCPFLabelKey]) {
          values = {
            ...values,
            ...dataValues[bilanCPFLabelKey],
          }
        }

        return {
          ...values,
          id: index,
          label: bilanCPFLabels[bilanCPFLabelKey],
        }
      })
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={t('statistics.bilanCPF.title')}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeCPFByFormation()}
      </>
    )
  }

  renderAppraisalTypeIndividual = () => {
    const { t, managerOfficeStats } = this.props

    const columns = [
      {
        id: 'tax_excluded',
        title: t('statistics.bilanIndividual.tax_excluded'),
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: t('statistics.bilanIndividual.accompaniment_count'),
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: t('statistics.bilanIndividual.hour_count'),
        selector: 'hour_count',
      },
    ]

    const defaultData = {
      tax_excluded: 0,
      accompaniment_count: 0,
      hour_count: 0,
    }

    let data = []
    if (managerOfficeStats.bilanIndividual.length > 0) {
      data = managerOfficeStats.bilanIndividual.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return (
      <StatsTable
        className="statsTable"
        title={t('statistics.bilanIndividual.title')}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className="statistics-section">
        <SectionTitle label={t('statistics.appraisal_types_statistics')} />
        {this.renderAppraisalTypePoleEmploi()}
        {this.renderAppraisalTypeFormationPlan()}
        {this.renderAppraisalTypeCPF()}
        {this.renderAppraisalTypeIndividual()}
      </div>
    )
  }
}
